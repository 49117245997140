import React, { Component } from 'react';

import api from "services/api";

import { clear } from 'services/storage'
import { logout, getToken, getRenewToken } from 'services/auth'

import { Loader, Grid, Header, Image, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom'

import "./styles.css"

import { text, html } from 'services/locales'
import { Helmet } from 'react-helmet'

export default class Logout extends Component {

    state = {
        isMobileDevice: false
    }

    componentDidMount() {

        const authResult = new URLSearchParams(window.location.search);
        const reason = authResult.get('reason');

        window.addEventListener('resize', this.handleWindowSizeChange);
        this.handleWindowSizeChange();

        if (getToken()) {
            const axiosConfig = {
                headers: {
                    'token': getToken(),
                    'removeAuthorization': true
                }
            };
            api.delete("/logoff", axiosConfig)
        } else if (getRenewToken()) {
            const axiosConfig = {
                headers: {
                    'token': getRenewToken(),
                    'removeAuthorization': true
                }
            };
            api.delete("/logoff", axiosConfig)
        }

        logout()
        clear()

        setTimeout(function () {
            window.location.href = "/siscare/login" + (reason ? ("?logout=" + reason) : "");
        }, 1000)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        const isMobileDevice = window.innerWidth < 500;
        const { sidebarVisible } = this.state;
        if (isMobileDevice && sidebarVisible) {
            this.handleSidebar();
        }
        this.setState({
            isMobileDevice: isMobileDevice
        })
    };


    render() {
        const { isMobileDevice } = this.state;
        return (

            <div className="fundo-logout" style={{
                backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/bg.png)",
            }}>

                <Helmet title={`Logout ${text("geral.html-title")}`} />

                <Grid padded style={{ height: '100vh', width: '100vw' }} textAlign='center' verticalAlign='middle'>

                    <Grid.Column style={{ background: '#FFFFFF', width: isMobileDevice ? '100vw' : '400px', }}>

                        <Header as="h1" icon >

                            <Image src={process.env.PUBLIC_URL + '/images/sis-care-icon.png'} />

                            <Header.Content>
                                {process.env.REACT_APP_NAME}
                                <Header.Subheader style={{ fontSize: "12px" }}>
                                    {process.env.REACT_APP_DESCRIPTION}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>

                        <Divider />

                        <br />

                        <Loader active inline='centered' />
                        {text("logout.encerrando")}

                        <br />
                        <br />

                        <small>
                            {text("logout.voltar-login-descricao")}&nbsp;
                            <Link
                                to="/login">
                                {text("logout.clique-aqui-login")}
                            </Link>
                        </small>

                        <Divider />

                        <small>
                            <b>Powered by &copy;Hidra</b>
                            <br />
                            {html("geral.direitos1")}.
                            <br />
                            {html("geral.direitos2")}.
                        </small>

                    </Grid.Column>

                </Grid>

            </div>
        );
    }
}