import React, { Component } from 'react';

import { Segment, Header, List } from 'semantic-ui-react'

import api from 'services/api';
import { text } from 'services/locales';

export default class EntidadesContatos extends Component {

    state = {
        itens: null
    }

    componentDidMount() {
        this.loadItens();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id || prevProps.refresh !== this.props.refresh) {
            this.loadItens();
        }
    }

    loadItens = () => {
        const { id } = this.props;

        if (id) {
            api.get("/hidra/entidades/" + id + "/contatos")
                .then((response) =>
                    this.setState({ itens: response.data })
                )
                .catch(() =>
                    this.setState({ itens: null })
                )
        }
    }

    renderIcon(tipo) {
        switch (tipo) {
            case 'TELEFONE':
                return 'phone';
            case 'EMAIL':
                return 'mail';
            case 'SITE':
                return 'globe';
            default:
                return 'circle'
        }
    }

    renderContato(item) {
        switch (item.tipo) {
            case 'TELEFONE':
                return <a href={`tel:${item.contato}`}>{item.contato}</a>;
            case 'EMAIL':
                return <a href={`mailto:${item.contato}`}>{item.contato}</a>;
            case 'SITE':
                return <a target='_blank' rel="noopener noreferrer" href={`${item.contato}`}>{item.contato}</a>;
            default:
                return item.descricao
        }
    }

    render() {

        const { itens } = this.state;
        const { inverted, header } = this.props

        return (
            <>

                {itens && itens.length > 0 &&
                    <div className="box">
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {text("entidade.contatos")}
                        </Header>
                        <Segment attached inverted={inverted}>

                            <List>

                                {itens.map((item) =>
                                    <List.Item>
                                        <List.Icon name={this.renderIcon(item.tipo)} />
                                        <List.Content>{this.renderContato(item)} {item.descricao ? (`(${item.descricao})`) : ''}</List.Content>
                                    </List.Item>
                                )}

                            </List>

                        </Segment>
                    </div>
                }
            </>
        )
    }
}