import React, { Component } from 'react';

import { Button, Message, List } from 'semantic-ui-react'

import { text } from 'services/locales';
import api from 'services/api';


export default class SuporteTestesGeral extends Component {

    state = {
        loading: false,
        error: null,
        sucesses: null
    }

    enviar = url => () => {
        this.setState({ loading: true, error: null, sucesses: null });

        api.get(url)
            .then((response) => {
                if (response.data != null) {
                    this.setState({
                        loading: false,
                        sucesses: "Requisição enviada com sucesso: " + JSON.stringify(response.data)
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: text("formularios.erro_request")
                });
            });
    }

    delete = url => () => {
        this.setState({ loading: true, error: null, sucesses: null });

        api.delete(url)
            .then((response) => {
                if (response != null) {
                    this.setState({
                        loading: false,
                        sucesses: "Requisição enviada com sucesso"
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: text("formularios.erro_request")
                });
            });
    }

    ping = () => {
        this.setState({ loading: true, error: null, sucesses: null });

        api.get('/ping')
            .then((response) => {
                if (response.data != null) {
                    this.setState({
                        loading: false,
                        sucesses: "Ping ok. " + JSON.stringify(response.data)
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: text("formularios.erro_request")
                });
            });
    }



    render() {

        const { loading } = this.state;

        return (
            <>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <List divided loading={loading}>
                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="blue"
                                onClick={this.ping}
                            >
                                Ping
                            </Button>
                        </List.Content>
                        <List.Content><b>Ping</b></List.Content>
                        <List.Description> Testa a disponibilidade do back-end</List.Description>
                    </List.Item>
                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="red"
                                onClick={this.delete('/historico/acompanhamentos')}
                            >
                                Remover
                            </Button>
                        </List.Content>
                        <List.Content><b> Remover todos os históricos</b></List.Content>
                        <List.Description>  Remove todos os históricos do serviço de histórico</List.Description>
                    </List.Item>
                </List>

            </>

        );
    }

}