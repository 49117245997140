import { Component } from 'react';

//Limpa o sorage local e redireciona para o inicio
export default class Reset extends Component {

    render() {
        localStorage.clear()
        window.location.href = "/siscare/login";
        return null
    }
}