import React, { Component } from 'react';

import { Button, Message, List } from 'semantic-ui-react'

import { text } from 'services/locales';
import api from 'services/api';


export default class SuporteTestesAcos extends Component {

    state = {
        loading: false,
        error: null,
        sucesses: null
    }

    enviar = url => () => {
        this.setState({ loading: true, error: null, sucesses: null });

        api.get(url)
            .then((response) => {
                if (response.data != null) {
                    this.setState({
                        loading: false,
                        sucesses: "Requisição enviada com sucesso: " + JSON.stringify(response.data)
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: text("formularios.erro_request")
                });
            });
    }

    delete = url => () => {
        this.setState({ loading: true, error: null, sucesses: null });

        api.delete(url)
            .then((response) => {
                if (response != null) {
                    this.setState({
                        loading: false,
                        sucesses: "Requisição enviada com sucesso"
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: text("formularios.erro_request")
                });
            });
    }

    render() {

        const { loading } = this.state;

        return (
            <>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <List divided loading={loading}>

                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="blue"
                                onClick={this.enviar('/objetos/acompanhamentos/testes/1')}
                            >
                                Criar
                            </Button>
                        </List.Content>
                        <List.Content><b>Criar acos de teste #1</b></List.Content>
                        <List.Description>Cria varios acompanhamentos com origens e hostilidades diferentes</List.Description>
                    </List.Item>

                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="blue"
                                onClick={this.enviar('/objetos/acompanhamentos/testes/2')}
                            >
                                Criar
                            </Button>
                        </List.Content>
                        <List.Content><b>Criar acos de teste #2</b></List.Content>
                        <List.Description> Cria acompanhamentos de todas as origens, sem classificação</List.Description>
                    </List.Item>

                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="blue"
                                onClick={this.enviar('/objetos/acompanhamentos/testes/fusao1')}
                            >
                                Criar
                            </Button>
                        </List.Content>
                        <List.Content><b>Criar acos para fusão #1</b></List.Content>
                        <List.Description> Cria acompanhamentos que podem ser fundidos pela sua identificacao</List.Description>
                    </List.Item>

                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="blue"
                                onClick={this.enviar('/objetos/acompanhamentos/testes/fusao2')}
                            >
                                Criar
                            </Button>
                        </List.Content>
                        <List.Content><b>Criar acos para fusão #2</b></List.Content>
                        <List.Description> Cria acompanhamentos que podem ser fundidos pela sua cinematica</List.Description>
                    </List.Item>

                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="blue"
                                onClick={this.enviar('/objetos/acompanhamentos/testes/sem-cinematica')}
                            >
                                Criar
                            </Button>
                        </List.Content>
                        <List.Content><b>Criar acos sem cinemática</b></List.Content>
                        <List.Description> Cria acompanhamentos sem cineática (posição, rumo e velocidade)</List.Description>
                    </List.Item>

                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="red"
                                onClick={this.delete('/objetos/objetos')}
                            >
                                Remover
                            </Button>
                        </List.Content>
                        <List.Content><b>Remover todos os objetos</b></List.Content>
                        <List.Description>Remove todos os objetos do serviço de objetos</List.Description>
                    </List.Item>

                </List>

            </>

        );
    }

}