import React, { Component } from 'react';

import { Segment, Header, Divider, Statistic } from 'semantic-ui-react'

import { text } from 'services/locales';
import { convertDMS } from 'util/functions'
import { nosParaQuilometrosHora } from 'util/conversores'

export default class CinematicaCompleta extends Component {

    render() {

        const { item, inverted, header } = this.props

        const cinematica = item ? item.cinematica : null;
        const ambiente = item ? item.ambiente : null

        return (
            <>
                {cinematica &&
                    <div className="box">

                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {text("acompanhamento.cinematica")}
                        </Header>
                        <Segment inverted={inverted} attached textAlign="center">

                            {!isNaN(cinematica.posicao.geo.lat) && !isNaN(cinematica.posicao.geo.lng) &&
                                <>
                                    <Statistic inverted={inverted} size="tiny" label={text("acompanhamento.posicao")} value={convertDMS(cinematica.posicao.geo.lat, cinematica.posicao.geo.lng)} />
                                    <br />
                                </>
                            }

                            {!isNaN(cinematica.posicao.precisao) && cinematica.posicao.precisao !== 0 &&
                                <>
                                    <Statistic inverted={inverted} size="tiny" label={text("acompanhamento.precisao")} value={Number(cinematica.posicao.precisao).toFixed(1) + "m"} />
                                    <br />
                                </>
                            }

                            <Divider />

                            {ambiente && (ambiente === "SUPTERRA") ? (
                                <>

                                    <Statistic.Group inverted={inverted} widths='three' size="tiny">
                                        {!isNaN(cinematica.rumo.fundo) &&
                                            <Statistic label={text("cinematica.sentido")} value={Number(cinematica.rumo.fundo).toFixed(1) + "°"} />
                                        }

                                        {!isNaN(cinematica.velocidade.fundo) &&
                                            <Statistic label={text("cinematica.velocidade")} value={Number(nosParaQuilometrosHora(cinematica.velocidade.fundo)).toFixed(1) + " " + text("cinematica.kmh")} />
                                        }
                                

                                    {!isNaN(cinematica.altitude) &&
                                        <>
                                            
                                            <Statistic inverted={inverted} size="tiny" label={text("cinematica.altitude")} value={Number(cinematica.altitude).toFixed(1) + "m"} />
                                        </>
                                    }
                                        </Statistic.Group>

                                </>
                            ) :
                                (
                                    <>
                                        <Statistic.Group inverted={inverted} widths='two' size="tiny">
                                            {!isNaN(cinematica.rumo.fundo) &&
                                                <Statistic label={text("acompanhamento.rumo-fundo")} value={Number(cinematica.rumo.fundo).toFixed(1) + "°"} />
                                            }

                                            {!isNaN(cinematica.velocidade.fundo) &&
                                                <Statistic label={text("acompanhamento.velocidade-fundo")} value={Number(cinematica.velocidade.fundo).toFixed(1) + " " + text("cinematica.nos")} />
                                            }
                                        </Statistic.Group>

                                        <Statistic.Group inverted={inverted} widths='two' size="tiny">
                                            {!isNaN(cinematica.rumo.sup) &&
                                                <Statistic label={text("acompanhamento.rumo-superficie")} value={Number(cinematica.rumo.sup).toFixed(1) + "°"} />
                                            }

                                            {!isNaN(cinematica.velocidade.sup) &&
                                                <Statistic label={text("acompanhamento.velocidade-superficie")} value={Number(cinematica.velocidade.sup).toFixed(1) + " " + text("cinematica.nos")} />
                                            }
                                        </Statistic.Group>

                                        {!isNaN(cinematica.taxaManobra) &&
                                            <>
                                                <Statistic inverted={inverted} size="tiny" label={text("acompanhamento.taxa-manobra")} value={Number(cinematica.taxaManobra).toFixed(1)} />
                                                <br />
                                            </>
                                        }

                                        {!isNaN(cinematica.altitude) && cinematica.altitude !== 0 &&
                                            <>
                                                <Divider />
                                                <Statistic inverted={inverted} size="tiny" label={cinematica.altitude >= 0 ? text("acompanhamento.altitude") : text("acompanhamento.profundidade")} value={Number(cinematica.altitude).toFixed(1) + "m"} />
                                            </>
                                        }
                                    </>
                                )
                            }

                            <br />

                        </Segment>
                    </div>
                }
            </>
        )
    }
}