import React, { Component } from 'react';

import { Segment, Header, Button, Icon, Divider, Message, Modal, Form, TextArea, Label } from 'semantic-ui-react'
import api from 'services/api';
import { text } from 'services/locales';
import { isDev } from 'util/functions'
import { isCoord } from 'services/auth'
import MicRecorder from 'mic-recorder-to-mp3';
import ReactAudioPlayer from 'react-audio-player';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default class AcoesEvento extends Component {

    state = {
        showConfirmDialog: false,
        showAudioDialog: false,
        actionToBeConfirmed: null,
        comentario: null,
        file: null,
        sending: false,
        isRecording: false,
        segundos: 0,
        minutos: 0
    }

    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    reenviarNotificacao() {
        if (this.props.evento) {
            this.setState({
                sending: true,
                error: null,
                sucesses: null
            });
            const { evento } = this.props;
            api.put("/eventos/" + evento.id + "/acoes/notificar")
                .then(() => {
                    this.setState({
                        sending: false,
                        sucesses: text("eventos.notificacao-reenviada")
                    });
                })
                .catch(error => {
                    var mensagemErro = text("formularios.erro_request");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    }
                    this.setState({
                        sending: false,
                        error: mensagemErro
                    });
                });
        }
    }

    handleEnviar() {
        if (this.props.evento) {
            this.setState({
                sending: true,
                error: null,
                sucesses: null
            });

            const { evento } = this.props;
            const { actionToBeConfirmed, comentario, file } = this.state;

            var url;
            var sucesses;

            switch (actionToBeConfirmed) {
                case 'reconhecer':
                    url = "/eventos/" + evento.id + "/acoes/reconhecer";
                    sucesses = text("eventos.evento-reconhecido");
                    break;
                case 'encerrar':
                    url = "/eventos/" + evento.id + "/acoes/encerrar";
                    sucesses = text("eventos.evento-encerrado");
                    break;
                case 'comentar':
                    url = "/eventos/" + evento.id + "/acoes/comentar";
                    sucesses = text("eventos.evento-comentado");
                    break;
                case 'enviar-audio':
                    url = "/eventos/" + evento.id + "/acoes/enviar-audio";
                    sucesses = text("eventos.evento-audio-enviado");
                    break;
                default:
                    url = null;
                    break;
            }

            if (url) {

                var bodyFormData = new FormData();
                if (comentario) bodyFormData.append('text', comentario);
                if (file) bodyFormData.append('file', file);

                api({
                    method: 'post',
                    url: url,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                    .then(() => {
                        this.setState({
                            sending: false,
                            sucesses: sucesses,
                            showConfirmDialog: false,
                            showAudioDialog: false,
                            actionToBeConfirmed: null,
                            comentario: null,
                            error: null,
                            file: null,
                            fileName: null,
                            audioName: null
                        });
                    })
                    .catch(error => {
                        var mensagemErro = text("formularios.erro_request");
                        if (error.response && error.response.data) {
                            mensagemErro = error.response.data.message;
                        }
                        this.setState({
                            sending: false,
                            error: mensagemErro
                        });
                    });
            }
        }
    }

    handleFileSelect = (e) => {
        if (e && e.target && e.target.files && e.target.files.length > 0)
            this.setState({ fileName: e.target.files[0].name, file: e.target.files[0] });
    }

    handleShowDialog = (e, { action }) => {
        this.setState(
            {
                showConfirmDialog: true,
                actionToBeConfirmed: action,
                sucesses: null,
                error: null,
                comentario: null,
                file: null,
                fileName: null,
                audioName: null
            }
        )
    }

    handleShowAudioDialog = (e, { action }) => {
        this.setState(
            {
                showAudioDialog: true,
                actionToBeConfirmed: action,
                sucesses: null,
                error: null,
                comentario: null,
                file: null,
                fileName: null,
                audioName: null
            }
        )
    }

    renderModalTitle = () => {
        switch (this.state.actionToBeConfirmed) {
            case 'reconhecer':
                return text("eventos.confirmar-acao-reconhecer");
            case 'encerrar':
                return text("eventos.confirmar-acao-encerrar");
            case 'comentar':
                return text("eventos.confirmar-acao-comentar");
            default:
                return null;
        }
    }

    handleCancelAction = () => {
        this.setState({ showConfirmDialog: false, showAudioDialog: false, actionToBeConfirmed: null, comentario: null, error: null, file: null, fileName: null, audioName: null })
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    // handleSendAudio = file => {
    //     const { evento } = this.props
    //     this.setState({
    //         sending: true,
    //         error: null
    //     });
    //     const bodyFormData = new FormData();
    //     bodyFormData.append('file', file);

    //     api({
    //         method: 'post',
    //         url: "/eventos/" + evento.id + "/acoes/comentar",
    //         data: bodyFormData,
    //         headers: { 'Content-Type': 'multipart/form-data' }
    //     })
    //         .then(response => {
    //             this.setState({
    //                 sending: false,
    //                 error: null,
    //                 mensagem: '',
    //                 isRecording: false,
    //                 showConfirmDialog: false
    //             });
    //         })
    //         .catch(error => {
    //             var mensagemErro = text("chat.erro");
    //             if (error.response && error.response.data) {
    //                 mensagemErro = error.response.data.message;
    //             }
    //             this.setState({
    //                 sending: false,
    //                 error: mensagemErro,
    //             });
    //         });
    // }

    getSegundos() {
        return ('0' + this.state.segundos % 60).slice(-2)
    }

    getMinutos() {
        return Math.floor(this.state.segundos / 60)
    }

    start = () => {
        var _this = this
        navigator.getMedia = (navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);


        navigator.getMedia({ audio: true },
            () => {
                Mp3Recorder
                    .start()
                    .then(() => {
                        this.incrementer = setInterval(function () {
                            _this.setState({
                                segundos: (_this.state.segundos + 1)
                            })
                        }, 1000)
                        this.setState({ isRecording: true });
                    }).catch((e) => console.error(e));
            },
            () => {
                this.setState({ error: text("anexo.erro-gravacao") })
            },
        );
    }

    stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                var nomeArquivo = Math.random().toString(36).slice(-10) + '.mp3'
                var arquivo = new File(
                    [blob],
                    nomeArquivo,
                    { lastModified: Date.now() }
                );
                clearInterval(this.incrementer)
                // this.handleSendAudio(arquivo)
                this.setState({ file: arquivo, audioName: arquivo.name, showAudioDialog: true, isRecording: false, segundos: 0, minutos: 0 });
            })
    }


    render() {

        const { sending } = this.state
        const { evento, inverted, header, compact } = this.props

        return (
            <div className="box">
                <Modal
                    open={this.state.showConfirmDialog}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    centered={false}
                >
                    <Header as="h1">{this.renderModalTitle()}</Header>
                    <Modal.Content >
                        {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                        <Form loading={sending}>
                            <b>{text("eventos.comentario")} {this.state.actionToBeConfirmed === 'comentar' ? '' : (<>({text("geral.opcional")})</>)}</b>
                            <TextArea
                                placeholder={text("eventos.comentario-placeholder")}
                                rows={3}
                                name='comentario'
                                value={this.state.comentario?this.state.comentario:""}
                                onChange={this.handleChange}
                            />
                        </Form>
                        <br />

                        <Button
                            loading={sending}
                            disabled={sending}
                            icon
                            labelPosition="left"
                            onClick={() => this.fileInputRef.current.click()}
                        >
                            <Icon name="paperclip" /> {text("anexo.enviar-arquivo")}
                        </Button>
                        &nbsp;{this.state.fileName}
                        <input
                            ref={this.fileInputRef}
                            type="file"
                            hidden
                            onChange={this.handleFileSelect}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            loading={sending}
                            disabled={sending}
                            size="big"
                            onClick={this.handleCancelAction}
                            icon labelPosition='left'
                        >
                            <Icon name='close' /> {text("geral.cancelar")}
                        </Button>
                        <Button
                            loading={sending}
                            disabled={sending}
                            size="big"
                            color='blue'
                            onClick={this.handleEnviar.bind(this)}
                            icon labelPosition='right'
                        >
                            <Icon name='angle double right' /> {text("formularios.enviar")}
                        </Button>
                    </Modal.Actions>
                </Modal>

                {/* NOVO MODAL */}
                <Modal
                    open={this.state.showAudioDialog}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    centered={false}
                >
                    <Header as="h1">{!this.state.audioName ? text('anexo.enviar-audio') : text('anexo.enviar-gravacao')}</Header>
                    <Modal.Content >
                        {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                        {this.state.audioName &&
                            <ReactAudioPlayer
                                src={window.URL.createObjectURL(this.state.file)}
                                controls
                                style={{ width: '100%' }}
                                autoplay
                                controlsList="nodownload"
                            />
                        }

                        {!this.state.audioName &&
                            <Button
                                onClick={!this.state.isRecording ? this.start : this.stop}
                                disabled={sending}
                                loading={sending}
                                color={this.state.isRecording ? "red" : undefined}
                                labelPosition="left"
                                icon
                            >
                                <Icon name={!this.state.isRecording ? "microphone" : "stop"} />
                                {!this.state.isRecording ? text("anexo.gravar-audio") : text('anexo.parar-gravacao')}
                            </Button>
                        }

                        {this.state.isRecording &&
                            <>
                                &nbsp;

                                        <Label>
                                    {this.getMinutos()}:{this.getSegundos()}
                                </Label>

                                        &nbsp;&nbsp;
                                    </>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            loading={sending}
                            disabled={sending}
                            size="big"
                            onClick={this.handleCancelAction}
                            icon labelPosition='left'
                        >
                            <Icon name='close' /> {text("geral.cancelar")}
                        </Button>
                        <Button
                            loading={sending}
                            disabled={sending}
                            size="big"
                            color='blue'
                            onClick={this.handleEnviar.bind(this)}
                            icon labelPosition='right'
                        >
                            <Icon name='angle double right' /> {text("formularios.enviar")}
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                    {text("eventos.acoes")}
                </Header>
                <Segment attached inverted={inverted}>

                    {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                    {isCoord() && evento && (evento.estado.codigo === 'ativo' || evento.estado.codigo === 'reconhecido') &&
                        <>
                            <Button
                                fluid
                                size={compact ? "medium" : "big"}
                                color="yellow"
                                onClick={this.handleShowDialog}
                                action="reconhecer"
                                loading={sending}
                                icon labelPosition='left'
                                inverted={inverted}
                                compact={compact}
                            >
                                <Icon name='check' />
                                {text("eventos.reconhecer")}
                            </Button>
                            {!compact ? (<br />) : ('')}
                        </>
                    }

                    {isCoord() && evento && evento.estado.codigo === 'reconhecido' &&
                        <>
                            <Button
                                fluid
                                color="red"
                                size={compact ? "medium" : "big"}
                                loading={sending}
                                onClick={this.handleShowDialog}
                                action="encerrar"
                                icon labelPosition='left'
                                inverted={inverted}
                                compact={compact}
                            >
                                <Icon name='close' />
                                {text("eventos.encerrar")}
                            </Button>
                            {!compact ? (<br />) : ('')}
                        </>
                    }

                    {isDev() && evento && (evento.estado.codigo === 'ativo' || evento.estado.codigo === 'reconhecido') &&
                        <>
                            <Divider />
                            <Button
                                fluid
                                color="gray"
                                onClick={this.reenviarNotificacao.bind(this)}
                                loading={sending}
                                icon labelPosition='left'
                                inverted={inverted}
                                compact={compact}
                            >
                                <Icon name='alarm' />
                                {text("eventos.reenviar-notificacao")}
                            </Button>
                            {!compact ? (<br />) : ('')}
                        </>
                    }

                    <Button
                        fluid
                        color="blue"
                        onClick={this.handleShowDialog}
                        action="comentar"
                        loading={sending}
                        icon labelPosition='left'
                        inverted={inverted}
                        compact={compact}
                    >
                        <Icon name='mail' />
                        {text("eventos.enviar-comentario")}
                    </Button>
                    <br />
                    <Button
                        fluid
                        onClick={this.handleShowAudioDialog}
                        action="enviar-audio"
                        color="teal"
                        loading={sending}
                        icon labelPosition='left'
                        inverted={inverted}
                        compact={compact}
                    >
                        <Icon name={"microphone"} />
                        {text("anexo.enviar-audio")}
                    </Button>

                </Segment>

            </div>
        )
    }
}