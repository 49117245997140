import React, { Component } from 'react';

import { Header, Container, Image } from 'semantic-ui-react'

import { Link } from 'react-router-dom';
import Footer from 'components/footer'
import { text } from 'services/locales'
import "./styles.css";
import { Helmet } from 'react-helmet'

export default class Error extends Component {
  render() {

    const { footer } = this.props;

    return (

      <Container style={{ paddingTop: '2em' }}>

        <Helmet title={`${text("erro_page.404")} ${text("geral.html-title")}`} />

        <Header as="h1" >
          {text('erro_page.404')}
          <Header.Subheader>{text('erro_page.404-descricao')}. <Link to="/">{text('erro_page.voltar')}.</Link></Header.Subheader>
        </Header>

        <br />

        <Image size="huge" rounded src={process.env.PUBLIC_URL + "/images/404.jpg"} centered />

        {footer === true &&
          <Footer />
        }

      </Container>

    );
  }

}