import React, { Component } from 'react';

import { Segment, Header, List, Icon } from 'semantic-ui-react'

import api from 'services/api';
import { text } from 'services/locales';
import { Link } from 'react-router-dom'

export default class FilhosLocal extends Component {

    state = {
        filhos: null
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id || prevProps.refresh !== this.props.refresh) {
            this.loadItens();
        }
    }

    componentDidMount() {
        this.loadItens();
    }

    loadItens = () => {
        const { id } = this.props;

        if (id) {
            var url = "/hidra/locais/" + id + "/filhos?size=99";

            api.get(url)
                .then((response) =>
                    this.setState({ filhos: response.data.content })
                )
                .catch(() =>
                    this.setState({ filhos: null })
                )
        }

    }

    render() {

        const { filhos } = this.state;
        const { inverted, header, pai } = this.props

        return (
            <>
                {(pai || (filhos && filhos.length > 0)) &&
                    <div className="box">
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {text("local.hierarquia")}
                        </Header>
                        <Segment attached inverted={inverted} style={{ overflow: 'auto', maxHeight: "350px", padding: "5px" }} >
                            <List divided relaxed inverted={inverted} selection >

                                {pai &&
                                    <List.Item title={text("local.pai")} key={pai.id} as={Link} to={'/local/' + pai.id}>
                                        <List.Content floated='right'>
                                            <Icon name="arrow up" />
                                        </List.Content>
                                        <Icon
                                            name={pai.tipo.simbolo ? pai.tipo.simbolo : 'map marker alternate'}
                                            size="large"
                                            style={{color: pai.tipo.cor ? pai.tipo.cor : '#808080'}}
                                        />
                                        <List.Content >
                                            <List.Header>{pai.sigla}</List.Header>
                                            <List.Description><small>{pai.nome}</small></List.Description>
                                        </List.Content>
                                    </List.Item>
                                }

                                {filhos && filhos.length > 0 && filhos.map((item) =>
                                    <List.Item title={text("local.filho")} key={item.id} as={Link} to={'/local/' + item.id}>
                                        <List.Content floated='right'>
                                            <Icon name="arrow down" />

                                        </List.Content>
                                        <Icon
                                            name={item.tipo.simbolo ? item.tipo.simbolo : 'map marker alternate'}
                                            style={{color: item.tipo.cor ? item.tipo.cor : '#808080'}}
                                            size="large"
                                        />

                                        <List.Content >
                                            <List.Header>{item.sigla}</List.Header>
                                            <List.Description><small>{item.nome}</small></List.Description>
                                        </List.Content>

                                    </List.Item>
                                )}
                            </List>
                        </Segment>
                    </div>
                }
            </>
        )
    }
}