import moment from 'moment';

var serverTime = moment().utc()

export const setServerTime = (time) => {
    serverTime = moment(time)
}

export const getServerTime = () =>{
    return serverTime
}
