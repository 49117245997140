import React, { Component } from 'react';

import { Button, Form, Header, Icon, Message, Divider, Container, Grid, Dropdown } from 'semantic-ui-react'
import { Formik } from "formik";
import api from 'services/api';
import { text } from 'services/locales';
import { Link, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'


export default class AdminLocalFormulario extends Component {

    state = {
        redirect: false,
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true,
        simbolos: [],
    };


    componentDidMount() {

        this.setState({ loading: true });

        if (this.props.match.params.id) {
            const { id } = this.props.match.params;
            api.get("/hidra/locais/tipos/" + id)
                .then((response) => {
                    this.setState({
                        item: response.data,
                        simbolo: response.data.simbolo
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error: text("formularios.erro_request")
                    });
                });
        }
        api.get("/hidra/icones/all")
            .then((response) => {
                const simbolos = response.data
                .sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))
                .map((item) => ({
                    key: item.id,
                    text: item.nome,
                    value: item.icone,
                    icon: item.icone
                }))
                this.setState({ loading: false, simbolos: simbolos });

            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: text("formularios.erro_request")
                });
            });
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    render() {

        const { item, loading, submitting, redirect, sucesses } = this.state;
        const { id } = this.props.match.params;

        if (redirect === true) {
            return <Redirect to={{
                pathname: '/admin/tipo-locais',
                state: { sucesses: sucesses }
            }}
            />
        }

        return (
            <Container style={{ padding: '2em', backgroundColor: "#fff" }}>

                <Helmet title={`
                ${id ?
                        (
                            text("tipo-local.atualizar-tipo") + " '" + (item ? item.nome : '') + "'"
                        )
                        :
                        text("tipo-local.novo-tipo")
                    } ${text("geral.html-title")}`} />

                <Formik
                    initialValues={{
                        nome: item ? item.nome : '',
                        simbolo: item ? item.simbolo : '',
                        cor: item ? item.cor : '#FF0000'
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        this.setState({ error: null, submitting: true, sucesses: null });

                        if (item) { //editando um item
                            api.put('/hidra/locais/tipos/' + item.id,
                                {
                                    "nome": values.nome,
                                    "cor": values.cor,
                                    "simbolo": this.state.simbolo
                                }
                            )
                                .then((response) => {
                                    if (response.data.error != null) {
                                        this.setState({ submitting: false, error: response.data });
                                    } else {
                                        this.setState({ submitting: false, error: null, sucesses: text("tipo-local.atualizado-sucesso"), redirect: true });
                                    }
                                })
                                .catch((error) => {
                                    var mensagemErro = text("formularios.erro");
                                    if (error.response && error.response.data.message) {
                                        mensagemErro = error.response.data.message;
                                    } else if (error.request) {
                                        mensagemErro = text("formularios.erro_request")
                                    }
                                    this.setState({
                                        submitting: false,
                                        error: mensagemErro
                                    });
                                });
                        } else { // novo item
                            api.post('/hidra/locais/tipos',
                                {
                                    "nome": values.nome,
                                    "cor": values.cor,
                                    "simbolo": this.state.simbolo
                                }
                            )
                                .then((response) => {
                                    if (response.data.error != null) {
                                        this.setState({ submitting: false, error: response.data });
                                        window.scrollTo(0, 0)
                                    } else {
                                        resetForm()
                                        this.setState({ submitting: false, error: null, sucesses: text("tipo-local.criado-sucesso"), redirect: true });
                                        window.scrollTo(0, 0)
                                    }
                                })
                                .catch((error) => {
                                    var mensagemErro = text("formularios.erro");
                                    if (error.response && error.response.data.message) {
                                        window.scrollTo(0, 0)
                                        mensagemErro = error.response.data.message;
                                    } else if (error.request) {
                                        window.scrollTo(0, 0)
                                        mensagemErro = text("formularios.erro_request")
                                    }
                                    this.setState({
                                        submitting: false,
                                        error: mensagemErro
                                    });
                                });
                        }
                    }}

                    render={({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (

                            <Form
                                onSubmit={handleSubmit}
                                loading={loading}
                            >

                                <Header as="h1">
                                    {id ?
                                        (
                                            text("tipo-local.atualizar-tipo") + " '" + (item ? item.nome : '') + "'"
                                        )
                                        :
                                        text("tipo-local.novo-tipo")
                                    }
                                </Header>

                                <br />
                                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}

                                <Form.Field required>
                                    <label>{text("tipo-local.nome")}</label>
                                    <Form.Input
                                        placeholder={text("tipo-local.nome")}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nome}
                                        name="nome"
                                        width={8}
                                    />
                                </Form.Field>

                                <Form.Field required style={{ width: '150px' }}>
                                    <label>{text("tipo-local.cor")}</label>
                                    <input
                                        type="color"
                                        name="cor"
                                        value={values.cor}
                                        onChange={handleChange}
                                    />
                                </Form.Field>

                                <Form.Field style={{ width: '300px' }}>
                                    <label>{text("tipo-local.icone")}
                                    &nbsp;&nbsp;&nbsp;
                                        <Icon
                                            floated='right'
                                            name={this.state.simbolo}
                                            style={{ color: values.cor }}
                                            size="large"
                                        />
                                    </label>
                                    <Dropdown
                                        placeholder={text("tipo-local.icone-descricao")}
                                        onChange={this.handleChange}
                                        value={this.state.simbolo}
                                        name="simbolo"
                                        search
                                        selection
                                        options={this.state.simbolos}
                                        width={5}
                                    />
                                </Form.Field>

                                <Divider />
                                <br />
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Button
                                            icon labelPosition='left'
                                            size='large'
                                            as={Link}
                                            to="/admin/tipo-locais"
                                            disabled={loading}
                                        >
                                            <Icon name={item ? 'cancel' : 'angle left'} /> {item ? text("formularios.cancelar") : text("formularios.voltar")}
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            primary
                                            loading={submitting}
                                            type='submit'
                                            icon labelPosition='right'
                                            size="big"
                                            disabled={loading || submitting}
                                        >
                                            <Icon name='save' /> {item ? text("formularios.atualizar") : text("formularios.salvar")}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        )
                    }
                />

            </Container >
        );
    }

}