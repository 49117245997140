import React, { Component } from 'react';

import { Button, Message, List } from 'semantic-ui-react'

import api from 'services/api';

export default class SuporteTestesEventos extends Component {

    state = {
        loading: false,
        error: null,
        sucesses: null
    }

    reconhecerTodosEventos = () => {
        this.setState({ loading: true, error: null, sucesses: null });

        var bodyFormData = new FormData();
        bodyFormData.append('text', 'Reconhecido em lote pela tela de suporte');

        api.get('/eventos?size=999&e=ATIVO')
            .then((response => {
                response.data.content.map((evento) => {
                    api({
                        method: 'post',
                        url: "/eventos/" + evento.id + "/acoes/reconhecer",
                        data: bodyFormData,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                        .then(() => { })
                        .catch(() => { })
                    return null;
                });
                this.setState({ loading: false, error: null, sucesses: "Eventos reconhecidos" });
            }))
            .catch(() => {
                this.setState({ loading: false, error: "Erro ao consultar eventos ativos", sucesses: null });
            })
    }

    encerrarTodosEventos = () => {
        this.setState({ loading: true, error: null, sucesses: null });

        var bodyFormData = new FormData();
        bodyFormData.append('text', 'Encerrado em lote pela tela de suporte');

        api.get('/eventos?size=999&e=RECONHECIDO')
            .then((response => {
                response.data.content.map((evento) => {
                    api({
                        method: 'post',
                        url: "/eventos/" + evento.id + "/acoes/encerrar",
                        data: bodyFormData,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                        .then(() => { })
                        .catch(() => { })
                    return null;
                });
                this.setState({ loading: false, error: null, sucesses: "Eventos encerrados" });
            }))
            .catch(() => {
                this.setState({ loading: false, error: "Erro ao consultar eventos reconhecidos", sucesses: null });
            })
    }

    render() {

        const { loading } = this.state;

        return (
            <>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <List divided loading={loading}>

                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="blue"
                                onClick={this.reconhecerTodosEventos}
                            >
                                Reconhecer
                            </Button>
                        </List.Content>
                        <List.Content><b>Reconhecer todos os eventos</b></List.Content>
                        <List.Description>Reconhece todos os eventos ativos</List.Description>
                    </List.Item>


                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                color="red"
                                onClick={this.encerrarTodosEventos}
                            >
                                Encerrar
                            </Button>
                        </List.Content>
                        <List.Content><b>Encerrar todos os eventos</b></List.Content>
                        <List.Description>Encerrar todos os eventos reconhecidos</List.Description>
                    </List.Item>


                </List>

            </>

        );
    }

}