import React from "react";

import { Route, Switch } from 'react-router-dom';

import Main from 'pages/main';
import CenarioTatico from 'pages/cenarioTatico/main';
import Error from 'pages/error';
import Sobre from 'pages/sobre';
import AcoTabela from 'pages/acompanhamentos';
import Acompanhamento from 'pages/acompanhamento';
import Eventos from 'pages/eventos/table';
import Evento from 'pages/eventos/item';
import NovoEvento from 'pages/eventos/form';
import Admin from 'pages/admin/layout';
import Suporte from 'pages/suporte/layout';
import Perfil from 'pages/perfil';
import Locais from 'pages/locais/table'
import Local from 'pages/locais/item'
import Chat from 'pages/chat/main'
import Usuario from 'pages/usuarios/item'
import MeusDados from 'pages/usuarios/meus-dados'
import EstacaoMeteorologica from 'pages/estacoesMeteorologicas/item'
import DocumentacaoAPI from 'pages/documentacaoAPI'

class Routes extends React.Component {

    constructor(props) {
        super(props);
        this._chat = React.createRef();
        this._events = React.createRef();
        this._event = React.createRef();
    }

    handleNewWebsocketMessage(topic, msg) {
        if (topic.includes("eventos")) {
            
            if (this._event && this._event.current && this._event.current.handleNewEvent)
                this._event.current.handleNewEvent(msg);

            if (this._events && this._events.current && this._events.current.handleNewEvent)
                this._events.current.handleNewEvent(msg);

        } else if (topic.includes("chat")) {

            if (this._chat && this._chat.current && this._chat.current.handleRXWS)
                this._chat.current.handleRXWS(msg);

        }
    }

    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/" component={Main} />
                    <Route exact path="/mapa" component={CenarioTatico} />
                    <Route exact path="/sobre" component={Sobre} />
                    <Route exact path="/eventos" render={props => <Eventos {...props} ref={this._events} />} />
                    <Route exact path="/evento/:id" render={props => <Evento {...props} ref={this._event} />} />
                    <Route exact path="/eventos/novo" component={NovoEvento} />
                    <Route exact path="/perfil" component={Perfil} />
                    <Route exact path="/chat/:user?" render={props => <Chat {...props} ref={this._chat} />} />
                    <Route exact path="/acompanhamentos/:busca?" component={AcoTabela} />
                    <Route exact path="/acompanhamento/uuid/:uuid" component={Acompanhamento} />
                    <Route exact path="/locais" component={Locais} />
                    <Route exact path="/local/:id" component={Local} />
                    <Route exact path="/estacao-meteorologica/:id" component={EstacaoMeteorologica} />
                    <Route exact path="/usuario/:login" component={Usuario} />
                    <Route exact path="/meus-dados" component={MeusDados} />
                    <Route exact path="/api-docs" component={DocumentacaoAPI} />

                    <Route path="/admin" component={Admin} />

                    <Route path="/suporte" component={Suporte} />

                    <Route path="*" render={props => <Error {...props} footer={true} />} />
                </Switch>
            </>
        );
    }
}

export default Routes;