export const corVento = (intensidade) => {

    if (intensidade > 118) return "#fe460e";
    if (intensidade > 103) return "#fe8d1d";
    if (intensidade > 89) return "#ffbd4d";
    if (intensidade > 75) return "#ffd664";
    if (intensidade > 62) return "#feed86";
    if (intensidade > 50) return "#fff3a5";
    if (intensidade > 39) return "#fefacb";
    if (intensidade > 29) return "#daf3e4";
    if (intensidade > 20) return "#b2eefa";
    if (intensidade > 12) return "#96d4fa";
    if (intensidade > 6) return "#78b7fd";
    if (intensidade > 1) return "#5999ec";

    return "#3c77dc";
}