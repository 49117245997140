import React from "react";

import { Route, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react'

import Main from '../main';
import Testes from '../testes/index.js';
import Stats from '../stats'
import Configuracoes from "../configuracoes"
import Error from 'pages/error';

import { text } from 'services/locales'
import Footer from 'components/footer'

import { isAdmin } from 'services/auth';

import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

class Routes extends React.Component {
    render() {
        return (
            <>
                {!isAdmin() &&
                    <Redirect to='/' />
                }

                <Helmet title={`${text("menu.suporte")} ${text("geral.html-title")}`} />

                <div style={{backgroundColor:'#ff0000', height:'2px'}}></div>

                <Switch>
                    <Route exact path="/suporte" component={Main} />
                    <Route exact path="/suporte/testes" component={Testes} />
                    <Route exact path="/suporte/stats" component={Stats} />
                    <Route exact path="/suporte/config" component={Configuracoes} />
                    <Route path="*" render={props => <Error {...props} footer={false} />} />
                </Switch>

                <Container  >
                    <Footer />
                </Container>

            </>
        );
    }
}

export default Routes;