import React, { Component } from 'react';

import { Header, Icon, Grid, Label, Container, Message } from 'semantic-ui-react'

import api from 'services/api';
import { text } from 'services/locales';
import { getIcon } from 'util/eventos'
import { Helmet } from 'react-helmet'
import Mapa from 'components/objetos/entidades/mapa'
import Footer from 'components/footer'

import InfoEvento from 'components/objetos/eventos/info'
import Acoes from 'components/objetos/eventos/acoes'
import Historico from 'components/objetos/eventos/historico'
import EstacoesMeteorologicasProximas from 'components/objetos/meteorologia/estacoesProximas'

export default class Evento extends Component {

    state = {
        item: null,
        acoes: [],
        loading: false,
        local: null
    };

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState(
                {
                    item: null,
                    acoes: [],
                    loading: false,
                    local: null
                }
                , () => {
                    this.loadItem()
                }
            )
        }
    }

    componentDidMount() {
        this.loadItem()
    }

    loadItem() {
        if (this.props.match.params.id) {
            this.setState({ loading: true });
            const { id } = this.props.match.params;
            api.get("/eventos/" + id)
                .then(response => {
                    this.setState({
                        item: response.data,
                        loading: false
                    });
                    this.loadLocal(response.data.local)
                })
                .catch(error => {
                    var mensagemErro = text("formularios.erro_request");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    }
                    this.setState({
                        sending: false,
                        error: mensagemErro
                    });
                });
        }
    }

    loadLocal(id) {
        if (id === null) return

        api.get("/hidra/locais/" + id)
            .then(response => {
                this.setState({
                    local: response.data
                });
            })
            .catch(() => {
                this.setState({
                    local: null
                });
            })
    }

    renderEstado(item) {
        switch (item.estado.codigo) {
            case 'ativo':
                return (<Label tag size="massive" color='green'>{text("eventos.ativo")}</Label>)
            case 'reconhecido':
                return (<Label tag size="massive" color='yellow'>{text("eventos.reconhecido")}</Label>)
            case 'inativo':
                return (<Label tag size="massive" color='grey'>{text("eventos.inativo")}</Label>)
            default: return null;
        }
    }

    handleNewEvent(msg) {
        const { item } = this.state
        if (msg.id === item.id)
            this.loadItem()
    }

    render() {
        const { item, local } = this.state

        return (

            <Container style={{ padding: '2em' }}>

                <Helmet title={`${item ? (item.titulo) : ''} ${text("geral.html-title")}`} />

                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>
                        <Header as="h1" >
                            {item &&
                                <>
                                    <Icon
                                        style={{ color: item.tipo.severidade.cor ? item.tipo.severidade.cor : '#808080' }}
                                        name={getIcon(item.tipo.severidade.codigo)}
                                    />
                                    <Header.Content>
                                        {item.titulo}
                                        <Header.Subheader>
                                            {item.tipo.nome}
                                        </Header.Subheader>
                                    </Header.Content>
                                </>
                            }
                        </Header>
                    </div>

                    <div style={{ flex: "250px", textAlign: "right" }}>
                        {item &&
                            this.renderEstado(item)
                        }
                    </div>
                </div>

                <br />

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <Grid stackable columns={2} >
                    <Grid.Column width={10}>

                        <InfoEvento evento={item} local={local} />

                        <Historico evento={item} showLinks/>

                    </Grid.Column>
                    <Grid.Column width={6}>

                        <Acoes evento={item} />

                        <br />

                        {item && item.lat && item.lng ?
                            <>
                                <EstacoesMeteorologicasProximas
                                    lat={item.lat}
                                    lng={item.lng}
                                    raio={20}
                                    showLinks={true} 
                                />
                                <br />
                                <Mapa
                                    title={text("eventos.posicao")}
                                    position={[item.lat, item.lng]}
                                    zoom={10}
                                    icon={getIcon(item.tipo.severidade.codigo)}
                                    color={item.tipo.severidade.cor}
                                />
                            </>
                            : null}

                    </Grid.Column>
                </Grid>
                <Footer />

            </Container >
        )
    }
}