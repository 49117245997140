import { text } from 'services/locales'

export const optionsOrigens = [
    { text: text('origens.adsb'), value: 'adsb' },
    { text: text('origens.ais'), value: 'ais' },
    { text: text('origens.cel'), value: 'cel' },
    // { text: text('origens.grfmar'), value: 'grfmar'},
    // { text: text('origens.lrit'), value: 'lrit'},
    // { text: text('origens.preps'), value: 'preps'},
    // { text: text('origens.simmap'), value: 'simmap'},
    // { text: text('origens.vrmtc'), value: 'vrmtc'},
    // { text: text('origens.mssis'), value: 'mssis'}
]

export const optionsHostilidade = [
    { text: text('classificacao.hostilidade-amigo'), value: 'f', color: '#00ff00' },
    { text: text('classificacao.hostilidade-neutro'), value: 'n', color: '#6b6b6b' },
    { text: text('classificacao.hostilidade-hostil'), value: 'h', color: '#ff0000' },
    { text: text('classificacao.hostilidade-desconhecido'), value: 'u', color: '#ffff00' }
]

export const optionsAmbiente = [
    { text: text('classificacao.ambiente-aereo'), value: 'a' },
    { text: text('classificacao.ambiente-supterra'), value: 'g' },
    { text: text('classificacao.ambiente-supmar'), value: 's' },
    { text: text('classificacao.ambiente-submarino'), value: 'u' },
    { text: text('classificacao.ambiente-desconhecido'), value: 'd' }
]

export const optionsSimbologia = [
    //{ text: text('filtro.simples'), value: 'simples' },
    //{ text: text('filtro.completa'), value: 'completa' }
]

export const optionsAreas = [
    { text: text('areas.areas-circulares'), value: 'circular' }
]