import React, { Component } from 'react';

import { Button, Icon, Header, Form, Table, Message, Grid, Dropdown, Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import api from 'services/api';
import { text, html } from 'services/locales';
import Footer from 'components/footer'
import { Helmet } from 'react-helmet'

export default class TabelaLocais extends Component {

    state = {
        itens: [],
        busca: '',
        loading: true,
        error: null,
        pageNumber: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        lastPage: true,
        firstPage: true,
        pageSize: 10,
        sort: "nome",
        sortDirection: 'ascending',
        filtro: {
            ativo: false,
            tipos: []
        }
    }

    componentDidMount() {
        if (this.props.match.params.busca) {
            this.setState({ busca: this.props.match.params.busca });
            this.loadItens(0, this.props.match.params.busca);
        } else {
            this.loadItens();
        }
        this.loadTipoLocais();
    }

    loadItens = (page = 0, busca = null) => {
        this.setState({ loading: true, error: null });

        const { sort, sortDirection, pageSize, filtro } = this.state;

        let url = '/hidra/locais?page=' + page + "&size=" + pageSize;
        if (busca != null) {
            url += '&q=' + busca
        }

        if (sort != null) {
            url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
        }

        if (filtro) {
            if (filtro.tipos) {
                url += "&t="
                filtro.tipos.forEach((item, index) => {
                    url += (index > 0 ? "," : "") + item
                });
            }
        }

        api.get(url)
            .then(response => {
                if (response.data.content != null) {
                    this.setState({
                        loading: false,
                        itens: response.data.content,
                        totalPages: response.data.totalPages,
                        totalElements: response.data.totalElements,
                        lastPage: response.data.last,
                        firstPage: response.data.first,
                        pageNumber: response.data.number,
                        numberOfElements: response.data.numberOfElements
                    });
                }
            })
            .catch(error => {
                var errorMsg = text("formularios.erro_request")
                if (error.response && error.response.data && error.response.data.message)
                    errorMsg = error.response.data.message
                this.setState({
                    loading: false,
                    itens: [],
                    error: errorMsg
                });
            });
    };

    loadTipoLocais() {
        api.get("hidra/locais/tipos?size=999&sort=nome,asc")
            .then((response) => {
                const optionsTiposLocais = response.data.content.map((item) => ({
                    key: item.id,
                    text: item.nome,
                    value: item.id,
                }))

                const filtroTipos = response.data.content.map((item) => (
                    item.id
                ))
                const { filtro } = this.state;
                this.setState(
                    {
                        optionsTiposLocais: optionsTiposLocais,
                        filtro:
                        {
                            ...filtro,
                            tipos: filtroTipos
                        }
                    }, () =>
                    this.loadItens()
                );
            }
            )
    }

    handleChangeDropdown = (e, { name, value }) => {
        const { busca } = this.state
        this.setState({ [name]: value }, () => { this.loadItens(0, busca); });
    }

    handleRefresh = () => {
        const { pageNumber, busca } = this.state
        this.loadItens(pageNumber, busca);
    }

    handleSearch = e => {
        e.preventDefault();
        const { busca } = this.state;
        this.loadItens(0, busca);
    }

    handleNextPage = () => {
        const { pageNumber, lastPage, busca } = this.state;
        if (lastPage === true) return;

        const novoPageNumber = pageNumber + 1;
        this.loadItens(novoPageNumber, busca);
    }
    handlePreviousPage = () => {
        const { pageNumber, firstPage, busca } = this.state;
        if (firstPage === true) return;

        const novoPageNumber = pageNumber - 1;
        this.loadItens(novoPageNumber, busca);
    }

    handleSort = clickedColumn => () => {
        const { busca, sort, sortDirection } = this.state

        this.setState({
            sortDirection: clickedColumn === sort ? (sortDirection === 'ascending' ? 'descending' : 'ascending') : 'ascending', //Se for a mesma coluna clicada, inverte a ordenação, caso contrário, define como ascendente 
            sort: clickedColumn
        }, () => {
            this.loadItens(0, busca);
        })
    }

    handleFiltro = () => {
        const { filtro } = this.state;
        this.setState({
            filtro:
            {
                ...filtro,
                ativo: !filtro.ativo
            }
        }
        )
    }

    handleFiltroTiposLocais = (valor) => {
        const { filtro } = this.state;
        var temp = filtro.tipos;

        if (temp.includes(valor)) {
            if (temp.length === 1) return; //Não permite desmarcar todos
            temp.splice(temp.indexOf(valor), 1);
        } else {
            temp.push(valor)
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    tipos: temp
                }
            },
            () => {
                this.loadItens(0);
            }
        )
    }

    render() {

        const { filtro, itens, loading, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements, sort, sortDirection } = this.state;

        const optionsPageSize = [
            { key: 10, text: '10', value: 10 },
            { key: 30, text: '30', value: 30 },
            { key: 50, text: '50', value: 50 },
            { key: 100, text: '100', value: 100 },
        ]

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`${text("local.titulo")} ${text("geral.html-title")}`} />

                <Grid stackable columns={2} >
                    <Grid.Column>
                        <Header as="h1" >
                            {text("local.titulo")}
                            <Header.Subheader>
                                {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Form >
                            <Form.Group widths='equal'>
                                <Form.Input
                                    fluid icon='search'
                                    iconPosition='left'
                                    name="busca"
                                    placeholder={text('local.buscar')}
                                    value={this.state.busca}
                                    onChange={e => this.setState({ busca: e.target.value })}
                                />
                                <Button color='blue' onClick={this.handleSearch} >
                                    {text('tabelas.buscar')}
                                </Button>

                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <Table celled striped selectable sortable >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='1'>
                                <Button
                                    icon
                                    basic compact
                                    labelPosition='right'
                                    onClick={this.handleFiltro}
                                    toggle active={filtro.ativo}
                                >
                                    <Icon name='filter' />
                                    {text("formularios.filtros")}
                                </Button>
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='5' textAlign="right">
                                <Button icon basic compact onClick={this.handleRefresh} loading={loading} title={text("geral.atualizar")} >
                                    <Icon name='refresh' />
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    {filtro.ativo &&
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='5'>
                                    <Grid divided columns={2} >
                                        <Grid.Column>
                                            <b>{text("local.tipo")}</b>

                                            <Form.Group grouped className="filter-checkbox-grid">
                                                {this.state.optionsTiposLocais.map(item =>
                                                    <Form.Checkbox
                                                        key={item.value}
                                                        value={item.value}
                                                        label={item.text}
                                                        checked={filtro.tipos.includes(item.value)}
                                                        onChange={(e, { value }) => this.handleFiltroTiposLocais(value)}
                                                    />
                                                )}
                                            </Form.Group>
                                        </Grid.Column>
                                        <Grid.Column>

                                        </Grid.Column>
                                    </Grid>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                    }

                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={sort === 'nome' ? sortDirection : null}
                                onClick={this.handleSort('nome')}
                            >
                                {text("local.nome")}</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sort === 'pai.nome' ? sortDirection : null}
                                onClick={this.handleSort('pai.nome')}
                            >
                                {text("local.pai")}</Table.HeaderCell>
                            <Table.HeaderCell
                                collapsing
                                sorted={sort === 'sigla' ? sortDirection : null}
                                onClick={this.handleSort('sigla')}>
                                {text("local.sigla")}</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={sort === 'tipo.nome' ? sortDirection : null}
                                onClick={this.handleSort('tipo.nome')}>
                                {text("local.tipo")}</Table.HeaderCell>

                            <Table.HeaderCell collapsing>{text("tabelas.detalhes")}</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {

                            itens.map((item) =>
                                <Table.Row key={item.id}>
                                    <Table.Cell>
                                        <Link to={'/local/' + item.id}>
                                            {item.nome}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.pai ?
                                            (
                                                <Link
                                                    to={"/local/" + item.pai.id}
                                                    title={item.pai.nome}
                                                >
                                                    {item.pai.sigla}
                                                </Link>
                                            )
                                            :
                                            (<>-</>)
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.sigla}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon
                                            name={item.tipo.simbolo ? item.tipo.simbolo : 'map marker alternate'}
                                            style={{ color: item.tipo.cor ? item.tipo.cor : '#808080' }}
                                            size="large"
                                        /> {item.tipo.nome}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <Button icon size='mini' as={Link}
                                            to={'/local/' + item.id}
                                        > <Icon name='search plus' />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2'>
                                {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
                                &nbsp;{text("tabelas.tamanho-pagina")}&nbsp;
                                <Dropdown
                                    compact
                                    selection
                                    value={this.state.pageSize}
                                    options={optionsPageSize}
                                    onChange={this.handleChangeDropdown}
                                    name="pageSize"
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='3'>
                                <Button
                                    disabled={lastPage}
                                    floated='right'
                                    icon labelPosition='right'
                                    size='medium'
                                    onClick={this.handleNextPage}
                                >
                                    <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                                </Button>
                                <Button
                                    disabled={firstPage}
                                    floated='right'
                                    icon labelPosition='left'
                                    size='medium'
                                    onClick={this.handlePreviousPage}
                                >
                                    <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>

                </Table>

                <Footer />

            </Container>
        );
    }

}