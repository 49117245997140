import React, { Component } from 'react';

import { Segment, Header, Button, Icon as IconSUI } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { getStyle } from "util/acompanhamentos"
import { text } from 'services/locales'
import { Map, TileLayer, LayersControl, Polyline, FeatureGroup, ZoomControl } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import './styles.css'

import RotatedMarker from '../../../../pages/cenarioTatico/componentes/RotatedMarker';
import { layers } from 'util/layersOptions';

const { BaseLayer } = LayersControl

export default class Mapa extends Component {

    state = {
        zoom: 15
    }

    handleMoveEnd = () => {
        const map = this.refs.map.leafletElement
        if (map.getZoom() < 2) {
            map.setZoom(2)
        } else {
            this.setState({ zoom: map.getZoom() })
        }
    }

    render() {

        const { uuid, item, historico } = this.props;
        const position = item ? [item.cinematica.posicao.geo.lat, item.cinematica.posicao.geo.lng] : [-15, -45];

        return (
            <>
                {item &&
                    <div className="box">
                        <Header as='h2' attached='top'>
                            {text("acompanhamento.posicao")}
                        </Header>
                        <Segment attached>
                            <div className="mapa">
                                <Map
                                    ref='map'
                                    center={position}
                                    zoom={this.state.zoom}
                                    style={{ width: "100%", height: "350px" }}
                                    onMoveEnd={this.handleMoveEnd}
                                    dragging={false}
                                    touchZoom={false}
                                    scrollWheelZoom={false}
                                    zoomControl={false}
                                    maxBounds={[[-90, -180], [90, 180]]}
                                >

                                    <ZoomControl position="topright" />
                                    <LayersControl position="topright" >

                                        {layers.map(item =>
                                            <BaseLayer key={item.url} checked={item.checked} name={item.name}>
                                                <TileLayer
                                                    attribution={item.attribution}
                                                    url={item.url}
                                                />
                                            </BaseLayer>
                                        )}

                                        <FeatureGroup>
                                            {historico && historico.length > 0 &&
                                                <Polyline color="#2664E3" positions={historico} />
                                            }
                                            <RotatedMarker
                                                key={uuid}
                                                position={position}
                                                icon={getStyle(item.cinematica.velocidade.fundo, item.hostilidade, item.origem)}
                                                opacity={item.perdido ? 0.5 : 1}
                                                rotationAngle={item.cinematica.velocidade.fundo && item.cinematica.velocidade.fundo > 0 ? item.cinematica.rumo.fundo : 0}
                                                rotationOrigin={'center'}
                                            >
                                            </RotatedMarker>
                                        </FeatureGroup>

                                    </LayersControl>
                                </Map>
                            </div>
                            <Button primary fluid as={Link}
                                to={{
                                    pathname: '/mapa',
                                    state: {
                                        goto: position
                                    }
                                }}>
                                <IconSUI name="map marker alternate" />
                                {text("cenario-tatico.ver-no-mapa")}
                            </Button>
                        </Segment>
                    </div>
                }
            </>
        )
    }
}