import React, { Component } from 'react';

import { Header, Container, Menu, Grid, Label, Icon } from 'semantic-ui-react'

import Footer from 'components/footer'
import { text } from 'services/locales'
import api from 'services/api';
import { Helmet } from 'react-helmet'
import ChatContent from '../content'
import UIfx from 'uifx';
import { isHidden } from 'util/functions'

import './styles.css'

const notification = new UIfx(
    process.env.PUBLIC_URL + '/sounds/notificacao.wav',
    { volume: 0.5 }
)

export default class Chat extends Component {

    state = {
        chats: [],
        readCount: [],
        chatSelecionado: null,
        unread: 0,
        locais: []
    }

    constructor(props) {
        super(props);
        this._chatSelecionado = React.createRef();
    }

    componentDidMount() {

        api.get("/hidra/locais/usuarios?remover-meu-usuario=1")
            .then(response => {
                this.setState(
                    { locais: response.data }
                    , () => {
                        this.loadUnreadCount();
                    });

                const { user } = this.props.match.params;

                if (user != null) {//Verifico se o valor informado pela url, se houver, esta presente nos usuarios disponiveis para selecionar esse chat
                    response.data.forEach(item => {
                        item.usuarios.forEach((usuario) => {
                            if (usuario.login === user)
                                this.setState({ chatSelecionado: user });
                        }
                        )
                    })
                }

            })
            .catch(error => {
                var mensagemErro = text("formularios.erro_request");
                if (error.response && error.response.data) {
                    mensagemErro = error.response.data.message;
                }
                this.setState({
                    sending: false,
                    error: mensagemErro
                });
            })
    }

    loadUnreadCount() {
        api.get("/chat/mensagens/unread/count")
            .then(response => {
                this.setState({ readCount: response.data, unread: response.data._total })
            })
            .catch(() => {
                this.setState({ readCount: [], unread: 0 })
            })
    }

    handleItemClick = (e, { id, name }) => {
        this.setState(
            { chatSelecionado: null, nomeChatSelecionado: null }
            , () => {
                const { readCount } = this.state;

                if (readCount[id] != null) {
                    this.setState(
                        {
                            chatSelecionado: id,
                            nomeChatSelecionado: name,
                            unread: this.state.unread - readCount[id],
                            readCount: {
                                ...readCount,
                                [id]: 0
                            }
                        }
                    );
                } else {
                    this.setState({ chatSelecionado: id, nomeChatSelecionado: name });
                }
            }
        )
    }

    renderMenu(local) {
        const { chatSelecionado, readCount } = this.state

        return (
            <div key={local.id}>
                <Menu.Item header>
                    {local.nome}
                </Menu.Item>
                {local.usuarios.map((usuario) =>
                    <Menu.Item
                        id={usuario.login}
                        key={usuario.login}
                        name={usuario.nome + (usuario.nomeExibicao ? (" (" + usuario.nomeExibicao + ")") : "")}
                        active={chatSelecionado === usuario.login}
                        onClick={this.handleItemClick}
                    >
                        <Icon.Group >
                            <Icon name={'user'} />
                        </Icon.Group>
                        {readCount[usuario.login] > 0 &&
                            <Label color='red'>{readCount[usuario.login]}</Label>
                        }
                            &nbsp;{usuario.nomeExibicao ? usuario.nomeExibicao : (usuario.nome.length > 20 ? (usuario.nome.substring(0, 20) + "...") : (usuario.nome))}
                    </Menu.Item>
                )}
            </div>
        )
    }

    handleRXWS(msg) {

        if (!isHidden())
            notification.play()

        if (msg.org === this.state.chatSelecionado) {//Se recebo a notificação do chat selecionado, notifico ele com a nova mensagem
            if (this._chatSelecionado && this._chatSelecionado.current) {
                this._chatSelecionado.current.handleRX(msg);
            }
        } else {//Se é outro chat, incremento a contagem de não lidos
            var { readCount } = this.state;
            var cont = 1;
            if (readCount[msg.org]) {
                cont = readCount[msg.org] + 1
            }
            this.setState(
                {
                    unread: this.state.unread + 1,
                    readCount: {
                        ...readCount,
                        [msg.org]: cont
                    }
                }
            );

        }
    }

    render() {

        const { locais, unread } = this.state

        return (
            <>
                <Container style={{ padding: '2em' }}>

                    <Helmet title={`${unread > 0 ? '(' + unread + ') ' : ''}${text("chat.titulo")} ${text("geral.html-title")}`} />

                    <br />

                    <Grid style={{ height: '70vh' }}>
                        <Grid.Column width={4} style={{ padding: '0' }}>

                            <Header as="h1" >
                                <Header.Content>
                                    {text('chat.titulo')}{unread > 0 ? ' (' + unread + ')' : ''}
                                </Header.Content>
                            </Header>

                            <Menu fluid vertical className="menu-chat" >
                                {locais.map((item) =>
                                    this.renderMenu(item)
                                )}
                            </Menu>

                        </Grid.Column>

                        <Grid.Column width={12} style={{ padding: '0' }}>

                            <ChatContent
                                user={this.state.chatSelecionado}
                                name={this.state.nomeChatSelecionado}
                                ref={this._chatSelecionado}
                            />

                        </Grid.Column>

                    </Grid>

                    <Footer />
                </Container>

            </>
        );
    }

}