import React, { Component } from 'react';

import { Segment, Header, List, Button } from 'semantic-ui-react'

import api from 'services/api';
import { text } from 'services/locales';
import { Link } from 'react-router-dom'
import { jwtSubject } from 'services/auth';

export default class UsuariosLocal extends Component {

    state = {
        usuarios: null
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id || prevProps.refresh !== this.props.refresh) {
            this.loadItens();
        }
    }

    componentDidMount() {
        this.loadItens();
    }

    loadItens = () => {
        const { id } = this.props;

        if (id) {
            var url = "/hidra/locais/" + id + "/usuarios";

            api.get(url)
                .then((response) =>
                    this.setState({ usuarios: response.data })
                )
                .catch(() =>
                    this.setState({ usuarios: null })
                )
        }

    }

    render() {

        const { usuarios } = this.state;
        const { inverted, header } = this.props

        return (
            <>
                {usuarios && usuarios.length > 0 &&
                    <div className="box">
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {text("local.usuarios-deste-local")}
                        </Header>
                        <Segment attached inverted={inverted} style={{ overflow: 'auto', maxHeight: "300px", padding: "5px" }} >
                            <List divided relaxed inverted={inverted} selection>
                                {usuarios.map((item) =>
                                    <List.Item key={item.id} >
                                        {item.login !== jwtSubject() &&
                                            <List.Content floated='right'>
                                                <Button
                                                    title={text("usuario.conversar-com-usuario")}
                                                    as={Link}
                                                    to={'/chat/' + item.login}
                                                    icon="chat"
                                                />
                                            </List.Content>
                                        }
                                        <List.Content as={Link} to={'/usuario/' + item.login}>
                                            <List.Header>{item.nome}</List.Header>
                                            <List.Description>{item.nomeExibicao ? item.nomeExibicao : item.login} ({item.perfil.nome})</List.Description>
                                        </List.Content>
                                    </List.Item>
                                )}
                            </List>
                        </Segment>
                    </div>
                }
            </>
        )
    }
}