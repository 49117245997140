import React, { Component } from 'react';

import { Segment, Header, Divider } from 'semantic-ui-react'

import { text } from 'services/locales';
import { convertDMS } from 'util/functions'
import { nosParaQuilometrosHora } from 'util/conversores'

export default class CinematicaSimples extends Component {

    render() {

        const { item, inverted, header } = this.props
        const cinematica = item ? item.cinematica : null;
        const ambiente = item ? item.ambiente : null

        return (
            <>

                {cinematica &&
                    <div>

                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {text("acompanhamento.cinematica")}
                        </Header>
                        <Segment inverted={inverted} attached>
                            {!isNaN(cinematica.posicao.geo.lat) && !isNaN(cinematica.posicao.geo.lng) &&
                                <>
                                    {text("acompanhamento.posicao")}: {convertDMS(cinematica.posicao.geo.lat, cinematica.posicao.geo.lng)}
                                    <br />
                                </>
                            }

                            {!isNaN(cinematica.posicao.precisao) && cinematica.posicao.precisao !== 0 &&
                                <>
                                    {text("acompanhamento.precisao")}: {Number(cinematica.posicao.precisao).toFixed(1) + "m"}
                                    <br />
                                </>
                            }

                            <Divider />

                            {ambiente && (ambiente === "SUPTERRA") ? (
                                <>

                                    {!isNaN(cinematica.rumo.fundo) &&
                                        <>
                                            {text("cinematica.sentido")}: {Number(cinematica.rumo.fundo).toFixed(1) + "°"}
                                            <br />
                                        </>
                                    }

                                    {!isNaN(cinematica.velocidade.fundo) &&
                                        <>
                                            {text("cinematica.velocidade")}: {Number(nosParaQuilometrosHora(cinematica.velocidade.fundo)).toFixed(1) + text("cinematica.kmh")}
                                            <br />
                                        </>
                                    }

                                    {!isNaN(cinematica.altitude) &&
                                        <>
                                            {text("acompanhamento.altitude")}: {Number(cinematica.altitude).toFixed(1) + "m"}
                                            <br />
                                        </>
                                    }

                                </>
                            ) : (
                                    <>
                                        {!isNaN(cinematica.rumoFundo) &&
                                            <>
                                                {text("acompanhamento.rumo-fundo")}: {Number(cinematica.rumoFundo).toFixed(1) + "°"}
                                                <br />
                                            </>
                                        }

                                        {!isNaN(cinematica.velocidade.fundo) &&
                                            <>
                                                {text("acompanhamento.velocidade-fundo")}: {Number(cinematica.velocidade.fundo).toFixed(1) + " " + text("cinematica.nos")}
                                                <br />
                                            </>
                                        }

                                        {!isNaN(cinematica.rumo.sup) &&
                                            <>
                                                {text("acompanhamento.rumo-superficie")}: {Number(cinematica.rumo.sup).toFixed(1) + "°"}
                                                <br />
                                            </>
                                        }

                                        {!isNaN(cinematica.velocidade.sup) &&
                                            <>
                                                {text("acompanhamento.velocidade-superficie")}: {Number(cinematica.velocidade.sup).toFixed(1) + " " + text("cinematica.nos")}
                                                <br />
                                            </>
                                        }

                                        {!isNaN(cinematica.taxaManobra) && cinematica.taxaManobra !== 0 &&
                                            <>
                                                {text("acompanhamento.taxa-manobra")}: {Number(cinematica.taxaManobra).toFixed(1)}
                                                <br />
                                            </>
                                        }

                                        {!isNaN(cinematica.altitude) && cinematica.altitude !== 0 &&
                                            <>
                                                <Divider />
                                                {(cinematica.altitude >= 0 ? text("acompanhamento.altitude") + ": " : text("acompanhamento.profundidade") + ": ") + Number(cinematica.altitude).toFixed(1) + "m"}
                                                <br />
                                            </>
                                        }
                                    </>
                                )
                            }

                        </Segment>
                    </div>
                }
            </>
        )
    }
}