import React, { Component } from 'react';

import { Tab } from 'semantic-ui-react'
import Geral from './tabs/geral.js'
import Acos from './tabs/acompanhamentos.js'
import AcosCompleto from './tabs/acompanhamentosCompleto.js'
import Eventos from './tabs/eventos.js'
import MensagensWebsocket from './tabs/mensagensWebsocket'
import Meteorologia from './tabs/meteorologia'

const panes = [
    { menuItem: 'Geral', render: () => <Tab.Pane><Geral /></Tab.Pane> },
    { menuItem: 'Acompanhamentos', render: () => <Tab.Pane><Acos /></Tab.Pane> },
    { menuItem: 'Acompanhamentos Form', render: () => <Tab.Pane><AcosCompleto /></Tab.Pane> },
    { menuItem: 'Eventos', render: () => <Tab.Pane><Eventos /></Tab.Pane> },
    { menuItem: 'Meteorologia', render: () => <Tab.Pane><Meteorologia /></Tab.Pane> },
    { menuItem: 'Mensagens Websocket', render: () => <Tab.Pane><MensagensWebsocket /></Tab.Pane> }
]

export default class SuporteTabs extends Component {
    render() {
        return (
            <Tab panes={panes} menu={{ fluid: true, vertical: true, tabular: 'right' }} />
        );
    }

}