import React, { Component } from 'react';

import { Container, Header, Segment, Form, Comment, Message, Button, Icon, Confirm, Label } from 'semantic-ui-react'

import { text } from 'services/locales'
import api from 'services/api';
import { jwtSubject } from 'services/auth';
import { isDev } from 'util/functions'
import UserLink from 'components/UserLink';
import Anexos from '../anexos'
import MicRecorder from 'mic-recorder-to-mp3';
import ReactAudioPlayer from 'react-audio-player';
import './styles.css'
import { formatarDataHora } from 'util/dataHora'

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default class ChatContent extends Component {

    state = {
        conversas: [],
        size: 30,
        lastPage: false,
        sending: false,
        error: null,
        mensagem: '',
        file: null,
        fileName: null,
        loading: false,
        showFileConfirm: false,
        showAudioConfirm: false,
        isRecording: false,
        segundos: 0,
        minutos: 0
    }

    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.handleRefresh();
        }
    }

    componentDidMount() {
        this.handleRefresh();

    }

    getSegundos() {
        return ('0' + this.state.segundos % 60).slice(-2)
    }

    getMinutos() {
        return Math.floor(this.state.segundos / 60)
    }

    start = () => {
        var _this = this

        navigator.getMedia = (navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);

        navigator.getMedia({ audio: true },
            () => {
                Mp3Recorder
                    .start()
                    .then(() => {
                        this.incrementer = setInterval(function () {
                            _this.setState({
                                segundos: (_this.state.segundos + 1)
                            })
                        }, 1000)
                        this.setState({ isRecording: true });
                    }).catch((e) => console.error(e));
            },
            () => {
                this.setState({ error: text("anexo.erro-gravacao") })
            },
        );
    }

    stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                var nomeArquivo = Math.random().toString(36).slice(-10) + '.mp3'
                var arquivo = new File(
                    [blob],
                    nomeArquivo
                );
                clearInterval(this.incrementer)
                // this.handleSendAudio(arquivo)
                this.setState({ file: arquivo, audioName: arquivo.name, showFileConfirm: true, isRecording: false, segundos: 0, minutos: 0 });
            })
    }

    handleRefresh(scrollToBottom = true) {

        const { user } = this.props
        const { size } = this.state

        this.setState({
            sending: false,
            error: null,
            conversas: [],
            mensagem: '',
            file: null,
            fileName: null,
            loading: true,
            showFileConfirm: false
        });

        if (isDev()) {
            this.gerarMensagemAleatoria()
        }

        if (user) {
            api.get("/chat/mensagens/" + user + "?size=" + size + "&sort=time,desc")
                .then(response => {
                    this.setState(
                        {
                            conversas: response.data.content.reverse(),
                            lastPage: response.data.last
                        }
                        , () => {
                            if (scrollToBottom) {
                                this.scrollToBottom(true)
                                setTimeout(() => this.scrollToBottom(true), 1000);//Deve ser melhorado essa solução. Para garantir que quando carregue com anexos seja rolado para baixo corretamente, chamo isso um tempo depois
                            }
                        }
                    );
                    api.post("/chat/mensagens/marcar-como-lido/origin/" + user);
                })
                .catch(() => {
                    this.setState({ conversas: [], lastPage: true })
                })
        }

        this.setState({
            loading: false
        });
    }

    handleLoadMore = () => {
        const { size } = this.state;

        this.setState(
            { size: size + 100 }
            , () => {
                this.handleRefresh(false)
            }
        )
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    handleFileSelect = (e) => {
        if (e && e.target && e.target.files && e.target.files.length > 0)
            this.setState({ fileName: e.target.files[0].name, file: e.target.files[0], mensagem: null, showFileConfirm: true });
    }

    // handleSendAudio = file => {
    //     const { user } = this.props
    //     const { mensagem } = ''

    //     this.setState({
    //         sending: true,
    //         error: null
    //     });

    //     if (!user) {
    //         this.setState({
    //             sending: false
    //         });

    //     } else {
    //         const bodyFormData = new FormData();
    //         bodyFormData.append('destination', user);
    //         bodyFormData.append('file', file);

    //         api({
    //             method: 'post',
    //             url: "/chat/mensagens",
    //             data: bodyFormData,
    //             headers: { 'Content-Type': 'multipart/form-data' }
    //         })
    //             .then(response => {
    //                 this.setState({
    //                     sending: false,
    //                     error: null,
    //                     mensagem: '',
    //                     isRecording: false,
    //                 });
    //                 this.handleAtachedNewMessage(mensagem, response.data.file)
    //             })
    //             .catch(error => {
    //                 var mensagemErro = text("chat.erro");
    //                 if (error.response && error.response.data) {
    //                     mensagemErro = error.response.data.message;
    //                 }
    //                 this.setState({
    //                     sending: false,
    //                     error: mensagemErro,
    //                 });
    //             });
    //     }
    // }

    handleSendMessage = e => {
        if (e) e.preventDefault();

        const { user } = this.props
        const { mensagem } = this.state;

        if (!mensagem)
            return

        this.setState({
            sending: true,
            error: null
        });

        if (!user) {
            this.setState({
                sending: false
            });
        } else {
            var bodyFormData = new FormData();
            bodyFormData.append('destination', user);
            if (mensagem) bodyFormData.append('text', mensagem);

            api({
                method: 'post',
                url: "/chat/mensagens",
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
                .then(response => {
                    this.setState({
                        sending: false,
                        error: null,
                        mensagem: ''
                    });

                    if (isDev()) {
                        this.gerarMensagemAleatoria()
                    }
                    this.handleRX(response.data)
                })
                .catch(error => {
                    var mensagemErro = text("chat.erro");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    }
                    this.setState({
                        sending: false,
                        error: mensagemErro
                    });
                });
        }
    }

    handleSendFile = e => {
        if (e) e.preventDefault();

        const { user } = this.props
        const { file } = this.state;
        this.setState({
            sending: true,
            error: null,
            showFileConfirm: false,
            audioName: null
        });

        if (!user) {
            this.setState({
                sending: false
            });
        } else {
            var bodyFormData = new FormData();
            bodyFormData.append('destination', user);
            if (file) bodyFormData.append('file', file);

            api({
                method: 'post',
                url: "/chat/mensagens",
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
                .then(response => {
                    this.setState({
                        sending: false,
                        error: null,
                        file: null,
                        fileName: null,
                        isRecording: false
                    });

                    this.handleRX(response.data)
                })
                .catch(error => {
                    var mensagemErro = text("chat.erro-arquivo");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    }
                    this.setState({
                        sending: false,
                        error: mensagemErro,
                        file: null,
                        fileName: null
                    });
                });
        }
    }

    renderConversas(item) {

        return (
            <Comment key={item.id} fluid className="comment-container">
                <Comment.Content className={(jwtSubject() === item.org) ? "my-comment" : "your-comment"}>
                    <Comment.Text className="comment-text">
                        {item.txt}
                        {item.file &&
                            <>
                                {item.txt ? (<br />) : null}
                                <Anexos
                                    hash={item.file}
                                />
                            </>
                        }
                    </Comment.Text>
                    <Comment.Metadata className="meta-comment">
                        {formatarDataHora(item.time)}
                    </Comment.Metadata>
                </Comment.Content>
            </Comment>
        )
    }

    scrollToBottom(forced = false) {
        var el = document.getElementById('mensagens');

        if (!el) return;

        if (forced || Math.ceil(el.scrollHeight - el.scrollTop) - el.clientHeight < 100)
            el.scrollTo(0, el.scrollHeight)
    }

    handleRX(msg) {
        var { conversas } = this.state
        conversas.push(msg)
        this.setState(
            { conversas: conversas }
            , () => {
                this.scrollToBottom()
            }
        );

        if (msg.id) {
            api.post("/chat/mensagens/marcar-como-lido/" + msg.id);
        }

    }

    handleCancelFile = () => {
        this.setState({ showFileConfirm: false, file: null, fileName: null, audioName: null })
    }

    gerarMensagemAleatoria() {
        var temp = ['Lorem ipsum nam mauris taciti id mauris netus nibh porttitor fames', 'Eros nibh habitant iaculis sodales sem', 'Urna imperdiet arcu facilisis pellentesque est amet ad cubilia', 'Rutrum curabitur ultricies', 'Ligula varius feugiat vel litora bibendum porta fusce', 'Imperdiet nibh inceptos mattis etiam morbi', 'Ante ut faucibus sagittis nam cras pellentesque nec congue est curabitur', 'Ad a phasellus justo quam arcu cras hac diam ipsum lorem nisi rutrum', 'Purus posuere litora pellentesque adipiscing amet vivamus ut sem potenti congue', 'Ornare imperdiet accumsan ac imperdiet aenean laoreet congue dolor nunc', 'Viverra imperdiet dictum turpis mauris non a consequat curae', 'Taciti lacinia lobortis amet et pharetra nec ut fringilla iaculis', 'Malesuada etiam praesent pretium viverra sollicitudin vulputate quis eu', 'Duis etiam litora quis taciti ut in venenatis']
        const randomElement = temp[Math.floor(Math.random() * temp.length)];
        this.setState({ mensagem: randomElement })
    }

    render() {

        const { user, name } = this.props
        const { conversas, sending, loading, lastPage } = this.state
        return (
            <Container fluid>

                <Confirm
                    open={this.state.showFileConfirm}
                    cancelButton='Cancelar'
                    confirmButton='Enviar'
                    onCancel={this.handleCancelFile}
                    onConfirm={this.handleSendFile}
                    size='tiny'
                    content={
                        this.state.audioName ?
                            <ReactAudioPlayer
                                src={window.URL.createObjectURL(this.state.file)}
                                style={{ width: '95%', margin: '10px' }}
                                controls
                                controlsList="nodownload"
                            />
                            : this.state.fileName}
                    header={text("chat.confimacao-enviar-arquivo")}
                />

                <>
                    <Header
                        as='h3'
                        attached='top'
                    >
                        <div style={{ display: "flex" }}>
                            <div style={{ flex: "100%", paddingTop:'4px' }}>
                                {user &&
                                    <Icon.Group>
                                        <Icon name="user" />
                                        <Icon corner='bottom right' name='chat' />
                                    </Icon.Group>
                                }
                                <Header.Content>
                                    {user &&
                                        <>
                                            &nbsp; <UserLink login={user} name={name} />
                                        </>
                                    }
                                </Header.Content>
                            </div>

                            <div style={{ flex: "200px", textAlign: "right" }}>

                                <Button
                                    onClick={!this.state.isRecording ? this.start : this.stop}
                                    disabled={!user || sending}
                                    loading={sending}
                                    icon={!this.state.isRecording ? "microphone" : 'stop'}
                                    circular
                                    color={this.state.isRecording ? "red" : undefined}
                                    basic
                                    size="small"
                                    title={!this.state.isRecording ? text("anexo.gravar-audio") : text('anexo.parar-gravacao')}
                                />
                                {this.state.isRecording &&
                                    <>
                                        &nbsp;

                                        <Label>
                                            {this.getMinutos()}:{this.getSegundos()}
                                        </Label>

                                        &nbsp;&nbsp;
                                    </>
                                }

                                <Button
                                    disabled={!user || sending}
                                    loading={sending}
                                    icon="paperclip"
                                    circular
                                    basic
                                    size="small"
                                    title={text("anexo.enviar-arquivo")}
                                    onClick={() => this.fileInputRef.current.click()}
                                />

                                <input
                                    ref={this.fileInputRef}
                                    type="file"
                                    hidden
                                    onChange={this.handleFileSelect}
                                />


                            </div>
                        </div>
                    </Header>

                    <Segment attached style={{ padding: '0' }}>

                        <Comment.Group className="comment-group" loading={loading.toString()} id='mensagens'>

                            {!user &&
                                text("chat.selecione-conversa")
                            }

                            {loading === false && user && conversas && lastPage === false &&
                                <center>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        onClick={this.handleLoadMore}
                                    >
                                        <Icon name='refresh' />
                                        {text("chat.carregar-mais")}
                                    </Button>
                                </center>
                            }

                            {conversas && conversas.map((item) =>
                                this.renderConversas(item)
                            )}

                            <br />

                        </Comment.Group>

                        <div className="send-area">
                            <Form
                                loading={sending}
                                onSubmit={this.handleSendMessage}
                            >
                                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}

                                {!this.state.error &&
                                    <Form.Input
                                        disabled={!user}
                                        placeholder={text("chat.digite-mensagem")}
                                        name='mensagem'
                                        value={this.state.mensagem}
                                        onChange={this.handleChange}
                                        size="big"
                                        action={{ icon: 'chevron right' }}
                                    />
                                }
                            </Form>
                        </div>
                    </Segment>
                </>

            </Container >
        );
    }

}