import React, { Component } from 'react';

import { Icon, Modal, Button, Header, Grid, GridColumn } from 'semantic-ui-react';
import api from 'services/api';
import { text } from 'services/locales';
import { getIcon } from 'util/eventos'
import styled, { keyframes } from 'styled-components'
import UIfx from 'uifx';

import InfoEvento from 'components/objetos/eventos/info'
import Mapa from 'components/objetos/entidades/mapa'

const animation = keyframes`
    0% { opacity: 1 }
    50% { opacity: 0}
    100% { opacity: 1}
`;

const Icone = styled.span`
    animation-name: ${animation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
`;

export default class EventoModal extends Component {

    state = {
        item: null
    }

    componentDidMount() {
        this.intervalSound = setInterval(() => this.handlePlaySound(), 3000);
        this.handlePlaySound();

        this.handleGetEventInfo();
    }

    componentWillUnmount() {
        clearInterval(this.intervalSound);
    }

    handlePlusModal = () => {
        const { evento } = this.props;

        if (evento) {
            var url = "/siscare/evento/" + evento.id
            window.location.href = url;
        }
    }

    handlePlaySound = () => {
        const alarm = new UIfx(
            process.env.PUBLIC_URL + '/sounds/alarme.wav'
        )
        alarm.play()
    }

    handleGetEventInfo = () => {
        const { evento } = this.props;

        if (evento) {
            api.get("/eventos/" + evento.id)
                .then(response => {
                    this.setState({
                        item: response.data,
                        loading: false
                    });
                    this.loadLocal(response.data.local)
                })
                .catch(error => {
                    var mensagemErro = text("formularios.erro_request");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    }
                    this.setState({
                        sending: false,
                        error: mensagemErro
                    });
                });
        }
    }


    render() {
        const { evento } = this.props;
        const { item } = this.state;
        return (
            <>
                {evento &&
                    <Modal
                        closeOnEscape={false}
                        closeOnDimmerClick={false}
                        centered={false}
                        open={evento !== null}
                    >
                        <Header as="h1" >
                            <Icone>
                                <Icon
                                    size="large"
                                    name={item ? getIcon(item.tipo.severidade.codigo) : "alarm"}
                                    style={{ color: item && item.tipo.severidade.cor ? item.tipo.severidade.cor : '#808080' }}
                                />
                            </Icone>
                            <Header.Content>
                                {evento.titulo}
                                <Header.Subheader>
                                    {evento.descricao}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                        <Modal.Content scrolling>
                            <Grid columns={2}>
                                <Grid.Column>
                                    {item &&
                                        <>
                                            <InfoEvento evento={item} header="h3" />
                                        </>
                                    }
                                </Grid.Column>
                                <GridColumn>

                                    {item && item.lat && item.lng ?
                                        <Mapa
                                            header="h3"
                                            title={text("eventos.posicao")}
                                            position={[item.lat, item.lng]}
                                            zoom={10}
                                            icon={getIcon(item.tipo.severidade.codigo)}
                                            color={item.tipo.severidade.cor}
                                            hidegoto={true}
                                        />
                                        : null}
                                </GridColumn>
                            </Grid>

                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                size="big"
                                color='red'
                                onClick={this.handlePlusModal}
                                icon labelPosition='right'
                            >
                                <Icon name='angle double right' /> {text("geral.continuar")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }
            </>
        )
    }
}