import React, { Component } from 'react';

import { Header, Container, Segment, List } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { text } from 'services/locales'
import './styles.css'

export default class Suporte extends Component {

    render() {

        return (
            <Container style={{ padding: '2em' }}>

                <Header as="h1"> {text("admin.suporte")}</Header>

                <br />

                <div className="admin-grid">

                   

                    
                         <div>
                            <Header as='h3' attached='top'>
                                {text("admin.suporte")}
                            </Header>
                            <Segment attached >
                                <List bulleted>
                                    <List.Item
                                        as={Link}
                                        to="/suporte/config"
                                    >
                                        {text("admin.config")}
                                    </List.Item>
                                    <List.Item
                                        as={Link}
                                        to="/suporte/stats"
                                    >
                                        {text("admin.stats")}
                                    </List.Item>
                                    <List.Item
                                        as={Link}
                                        to="/suporte/testes"
                                    >
                                        {text("admin.suporte-testes")}
                                    </List.Item>
                                    <List.Item
                                        as={Link}
                                        to="/api-docs"
                                    >
                                        {text("admin.api-docs")}
                                    </List.Item>
                                </List>
                            </Segment>
                        </div> 
                    

                </div>


            </Container>
        );
    }

}