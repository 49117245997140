import React, { Component } from 'react';

import { Loader, Image, Divider, Button, Icon } from 'semantic-ui-react'
import { text } from 'services/locales';
import api from 'services/api';

import { humanFileSize, forceDownload } from 'util/functions'

import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player';

export default class AnexosAcoesEventos extends Component {

    state = {
        arquivos: null,
        loading: false
    }

    componentDidMount() {
        this.loadItens();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.hash !== this.props.hash) {
            this.loadItens();
        }
    }

    loadItens = () => {
        const { hash } = this.props;

        if (hash) {
            this.setState({ loading: true })
            api.get("/arquivos/" + hash)
                .then((response) => {
                    this.setState({ arquivos: response.data, loading: false })
                })
                .catch(error => {
                    var mensagemErro = text("anexo.erro");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    } else if (error.request) {
                        mensagemErro = text("anexo.erro")
                    }
                    this.setState({
                        loading: false,
                        error: mensagemErro
                    });
                })
        }
    }

    renderIconeAnexo = (anexo) => {
        const tipo = anexo.type.split("/");
        switch (tipo[0]) {
            case 'image':
                return 'file image outline';
            case 'audio':
                return 'file audio outline';
            case 'video':
                return 'file video outline';
            case 'application':
                switch (tipo[1]) {
                    case 'pdf':
                        return 'file pdf outline';
                    case 'x-compressed-tar':
                        return 'file archive outline';
                    default:
                        return 'file outline';
                }
            case 'text':
                return 'file alternate outline';
            default:
                return 'file outline';
        }
    }

    renderAnexo = (anexo) => {
        const tipo = anexo.type.split("/");
        const url = process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_SLASH + "arquivos/" + anexo.url;
        switch (tipo[0]) {
            case 'image':
                return (
                    <Image
                        style={{ width: "100%" }}
                        src={url}
                    />
                );
            case 'video':
                return (
                    <>
                        <ReactPlayer
                            controls={true}
                            width="100%"
                            url={
                                [
                                    url,
                                    anexo.type
                                ]
                            }
                        />
                        <small><em>{text("anexo.aviso-video-invalido")}</em></small>
                    </>
                )
            case 'audio':
                return (
                    <>
                        <ReactAudioPlayer
                            src={url}
                            style={{ width: '100%' }}
                            controls
                            controlsList="nodownload"
                        />
                        <small><em>{text("anexo.aviso-audio-invalido")}</em></small>
                    </>
                )
            default:
                return (
                    <>
                        {anexo.file}
                        <br /><br />
                    </>
                )

        }
    }

    render() {

        const { inverted, showDivider } = this.props
        const { arquivos, loading, error } = this.state

        return (
            <>
                {arquivos && arquivos.files &&
                    <>
                        {showDivider &&
                            <Divider horizontal>
                                <small>
                                    <Icon inverted={inverted} name='paperclip' inline="true" />
                                </small>
                            </Divider>
                        }

                        {arquivos.files.map((item, index) =>
                            <div key={item.file}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ flex: "60px", textAlign: "left" }}>
                                        <Icon
                                            name={this.renderIconeAnexo(item)}
                                            circular
                                            size="large"
                                            title={item.type}
                                            inverted={inverted}
                                        />
                                    </div>
                                    <div style={{ flex: "100%" }}>
                                        {this.renderAnexo(item)}

                                        <Button
                                            animated='vertical'
                                            fluid
                                            title={item.file}
                                            size="small"
                                            onClick={() => forceDownload(process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_SLASH + "arquivos/" + item.url, item.url)}
                                            inverted={inverted}
                                        >
                                            
                                                <Icon name='download' /> {text("anexo.download")} ({humanFileSize(item.size)})
                                            
                                        </Button>
                                    </div>
                                </div>
                                {index < arquivos.files.length - 1 &&
                                    <Divider />
                                }
                            </div>
                        )}

                    </>
                }

                {error &&
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "60px", textAlign: "left" }}>
                            <Icon
                                name="file excel outline"
                                circular
                                size="large"
                                color="red"
                                title={error}
                            />
                        </div>
                        <div style={{ flex: "100%" }}>
                            <em>{error}</em>
                        </div>
                    </div>
                }

                {loading &&
                    <Loader active inline='centered' />
                }
            </>
        )
    }
}