import React, { Component } from 'react';

import { Grid, Header, Menu, Loader, Message, Dropdown } from 'semantic-ui-react'
import Footer from 'components/footer'
import { Helmet } from 'react-helmet'
import { text } from 'services/locales'
import SwaggerUI from "swagger-ui-react"
import api from 'services/api';

import "swagger-ui-react/swagger-ui.css"

const menus = [
    { id: 'api-gateway', titulo: 'API Gateway', url: '/v2/api-docs', groups: ['v1'] },
    { id: 'servico-arquivos', titulo: 'Serviço de Arquivos', url: '/arquivos/v2/api-docs', groups: ['v1'] },
    { id: 'servico-chat', titulo: 'Serviço de Chat', url: '/chat/v2/api-docs', groups: ['v1'] },
    { id: 'servico-classificacao', titulo: 'Serviço de Classificação', url: '/classificacao/v2/api-docs', groups: ['v1'] },
    { id: 'servico-hidra', titulo: 'Serviço Hidra', url: '/hidra/v2/api-docs', groups: ['v1'] },
    { id: 'servico-historico', titulo: 'Serviço de Histórico', url: '/historico/v2/api-docs', groups: ['v1'] },
    { id: 'servico-meteorologia', titulo: 'Serviço de Meteorologia', url: '/meteorologia/v2/api-docs', groups: ['v1'] },
    { id: 'servico-objetos', titulo: 'Serviço de Objetos', url: '/objetos/v2/api-docs', groups: ['v1', 'v2'] },
    { id: 'servico-sinais', titulo: 'Serviço de Sinais', url: '/sinais/v2/api-docs', groups: ['v1'] },
    { id: 'servico-eventos', titulo: 'Serviço de Eventos', url: '/eventos/v2/api-docs', groups: ['v1'] },
]

export default class Docs extends Component {

    state = {
        selecao: null,
        loading: false,
        docs: null,
        error: null,
        groups: [],
        groupSelected: null,
        url: null
    }

    renderMenu(item) {
        const { selecao } = this.state

        return (

            <Menu.Item
                id={item.id}
                key={item.id}
                name={item.titulo}
                url={item.url}
                groups={item.groups}
                active={selecao === item.id}
                onClick={this.handleItemClick}
            >
                {item.titulo}
            </Menu.Item>

        )
    }

    handleItemClick = (e, { id, url, groups }) => {

        const groupsOptions = groups.map((index) => ({
            key: index,
            text: index,
            value: index,
        }))

        this.setState(
            {
                selecao: id,
                url: url,
                groups: groupsOptions,
                groupSelected: 'v1'
            },
            () => this.handleLoadDocs()
        )

    }

    handleLoadDocs() {

        this.setState(
            {
                loading: true,
                docs: null,
                error: null
            })

        const { url, groupSelected } = this.state;

        const requestURL = url + '?group=' + groupSelected

        api.get(requestURL)
            .then(response => {
                this.setState({
                    docs: response.data,
                    loading: false
                });
            })
            .catch(error => {
                var errorMsg = text("formularios.erro_request")
                if (error.response && error.response.data && error.response.data.message)
                    errorMsg = error.response.data.message
                this.setState({
                    docs: null,
                    loading: false,
                    error: errorMsg
                });
            });

    }

    handleVersionChange = (e, { value }) => {
        this.setState(
            {
                groupSelected: value
            },
            () => this.handleLoadDocs()
        )

    }

    render() {

        const { docs, loading, groups } = this.state;

        return (

            <div style={{ padding: '30px' }}>

                <Helmet title={`Documentação da API REST ${text("geral.html-title")}`} />

                <Grid>
                    <Grid.Column width={3} style={{ padding: '5px' }}>

                        <Header as="h3" >
                            <Header.Content>
                                API REST - Documentação
                            </Header.Content>
                        </Header>

                        <Menu fluid vertical >
                            {menus.map((item) =>
                                this.renderMenu(item)
                            )}
                        </Menu>

                    </Grid.Column>

                    <Grid.Column width={13} style={{ paddingTop: '0', backgroundColor: '#FFF', borderRadius: '5px' }} >

                        {loading &&
                            <Loader style={{ marginTop: '30px' }} active inline='centered' />
                        }

                        {this.state.error && <Message style={{ marginTop: '30px' }} negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}

                        {docs &&
                            <>
                                <div style={{ float: 'right' }}>
                                    Versão: {' '}
                                    <Dropdown
                                        inline
                                        placeholder='Versão'
                                        value={this.state.groupSelected}
                                        options={groups}
                                        onChange={this.handleVersionChange}
                                    />
                                </div>

                                <SwaggerUI
                                    spec={docs}
                                    style={{ margin: '0', padding: '0' }}
                                />
                            </>
                        }

                    </Grid.Column>

                </Grid>

                <Footer />
            </div>
        );
    }

}