import React, { Component } from 'react';

import { Menu, Sidebar, Segment, Icon, Modal, Header, Grid, Button, Form, Divider, Message } from "semantic-ui-react"
import { Formik } from "formik";

import { Map, TileLayer, LayersControl, ZoomControl, AttributionControl, FeatureGroup } from "react-leaflet";
import L from 'leaflet';

import api from 'services/api';

import "./styles.css"
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

import { text } from 'services/locales'
import { Helmet } from 'react-helmet'
import { convertDMS, isDev } from 'util/functions'

import CamadaAcompanhamentos from '../camadas/acompanhamentos';
import Camadas from '../camadas/outras';
import CamadaLocais from '../camadas/locais'
import CamadaAreas from '../camadas/areas'
import CamadaEstacoesMeteorologicas from '../camadas/estacoes-meteorologicas'
import CamadaEventos from '../camadas/eventos'
import DicaObjeto from '../componentes/dicaObjeto/main'
import Filtro from '../componentes/sidebar/filtro'
import Goto from '../componentes/sidebar/irpara'
import SidebarAcompanhamentos from '../componentes/sidebar/acompanhamentos'
import SidebarLocais from '../componentes/sidebar/locais'
import FiltroCamadas from '../componentes/sidebar/camadas'

import { getVersion, getFilterLayer, getFilterObject, getMap, setMap, getMapLayer, setMapLayer, setCTOptions, getCTOptions } from 'services/storage'
import { layers } from 'util/layersOptions'

import Control from "react-leaflet-control"

import PolylineMeasure from 'components/PolylineMeasure/PolylineMeasure'

import { EditControl } from "react-leaflet-draw"
import "./circle.css"

const { BaseLayer } = LayersControl

const attribution = ` | ${text("geral.um-produto")} | v. ${getVersion()}`

// DECLARAÇÃO DE NOMES PARA FERRAMENTA DE DESENHOS CIRCULARES
L.drawLocal.draw.toolbar.buttons.circle = text("areas.desenho");
L.drawLocal.draw.toolbar.actions.text = text("areas.cancelar");
L.drawLocal.draw.toolbar.actions.title = text("areas.cancelar");
L.drawLocal.draw.handlers.circle.tooltip.start = text("areas.tooltip-start");
L.drawLocal.draw.handlers.circle.radius = text("areas.raio");
L.drawLocal.draw.handlers.simpleshape.tooltip.end = text("areas.tooltip-end");

export default class Mapa extends Component {

    state = {
        lat: -15,
        lng: -45,
        zoom: 5,
        bounds: {
            latMin: null,
            lngMin: null,
            latMax: null,
            lngMax: null
        },
        objetoSelecionado: null,
        ctOptions: {
            sidebar: null
        },
        stats: {
            area: 0,
            total: 0
        },
        filtroObjetos: null,
        filtroCamadas: null,
        loading: false,
        submitting: false,
        error: null,
        autoUpdateDisabled: false
    }

    componentDidMount() {
        this.setState({ loading: true })

        const map = this.refs.map.leafletElement

        //Remove o texto da atribuição, quando reposicionei para a esquera, ele duplicava o 'Leaftlet'
        map.attributionControl.setPrefix("");

        if (this.props && this.props.location && this.props.location.state) {
            const { goto } = this.props.location.state
            if (goto) {
                map.setView(goto, 18)
            }
        }

        var mapOptions = getMap()
        if (mapOptions) {

            var lat = 0;
            var lng = 0;
            var zoom = 10;

            if (mapOptions) {
                if (mapOptions.zoom) {
                    zoom = mapOptions.zoom;
                }
                if (mapOptions.center && mapOptions.center.lat && mapOptions.center.lng) {
                    lat = mapOptions.center.lat
                    lng = mapOptions.center.lng
                }
            }

            this.setState({ lat: lat, lng: lng, zoom: zoom })
        }

        var filterObj = getFilterObject()
        if (filterObj) {
            this.setState({ filtroObjetos: filterObj })
        }

        var filterLayer = getFilterLayer()
        if (filterLayer) {
            this.setState({ filtroCamadas: filterLayer })
        }

        var ctOptions = getCTOptions()
        if (ctOptions) {
            this.setState({ ctOptions: ctOptions })
        }

        this.handleRefresh()
        this.setState({ loading: false })
    }

    handleMoveStart = () => {
        this.setState({ autoUpdateDisabled: true })//Desabilito a atualização automatica das camadas enquanto o mapa estiver sendo movido para evitar requisições simultâneas
    }

    handleMoveEnd = () => {
        const map = this.refs.map.leafletElement

        this.setState({ autoUpdateDisabled: false })//Habilito a atualização automatica das camadas 

        var ok = true;

        if (map.getZoom() < 2) {
            ok = false
            map.setZoom(2)
        }

        if (ok) {
            this.handleRefresh()
            this.setState({ zoom: map.getZoom() })
            var mapOptions = { zoom: map.getZoom(), center: map.getCenter() }
            setMap(mapOptions);
        }

        //Gera saida para o config do baixa tiles
        //console.log("bbox=" + map.getBounds().getSouthWest().lng + ","+map.getBounds().getSouthWest().lat+"," + map.getBounds().getNorthEast().lng + ","+map.getBounds().getNorthEast().lat)
    }

    //Ferramenta para desenhos circulares
    handleDrawCircle = (e) => {

        let type = e.layerType;
        let layer = e.layer;
        const map = this.refs.map.leafletElement
        map.removeLayer(layer)
        if (type === 'circle') {

            this.setState({
                raio: layer._mRadius,
                latitude: layer._latlng.lat.toFixed(6),
                longitude: layer._latlng.lng.toFixed(6),
                showDialog: true,
                layer: layer
            })

        }
    }

    handleCancelarArea = () => {
        this.setState({ showDialog: false, layer: null, raio: null, latitude: null, longitude: null, error: null })
    }

    handleForceRefresh = () => {
        this.setState({
            loading: true,
            bounds: {
                latMin: null,
                lngMin: null,
                latMax: null,
                lngMax: null
            }
        }, () => {
            this.handleRefresh()
            this.setState({ loading: false })
        })
    }

    handleRefresh = () => {
        const map = this.refs.map.leafletElement

        const bounds = {
            latMin: map.getBounds().getNorthEast().lat,
            lngMin: map.getBounds().getNorthEast().lng,
            latMax: map.getBounds().getSouthWest().lat,
            lngMax: map.getBounds().getSouthWest().lng
        }

        this.setState({ bounds: bounds })
    }

    handleMouseMove = (e) => {
        if (this.props.mobile) return;

        document.getElementById('mousePosition').style.display = '';
        document.getElementById('mousePositionContainer').textContent = convertDMS(e.latlng.lat, e.latlng.lng)
    }

    handleMouseOut = (e) => {
        if (this.props.mobile) return;

        document.getElementById('mousePosition').style.display = 'none';
        document.getElementById('mousePositionContainer').textContent = ''
    }

    handleDblclick = (e) => {
        this.handleShowDicaObjeto(e.latlng, "latlng")
    }

    handleZoomOut = () => {
        const map = this.refs.map.leafletElement
        map.setZoom(map.getZoom() - 1)
    }

    handleZoomIn = () => {
        const map = this.refs.map.leafletElement
        map.setZoom(map.getZoom() + 1)
    }

    handleLayerChange = (e) => {
        if (e && e.layer) {
            setMapLayer(e.layer._url)
            this.handleLayerLegend(e.layer._url)
        }
    }

    handleLayerLegend = (url) => {

        if (!document.getElementById('layerLegend')) return;

        document.getElementById('layerLegend').style.display = 'none';
        document.getElementById('layerLegend').innerHTML = ''

        const layer = layers.find(item => item.url === url);

        if (layer) {
            if (layer.legend) {
                document.getElementById('layerLegend').style.display = '';
                document.getElementById('layerLegend').innerHTML = layer.legend
            }
        }
    }

    handleGoto = (latlong) => {
        const map = this.refs.map.leafletElement
        map.setView(latlong, 15)
    }

    handleGotoZoom = (latlong, z) => {
        const map = this.refs.map.leafletElement
        map.setView(latlong, z)
    }

    handleShowDicaObjeto = (id, tipo) => {
        this.handleSidebar('objeto', { id: id, tipo: tipo })
    }

    handleSidebar = (e, objetoSelecionado) => {
        const { ctOptions } = this.state;

        if (e !== null && ctOptions.sidebar !== e) {
            this.setState(
                {
                    ctOptions: {
                        ...ctOptions,
                        sidebar: e
                    },
                    objetoSelecionado: objetoSelecionado ? objetoSelecionado : null
                },
                () => {
                    setCTOptions(this.state.ctOptions)
                }
            )
        } else {
            if (objetoSelecionado) {
                this.setState({ objetoSelecionado: objetoSelecionado })
            } else
                this.handleHideSidebar()
        }
    }

    handleHideSidebar = () => {
        const { ctOptions } = this.state;

        this.setState(
            {
                ctOptions: {
                    ...ctOptions,
                    sidebar: null
                },
                objetoSelecionado: null
            },
            () => {
                setCTOptions(this.state.ctOptions)
            }
        )
    }

    renderSidebar() {
        const { mobile } = this.props;

        switch (this.state.ctOptions.sidebar) {
            case 'objeto':
                if (this.state.objetoSelecionado) {
                    return (
                        <DicaObjeto
                            id={this.state.objetoSelecionado.id}
                            tipo={this.state.objetoSelecionado.tipo}
                            onClose={this.handleHideSidebar}
                            onGoto={(e) => { this.handleGoto(e) }}
                            onRefresh={(e) => { this.handleForceRefresh() }}
                            onSelectLocal={(id) => { this.handleShowDicaObjeto(id, 'local') }}
                            showLinks={!mobile}
                        />
                    );
                } else {
                    this.handleHideSidebar();
                }
                break;
            case 'filtro':
                return (
                    <Filtro
                        onClose={this.handleHideSidebar}
                        onUpdateFilter={(f) => { this.setState({ filtroObjetos: f }) }}
                    />);
            case 'goto':
                return (
                    <Goto
                        onClose={this.handleHideSidebar}
                        onGoto={(latlong) => { this.handleGoto(latlong) }}
                        onGotoZoom={(latlong, z) => { this.handleGotoZoom(latlong, z) }}
                    />);
            case 'aco':
                return (
                    <SidebarAcompanhamentos
                        onClose={this.handleHideSidebar}
                        onGoto={(latlong) => { this.handleGoto(latlong) }}
                        onGotoZoom={(latlong, z) => { this.handleGotoZoom(latlong, z) }}
                        onSelectAco={(id) => { this.handleShowDicaObjeto(id, 'aco') }}
                    />);
            case 'locais':
                return (
                    <SidebarLocais
                        onClose={this.handleHideSidebar}
                        onGoto={(latlong) => { this.handleGoto(latlong) }}
                        onGotoZoom={(latlong, z) => { this.handleGotoZoom(latlong, z) }}
                        onSelectLocal={(id) => { this.handleShowDicaObjeto(id, 'local') }}
                    />);
            case 'camadas':
                return (
                    <FiltroCamadas
                        onClose={this.handleHideSidebar}
                        onUpdateFilter={(f) => { this.setState({ filtroCamadas: f }) }}
                    />);
            default:
                return null
        }

    }

    //Verifica se a camada deve ser marcada como 'checked' ou seja, a camada visivel quando carrega o mapa.
    //Para isso verifica se há no storage local a ultima marcada, senão, verifica a propriedade padrão da camada
    isLayerChecked = (layer) => {
        if (getMapLayer())
            return getMapLayer() === layer.url;
        else
            return layer.checked;
    }

    handleUpdateStats = (stats) => {
        this.setState({ stats: { total: stats[0], area: stats[1] } })
    }

    render() {

        const position = [this.state.lat, this.state.lng];
        const { loading, bounds, submitting, stats } = this.state;
        const { mobile } = this.props;

        const diffHeight = mobile ? 2 : 53; //Diferença de altura a ser subtraida da altura do navegador, considerando o menu ou a falta dele

        return (
            <>

                <Modal
                    mini
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    centered={false}
                    open={this.state.showDialog}
                >
                    <Header as="h1" >
                        {text("areas.cadastrar-area")}
                    </Header>
                    <Modal.Content scrolling>
                        <Formik
                            initialValues={{
                                nome: '',
                                cor: '#FF0000',
                            }}
                            onSubmit={(values) => {
                                this.setState({ error: null, submitting: true, sucesses: null });
                                const map = this.refs.map.leafletElement
                                api.post('/hidra/areas/circular/',
                                    {
                                        "nome": values.nome,
                                        "cor": values.cor,
                                        "raio": this.state.raio,
                                        "lat": this.state.latitude,
                                        "lng": this.state.longitude
                                    }
                                )
                                    .then((response) => {
                                        if (response.data.error == null) {
                                            this.setState({ submitting: false, error: null, sucesses: text("areas.criada-sucesso"), layer: null, raio: null, latitude: null, longitude: null, showDialog: false }, this.handleForceRefresh(), map.removeLayer(this.state.layer));
                                        }
                                    })
                                    .catch((error) => {
                                        var mensagemErro = text("formularios.erro");
                                        if (error.response && error.response.data.message) {
                                            mensagemErro = error.response.data.message;
                                        } else if (error.request) {
                                            mensagemErro = text("formularios.erro_request")
                                        }
                                        this.setState({
                                            submitting: false,
                                            error: mensagemErro
                                        });
                                    });

                            }}
                            render={({
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (

                                <Form
                                    onSubmit={handleSubmit}
                                    loading={loading}
                                    submitting={submitting}
                                >
                                    {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                                    <Form.Group>
                                        <Form.Field required style={{ width: '200px' }}>
                                            <label>{text("geral.nome")}</label>
                                            <Form.Input
                                                placeholder={text("geral.nome")}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.nome}
                                                name="nome"
                                            />
                                        </Form.Field>

                                        <Form.Field>
                                            <label>{text("tipo-local.cor")}</label>
                                            <input
                                                type="color"
                                                name="cor"
                                                value={values.cor}
                                                defaultValue="#FF0000"
                                                onChange={handleChange}
                                            //style={{ height: '38px', width: '80px'}}
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Divider />
                                    <Grid columns={2}>
                                        <Grid.Column textAlign="left">
                                            <Button
                                                icon labelPosition='left'
                                                size='large'
                                                type='button'
                                                disabled={loading}
                                                onClick={this.handleCancelarArea}
                                            >
                                                <Icon name='cancel' /> {text("formularios.cancelar")}
                                            </Button>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            <Button
                                                primary
                                                type='submit'
                                                icon labelPosition='right'
                                                size="large"
                                            >
                                                <Icon name='save' /> {text("formularios.salvar")}
                                            </Button>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            )
                            }
                        />
                    </Modal.Content>
                </Modal>

                <Helmet title={`${text("menu.mapa")} ${text("geral.html-title")}`} />
                <div className="ct-grid">
                    {!mobile &&
                        <div className="painel-menu">
                            <Menu icon vertical inverted>
                                {/* <Menu.Item
                                    onClick={this.handleZoomIn}
                                    icon='plus' 
                                />

                                <Menu.Item
                                    onClick={this.handleZoomOut}
                                    icon='minus' 
                                /> */}

                                <Menu.Item
                                    title={text("cenario-tatico-menu.filtro")}
                                    onClick={() => this.handleSidebar('filtro')}
                                    active={this.state.ctOptions.sidebar === 'filtro'}
                                    icon='filter'
                                />

                                <Menu.Item
                                    title={text("cenario-tatico-menu.camadas")}
                                    onClick={() => this.handleSidebar('camadas')}
                                    active={this.state.ctOptions.sidebar === 'camadas'}
                                    icon="clone"
                                />

                                <Menu.Item
                                    title={text("cenario-tatico-menu.acompanhamentos")}
                                    onClick={() => this.handleSidebar('aco')}
                                    active={this.state.ctOptions.sidebar === 'aco'}
                                    icon='location arrow'
                                />

                                <Menu.Item
                                    title={text("cenario-tatico-menu.locais")}
                                    onClick={() => this.handleSidebar('locais')}
                                    active={this.state.ctOptions.sidebar === 'locais'}
                                    icon='map marker alternate'
                                />

                                <Menu.Item
                                    title={text("cenario-tatico-menu.irpara")}
                                    onClick={() => this.handleSidebar('goto')}
                                    active={this.state.ctOptions.sidebar === 'goto'}
                                    icon='arrow alternate circle right'
                                />

                                {isDev() &&
                                    <Menu.Item
                                        title={text("cenario-tatico-menu.atualizar")}
                                        onClick={this.handleForceRefresh}
                                        icon='refresh'
                                    />
                                }

                            </Menu>
                        </div>
                    }
                    <div className="painel-mapa">
                        <Sidebar.Pushable as={Segment}>
                            <Sidebar
                                as={Segment}
                                animation='overlay'
                                direction='left'
                                inverted
                                onHide={() => this.handleHideSidebar}
                                vertical
                                visible={this.state.ctOptions.sidebar !== null}
                                width='wide'
                                style={{ padding: "10px" }}
                            >
                                {this.state.ctOptions.sidebar && this.renderSidebar()}
                            </Sidebar>
                            <Sidebar.Pusher dimmed={this.state.ctOptions.sidebar !== null && mobile}>

                                <Map
                                    ref='map'
                                    className="cenario-tatico"
                                    center={position}
                                    zoom={this.state.zoom}
                                    zoomControl={false}
                                    onMoveStart={this.handleMoveStart}
                                    onMoveEnd={this.handleMoveEnd}
                                    onMouseMove={this.handleMouseMove}
                                    onMouseOut={this.handleMouseOut}
                                    onBaseLayerChange={this.handleLayerChange}
                                    maxBounds={[[-90, -180], [90, 180]]}
                                    boxZoom
                                    noMoveStart
                                    style={{ height: "calc(100vh - " + diffHeight + "px)" }}
                                // doubleClickZoom={false}
                                // ondblclick={this.handleDblclick}
                                >

                                    {!mobile &&
                                        <ZoomControl className="my-zoom-control" position="topright" />
                                    }

                                    {!mobile &&
                                        <AttributionControl position="bottomleft" />
                                    }

                                    <LayersControl position="topright">

                                        {//Camadas de mapas
                                            layers.map(item =>
                                                <BaseLayer key={item.url} checked={this.isLayerChecked(item)} name={item.name}>
                                                    <TileLayer
                                                        attribution={mobile ? null : (item.attribution + attribution)}
                                                        url={item.url}
                                                    />
                                                </BaseLayer>
                                            )}

                                        {//Demais camadas
                                            !loading &&
                                            <>
                                                <CamadaEventos
                                                    frequencia={7000}
                                                    bounds={bounds}
                                                    onClick={(id, tipo) => { this.handleShowDicaObjeto(id, tipo) }}
                                                    objetoSelecionado={this.state.objetoSelecionado}
                                                    zoom={this.state.zoom}
                                                    autoUpdateDisabled={this.state.autoUpdateDisabled}
                                                />

                                                <CamadaAcompanhamentos
                                                    filtro={this.state.filtroObjetos}
                                                    frequencia={5000}
                                                    bounds={bounds}
                                                    onClick={(id, tipo) => { this.handleShowDicaObjeto(id, tipo) }}
                                                    objetoSelecionado={this.state.objetoSelecionado}
                                                    autoUpdateDisabled={this.state.autoUpdateDisabled}
                                                    onUpdateStats={(stats) => { this.handleUpdateStats(stats) }}
                                                />

                                                <CamadaAreas
                                                    filtro={this.state.filtroObjetos}
                                                    frequencia={60000}
                                                    bounds={bounds}
                                                    onClick={(id, tipo) => { this.handleShowDicaObjeto(id, tipo) }}
                                                    objetoSelecionado={this.state.objetoSelecionado}
                                                    zoom={this.state.zoom}
                                                    autoUpdateDisabled={this.state.autoUpdateDisabled}
                                                />

                                                <CamadaEstacoesMeteorologicas
                                                    filtro={this.state.filtroObjetos}
                                                    frequencia={30000}
                                                    bounds={bounds}
                                                    onClick={(id, tipo) => { this.handleShowDicaObjeto(id, tipo) }}
                                                    objetoSelecionado={this.state.objetoSelecionado}
                                                    zoom={this.state.zoom}
                                                    autoUpdateDisabled={this.state.autoUpdateDisabled}
                                                />

                                                <CamadaLocais
                                                    filtro={this.state.filtroObjetos}
                                                    frequencia={300000}
                                                    bounds={bounds}
                                                    onClick={(id, tipo) => { this.handleShowDicaObjeto(id, tipo) }}
                                                    objetoSelecionado={this.state.objetoSelecionado}
                                                    zoom={this.state.zoom}
                                                    autoUpdateDisabled={this.state.autoUpdateDisabled}
                                                />

                                                <Camadas
                                                    bounds={bounds}
                                                    filtro={this.state.filtroCamadas}
                                                    zoom={this.state.zoom}
                                                    autoUpdateDisabled={this.state.autoUpdateDisabled}
                                                />
                                            </>
                                        }
                                    </LayersControl>

                                    {mobile &&
                                        <Control position="topright" className="leaflet-bar leaflet-control">

                                            {/*eslint-disable-next-line*/}
                                            <a
                                                href="#"
                                                className="leaflet-control-custom"
                                                onClick={() => this.handleSidebar('filtro')}
                                                title={text("cenario-tatico-menu.filtro")}
                                            >
                                                <Icon name='filter' color="grey" />
                                            </a>

                                            {/*eslint-disable-next-line*/}
                                            <a
                                                href="#"
                                                className="leaflet-control-custom"
                                                onClick={() => this.handleSidebar('aco')}
                                                title={text("cenario-tatico-menu.acompanhamentos")}
                                            >
                                                <Icon name='location arrow' color="grey" />
                                            </a>

                                            {/*eslint-disable-next-line*/}
                                            <a
                                                href="#"
                                                className="leaflet-control-custom"
                                                onClick={() => this.handleSidebar('locais')}
                                                title={text("cenario-tatico-menu.locais")}
                                            >
                                                <Icon name='map marker alternate' color="grey" />
                                            </a>

                                            {/*eslint-disable-next-line*/}
                                            <a
                                                href="#"
                                                className="leaflet-control-custom"
                                                onClick={() => this.handleSidebar('goto')}
                                                title={text("cenario-tatico-menu.irpara")}
                                            >
                                                <Icon name='arrow alternate circle right' color="grey" />
                                            </a>

                                        </Control>
                                    }

                                    {!mobile &&
                                        <>
                                            < PolylineMeasure />

                                            <FeatureGroup>
                                                <EditControl
                                                    position='topright'
                                                    ref='edit'
                                                    onCreated={this.handleDrawCircle}
                                                    draw={{
                                                        polygon: false,
                                                        polyline: false,
                                                        marker: false,
                                                        circlemarker: false,
                                                        rectangle: false,
                                                        circle: {
                                                            shapeOptions: {
                                                                color: '#FF0000'
                                                            }
                                                        }
                                                    }}
                                                    edit={{
                                                        remove: false,
                                                        edit: false
                                                    }}
                                                />
                                            </FeatureGroup>
                                        </>
                                    }

                                    {!mobile &&
                                        <div className="ct-stats">

                                            <span id="mousePosition" style={{ display: 'none' }} title={text("cenario-tatico-footer.mouse")}>
                                                <Icon name="mouse pointer" /><span id="mousePositionContainer"></span>
                                            &nbsp;|&nbsp;
                                        </span>

                                            <span title={text("cenario-tatico-footer.bounds")}>
                                                <Icon name="map" />{convertDMS(bounds.latMin, bounds.lngMin)} - {convertDMS(bounds.latMax, bounds.lngMax)}
                                            &nbsp;|&nbsp;
                                        </span>

                                            <span title={text("cenario-tatico-footer.zoom")}>
                                                <Icon name="search" />{this.state.zoom}
                                            </span>

                                            {stats &&
                                                <>
                                                    &nbsp;|&nbsp;
                                                    <span title={text("cenario-tatico-footer.total-acompanhamentos")}>
                                                        <Icon name={"location arrow"} />{stats.total}
                                                    </span>
                                                    &nbsp;|&nbsp;
                                                    <span title={text("cenario-tatico-footer.acompanhamentos-visiveis")}>
                                                        <Icon name={"eye"} />{stats.area}
                                                    </span>
                                                </>
                                            }
                                        </div>
                                    }

                                    {!mobile &&
                                        <div className="layer-legend" id="layerLegend"></div>
                                    }

                                </Map>
                            </Sidebar.Pusher>
                        </Sidebar.Pushable>
                    </div>
                </div>


            </>
        )
    }
}