import React, { Component } from 'react';

import { Segment, Header } from 'semantic-ui-react'
import { text } from 'services/locales';
import LocalLink from 'components/LocalLink';
import {formatarDataHoraComTempoAtras} from 'util/dataHora'

export default class InfoEvento extends Component {

    state = {
    }

    render() {

        const { evento, local, inverted, header } = this.props

        return (
            <>
                {evento &&
                    <>
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {text("eventos.informacoes")}
                        </Header>
                        <Segment attached inverted={inverted} >

                            {evento &&
                                <>
                                    <b>{text("eventos.tipo")}:</b>&nbsp;{evento.tipo.nome} <br />
                                    <b>{text("eventos.severidade")}:</b>&nbsp;{evento.tipo.severidade.nome} <br />
                                    <b>{text("eventos.ocorrencia")}:</b>&nbsp;{formatarDataHoraComTempoAtras(evento.ocorrencia)} <br />

                                    {local &&
                                        <>
                                            <b>{text("eventos.local")}:</b> <LocalLink id={local.id} nome={local.nome} /> <br />
                                        </>
                                    }

                                </>
                            }
                        </Segment>

                    </>
                }
            </>
        )
    }
}