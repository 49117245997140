import React, { Component } from 'react';

import { Button, Container, Header, Segment, Icon } from 'semantic-ui-react'

import Cabecalho from 'components/objetos/acompanhamentos/cabecalho'
import Fotos from 'components/objetos/entidades/fotos'
import Info from 'components/objetos/acompanhamentos/info'
import Mapa from 'components/objetos/acompanhamentos/mapa'
import Cinematica from 'components/objetos/acompanhamentos/cinematica/completa'
import Telemetria from 'components/objetos/acompanhamentos/telemetria'
import Classificacao from 'components/objetos/acompanhamentos/classificacao'
import LocalLink from 'components/LocalLink'
import UserLink from 'components/UserLink'
import Footer from 'components/footer'
import { Helmet } from 'react-helmet'
import { text } from 'services/locales'
import api from 'services/api';
import { Link } from 'react-router-dom'
import { jwtSubject } from 'services/auth';

export default class Acompanhamento extends Component {

    state = {
        item: null,
        uuid: null,
        mmsi: null,
        titulo: null,
        subtitulo: null,
        loading: false,
        user: null,
        historico: [],
        parametros: []
    };

    componentDidMount() {
        if (this.props.match.params.uuid) {
            this.setState(
                { uuid: this.props.match.params.uuid }
                , () => {
                    this.interval = setInterval(() => this.handleRefresh(), 10000);
                    this.handleRefresh()
                }
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleRefresh = () => {
        this.loadItem();
    }

    loadItem = () => {
        const { uuid } = this.state;

        if (uuid) {

            this.setState({ loading: true })

            api.get("/objetos/acompanhamentos/" + uuid)
                .then((response) => {
                    this.setState({ item: response.data });

                    this.loadHistorico(response.data.cinematica.posicao.geo.lat, response.data.cinematica.posicao.geo.lng)

                    this.loadParametros()

                    var identificacao = response.data.identificacao
                    if (identificacao) {

                        if (identificacao.nome) {
                            this.setState({ titulo: identificacao.nome, subtitulo: uuid, mmsi: identificacao.mmsi });
                        } else if (identificacao.identificador) {
                            this.setState({ titulo: identificacao.identificador, subtitulo: uuid, mmsi: identificacao.mmsi });
                        } else {
                            this.setState({ titulo: uuid, mmsi: identificacao.mmsi });
                        }

                        if (identificacao.usuario)
                            this.loadUserInfo(identificacao.usuario)
                    }
                    this.setState({ loading: false })
                })
                .catch(() => {
                    this.setState({ historico: [], parametros: [], classificacao: null, user: null, item: null, titulo: null, subtitulo: null, mmsi: null, loading: false });
                })
        }
    }

    loadUserInfo = (login) => {
        api.get("/hidra/usuarios/login/" + login)
            .then(response => {
                this.setState({
                    user: response.data,
                    titulo: response.data.nome
                });
            })
            .catch(() => {
                this.setState({
                    user: null
                });
            })
    }

    loadHistorico = (lat, lng) => {
        const { uuid } = this.state;

        if (uuid === null) {
            return;
        }

        if (uuid) {
            api.get("historico/acompanhamentos/" + uuid + "/historico/recente?size=999&sort=data,desc")
                .then((response) => {
                    var historico = response.data.content.map((item) => (
                        [item.lat, item.lng]
                    ));
                    
                    if (lat && lng)
                        historico.unshift([lat, lng])//add a posição atual do objeto na primeira posição do array

                    this.setState({ historico: historico })
                })
                .catch(() => {
                    this.setState({ historico: [] })
                })
        }

    }

    loadParametros = () => {
        const { uuid } = this.state;

        if (uuid === null) {
            return;
        }

        if (uuid) {
            api.get("/objetos/acompanhamentos/" + uuid + "/parametros")
                .then(response => {
                    this.setState({ parametros: response.data });
                })
                .catch(() => {
                    this.setState({ parametros: [] });
                })
        }
    }

    render() {

        const { uuid, item, loading, historico, titulo, subtitulo, user, parametros, mmsi } = this.state;
        const classificacao = item ? item.classificacao : null;

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`${titulo ? titulo : uuid} ${text("geral.html-title")}`} />

                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>
                        <Cabecalho titulo={titulo} subtitulo={subtitulo} />
                    </div>
                    <div style={{ flex: "100px", textAlign: "right" }}>
                        <Button icon='refresh' size='large' onClick={this.handleRefresh} loading={loading} />
                    </div>
                </div>

                <br />

                <div className="page-grid-2-columns">
                    <Info uuid={uuid} item={item} showLinks />
                    <Cinematica uuid={uuid} item={item} />
                    <Mapa uuid={uuid} item={item} historico={historico} />
                    <Telemetria uuid={uuid} item={parametros} />

                    {user &&
                        <div className="box">
                            <Header as="h2" attached='top'>
                                {text("acompanhamento.usuario")}
                                {user.login !== jwtSubject() &&
                                    <Button
                                        as={Link}
                                        to={"/chat/" + user.login}
                                        floated="right"
                                        size="small"
                                        color="blue"
                                    >
                                        <Icon name="chat" />
                                        {text("usuario.conversar-com-usuario")}
                                    </Button>
                                }
                            </Header>
                            <Segment attached>
                                <b>{text("usuario.nome")}:</b>&nbsp;<UserLink disabled login={user.login} name={user.nome} /> <br />
                                {user.nomeExibicao &&
                                    <><b>{text("usuario.nomeExibicao")}:</b>&nbsp;{user.nomeExibicao} <br /></>
                                }
                                <b>{text("usuario.local")}:</b>&nbsp;<LocalLink id={user.local.id} nome={user.local.nome} /> <br />
                                <b>{text("usuario.perfil")}:</b>&nbsp;{user.perfil.nome}
                            </Segment>
                        </div>
                    }

                    <Fotos mmsi={mmsi} hash={user ? user.imagem : null} />
                    <Classificacao uuid={uuid} item={classificacao} />

                </div>

                <br /><br />

                <Footer />
            </Container>
        );
    }

}