import React, { Component } from 'react';

import { Polygon, Tooltip } from "react-leaflet";

export default class SimbologiaLocalPoligono extends Component {

    buildPositions(posicoes) {
        return posicoes
            .sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)) //ordenação para garantir o correto desenho
            .map((item) => (
                [item.lat, item.lng]
            ));
    }

    render() {

        const { item, zIndexOffset, objetoSelecionado } = this.props;
        const selecionado = (objetoSelecionado && objetoSelecionado.tipo === 'local' && objetoSelecionado.id === item.id) ? true : false

        return (
            <Polygon
                key={item.id}
                id={item.id}
                color={item.cor ? item.cor : 'red'}
                positions={this.buildPositions(item.posicoes)}
                onClick={this.props.onClick}
                fillColor={'none'}
                zIndexOffset={zIndexOffset}
                opacity={selecionado ? 1 : 0.5}
            >
                <Tooltip permanent className="tooltip" offset={[0, 0]} opacity={0.8} >
                    <b>{item.nome}</b>
                </Tooltip>
            </Polygon>
        )
    }
}