import React, { Component } from 'react';

import L from 'leaflet';
import { Marker, Tooltip } from "react-leaflet";
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components'
import { Icon, Image } from 'semantic-ui-react'
import { corVento } from 'util/beaufort'
import { text } from 'services/locales'

const Objeto = styled.div`
    display: flex;
`;

const DesenhoEstacao = styled.div`
    padding: 2px;
    background-color:${props => props.selecionado ? '#c3c3c3' : ''};
    border:${props => props.selecionado ? 'solid 1px #FF0000' : 'none'};
    text-shadow:
    -1px -1px 0 #FFF,  
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
`;

const TextoDireitaDesenhoEstacao = styled.div`
    padding: 5px 0 0 1px;
    font-size: 12px;
    text-shadow:
    -1px -1px 0 #FFF,  
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
    font-weight: bold;
`;

const TextoEsquerdaDesenhoEstacao = styled.div`
    text-align: right;    
    text-shadow:
    -1px -1px 0 #333,  
    1px -1px 0 #333,
    -1px 1px 0 #333,
    1px 1px 0 #333;
    padding: 2px 0 0 0;
    transform: rotate(${props => props.direcao}deg);
`;

export default class SimbologiaEstacaoMeteorologica extends Component {

    getStyle = (item, objetoSelecionado) => {
        return L.divIcon({
            className: 'icone-simbologia-estacao',
            html: ReactDOMServer.renderToString(
                <Objeto>

                    {!isNaN(item.direcaoVento) && item.direcaoVento && !isNaN(item.intensidadeVento) && item.intensidadeVento &&
                        <TextoEsquerdaDesenhoEstacao direcao={item.direcaoVento + 180}>
                            <Icon
                                name='long arrow alternate up'
                                size="large"
                                style={{ color: corVento(item.intensidadeVento) }}
                            />
                        </TextoEsquerdaDesenhoEstacao>
                    }

                    <DesenhoEstacao selecionado={(objetoSelecionado != null && objetoSelecionado.tipo === 'estacao' && objetoSelecionado.id === item.id)}>
                        {item.icone ? (
                            <Image
                                src={`${process.env.PUBLIC_URL}/images/tempo/${item.icone}.png`}
                                style={{ width: '20px' }}
                            />
                        ) :
                            (
                                <Icon.Group
                                    size="large"
                                >
                                    <Icon
                                        name='map marker alternate'
                                        color="blue"
                                    />
                                    <Icon
                                        corner
                                        name="cloud"
                                        color="blue"
                                    />
                                </Icon.Group>
                            )
                        }
                    </DesenhoEstacao>

                    {item.temperatura &&
                        <TextoDireitaDesenhoEstacao>
                            {Number(item.temperatura).toFixed(0)}°C
                        </TextoDireitaDesenhoEstacao>
                    }

                </Objeto>
            )
        });
    }

    render() {

        const { item, objetoSelecionado, zIndexOffset } = this.props;

        return (
            <Marker
                key={item.id}
                id={item.id}
                position={[item.lat, item.lng]}
                icon={this.getStyle(item, objetoSelecionado)}
                onClick={this.props.onClick}
                zIndexOffset={zIndexOffset}
            >
                <Tooltip className="tooltip" direction="right" offset={!isNaN(item.temperatura) && item.temperatura ? ([70, 0]) : ([10, 0])} opacity={0.9} >
                    <u>{item.nome} ({item.fonte})</u>

                    <br />

                    {item.descricao && <>{item.descricao} <br /></>}

                    {!isNaN(item.temperatura) && item.temperatura && <><b>{text("dados-meteorologicos.temperatura")}:&nbsp;</b>{Number(item.temperatura).toFixed(0)}°C <br /></>}

                    {!isNaN(item.direcaoVento) && item.direcaoVento && <><b>{text("dados-meteorologicos.vento")}:&nbsp;</b>{Number(item.direcaoVento).toFixed(0)}°</>}
                    {!isNaN(item.intensidadeVento) && item.intensidadeVento && <>/{Number(item.intensidadeVento).toFixed(1)} km/h</>}
                </Tooltip>
            </Marker>
        )
    }
}