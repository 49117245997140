import React, { Component } from 'react';

import { Segment, Header, Table } from 'semantic-ui-react'

import { text } from 'services/locales';

export default class Cinematica extends Component {

    rowSpanIdentificacaoHierarquica(valor) {
        var cont = 3;

        if (valor.naturezaEmprego)
            cont++;

        if (valor.controleOperacional)
            cont++;

        if (valor.finalidadeMeio)
            cont++;

        if (valor.plataforma)
            cont++;

        if (valor.informacaoVeiculo)
            cont++;

        return cont;
    }

    render() {

        const { item, inverted, header } = this.props

        return (
            <>

                {item && item.classificado &&
                    <div className="box">

                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {text("classificacao.classificacao")}
                        </Header>
                        <Segment inverted={inverted} attached textAlign="center">

                            <Table celled structured selectable inverted={inverted}>
                                <Table.Body>

                                    {item.hostilidade.valor &&
                                        <>
                                            <Table.Row>
                                                <Table.Cell rowSpan='3'>{text("classificacao.hostilidade")}</Table.Cell>
                                                <Table.Cell colSpan='2'>{text("classificacao.hostilidade-" + item.hostilidade.valor.hostilidade.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.grau-certeza")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.grau-certeza-" + item.hostilidade.valor.grauCerteza.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.tipo")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.tipo-" + item.hostilidade.valor.tipoClassificacao.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                        </>
                                    }
                                    {item.ambienteEspacial.valor &&
                                        <>
                                            <Table.Row>
                                                <Table.Cell rowSpan='3'>{text("classificacao.ambiente")}</Table.Cell>
                                                <Table.Cell colSpan='2'>{text("classificacao.ambiente-" + item.ambienteEspacial.valor.ambiente.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.grau-certeza")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.grau-certeza-" + item.ambienteEspacial.valor.grauCerteza.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.tipo")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.tipo-" + item.ambienteEspacial.valor.tipoClassificacao.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                        </>
                                    }
                                    {item.capacidadeOperativa.valor &&
                                        <>
                                            <Table.Row>
                                                <Table.Cell rowSpan='3'>{text("classificacao.capacidade-operativa")}</Table.Cell>
                                                <Table.Cell colSpan='2'>{text("classificacao.capacidade-operativa-" + item.capacidadeOperativa.valor.operacionabilidade.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.grau-certeza")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.grau-certeza-" + item.capacidadeOperativa.valor.grauCerteza.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.tipo")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.tipo-" + item.capacidadeOperativa.valor.tipoClassificacao.toLowerCase())}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    }
                                    {item.bandeira.valor &&
                                        <>
                                            <Table.Row>
                                                <Table.Cell rowSpan='3'>{text("classificacao.bandeira")}</Table.Cell>
                                                <Table.Cell colSpan='2'>{item.bandeira.valor.pais}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.grau-certeza")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.grau-certeza-" + item.bandeira.valor.grauCerteza.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.tipo")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.tipo-" + item.bandeira.valor.tipoClassificacao.toLowerCase())}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    }
                                    {item.identificacaoHierarquica.valor &&
                                        <>
                                            <Table.Row>
                                                <Table.Cell rowSpan={this.rowSpanIdentificacaoHierarquica(item.identificacaoHierarquica.valor)}>{text("classificacao.identificacao-hierarquica")}</Table.Cell>
                                            </Table.Row>
                                            {item.identificacaoHierarquica.valor.naturezaEmprego &&
                                                <Table.Row>
                                                    <Table.Cell>{text("classificacao.natureza-emprego")}</Table.Cell>
                                                    <Table.Cell >{item.identificacaoHierarquica.valor.naturezaEmprego} </Table.Cell>
                                                </Table.Row>
                                            }
                                            {item.identificacaoHierarquica.valor.controleOperacional &&
                                                <Table.Row>
                                                    <Table.Cell>{text("classificacao.controle-operacional")}</Table.Cell>
                                                    <Table.Cell >{item.identificacaoHierarquica.valor.controleOperacional} </Table.Cell>
                                                </Table.Row>
                                            }
                                            {item.identificacaoHierarquica.valor.finalidadeMeio &&
                                                <Table.Row>
                                                    <Table.Cell>{text("classificacao.finalidade-meio")}</Table.Cell>
                                                    <Table.Cell >{item.identificacaoHierarquica.valor.finalidadeMeio} </Table.Cell>
                                                </Table.Row>
                                            }
                                            {item.identificacaoHierarquica.valor.plataforma &&
                                                <Table.Row>
                                                    <Table.Cell>{text("classificacao.plataforma")}</Table.Cell>
                                                    <Table.Cell >{item.identificacaoHierarquica.valor.plataforma} </Table.Cell>
                                                </Table.Row>
                                            }
                                            {item.identificacaoHierarquica.valor.informacaoVeiculo &&
                                                <Table.Row>
                                                    <Table.Cell>{text("classificacao.veiculo")}</Table.Cell>
                                                    <Table.Cell >{item.identificacaoHierarquica.valor.informacaoVeiculo} </Table.Cell>
                                                </Table.Row>
                                            }
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.grau-certeza")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.grau-certeza-" + item.identificacaoHierarquica.valor.grauCerteza.toLowerCase())} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{text("classificacao.tipo")}</Table.Cell>
                                                <Table.Cell >{text("classificacao.tipo-" + item.identificacaoHierarquica.valor.tipoClassificacao.toLowerCase())}</Table.Cell>
                                            </Table.Row>


                                        </>
                                    }
                                </Table.Body>
                            </Table>
                        </Segment>
                    </div>
                }
            </>
        )
    }
}