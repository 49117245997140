import React, { Component } from 'react';

import { Segment, Header, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { text } from 'services/locales'
import { Map, TileLayer, LayersControl, Marker, ZoomControl } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import './styles.css'
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { layers } from 'util/layersOptions';
import { convertDMS } from 'util/functions'

import { getMapLayer, setMapLayer } from 'services/storage'

const { BaseLayer } = LayersControl

export default class Mapa extends Component {

    getStyle = (icon, color) => {
        return L.divIcon({
            className: '',
            html: ReactDOMServer.renderToString(
                <Icon
                    name={icon ? icon : 'map marker alternate'}
                    style={{ color: color ? color : '#808080' }}
                    size="large"
                />
            )
        });
    }

    isLayerChecked = (layer) => {
        if (getMapLayer())
            return getMapLayer() === layer.url;
        else
            return layer.checked;
    }

    handleLayerChange = (e) => {
        if (e && e.layer)
            setMapLayer(e.layer._url)
    }

    render() {

        const { title, position, zoom, icon, color, header, hidegoto } = this.props;

        return (
            <div className="box">
                <Header as={header ? header : 'h2'} attached='top'>
                    {title}
                    <Header.Subheader>
                        <small>{convertDMS(position[0],position[1])}</small>
                    </Header.Subheader>
                </Header>
                <Segment attached>
                    <div className="mapa">
                        <Map
                            ref='map'
                            onBaseLayerChange={this.handleLayerChange}
                            center={position}
                            zoom={zoom}
                            style={{ width: "100%", height: "350px" }}
                            dragging={false}
                            touchZoom={false}
                            scrollWheelZoom={false}
                            zoomControl={false}
                            maxBounds={[[-90, -180], [90, 180]]}
                        >

                            <ZoomControl position="topright" />
                            <LayersControl position="topright" >

                                {layers.map(item =>
                                    <BaseLayer key={item.url} checked={this.isLayerChecked(item)} name={item.name}>
                                        <TileLayer
                                            attribution={item.attribution}
                                            url={item.url}
                                        />
                                    </BaseLayer>
                                )}

                                <Marker
                                    position={position}
                                    icon={this.getStyle(icon, color)}
                                />

                            </LayersControl>
                        </Map>
                    </div>
                    {!hidegoto &&
                        <Button primary fluid as={Link}
                            to={{
                                pathname: '/mapa',
                                state: {
                                    goto: position
                                }
                            }}>
                            <Icon name="map marker alternate" />
                            {text("cenario-tatico.ver-no-mapa")}
                        </Button>
                    }
                </Segment>
            </div>
        )
    }
}