import React, { Component } from 'react';

import { Segment, Header, List, Button } from 'semantic-ui-react'

import api from 'services/api';
import { text } from 'services/locales';
import { Link } from 'react-router-dom'

export default class AcompanhamentosProximosLocal extends Component {

    state = {
        acompanhamentos: null
    }

    componentDidMount() {
        this.loadItens();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.center !== this.props.center || prevProps.refresh !== this.props.refresh) {
            this.loadItens();
        }
    }

    loadItens = () => {
        const { center } = this.props;

        if (center) {

            var latMin = center[0] + 0.1;
            var latMax = center[0] - 0.1;
            var lngMin = center[1] + 0.1;
            var lngMax = center[1] - 0.1;

            var url = "/objetos/acompanhamentos/mapa?latMin=" + latMin + "&lngMin=" + lngMin + "&latMax=" + latMax + "&lngMax=" + lngMax;

            api.get(url)
                .then((response) =>
                    this.setState({ acompanhamentos: response.data })
                )
                .catch(() =>
                    this.setState({ acompanhamentos: null })
                )
        }
    }

    render() {

        const { acompanhamentos } = this.state;
        const { inverted, header } = this.props

        return (
            <>
                {acompanhamentos && acompanhamentos.length > 0 &&
                    <div className="box">
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {text("local.acompanhamentos-proximos")}
                        </Header>
                        <Segment attached inverted={inverted} style={{ overflow: 'auto', maxHeight: "500px", padding: "5px" }} >
                            <List divided relaxed inverted={inverted} selection>
                                {acompanhamentos.map((item) =>
                                    <List.Item title={item.uuid} key={item.uuid} >
                                        <List.Content floated='right'>
                                            <Button
                                                title={text("cenario-tatico.ver-no-mapa")}
                                                as={Link}
                                                to={{
                                                    pathname: '/mapa',
                                                    state: {
                                                        goto: [item.lat, item.lng]
                                                    }
                                                }}
                                                icon="map marker alternate"
                                            />
                                        </List.Content>
                                        <List.Content as={Link} to={'/acompanhamento/uuid/' + item.uuid}>
                                            <List.Header>{item.nome}</List.Header>
                                            <List.Description>{item.org} - {item.uuid}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                )}
                            </List>
                        </Segment>
                    </div>
                }
            </>
        )
    }
}