import React, { Component } from 'react';

import { LayerGroup } from "react-leaflet";
import api from 'services/api';
import "./styles.css"
import 'leaflet/dist/leaflet.css';

import { comparaBounds } from 'util/functions'

import EstacaoMeteorologica from '../../simbologia/meteorologia/estacoes'

var updated= -1;

export default class CamadaEstacoesMeteorologicas extends Component {

    state = {
        estacoes: []
    }

    componentDidUpdate(prevProps) {

        var update = false;

        if (!comparaBounds(prevProps.bounds, this.props.bounds)) {
            update = true;
        }

        if (prevProps.filtro !== this.props.filtro) {
            update = true;
        }

        if (update) {
            this.handleRefreshEstacoesMeteorologicas();
        }

    }

    componentDidMount() {
        this.handleRefreshEstacoesMeteorologicas();
        this.intervalLocais = setInterval(() => this.handleRefresh(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalLocais);
    }

      //Controla o tempo de atualização da camada
    //Sempre marca o tempo da ultima atualização, 
    //Deste modo, mesmo se houver uma atualização da camada de forma manual, a frequencia será respeitada
    handleRefresh() {
        const { frequencia } = this.props;
        
        const now = new Date().getTime();

        if (now - updated > frequencia) {
            this.handleRefreshEstacoesMeteorologicas()
        }
    }

    handleRefreshEstacoesMeteorologicas() {
        updated = new Date().getTime()

        const { bounds, filtro, autoUpdateDisabled } = this.props

        if (autoUpdateDisabled)//Não atualizo esta camada se este parametro estiver ativo, ou seja a atualização automatica das camadas foi desabilitada
            return false;

        if (bounds === null || bounds.latMin === null || bounds.lngMin === null || bounds.latMax === null || bounds.lngMax === null) {
            return false;
        }

        var url = "/meteorologia/estacoes/mapa?latMin=" + bounds.latMin + "&lngMin=" + bounds.lngMin + "&latMax=" + bounds.latMax + "&lngMax=" + bounds.lngMax;

        if (filtro) {
            if (filtro.fontesMeteorologicas) {
                if (filtro.fontesMeteorologicas.length === 0) {
                    this.setState({ estacoes: [] });
                    return;
                }
                url += "&f="
                filtro.fontesMeteorologicas.forEach((item, index) => {
                    url += (index > 0 ? "," : "") + item
                });
            }
        }

        api.get(url)
            .then(response => {
                this.setState({ estacoes: response.data })
            })
            .catch(() => { })
    }

    handleClick = o => {
        this.props.onClick(o.target.options.id, "estacao")
    }

    render() {

        const { estacoes } = this.state;
        const { objetoSelecionado, zoom } = this.props;

        return (
            <LayerGroup>
                {estacoes.map((item) =>
                    <EstacaoMeteorologica
                        key={item.id}
                        item={item}
                        zoom={zoom}
                        onClick={(o) => { this.handleClick(o) }}
                        objetoSelecionado={objetoSelecionado}
                        zIndexOffset={995}
                    />
                )}
            </LayerGroup>
        )
    }
}