import React, { Component } from 'react';

import { Segment, Header, Divider, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { text } from 'services/locales';
import UserLink from 'components/UserLink';
import { formatarDataHora } from 'util/dataHora'

export default class Info extends Component {

    renderParametro(texto, valor) {
        if (valor) {
            return (
                <>
                    {texto}: {valor}<br />
                </>
            )
        }
        return null
    }

    render() {

        const { item, inverted, header, titulo, link, uuid, showLinks } = this.props

        return (
            <>

                {item &&
                    <div className="box">
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {titulo ? titulo : text("acompanhamento.informacoes-gerais")}
                        </Header>
                        <Segment attached inverted={inverted}>

                            {text("acompanhamento.visto-em")}: {formatarDataHora(item.timestamp)} <br />

                            {item.origem &&
                                <>
                                    {text("acompanhamento.origem")}: {text("origens." + item.origem.toLowerCase())}
                                </>
                            }
                            {item.descricaoOrigem &&
                                <>
                                    &nbsp;({item.descricaoOrigem})
                                            </>
                            }

                            <br />

                            {text("acompanhamento.perdido")}: {item.perdido ? text("geral.sim") : text("geral.nao")}<br />

                            <Divider />
                            {text("acompanhamento.uuid")}: {item.uuid}<br />
                            {item.identificacao.usuario &&
                                <>
                                    {text("acompanhamento.usuario")}: {showLinks ? (<UserLink login={item.identificacao.usuario} disabled />) : (item.identificacao.usuario)}
                                    <br />
                                </>
                            }
                            {item.identificacao.nome &&
                                <>
                                    {text("acompanhamento.nome")}: {item.identificacao.nome}
                                    <br />
                                </>
                            }
                            {item.identificacao.na &&
                                <>
                                    {text("acompanhamento.na")}: {item.identificacao.na}
                                    <br />
                                </>
                            }
                            {item.identificacao.mmsi &&
                                <>
                                    {text("acompanhamento.mmsi")}: {item.identificacao.mmsi}
                                    <br />
                                </>
                            }
                            {item.identificacao.imo &&
                                <>
                                    {text("acompanhamento.imo")}: {item.identificacao.imo}
                                    <br />
                                </>
                            }
                            {item.identificacao.irin &&
                                <>
                                    {text("acompanhamento.irin")}: {item.identificacao.irin}
                                    <br />
                                </>
                            }
                            {item.identificacao.costado &&
                                <>
                                    {text("acompanhamento.costado")}: {item.identificacao.costado}
                                    <br />
                                </>
                            }
                            {item.identificacao.callsign &&
                                <>
                                    {text("acompanhamento.callsign")}: {item.identificacao.callsign}
                                    <br />
                                </>
                            }
                            {item.identificacao.identificador &&
                                <>
                                    {text("acompanhamento.identificador")}: {item.identificacao.identificador}
                                    <br />
                                </>
                            }

                            {item.parametros && (item.parametros.porto_origem || item.parametros.porto_destino) &&
                                <>
                                    <Divider />

                                    {this.renderParametro(text("acompanhamento.porto-origem"), item.parametros.porto_origem)}
                                    {this.renderParametro(text("acompanhamento.porto-destino"), item.parametros.porto_destino)}

                                </>
                            }
                        </Segment>
                        {link &&
                            <Button
                                as={Link}
                                to={"/acompanhamento/uuid/" + uuid}
                                fluid
                                primary
                                attached="bottom"
                            >
                                <Icon name="plus" />
                                {text("geral.mais-detalhes")}
                            </Button>
                        }
                    </div>
                }
            </>
        )
    }
}