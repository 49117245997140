import { text } from 'services/locales'

export const getIcon = (tipo) => {

  var icon = '';

  switch (tipo) {
    case 'alarme':
      icon = 'warning circle';
      break;
    case 'alerta':
      icon = 'warning sign';
      break;
    case 'informacao':
      icon = 'info circle';
      break;
    default:
      icon = 'circle';
      break;
  }

  return icon;
}

export const optionsSeveridades = [
  { text: text('severidade-evento.alarme'), value: 'alarme', icon: getIcon('alarme'), color: '#FF0000' },
  { text: text('severidade-evento.alerta'), value: 'alerta', icon: getIcon('alerta'), color: '#FFA500' },
  { text: text('severidade-evento.informacao'), value: 'informacao', icon: getIcon('informacao'), color: '#5472C0' }
]