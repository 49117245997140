import React, { Component } from 'react';

import { Button, Icon, Header, Table, Message, Grid, Container, Confirm, Checkbox } from 'semantic-ui-react'
import api from 'services/api';
import { text } from 'services/locales';
import { Helmet } from 'react-helmet'
import UserLink from 'components/UserLink';
import { getToken } from 'services/auth'
import { formatarTempoAtras, formatarDataHoraCompleta } from 'util/dataHora'

export default class UsuariosOnline extends Component {

    state = {
        itens: [],
        loading: true,
        error: null,
        success: null,
        mostrarTudo: false
    }

    componentDidMount() {
        this.loadItens();
    }

    loadItens = () => {
        this.setState({ loading: true, error: null, success: null, itens: [] });

        let url = this.state.mostrarTudo ? '/token' : '/token/online';

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            }
        };

        api.get(url, axiosConfig)
            .then((response) => {
                if (response.data != null) {
                    this.setState({
                        loading: false,
                        itens: response.data
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    itens: [],
                    error: text("formularios.erro_request")
                });
            });
    };

    handleRefresh = () => {
        this.loadItens();
    }

    isVencido(time) {
        return (new Date().getTime() > time) ? true : false
    }

    handleRemove = item => () => {
        this.setState({ showRemoveDialog: true, itemRemove: item, error: null, success: null })
    }

    handleConfirmRemove = () => {
        this.setState({ showRemoveDialog: false, loading: true })
        const { busca, itemRemove } = this.state

        var url = '/token'
        if (itemRemove)
            url = '/token/' + itemRemove.id

        api.delete(url)
            .then((response) => {

                this.setState({
                    loading: false,
                    itemRemove: null
                });

                this.loadItens(0, busca);
            })
            .catch((error) => {
                let errorMsg = text("tabelas.erro-remover");

                if (error.response.data && error.response.data.message && error.response.data.status && error.response.data.status >= 400 && error.response.data.status < 500) {
                    errorMsg = error.response.data.message
                }

                this.setState({
                    loading: false,
                    itemRemove: null,
                    error: errorMsg
                });
            });
    }

    handleCancelRemove = () => {
        this.setState({ showRemoveDialog: false, itemRemove: null })
    }

    handleToggle = () => {
        const { mostrarTudo } = this.state;
        this.setState({ mostrarTudo: !mostrarTudo }, () => this.loadItens())
    }

    render() {

        const { itens, loading, mostrarTudo } = this.state;

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`${text("tokens.titulo")} - ${text("geral.html-title")}`} />

                <Grid stackable columns={2} >
                    <Grid.Column>
                        <Header as="h1">
                            {text("tokens.titulo")}
                            <Header.Subheader>{mostrarTudo ? text("tokens.descricao-todos") : text("tokens.descricao")}</Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Checkbox toggle active={mostrarTudo}  onClick={this.handleToggle} label={text("tokens.mostrar-tudo")} />
                        &nbsp; &nbsp;  &nbsp; 
                        <Button icon size='large' onClick={this.handleRefresh} loading={loading}>
                            <Icon name='refresh' />
                        </Button>
                    </Grid.Column>
                </Grid>

                <Confirm
                    header={this.state.itemRemove ? text("tokens.dialogo-remover-titulo") : text("tokens.dialogo-remover-tudo-titulo")}
                    content={text("tokens.dialogo-remover")}
                    open={this.state.showRemoveDialog}
                    onCancel={this.handleCancelRemove}
                    onConfirm={this.handleConfirmRemove}
                    cancelButton={text("geral.nao")}
                    confirmButton={text("geral.sim")}
                />

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.success && <Message positive onDismiss={e => this.setState({ success: null })}>{this.state.success}</Message>}

                <Table celled striped selectable >
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell>
                                {text("tokens.usuario")}
                            </Table.HeaderCell>

                            <Table.HeaderCell>
                                {text("tokens.data-criacao")}
                            </Table.HeaderCell>

                            <Table.HeaderCell>
                                {text("tokens.data-vencimento-acesso")}
                            </Table.HeaderCell>

                            <Table.HeaderCell>
                                {text("tokens.data-vencimento-renovacao")}
                            </Table.HeaderCell>

                            <Table.HeaderCell >{text("historico-login.ip")}</Table.HeaderCell>

                            <Table.HeaderCell>{text("historico-login.agente")}</Table.HeaderCell>

                            <Table.HeaderCell collapsing>
                                {text("tokens.revogar")}
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {

                            itens.map((item, index) =>
                                <Table.Row key={index} positive={item.myToken}>
                                    <Table.Cell><UserLink login={item.subject} /></Table.Cell>
                                    <Table.Cell className="primeiraLetraMaiuscula" title={formatarDataHoraCompleta(item.issuedAt)}>{formatarTempoAtras(item.issuedAt)}</Table.Cell>
                                    <Table.Cell className="primeiraLetraMaiuscula" title={formatarDataHoraCompleta(item.expAccessToken)} error={this.isVencido(item.expAccessToken)}>{formatarTempoAtras(item.expAccessToken)}</Table.Cell>
                                    <Table.Cell className="primeiraLetraMaiuscula" title={formatarDataHoraCompleta(item.expRenewToken)} error={this.isVencido(item.expRenewToken)}>{formatarTempoAtras(item.expRenewToken)}</Table.Cell>
                                    <Table.Cell>{item.ip}</Table.Cell>
                                    <Table.Cell title={item.agente}>{item.agenteFormatado}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <Button
                                            onClick={this.handleRemove(item)}
                                            color="red"
                                            icon="ban"
                                            size="tiny"
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2'>
                                {text("geral.total")}: {itens.length}
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='5' textAlign="right">
                                <small>{mostrarTudo ? text("tokens.legenda-tabela-todos") : text("tokens.legenda-tabela")}</small>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>

                </Table>
                {mostrarTudo &&
                    <Button
                        onClick={this.handleRemove(null)}
                        color="red"
                        icon="ban"
                    >
                        {text("tokens.revogar-todos")}
                    </Button>
                }

            </Container>
        );
    }

}