import React, { Component } from 'react';

import { Button, Icon, Header, Table, Message, Grid, Container } from 'semantic-ui-react'
import api from 'services/api';
import { text, html } from 'services/locales';
import { Helmet } from 'react-helmet'
import UserLink from 'components/UserLink';
import { formatarDataHoraComTempoAtras } from 'util/dataHora'

export default class HistoricoAcessos extends Component {

    state = {
        itens: [],
        busca: null,
        loading: true,
        error: null,
        sucesses: null,
        pageNumber: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        lastPage: true,
        firstPage: true,
        sort: "dataHora",
        sortDirection: 'descending'
    }

    componentDidMount() {
        this.loadItens();

        if (this.props.location.state != null) {
            this.setState({ sucesses: this.props.location.state.sucesses })
        }
    }

    loadItens = (page = 0, busca = null) => {
        this.setState({ loading: true, error: null, sucesses: null });

        let url = '/hidra/usuarios/historico?size=50&page=' + page;
        if (busca != null) {
            url += '&q=' + busca
        }

        const { sort, sortDirection } = this.state;
        if (sort != null) {
            url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
        }
        api.get(url)
            .then((response) => {
                if (response.data.content != null) {
                    this.setState({
                        loading: false,
                        itens: response.data.content,
                        totalPages: response.data.totalPages,
                        totalElements: response.data.totalElements,
                        lastPage: response.data.last,
                        firstPage: response.data.first,
                        pageNumber: response.data.number,
                        numberOfElements: response.data.numberOfElements
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    itens: [],
                    error: text("formularios.erro_request")
                });
            });
    };

    handleRefresh = () => {
        const { pageNumber } = this.state
        this.loadItens(pageNumber);
    }

    handleSearch = e => {
        e.preventDefault();
        const { busca } = this.state;
        this.loadItens(0, busca);
    }

    handleNextPage = () => {
        const { pageNumber, lastPage, busca } = this.state;
        if (lastPage === true) return;

        const novoPageNumber = pageNumber + 1;
        this.loadItens(novoPageNumber, busca);
    }
    handlePreviousPage = () => {
        const { pageNumber, firstPage, busca } = this.state;
        if (firstPage === true) return;

        const novoPageNumber = pageNumber - 1;
        this.loadItens(novoPageNumber, busca);
    }

    handleSort = clickedColumn => () => {
        const { busca, sort, sortDirection } = this.state

        this.setState({
            sortDirection: clickedColumn === sort ? (sortDirection === 'ascending' ? 'descending' : 'ascending') : 'ascending', //Se for a mesma coluna clicada, inverte a ordenação, caso contrário, define como ascendente 
            sort: clickedColumn
        }, () => {
            this.loadItens(0, busca);
        })
    }

    render() {

        const { itens, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements, sort, sortDirection, loading } = this.state;

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`${text("historico-login.titulo-tabela")} ${text("geral.html-title")}`} />

                <Grid stackable columns={2} >
                    <Grid.Column>
                        <Header as="h1">{text("historico-login.titulo-tabela")}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button icon size='large' onClick={this.handleRefresh} loading={loading}>
                            <Icon name='refresh' />
                        </Button>
                    </Grid.Column>
                </Grid>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <Table celled striped selectable sortable >
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={sort === 'usuario.login' ? sortDirection : null}
                                onClick={this.handleSort('usuario.login')}
                            >
                                {text("historico-login.usuario")}</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={sort === 'dataHora' ? sortDirection : null}
                                onClick={this.handleSort('dataHora')}
                            >
                                {text("historico-login.data")}</Table.HeaderCell>

      
                            <Table.HeaderCell
                                sorted={sort === 'agente' ? sortDirection : null}
                                onClick={this.handleSort('agente')}
                            >{text("historico-login.agente")}</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {

                            itens.map((item) =>
                                <Table.Row key={item.id}>
                                    <Table.Cell>
                                        <UserLink name={item.usuario.nomeExibicao ? item.usuario.nomeExibicao : item.usuario.nome} login={item.usuario.login} />
                                    </Table.Cell>
                                    <Table.Cell className="primeiraLetraMaiuscula" > {formatarDataHoraComTempoAtras(item.dataHora)}</Table.Cell>
                                                   <Table.Cell title={item.agente}>{item.agenteFormatado}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell >
                                {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='2'>
                                <Button
                                    disabled={lastPage}
                                    floated='right'
                                    icon labelPosition='right'
                                    size='medium'
                                    onClick={this.handleNextPage}
                                >
                                    <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                                </Button>
                                <Button
                                    disabled={firstPage}
                                    floated='right'
                                    icon labelPosition='left'
                                    size='medium'
                                    onClick={this.handlePreviousPage}
                                >
                                    <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>

                </Table>

            </Container>
        );
    }

}