import React, { Component } from 'react';

import {
    Popup,
    Placeholder
} from 'semantic-ui-react';

import api from 'services/api';
import { Link } from 'react-router-dom'
import { text } from 'services/locales'
import LocalLink from 'components/LocalLink';

const cacheUsuarios = {}

//Exibe o nome ou login de usuario em um link para a sua página
//Ao passar o mouse por cima, exibe um popup com mais informações sobre ele
export default class UserLink extends Component {

    state = {
        user: null,
        username: null
    }

    componentDidMount() {
        const { name, login } = this.props;
        if (!name) {
           this.loadUserName(login)
        }
    }

    //Carrega o nome de exibição do usuário do cache local ou do backend
    loadUserName(login) {

        let loadData = false;

        if (cacheUsuarios[login]) {//Se existir no cache local
            
            const user = cacheUsuarios[login]//obtem o objeto do usuario

            const now = Date.now();
            const diff = now - user.timestamp;

            if (diff > 60 * 60 * 1000) {//Calcula se o cache desse usuario esta muito antigo, testa se tem mais de 1h
                loadData = true;//Se sim, carrega os dados do backend
            } else {
                //Caso contrário, obtem o nome do usuário do cache
                const username = user.exibicao ? user.exibicao : user.nome;
                this.setState({ username: username });
            }

        } else {//Se não existir no cache local, carrega os dados do backend
            loadData = true;
        }

        if (loadData) {//Carrega os dados do backend
            api.get(`hidra/usuarios/login/${login}/basico`)
                .then((response) => {
                    const user = response.data;
                    user.timestamp = Date.now();
                    const username = user.exibicao ? user.exibicao : user.nome;
                    cacheUsuarios[login] = user
                    this.setState({ username: username });
                })
                .catch(() => {
                    this.setState({ username: null });
                })
        }
    }

    //Rennderiza o popup
    renderContent() {
        const { user } = this.state;

        if (user) {
            return (
                <div style={{width:'300px'}}>
                    <b>{text("usuario.nome")}:</b> &nbsp;{user.nome} <br />
                    {user.nomeExibicao &&
                        <><b>{text("usuario.nomeExibicao")}:</b> &nbsp;{user.nomeExibicao} <br /></>
                    }
                    <b>{text("usuario.local")}:</b>&nbsp; <LocalLink id={user.local.id} nome={user.local.nome} disabled /> <br />
                    <b>{text("usuario.perfil")}:</b>&nbsp;{user.perfil.nome}<br />
                    {user.habilitado ? null : (
                        <><b>{text("usuario.habilitado")}:</b>  {user.habilitado ? text("geral.sim") : text("geral.nao")} <br /></>
                    )
                    }
                </div>
            )
        } else {
            return (
                <Placeholder style={{ width: 150 }}>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            )
        }
    }

    //Carrega os dados completos do usuario
    loadUserInfo() {
        const { login } = this.props;
        api.get("/hidra/usuarios/login/" + login)
            .then((response) => {
                this.setState({ user: response.data });
            })
            .catch(() => {
                this.setState({ user: null });
            })

    }

    //Carrega os dados do usuario completo quando o mouse estiver sobre o link
    handleOpen = () => {
        this.loadUserInfo()
    }

    render() {

        //login: cache de busca para os dados do usuário
        //name: se for informado o componente não tentará buscar do cache ou do backend o nome do usuário, e usará este
        //disabled: desabilita o popup
        const { login, name, disabled } = this.props;

        //Obtem do state o nome do usuario que foi carregado do cache ou do backend
        const { username } = this.state;

        return (
            <>
                <Popup
                    trigger={
                        <Link className={!disabled ? "link-prev" : "foo"} to={"/usuario/" + login}>{name ? name : (username ? username : login)}</Link>
                    }
                    disabled={disabled}
                    hoverable
                    content={this.renderContent()}
                    onOpen={this.handleOpen}
                    mouseEnterDelay={500}
                    mouseLeaveDelay={500}
                />
            </>
        )
    }
}