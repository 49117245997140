import React, { Component } from 'react';

import SockJsClient from 'react-stomp';
import { jwtSubject } from 'services/auth';

//Esse componente tem como responsabilidade se conectar ao websocket e notificar ao componente pai novas mensagens
export default class WebsocketClient extends Component {

    handleRX(topic, msg) {
        //Notifica para o componente acima (Layout)
        if (this.props.onNewMessage)
            this.props.onNewMessage(topic, msg)
    }

    handleOnConect() {
        if (this.props.onChangeState)
            this.props.onChangeState(true)
    }

    handleOnDisconnect() {
        if (this.props.onChangeState)
            this.props.onChangeState(false)
    }

    render() {

        return (

            <SockJsClient
                url={process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_SLASH + "ws"}
                options={{ 'server': jwtSubject() }}
                autoReconnect={true}
                topics={[
                    '/hidra/chat/' + jwtSubject(), // Tópico de mensagens do chat, com o nome do usuário explicito para se consumir apenas as mensagens com esse usuário como destinatário
                    '/hidra/eventos', //Topico de eventos
                    '/hidra/suporte', //Topico de suporte geral
                    '/hidra/suporte/' + jwtSubject() //Topico de suporte para este usuário
                ]}
                onMessage={(msg, topic) => { this.handleRX(topic, msg) }}
                onConnect={() => { this.handleOnConect() }}
                onDisconnect={() => { this.handleOnDisconnect() }}
                ref={(client) => { this.clientRef = client }} />

        )
    }
}