import React, { Component } from 'react';

import { Button, Icon, Header, Table, Message, Grid, Confirm, Container, Form } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import api from 'services/api';
import { text, html } from 'services/locales';
import { getIcon } from 'util/eventos'

export default class Tabela extends Component {

  state = {
    itens: [],
    busca: null,
    loading: true,
    error: null,
    sucesses: null,
    pageNumber: 0,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    lastPage: true,
    firstPage: true,
    sort: "nome",
    sortDirection: 'ascending',
    showRemoveDialog: false,
    itemRemove: null
  }

  componentDidMount() {
    this.loadItens();

    if (this.props.location.state != null) {
      this.setState({ sucesses: this.props.location.state.sucesses })
    }
  }

  loadItens = (page = 0, busca = null) => {

    if (this.state.sucesses === text("eventos.excluido-sucesso")) {
      this.setState({ loading: true, error: null });
    } else {
      this.setState({ loading: true, error: null, sucesses: null });
    }

    let url = '/eventos/tipos?page=' + page;
    if (busca != null) {
      url += '&q=' + busca
    }

    const { sort, sortDirection } = this.state;
    if (sort != null) {
      url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
    }
    api.get(url)
      .then((response) => {
        if (response.data.content != null) {
          this.setState({
            loading: false,
            itens: response.data.content,
            totalPages: response.data.totalPages,
            totalElements: response.data.totalElements,
            lastPage: response.data.last,
            firstPage: response.data.first,
            pageNumber: response.data.number,
            numberOfElements: response.data.numberOfElements
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          itens: [],
          error: text("formularios.erro_request")
        });
      });
  };

  handleSearch = e => {
    e.preventDefault();
    const { busca } = this.state;
    this.loadItens(0, busca);
  }

  handleNextPage = () => {
    const { pageNumber, lastPage, busca } = this.state;
    if (lastPage === true) return;

    const novoPageNumber = pageNumber + 1;
    this.loadItens(novoPageNumber, busca);
  }
  handlePreviousPage = () => {
    const { pageNumber, firstPage, busca } = this.state;
    if (firstPage === true) return;

    const novoPageNumber = pageNumber - 1;
    this.loadItens(novoPageNumber, busca);
  }

  handleSort = clickedColumn => () => {
    const { busca, sort, sortDirection } = this.state

    this.setState({
      sortDirection: clickedColumn === sort ? (sortDirection === 'ascending' ? 'descending' : 'ascending') : 'ascending', //Se for a mesma coluna clicada, inverte a ordenação, caso contrário, define como ascendente 
      sort: clickedColumn
    }, () => {
      this.loadItens(0, busca);
    })
  }

  handleRemove = item => () => {
    this.setState({ showRemoveDialog: true, itemRemove: item, error: null, sucesses: null })
  }

  handleConfirmRemove = () => {
    this.setState({ showRemoveDialog: false, loading: true })
    const { busca, itemRemove } = this.state

    api.delete('/eventos/tipos/' + itemRemove.id)
      .then((response) => {
        this.setState({
          loading: false,
          itemRemove: null,
          sucesses: text("eventos.excluido-sucesso"),
        });
        this.loadItens(0, busca);
      })
      .catch((error) => {
        let errorMsg = text("tabelas.erro-remover");

        if (error.response.data && error.response.data.message && error.response.data.status && error.response.data.status >= 400 && error.response.data.status < 500) {
          errorMsg = error.response.data.message
        }

        this.setState({
          loading: false,
          itemRemove: null,
          error: errorMsg
        });
      });
  }

  handleCancelRemove = () => {
    this.setState({ showRemoveDialog: false, itemRemove: null })
  }


  render() {

    const { itens, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements, sort, sortDirection } = this.state;

    return (
      <Container style={{ padding: '2em' }}>

        <Grid stackable columns={2} >
          <Grid.Column>
            <Header as="h1">{text("eventos.tipos")}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">

            <Form >
              <Form.Group widths='equal'>
                <Form.Input
                  fluid icon='search'
                  iconPosition='left'
                  name="busca"
                  placeholder={text('eventos.buscar-tipo')}
                  value={this.state.busca ? this.state.busca : ""}
                  onChange={e => this.setState({ busca: e.target.value })}
                />
                <Button onClick={this.handleSearch} >
                  {text('tabelas.buscar')}
                </Button>
                &nbsp;
                <Button
                  icon labelPosition='right'
                  color="blue"
                  as={Link}
                  to="/admin/tipo-evento/"
                >
                  <Icon name='plus' />  {text("tabelas.novo")}
                </Button>
              </Form.Group>
            </Form>


          </Grid.Column>
        </Grid>

        {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
        {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

        <Confirm
          header={text("tabelas.dialogo-remover")}
          content={this.state.itemRemove ? ("\"" + this.state.itemRemove.nome + "\"") : ''}
          open={this.state.showRemoveDialog}
          onCancel={this.handleCancelRemove}
          onConfirm={this.handleConfirmRemove}
          cancelButton={text("geral.nao")}
          confirmButton={text("geral.sim")}
        />

        <Table celled striped selectable sortable >
          <Table.Header>
            <Table.Row>

              <Table.HeaderCell
                sorted={sort === 'nome' ? sortDirection : null}
                onClick={this.handleSort('nome')}
              >
                {text("eventos.nome")}</Table.HeaderCell>

              <Table.HeaderCell
                sorted={sort === 'descricao' ? sortDirection : null}
                onClick={this.handleSort('descricao')}
              >
                {text("geral.descricao")}</Table.HeaderCell>

              <Table.HeaderCell
                sorted={sort === 'severidade' ? sortDirection : null}
                onClick={this.handleSort('severidade')}
              >
                {text("eventos.severidade")}</Table.HeaderCell>

              <Table.HeaderCell collapsing textAlign="center">{text("tabelas.acoes")}</Table.HeaderCell>

            </Table.Row>
          </Table.Header>

          <Table.Body>
            {

              itens.map((item) =>
                <Table.Row key={item.id}>

                  <Table.Cell>{item.nome}</Table.Cell>
                  <Table.Cell>{item.descricao}</Table.Cell>
                  <Table.Cell>
                    <Icon
                      style={{ color: item.severidade.cor ? item.severidade.cor : '#808080' }}
                      name={getIcon(item.severidade.codigo)}
                    />
                    {item.severidade.nome}
                  </Table.Cell>

                  <Table.Cell >
                    <Button.Group size='mini'>
                      <Button animated as={Link} to={"/admin/tipo-evento/" + (item.id)} color="green" >
                        <Button.Content hidden>{text("tabelas.editar")}</Button.Content>
                        <Button.Content visible><Icon name='edit outline' /></Button.Content>
                      </Button>
                      <Button animated onClick={this.handleRemove(item)} color="red">
                        <Button.Content hidden >{text("tabelas.remover")}</Button.Content>
                        <Button.Content visible ><Icon name='trash alternate' /></Button.Content>
                      </Button>
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell >
                {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>
                <Button
                  disabled={lastPage}
                  floated='right'
                  icon labelPosition='right'
                  size='medium'
                  onClick={this.handleNextPage}
                >
                  <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                </Button>
                <Button
                  disabled={firstPage}
                  floated='right'
                  icon labelPosition='left'
                  size='medium'
                  onClick={this.handlePreviousPage}
                >
                  <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>

        </Table>

      </Container>
    );
  }

}