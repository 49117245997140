import React, { Component } from 'react';
import { jwtSubject } from 'services/auth'
import { Redirect } from 'react-router-dom'

export default class Perfil extends Component {
    render() {
        return (
            <Redirect to={'/usuario/' + jwtSubject()} />
        )
    }
}