import axios from "axios";
import { getToken } from "./auth";
import { getLanguage } from './locales';
import { text } from './locales'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

api.interceptors.request.use(config => {

  //Intercepto a requisição para enviar a autorização, 
  //salvo em casos que invoco a api com o cabeçalho 'removeAuthorization'
  //Isso é necessário para endpoints que não necessitam de autorização para acesso, como o /ping ou o /token/renew
  //mas se for passado um token vencido ou inválido, retornam um 401
  if (!config.headers.removeAuthorization) {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  const language = getLanguage();
  if (language) {
    config.headers['Accept-Language'] = language;
  }

  return config;
});

export const error_handling = error => {
  if (error.response) {
    if (error.response.status === 403) {
      return text("geral.nao_autorizado");
    } else {
      return error.response.data.message
    }
  } else {
    return text("geral.erro_conexao");
  }
}

export default api;