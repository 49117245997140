import React, { Component } from 'react';

import {
    Divider,
    Grid
} from 'semantic-ui-react';

//import LocaleSwitcher from 'components/LocaleSwitcher';
import AppDownload from 'components/AppDownload'
import { Link } from 'react-router-dom'
import { text, html } from 'services/locales'
import { getVersion } from 'services/storage'

export default class UserIcon extends Component {

    render() {
        return (
            <div style={{ margin: "50px 0 50px 0" }}>
                <Divider />
                <Grid columns={2} >
                    <Grid.Column>
                        <b> {process.env.REACT_APP_NAME}</b>

                        &nbsp;-&nbsp;<small>{html("geral.versao", { v: getVersion() })}</small>

                        <br />
                        <small>{process.env.REACT_APP_DESCRIPTION}</small>
                        <br />
                        <Link to="/">{text("menu.home")}</Link> | <Link to="/sobre">{text("menu.sobre")}</Link> | <Link to="/perfil">{text("menu.perfil")}</Link> {/*| <LocaleSwitcher/>*/} | <AppDownload showLink={true} />
                    </Grid.Column>

                    <Grid.Column textAlign="right">
                        <b>Powered by &copy;Hidra</b> <br />
                        <small>{html("geral.direitos1")}<br />
                            {html("geral.direitos2")}</small>

                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}