import React, { Component } from 'react';

import { Image, Segment, Header, Button } from 'semantic-ui-react'
import { text } from 'services/locales'
import api from 'services/api';

export default class Fotos extends Component {

    state = {
        imagens: null,
        atual: 0
    }

    componentDidMount() {
        this.loadItens();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mmsi !== this.props.mmsi || prevProps.hash !== this.props.hash) {
            this.loadItens();
        }
    }

    loadItens = () => {
        const { hash, mmsi } = this.props;

        if (hash) {
            api.get("/arquivos/image/" + hash)
                .then((response) => {
                    const imagens = response.data.files.map((item) => ({
                        url: item.url
                    }))
                    this.setState({ imagens: imagens })
                })
                .catch(() => {
                    this.setState({ imagens: null })
                })
        }else{
            this.setState({imagens: null})
        }

        if (mmsi) {
            api.get("/arquivos/mmsi/" + mmsi)
                .then((response) => {
                    const imagens = response.data.files.map((item) => ({
                        url: item.url
                    }))
                    this.setState({ imagens: imagens, loading: false })
                })
                .catch(() => {
                    this.setState({ loading: false })
                })
        }else{
            this.setState({imagens: null})
        }
    }

    handleNextPage = () => {
        const { imagens, atual } = this.state;
        if (atual === imagens.length - 1) return;
        this.setState({ atual: atual + 1 })
    }

    handlePreviousPage = () => {
        const { atual } = this.state;
        if (atual === 0) return;
        this.setState({ atual: atual - 1 })
    }

    render() {

        const { imagens, atual } = this.state;
        const { inverted, header, buttonSize } = this.props

        return (
            <>
                {imagens && imagens.length > 0 &&
                    <div className="box">
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "100%" }}>
                                    {imagens.length === 1 ? text("geral.foto") : text("geral.fotos")}
                                </div>

                                {imagens.length > 1 &&
                                    <div style={{ flex: "150px", textAlign: "right" }}>
                                        <Button
                                            disabled={atual === 0}
                                            icon="angle left"
                                            size={buttonSize ? buttonSize : 'medium'}
                                            onClick={this.handlePreviousPage}
                                            title={text("tabelas.pagina-anterior")}
                                            inverted={inverted}
                                        />
                                        <Button
                                            disabled={atual === imagens.length - 1}
                                            icon="angle right"
                                            size={buttonSize ? buttonSize : 'medium'}
                                            onClick={this.handleNextPage}
                                            title={text("tabelas.proxima-pagina")}
                                            inverted={inverted}
                                        />
                                    </div>
                                }
                            </div>
                        </Header>
                        <Segment attached inverted={inverted} style={{padding:"3px"}}>
                            <Image fluid src={
                                process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_SLASH + "arquivos/" + imagens[atual].url
                            } />
                        </Segment>
                    </div>
                }
            </>
        )
    }
}