import React, { Component } from 'react';

import { Segment, Header, Button, Icon } from 'semantic-ui-react'

import api from 'services/api';
import { text } from 'services/locales';
import { Link } from 'react-router-dom'
import { convertDMS } from 'util/functions'

export default class Info extends Component {

    state = {
        item: null,
        endereco: null
    }

    componentDidMount() {
        this.loadItens();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id || prevProps.refresh !== this.props.refresh) {
            this.loadItens();
        }
    }

    loadItens = () => {
        const { id } = this.props;

        if (id) {
            api.get("/hidra/locais/" + id)
                .then((response) =>
                    this.setState({ item: response.data })
                )
                .catch(() =>
                    this.setState({ item: null })
                )

            api.get("/hidra/entidades/" + id + "/endereco")
                .then((response) =>
                    this.setState({ endereco: response.data })
                )
                .catch(() =>
                    this.setState({ endereco: null })
                )
        }
    }

    render() {

        const { item, endereco } = this.state;
        const { inverted, header, bntVerNoMapa, titulo } = this.props

        return (
            <>

                {item &&
                    <div className="box">
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {titulo ? titulo : text("local.informacoes-gerais")}
                        </Header>
                        <Segment attached inverted={inverted}>

                            <b>{text("local.nome")}:</b> {item.nome} <br />

                            <b>{text("local.sigla")}:</b> {item.sigla} <br />

                            <b>{text("local.tipo")}:</b> {item.tipo.nome} <br />

                            <b>{text("local.posicao")}:</b> {convertDMS(item.lat, item.lng)} <br />

                            {endereco &&
                                <>
                                    <b>{text("entidade.endereco")}:</b> {endereco.enderecoFormatado} <br />
                                </>
                            }

                            {bntVerNoMapa &&
                                <>

                                    <br />
                                    <Button primary fluid as={Link}
                                        to={{
                                            pathname: '/mapa',
                                            state: {
                                                goto: [item.lat, item.lng]
                                            }
                                        }}>
                                        <Icon name="map marker alternate" />
                                        {text("cenario-tatico.ver-no-mapa")}
                                    </Button>
                                </>
                            }

                        </Segment>
                    </div>
                }
            </>
        )
    }
}