import React, { Component } from 'react';

import { Button, Icon, Table, Dropdown, Header, Loader, Image, Popup } from 'semantic-ui-react'
import DadosMeteorologicos from 'components/objetos/meteorologia/dadosMeteorologicos'
import api from 'services/api';
import { text, html } from 'services/locales';
import { formatarDataHoraComTempoAtras } from 'util/dataHora'

export default class TabelaHistoricoDadosMeteorologicos extends Component {

    state = {
        itens: [],
        loading: true,
        error: null,
        pageNumber: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        lastPage: true,
        firstPage: true,
        pageSize: 10,
        sort: "dataHora",
        sortDirection: 'descending',
    }

    componentDidMount() {
        this.loadItens();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.estacao !== this.props.estacao || prevProps.refresh !== this.props.refresh) {
            this.loadItens();
        }
    }

    loadItens = (page = 0, busca = null) => {
        this.setState({ loading: true, error: null });

        const { sort, sortDirection, pageSize } = this.state;
        const { estacao } = this.props

        let url = '/meteorologia/dados-meteorologicos?e=' + estacao + '&page=' + page + "&size=" + pageSize;
        if (busca != null) {
            url += '&q=' + busca
        }

        if (sort != null) {
            url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
        }

        api.get(url)
            .then(response => {
                if (response.data.content != null) {
                    this.setState({
                        loading: false,
                        itens: response.data.content,
                        totalPages: response.data.totalPages,
                        totalElements: response.data.totalElements,
                        lastPage: response.data.last,
                        firstPage: response.data.first,
                        pageNumber: response.data.number,
                        numberOfElements: response.data.numberOfElements
                    });
                }
            })
            .catch(error => {
                var errorMsg = text("formularios.erro_request")
                if (error.response && error.response.data && error.response.data.message)
                    errorMsg = error.response.data.message
                this.setState({
                    loading: false,
                    itens: [],
                    error: errorMsg
                });
            });
    };

    handleChangeDropdown = (e, { name, value }) => {
        const { busca } = this.state
        this.setState({ [name]: value }, () => { this.loadItens(0, busca); });
    }

    handleRefresh = () => {
        const { pageNumber, busca } = this.state
        this.loadItens(pageNumber, busca);
    }

    handleSearch = e => {
        e.preventDefault();
        const { busca } = this.state;
        this.loadItens(0, busca);
    }

    handleNextPage = () => {
        const { pageNumber, lastPage, busca } = this.state;
        if (lastPage === true) return;

        const novoPageNumber = pageNumber + 1;
        this.loadItens(novoPageNumber, busca);
    }
    handlePreviousPage = () => {
        const { pageNumber, firstPage, busca } = this.state;
        if (firstPage === true) return;

        const novoPageNumber = pageNumber - 1;
        this.loadItens(novoPageNumber, busca);
    }

    handleSort = clickedColumn => () => {
        const { busca, sort, sortDirection } = this.state

        this.setState({
            sortDirection: clickedColumn === sort ? (sortDirection === 'ascending' ? 'descending' : 'ascending') : 'ascending', //Se for a mesma coluna clicada, inverte a ordenação, caso contrário, define como ascendente 
            sort: clickedColumn
        }, () => {
            this.loadItens(0, busca);
        })
    }


    render() {

        const { itens, loading, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements, sort, sortDirection } = this.state;

        const optionsPageSize = [
            { key: 10, text: '10', value: 10 },
            { key: 30, text: '30', value: 30 },
            { key: 50, text: '50', value: 50 },
            { key: 100, text: '100', value: 100 },
        ]

        return (
            <>
                {totalElements > 0 &&
                    <>
                        <Header as="h2">
                            {text("dados-meteorologicos.historico")}
                    &nbsp;
                    {loading &&
                                <Loader active inline size="mini" />
                            }
                        </Header>

                        <Table celled striped selectable sortable >

                            <Table.Header>
                                <Table.Row>

                                    <Table.HeaderCell
                                        sorted={sort === 'dataHora' ? sortDirection : null}
                                        onClick={this.handleSort('dataHora')}>
                                        {text("dados-meteorologicos.ultima-consulta")}</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        {text("dados-meteorologicos.temperatura")}</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        {text("dados-meteorologicos.vento")}</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        {text("dados-meteorologicos.situacao")}</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>{text("tabelas.detalhes")}</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {

                                    itens.map((item) =>
                                        <Table.Row key={item.id}>
                                            <Table.Cell className="primeiraLetraMaiuscula" >
                                                {formatarDataHoraComTempoAtras(item.dataHora)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!isNaN(item.temperatura) && item.temperatura && <>{Number(item.temperatura).toFixed(0)}°C</>}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!isNaN(item.direcaoVento) && item.direcaoVento && <>{Number(item.direcaoVento).toFixed(0)}°</>}/
                                                {!isNaN(item.intensidadeVento) && item.intensidadeVento && <>{Number(item.intensidadeVento).toFixed(1)} km/h</>}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.icone &&
                                                    <Image
                                                        src={`${process.env.PUBLIC_URL}/images/tempo/${item.icone}.png`}
                                                        style={{ width: "20px" }}
                                                        spaced
                                                    />
                                                }
                                                {item.descricaoTempo ? item.descricaoTempo : "-"}
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <Popup
                                                    on='click'
                                                    position='top right'
                                                    wide="very"
                                                    trigger={
                                                        <Button icon size="mini">
                                                            <Icon name='plus' />
                                                        </Button>
                                                    }
                                                    content={
                                                        <DadosMeteorologicos
                                                            item={item}
                                                        />
                                                    }
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='2'>
                                        {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
                                &nbsp;{text("tabelas.tamanho-pagina")}&nbsp;
                                <Dropdown
                                            compact
                                            selection
                                            value={this.state.pageSize}
                                            options={optionsPageSize}
                                            onChange={this.handleChangeDropdown}
                                            name="pageSize"
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell colSpan='4'>
                                        <Button
                                            disabled={lastPage}
                                            floated='right'
                                            icon labelPosition='right'
                                            size='medium'
                                            onClick={this.handleNextPage}
                                        >
                                            <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                                        </Button>
                                        <Button
                                            disabled={firstPage}
                                            floated='right'
                                            icon labelPosition='left'
                                            size='medium'
                                            onClick={this.handlePreviousPage}
                                        >
                                            <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>

                        </Table>

                    </>
                }
            </>
        );
    }

}