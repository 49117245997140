import React, { Component } from 'react';

import { Container, Button, Icon, Message, Form, Divider } from 'semantic-ui-react'
import { Formik } from 'formik';
import api from 'services/api';
import { text } from 'services/locales';

export default class AlterarSenha extends Component {

    state = {
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true,
    };


    render() {
        return (

            <Container style={{ padding: '0.5em' }}>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <br />
                <Formik
                    initialValues={{
                        senhaAtual: '',
                        senhaNova: '',
                        senhaNova2: ''
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        this.setState({ error: null, submitting: true, sucesses: null });

                        api.put('/hidra/usuarios/senha',
                            {
                                "senhaAtual": values.senhaAtual,
                                "senhaNova": values.senhaNova,
                                "senhaNova2": values.senhaNova2
                            }
                        )
                            .then((response) => {
                                if (response.data.error != null) {
                                    this.setState({ submitting: false, error: response.data });
                                    window.scrollTo(0, 0)
                                } else {
                                    resetForm()
                                    this.setState({ submitting: false, error: null, sucesses: text("usuario.senha-alterada") });
                                    window.scrollTo(0, 0)
                                }
                            })
                            .catch((error) => {
                                var mensagemErro = text("formularios.erro");
                                if (error.response && error.response.data) {
                                    window.scrollTo(0, 0)
                                    mensagemErro = error.response.data.message;
                                } else if (error.request) {
                                    window.scrollTo(0, 0)
                                    mensagemErro = text("formularios.erro_request")
                                }
                                this.setState({
                                    submitting: false,
                                    error: mensagemErro
                                });
                            });
                    }
                    }
                    render={({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (
                        <Form
                            onSubmit={handleSubmit}
                        >

                            <Form.Field required>
                                <Form.Field required inline>
                                    <label>{text("usuario.senha-atual")}</label><br />
                                    <input
                                        placeholder={text("usuario.senha-atual")}
                                        type='password'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.senhaAtual}
                                        name="senhaAtual"
                                        autoComplete="new-password"
                                    />
                                </Form.Field>
                            </Form.Field>

                            <Form.Field required>
                                <Form.Field required inline>
                                    <label>{text("usuario.senha-nova")}</label><br />
                                    <input
                                        placeholder={text("usuario.senha-nova")}
                                        type='password'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.senhaNova}
                                        name="senhaNova"
                                        autoComplete="new-password"
                                    /><br />
                                    <small>{text("usuario.senha-descricao")}</small>
                                </Form.Field>
                            </Form.Field>

                            <Form.Field required>
                                <Form.Field required inline>
                                    <label>{text("usuario.senha-nova2")}</label><br />
                                    <input
                                        placeholder={text("usuario.senha-nova2")}
                                        type='password'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.senhaNova2}
                                        name="senhaNova2"
                                        autoComplete="new-password"
                                    />
                                </Form.Field>
                            </Form.Field>

                            <br />
                            <Divider />
                            <br />

                            <Button
                                primary
                                loading={this.state.submitting}
                                type='submit'
                                floated="right"
                                icon labelPosition='right'
                                size="big"
                                disabled={this.state.submitting}
                            >
                                <Icon name='save' /> {text("formularios.atualizar")}
                            </Button>
                        </Form>
                    )}
                />
            </Container>
        )
    }
}