import React, { Component } from 'react';

import { Button, Icon, Image, Container, Header, Segment } from 'semantic-ui-react'

import api from 'services/api';
import Footer from 'components/footer'
import { Helmet } from 'react-helmet'
import { text } from 'services/locales'
import { Link } from 'react-router-dom'

import DadosMeteorologicos from 'components/objetos/meteorologia/dadosMeteorologicos'
import TabelaHistoricoDadosMeteorologicos from './historico'

import AcompanhamentosProximos from 'components/objetos/locais/acompanhamentos-proximos'

import { convertDMS } from 'util/functions'

export default class EstacaoMeteorologica extends Component {

    state = {
        item: null,
        loading: false,
        id: null,
        refresh: -1
    };

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState(
                {
                    item: null,
                    loading: true,
                    id: this.props.match.params.id,
                    refresh: -1
                }
                , () => {
                    this.loadItem()
                }
            )
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState(
                { id: this.props.match.params.id }
                , () => { this.loadItem(); }
            );
        }
    }

    loadItem = () => {
        this.setState({ loading: true, error: null });

        const { id } = this.state;

        let url = '/meteorologia/estacoes/' + id;

        api.get(url)
            .then((response) => {
                if (response.data != null) {
                    this.setState({
                        loading: false,
                        item: response.data
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    item: null,
                    error: text("formularios.erro_request")
                });
            });
    }
    handleRefresh = () => {
        this.loadItem()
        this.setState({ refresh: new Date().getTime() })
    }

    render() {

        const { item, id, loading, refresh } = this.state;

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`${item ? item.nome : id} ${text("geral.html-title")}`} />

                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>

                        <Header as="h1">
                            {item && item.dadoMeteorologicoRecente && item.dadoMeteorologicoRecente.icone &&
                                <Image
                                    src={`${process.env.PUBLIC_URL}/images/tempo/${item.dadoMeteorologicoRecente.icone}.png`}
                                    size="big"
                                />
                            }
                            <Header.Content>
                                {item ? item.nome : ''}
                                <Header.Subheader>
                                    {text("dados-meteorologicos.estacao-meteorologica")} {item ? (<>({item.fonte.sigla})</>) : null}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </div>

                    <div style={{ flex: "100px", textAlign: "right" }}>
                        <Button.Group icon>
                            <Button loading={loading} toggle icon onClick={this.handleRefresh}>
                                <Icon name='refresh' />
                            </Button>
                        </Button.Group>
                    </div>
                </div>

                <br />

                <div className="page-grid-2-columns">

                    {item &&
                        <>
                            <div className="box">
                                <Header as="h2" attached="top" >
                                    {text("geral.informacoes-gerais")}
                                </Header>
                                <Segment attached >
                                    <b>{text("dados-meteorologicos.estacao")}:</b> {item.nome}<br />
                                    <b>{text("dados-meteorologicos.fonte")}:</b> {item.fonte.nome} {item.fonte.sigla !== item.fonte.nome ? ("(" + item.fonte.sigla + ")") : null}<br />
                                    <b>{text("geo.posicao")}:</b> {convertDMS(item.lat, item.lng)}<br />

                                    <br />
                                    <Button primary fluid as={Link}
                                        to={{
                                            pathname: '/mapa',
                                            state: {
                                                goto: [item.lat, item.lng]
                                            }
                                        }}>
                                        <Icon name="map marker alternate" />
                                        {text("cenario-tatico.ver-no-mapa")}
                                    </Button>

                                </Segment>
                            </div>

                            <div className="box">
                                <Header as='h2' attached='top'>
                                    {text("dados-meteorologicos.dados-meteorologicos")}
                                </Header>
                                <Segment attached invrted>
                                    {item.dadoMeteorologicoRecente ? (
                                        <DadosMeteorologicos
                                            item={item.dadoMeteorologicoRecente}
                                        />
                                    ) : (
                                            <>
                                                <br />
                                                {text("dados-meteorologicos.nenhum-dados-meteorologicos")}
                                                <br /><br />
                                            </>
                                        )
                                    }

                                </Segment>
                            </div>

                            <AcompanhamentosProximos refresh={refresh} center={item ? [item.lat, item.lng] : null} />

                        </>
                    }

                </div>

                <TabelaHistoricoDadosMeteorologicos
                    estacao={id}
                    refresh={refresh}
                />

                <Footer />
            </Container>
        );
    }

}