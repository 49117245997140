import React, { Component } from 'react';

import { Button, Form, Icon, Message, Divider, Container, Grid, Dropdown, Confirm } from 'semantic-ui-react'
import { Formik } from "formik";
import api from 'services/api';
import { text } from 'services/locales';

import InputMask from 'react-input-mask'

export default class cadastroEndereco extends Component {

    state = {
        redirect: false,
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true,
        cidades: [],
        paises: [],
        estados: [],
        dropEstado: true,
        dropCidade: true,
        cep: '',
        showRemoveDialog: false,
        itemRemove: null,
        disabled: true,
        paisDefault: "BR" // Brasil como valor default
    };

    componentDidMount() {
        this.loadItens()
    }

    loadItens = () => {
        this.setState({ loading: true });

        if (this.props) {
            const { id } = this.props;

            api.get("/hidra/entidades/" + id + "/endereco")
                .then((response) => {
                    this.setState({
                        item: response.data,
                        pais: response.data.cidade.estado.pais.iso,
                        estado: response.data.cidade.estado.uf,
                        cidade: response.data.cidade.id,
                        cep: response.data.cep,
                        logradouro: response.data.logradouro,
                        bairro: response.data.bairro,
                        dropEstado: false,
                        dropCidade: false,
                        disabled: false
                    }, () => {
                        this.handleEstado()
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        item: null,
                    });
                });

            api.get("/hidra/localizacao/pais?size=999")
                .then((response) => {
                    const paises = response.data.content.map((item) => ({
                        key: item.id,
                        text: item.nome,
                        value: item.iso,
                    }));
                    this.setState({
                        paises: paises
                    }
                    );
                }).catch((error) => {
                    this.setState({
                        loading: false
                    });
                });

            // Somente para país como valor default
            api.get("/hidra/localizacao/estados/pais/" + this.state.paisDefault + "?size=999")
                .then((response) => {
                    const estados = response.data.content.map((item) => ({
                        key: item.id,
                        text: item.nome,
                        value: item.uf,
                    }));
                    this.setState({
                        estados: estados,
                        dropEstado: false
                    });
                }).catch((error) => {
                    this.setState({
                        loading: false
                    });
                });
        }

    }

    handleEstado() {
        api.get("/hidra/localizacao/estados/pais/" + this.state.pais + "?size=999")
            .then((response) => {
                const estados = response.data.content.map((item) => ({
                    key: item.id,
                    text: item.nome,
                    value: item.uf,
                }));
                this.setState({
                    estados: estados,
                }, () => {
                    this.handleCidade()
                });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }

    handleCidade() {
        api.get("/hidra/localizacao/cidades/estado/" + this.state.estado + "?size=999")
            .then((response) => {
                const cidades = response.data.content.map((item) => ({
                    key: item.id,
                    text: item.nome,
                    value: item.id,
                }));
                this.setState({
                    loading: false,
                    cidades: cidades,
                });
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            });
    }

    handleRemove = item => () => {
        this.setState({ showRemoveDialog: true, itemRemove: item, error: null, sucesses: null })
    }

    handleConfirmRemove = () => {
        this.setState({ showRemoveDialog: false, loading: true })
        const { itemRemove } = this.state

        api.delete('/hidra/entidades/' + itemRemove + '/endereco')
            .then((response) => {
                this.setState({
                    sucesses: text("entidade.endereco-remover"),
                    loading: false,
                    itemRemove: null,
                    estado: null,
                    cidade: null,
                    dropCidade: true,
                    item: null,
                    disabled: true,
                    cep: ' ', // Com espaço para apagar o input
                    logradouro: '',
                    bairro: '',
                    // dropEstado: true // somente se não houver país default
                });
            })
            .catch((error) => {
                let errorMsg = text("tabelas.erro-remover");

                if (error.response.data && error.response.data.message && error.response.data.status && error.response.data.status >= 400 && error.response.data.status < 500) {
                    errorMsg = error.response.data.message
                }

                this.setState({
                    loading: false,
                    itemRemove: null,
                    error: errorMsg
                });
            });
    }

    handleCancelRemove = () => {
        this.setState({ showRemoveDialog: false, itemRemove: null })
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangePais = (e, { name, value }) => {
        //Somente será usada para país sem valor default
        this.setState({
            [name]: value,
            dropEstado: false
        });

        if (value !== undefined) {
            api.get("/hidra/localizacao/estados/pais/" + value + "?size=999") //pais
                .then((response) => {
                    const estados = response.data.content.map((item) => ({
                        key: item.id,
                        text: item.nome,
                        value: item.uf,
                    }));
                    this.setState({
                        loading: false,
                        estados: estados,
                        estado: null,
                        cidade: null
                    });
                }).catch((error) => {
                    this.setState({
                        loading: false
                    });
                });
        }
    }

    handleChangeEstado = (e, { name, value }) => {
        this.setState({
            [name]: value,
            dropCidade: false
        });

        if (value !== undefined && this.state.pais !== null) {
            api.get("/hidra/localizacao/cidades/estado/" + value + "?size=999") // uf
                .then((response) => {
                    const cidades = response.data.content.map((item) => ({
                        key: item.id,
                        text: item.nome,
                        value: item.id,
                    }));
                    this.setState({
                        loading: false,
                        cidades: cidades
                    });
                }).catch((error) => {
                    this.setState({
                        loading: false
                    });
                });
        }

    }

    handleChangeCidade = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    handleCEP(element) {
        var split = element.split(/[^\d\w]+/);
        if (split.length === 2) {
            var cep = split[0] + split[1];
        } else {
            cep = split
        }
        return String(cep)
    }

    render() {

        const { item, loading, submitting, disabled } = this.state;
        const { id } = this.props

        return (
            <Container style={{ padding: '0.5em', backgroundColor: "#fff" }}>
                <Formik
                    initialValues={{
                        pais: item ? this.state.pais : '',
                        estado: item ? this.state.estado : '',
                        cidade: item ? this.state.cidade : '',
                        cep: item ? this.state.cep : '',
                        logradouro: item ? this.state.logradouro : '',
                        bairro: item ? this.state.bairro : ''
                    }}
                    onSubmit={() => {
                        this.setState({ error: null, submitting: true, sucesses: null });
                        api.post('/hidra/entidades/' + id + '/endereco',
                            {
                                "cidade": this.state.cidade,
                                "cep": this.handleCEP(this.state.cep),
                                "logradouro": this.state.logradouro,
                                "bairro": this.state.bairro
                            }
                        )
                            .then((response) => {
                                if (response.data.error != null) {
                                    this.setState({ submitting: false, error: response.data });
                                    window.scrollTo(0, 0)
                                } else {
                                    this.setState({ submitting: false, error: null, sucesses: text("entidade.endereco-atualizado"), disabled: false }, this.loadItens());
                                    window.scrollTo(0, 0)
                                    this.props.onSubmit();
                                }
                            })
                            .catch((error) => {
                                var mensagemErro = text("formularios.erro");
                                if (error.response && error.response.data.message) {
                                    mensagemErro = error.response.data.message;
                                    window.scrollTo(0, 0)
                                } else if (error.request) {
                                    mensagemErro = text("formularios.erro_request")
                                    window.scrollTo(0, 0)
                                }
                                this.setState({
                                    submitting: false,
                                    error: mensagemErro
                                });
                            });
                    }}
                    render={({
                        handleSubmit,
                    }) => (
                            <Form
                                onSubmit={handleSubmit}
                                loading={loading}
                            >
                                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                                <Form.Group widths="equal">
                                    <Form.Field required style={{ width: '150px' }}>
                                        <label>{text("entidade.país")}</label>
                                        <Dropdown
                                            placeholder={text("entidade.país")}
                                            onChange={this.handleChangePais}
                                            value={this.state.pais}
                                            defaultValue={this.state.paisDefault}
                                            name="pais"
                                            search
                                            selection
                                            options={this.state.paises}
                                        />
                                    </Form.Field>

                                    <Form.Field required style={{ width: '150px' }}>
                                        <label>{text("entidade.estado")}</label>
                                        <Dropdown
                                            disabled={this.state.dropEstado}
                                            placeholder={text("entidade.estado")}
                                            onChange={this.handleChangeEstado}
                                            value={this.state.estado}
                                            name="estado"
                                            search
                                            selection
                                            options={this.state.estados}
                                        />
                                    </Form.Field>

                                    <Form.Field required style={{ width: '150px' }}>
                                        <label>{text("entidade.cidade")}</label>
                                        <Dropdown
                                            disabled={this.state.dropCidade}
                                            placeholder={text("entidade.cidade")}
                                            onChange={this.handleChangeCidade}
                                            value={this.state.cidade}
                                            name="cidade"
                                            search
                                            selection
                                            options={this.state.cidades}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Field required style={{ width: '150px' }}>
                                    <label>{text("entidade.cep")}</label>
                                    <InputMask
                                        mask={"99999-999"}
                                        placeholder={text("entidade.cep")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.cep}
                                        name="cep"
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label>{text("entidade.logradouro")}</label>
                                    <Form.Input
                                        placeholder={text("entidade.logradouro")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.logradouro}
                                        name="logradouro"
                                        width={10}
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label>{text("entidade.bairro")}</label>
                                    <Form.Input
                                        placeholder={text("entidade.bairro")}
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.bairro}
                                        name="bairro"
                                        width={3}
                                    />
                                </Form.Field>
                                <Confirm
                                    header={text("tabelas.dialogo-remover")}
                                    content={null}
                                    open={this.state.showRemoveDialog}
                                    onCancel={this.handleCancelRemove}
                                    onConfirm={this.handleConfirmRemove}
                                    cancelButton={text("geral.nao")}
                                    confirmButton={text("geral.sim")}
                                />
                                <br />
                                <Divider />
                                <br />
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Button
                                            disabled={disabled}
                                            onClick={this.handleRemove(id)}
                                            color="red"
                                            type="button"
                                            icon labelPosition='left'
                                            size="big"
                                        >
                                            <Icon name='trash alternate' /> {text("tabelas.remover")}
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            primary
                                            type="submit"
                                            loading={submitting}
                                            onClick={handleSubmit}
                                            icon labelPosition='right'
                                            size="big"
                                            disabled={loading || submitting}
                                        >
                                            <Icon name='save' /> {text("formularios.salvar")}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        )
                    }
                />

            </Container >
        );
    }

}