import React, { Component } from 'react';

import { ImageOverlay, TileLayer } from "react-leaflet";
import { comparaBounds } from 'util/functions'

export default class Mapa extends Component {

    state = {
        imagemRadarMeteorologico: "http://alertario.rio.rj.gov.br/upload/Mapa/semfundo/radar001.png?query=0",
        contadorRadarMeteorologico: 1,
        exibirRadarMeteorologico: false
    }

    componentDidMount() {
        this.atualiza();
    }

    componentWillUnmount() {
        clearInterval(this.intervalRadar);
    }

    componentDidUpdate(prevProps) {
        var update = false;

        if (!comparaBounds(prevProps.bounds, this.props.bounds)) {
            update = true;
        }

        if (prevProps.filtro !== this.props.filtro) {
            update = true;
        }

        if (update) {
            this.atualiza();
        }
    }

    atualiza() {
        const { filtro } = this.props;

        if (!filtro) {
            return;
        }

        var exibir = filtro.radarMeteorologico;

        //TODO verificar se o o radar esta na area visivel do bound 
        this.setState({ exibirRadarMeteorologico: exibir })

        if (exibir && !this.intervalRadar) {
            this.intervalRadar = setInterval(() => this.atualizaRadarMeteorologico(), 1000);
        }
        if (!exibir && this.intervalRadar) {
            clearInterval(this.intervalRadar);
            this.intervalRadar = null
        }

    }

    atualizaRadarMeteorologico() {

        const { autoUpdateDisabled } = this.props;

        if (autoUpdateDisabled)//Não atualizo esta camada se este parametro estiver ativo, ou seja a atualização automatica das camadas foi desabilitada
            return;

        var { contadorRadarMeteorologico } = this.state;

        contadorRadarMeteorologico++;
        if (contadorRadarMeteorologico > 20) {
            contadorRadarMeteorologico = 1;
        }

        const imagemRadarMeteorologico = `http://alertario.rio.rj.gov.br/upload/Mapa/semfundo/radar${contadorRadarMeteorologico < 10 ? "00" + contadorRadarMeteorologico : "0" + contadorRadarMeteorologico}.png?query=0`;

        this.setState({ imagemRadarMeteorologico: imagemRadarMeteorologico, contadorRadarMeteorologico: contadorRadarMeteorologico })
    }

    render() {
        const { exibirRadarMeteorologico, imagemRadarMeteorologico } = this.state;
        const { filtro, zoom } = this.props;
        return (
            <>
                {filtro && filtro.radarMeteorologico && exibirRadarMeteorologico &&
                    <ImageOverlay
                        bounds={[
                            [-24.431566999999998, -45.336972],
                            [-21.478793, -41.159092],
                        ]}
                        opacity="0.8"
                        url={imagemRadarMeteorologico}
                        zIndex={999}
                    />
                }

                {/* {filtro && filtro.cina &&
                    <ImageOverlay
                        bounds={[
                            [-23.392114, -47.608808],
                            [-23.402539, -47.592197],
                        ]}
                        opacity="0.9"
                        url={process.env.PUBLIC_URL + '/images/cina.png'}
                    />
                } */}

                {zoom < 8 && filtro && filtro.openweathermap && filtro.openweathermap !== 'none' &&
                    <TileLayer
                        attribution="©<a href='https://openweathermap.org/' target='_blank'>OpenWeather</a>"
                        url={"https://tile.openweathermap.org/map/" + filtro.openweathermap + "/{z}/{x}/{y}.png?appid=23f4de3a9691df3d1b2794915c04cea8"}
                        zIndex={999}
                    />
                }
            </>
        )
    }
}