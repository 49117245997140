import React, { Component } from 'react';

import { Button, Header, Grid, Icon, Table, Placeholder, Message } from 'semantic-ui-react'

import { Helmet } from 'react-helmet'

import { text } from 'services/locales';
import api from 'services/api';
import { isAdmin } from 'services/auth'
import { Redirect } from 'react-router-dom'
import { humanFileSize } from 'util/functions'
import { getServerTime } from 'services/time';
import { formatarDataHoraCompleta, formatarTempoAtras } from 'util/dataHora'

import './styles.css'

export default class Stats extends Component {

    state = {
        timestamp: 0,
        memoria: 0,
        swap: 0,
        cpu: 0
    }

    componentDidMount() {
        this.loadStats()
        this.interval = setInterval(() => this.loadStats(), 30000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    loadStats() {
        this.setState({ timestamp: getServerTime() })
        this.loadStat('gateway', '/stats')
        this.loadStat('arquivos', '/arquivos/stats')
        this.loadStat('objetos', '/objetos/stats')
        this.loadStat('hidra', '/hidra/stats')
        this.loadStat('chat', '/chat/stats')
        this.loadStat('historico', '/historico/stats')
        this.loadStat('sinais', '/sinais/stats')
        this.loadStat('eventos', '/eventos/stats')
        this.loadStat('meteorologia', '/meteorologia/stats')
        this.loadStat('classificacao', '/classificacao/stats')
    }

    handleRefresh = () => {
        this.loadStats()
    }

    loadStat(key, endpoint) {
        const errorKey = 'error' + key
        const loadingKey = 'loading' + key

        this.setState({ [key]: null, [errorKey]: null, [loadingKey]: true })

        api.get(endpoint)
            .then(response => {
                this.setState({ [key]: response.data, [loadingKey]: false, [errorKey]: null })
                if (key === "gateway") {
                    this.setState({
                        cpu: response.data.cargaCPUSO,
                        memoria: response.data.memoriaSO,
                        swap: response.data.swap
                    })
                }
            })
            .catch(error => {
                var mensagemErro = "Erro";
                if (error.response && error.response.data) {
                    mensagemErro = error.response.data.message;
                } else if (error.request) {
                    mensagemErro = "Erro ao enviar a requisição"
                }
                this.setState({ [errorKey]: mensagemErro, [loadingKey]: false })
            });
    }

    renderStats(titulo, key) {
        const errorKey = 'error' + key
        const loadingKey = 'loading' + key

        const stats = this.state[key];
        const erro = this.state[errorKey];
        const loading = this.state[loadingKey];

        return (
            <div>
                {!isAdmin() &&
                    <Redirect to='/' />
                }

                <Header inverted as='h4' attached='top' >
                    {loading === true &&
                        <Icon name='spinner' loading />
                    }
                    {loading !== true &&
                        <Icon name={erro !== null ? 'angle double down' : 'angle double up'} color={erro !== null ? 'red' : 'green'} />
                    }
                    <Header.Content>{titulo}</Header.Content>
                </Header>


                {loading === true &&
                    <Placeholder fluid inverted>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder>
                }

                {stats &&
                    <Table inverted basic='very' striped attached>
                        {stats.startTime &&
                            <Table.Row key="uptime">
                                <Table.Cell>{text("stats.upTime")}</Table.Cell>
                                <Table.Cell>{this.renderValue("upTime", stats.startTime)}</Table.Cell>
                            </Table.Row>
                        }
                        {Object.entries(stats).map(([key, value]) => {
                            if (key !== 'swap' && key !== 'memoriaSO' && key !== 'cargaCPUSO')
                                return (
                                    <Table.Row key={key}>
                                        <Table.Cell title={key}>{text("stats." + key)}</Table.Cell>
                                        <Table.Cell title={value}>{this.renderValue(key, value)}</Table.Cell>
                                    </Table.Row>
                                )
                            else return null
                        })}
                    </Table>
                }

                {erro !== null &&
                    <Message style={{ marginTop: '30px', marginBottom: '50px' }}>
                        <Message.Header>Serviço indisponível</Message.Header>
                        <p>{erro}</p>
                    </Message>
                }

            </div>
        )
    }

    renderValue(key, value) {
        switch (key) {
            case 'horario':
            case 'startTime':
            case 'buildTime':
                return formatarDataHoraCompleta(value)
            case 'upTime':
                return formatarTempoAtras(value)
            case 'memoriaMaximaJava':
            case 'totalMemoriaJava':
            case 'memoriaJava':
            case 'resourceSize':
            case 'memoriaSO':
            case 'swap':
                return humanFileSize(value)
            case 'cargaCPUJava':
            case 'cargaCPUSO':
                return (Number(value * 100).toFixed(2)) + '%'
            default:
                return value.toString()
        }
    }

    render() {

        return (
            <div style={{ padding: '10px', backgroundColor: '#000', minHeight: '100vh' }}>

                <Helmet title={`Stats ${text("geral.html-title")}`} />

                <Grid stackable columns={2} >
                    <Grid.Column>
                        <Header inverted as="h1" >
                            Stats
                        </Header>
                        <div style={{ color: '#fff' }}>
                            Processador: {this.renderValue("cargaCPUSO", this.state.cpu)} | Memória: {this.renderValue("memoriaSO", this.state.memoria)} | Swap: {this.renderValue("swap", this.state.swap)} | {formatarDataHoraCompleta(this.state.timestamp)}
                        </div>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button inverted icon size='large' onClick={this.handleRefresh} >
                            <Icon name='refresh' />
                        </Button>
                    </Grid.Column>
                </Grid>

                <br />

                <div className="stats-grid">
                    {this.renderStats("API Gateway", "gateway")}
                    {this.renderStats("Serviço do Hidra", "hidra")}
                    {this.renderStats("Serviço de objetos", "objetos")}
                    {this.renderStats("Serviço de histórico", "historico")}
                    {this.renderStats("Serviço de sinais", "sinais")}
                    {this.renderStats("Serviço de arquivos", "arquivos")}
                    {this.renderStats("Serviço de chat", "chat")}
                    {this.renderStats("Serviço de eventos", "eventos")}
                    {this.renderStats("Serviço de meteorologia", "meteorologia")}
                    {this.renderStats("Serviço de classificação", "classificacao")}
                </div>
            </div>
        );
    }

}