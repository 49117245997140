import React, { Component } from 'react';

import { LayerGroup } from "react-leaflet";
import api from 'services/api';
import "./styles.css"
import 'leaflet/dist/leaflet.css';

import { comparaBounds } from 'util/functions'

import Ponto from '../../simbologia/locais/ponto'
import Poligono from '../../simbologia/locais/poligono'

var updated= -1;

export default class CamadaLocais extends Component {

    state = {
        locais: []
    }

    componentDidUpdate(prevProps) {

        var update = false;

        if (!comparaBounds(prevProps.bounds, this.props.bounds)) {
            update = true;
        }

        if (prevProps.filtro !== this.props.filtro) {
            update = true;
        }

        if (update) {
            this.handleRefreshLocais();
        }

    }

    componentDidMount() {
        this.handleRefreshLocais();
        this.intervalLocais = setInterval(() => this.handleRefresh(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalLocais);
    }

      //Controla o tempo de atualização da camada
    //Sempre marca o tempo da ultima atualização, 
    //Deste modo, mesmo se houver uma atualização da camada de forma manual, a frequencia será respeitada
    handleRefresh() {
        const { frequencia } = this.props;
        
        const now = new Date().getTime();

        if (now - updated > frequencia) {
            this.handleRefreshLocais()
        }
    }

    handleRefreshLocais() {
        updated = new Date().getTime()

        const { bounds, filtro, autoUpdateDisabled } = this.props

        if (autoUpdateDisabled)//Não atualizo esta camada se este parametro estiver ativo, ou seja a atualização automatica das camadas foi desabilitada
            return false;

        if (bounds === null || bounds.latMin === null || bounds.lngMin === null || bounds.latMax === null || bounds.lngMax === null) {
            return false;
        }

        var url = "/hidra/locais/mapa?latMin=" + bounds.latMin + "&lngMin=" + bounds.lngMin + "&latMax=" + bounds.latMax + "&lngMax=" + bounds.lngMax;

        if (filtro) {
            if (filtro.tiposLocal) {
                if (filtro.tiposLocal.length === 0) {
                    this.setState({ locais: [] });
                    return;
                }
                url += "&t="
                filtro.tiposLocal.forEach((item, index) => {
                    url += (index > 0 ? "," : "") + item
                });
            }
        }

        api.get(url)
            .then(response => {
                this.setState({ locais: response.data })
            })
            .catch(() => { })
    }

    handleClick = o => {
        this.props.onClick(o.target.options.id, "local")
    }

    renderLocal(item, objetoSelecionado, zoom) {

        const zIndexOffset = item.pai ? 99991 : 990;

        if (!zoom)
            zoom = 99

        //Não desenho um local filho em um zoom muito baixo
        if (item.pai && zoom < 15)
            return

        //Sendo um poligono, só desenho esse formato em um zoom alto
        if (item.poligono && zoom >= 14) {
            return (
                <Poligono
                    key={item.id}
                    item={item}
                    onClick={(o) => { this.handleClick(o) }}
                    zoom={zoom}
                    objetoSelecionado={objetoSelecionado}
                    zIndexOffset={zIndexOffset}
                />
            )
        } else {
            return (
                <Ponto
                    key={item.id}
                    item={item}
                    onClick={(o) => { this.handleClick(o) }}
                    objetoSelecionado={objetoSelecionado}
                    zIndexOffset={zIndexOffset}
                />
            )
        }
    }

    render() {

        const { locais } = this.state;
        const { objetoSelecionado, zoom } = this.props;

        return (
            <LayerGroup>
                {locais.map((item) =>
                    this.renderLocal(item, objetoSelecionado, zoom)
                )}
            </LayerGroup>
        )
    }
}