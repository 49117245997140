import React, { Component } from 'react';

import { Link } from 'react-router-dom'
import { Button, Header, Icon, Segment } from "semantic-ui-react"
import api from 'services/api';

import { text } from 'services/locales'

import Info from 'components/objetos/acompanhamentos/info'
import Cinematica from 'components/objetos/acompanhamentos/cinematica/simples'
import Fotos from 'components/objetos/entidades/fotos'

export default class DicaAcompanhamento extends Component {

    state = {
        acompanhar: false,
        item: null,
        user: null
    }

    componentDidMount() {
        this.handleRefreshObjeto();
        this.interval = setInterval(() => this.handleRefreshObjeto(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleRefreshObjeto() {
        this.setState({ refresh: new Date().getTime() })

        const { uuid } = this.props;
        if (uuid) {
            api.get("/objetos/acompanhamentos/" + uuid)
                .then((response) => {
                    this.setState({ item: response.data });

                    var identificacao = response.data.identificacao
                    if (identificacao) {
                        this.setState({ mmsi: identificacao.mmsi });
                        if (identificacao.usuario)
                            this.loadUserInfo(identificacao.usuario)
                    }

                })
                .catch(() => {
                    this.setState({ item: null });
                })
        }

        if (this.state.acompanhar) {
            this.handleGoto()
        }
    }

    loadUserInfo = (login) => {
        api.get("/hidra/usuarios/login/" + login)
            .then(response => {
                this.setState({
                    user: response.data
                });
            })
            .catch(() => {
                this.setState({
                    user: null
                });
            })
    }

    handleAcompanhar = () => {
        const { acompanhar } = this.state;
        this.setState({ acompanhar: !acompanhar }
            , () => {
                if (this.state.acompanhar) {
                    this.handleGoto()
                }
            }
        )
    }

    handleGoto = () => {
        const { item } = this.state;
        if (item) {
            const latLong = [item.cinematica.posicao.geo.lat, item.cinematica.posicao.geo.lng];
            this.props.onGoto(latLong)
        }
    }

    render() {

        const { item, user, mmsi } = this.state;
        const { uuid, showLinks } = this.props;

        return (
            <>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>
                        <Header inverted as="h2">
                            {text("acompanhamento.acompanhamento")}
                        </Header>
                    </div>

                    <div style={{ flex: "200px", textAlign: "right" }}>

                        {showLinks &&
                            <Button
                                as={Link}
                                to={"/acompanhamento/uuid/" + (uuid)}
                                disabled={!uuid}
                                inverted
                                size='tiny'
                                icon="search plus"
                                title={text("geral.mais-detalhes")}
                            />
                        }

                        {showLinks &&
                            <Button
                                onClick={this.handleGoto}
                                inverted
                                size='tiny'
                                icon="crosshairs"
                                title={text("geral.centralizar")}
                            />
                        }

                        <Button
                            onClick={this.props.onClose}
                            inverted
                            size='tiny'
                            icon="close"
                            color="red"
                            title={text("geral.fechar")}
                        />
                    </div>
                </div>

                <br />

                {item &&
                    <div className="dica-objeto-grid">

                        <Info showLinks={showLinks} inverted item={item} header='h5' />

                        <Cinematica uuid={uuid} item={item} inverted header='h5' />

                        {showLinks &&
                            <Button.Group attached="bottom">
                                <Button
                                    onClick={this.handleAcompanhar}
                                    toggle active={this.state.acompanhar}
                                    inverted icon labelPosition='left'
                                >
                                    <Icon name="compress" /> {text("geral.acompanhar")}
                                </Button>
                            </Button.Group>
                        }

                        {user &&
                            <div className="box">
                                <Header as="h5" attached='top' inverted>
                                    {text("acompanhamento.usuario")}

                                    {showLinks &&
                                        <Link
                                            to={"/chat/" + user.login}
                                            style={{ float: 'right' }}
                                        >
                                            <Icon name="chat" />
                                            {text("usuario.conversar-com-usuario")}
                                        </Link>
                                    }

                                </Header>
                                <Segment attached inverted>
                                    <b>{text("usuario.nome")}:</b>&nbsp;{user.nome} <br />
                                    {user.nomeExibicao &&
                                        <><b>{text("usuario.nomeExibicao")}:</b>&nbsp;{user.nomeExibicao} <br /></>
                                    }
                                    <b>{text("usuario.local")}:</b>&nbsp;{user.local.nome} <br />
                                    <b>{text("usuario.perfil")}:</b>&nbsp;{user.perfil.nome}<br />
                                </Segment>
                            </div>
                        }

                        <Fotos mmsi={mmsi} hash={user ? user.imagem : null} inverted header='h5' />


                    </div>
                }

            </>
        )
    }
}