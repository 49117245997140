import React, { Component } from 'react';

import L from 'leaflet';
import { Marker } from "react-leaflet";
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

const Objeto = styled.div`
    display: flex;
`;

const DesenhoLocalPonto = styled.div`
    padding: 2px;
    background-color:${props => props.selecionado ? '#c3c3c3' : ''};
    border:${props => props.selecionado ? 'solid 1px #FF0000' : 'none'};
    text-shadow:
    -1px -1px 0 #FFF,  
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
`;

const TextoDireitaLocalPonto = styled.div`
    padding:5px 0 0 5px;
    font-size: 12px;
    text-shadow:
    -1px -1px 0 #FFF,  
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
    font-weight:bold;
`;

export default class SimbologiaLocalPonto extends Component {

    getStyle = (item, objetoSelecionado) => {
        return L.divIcon({
            className: 'icone-simbologia-local-ponto',
            html: ReactDOMServer.renderToString(
                <Objeto>
                    <DesenhoLocalPonto selecionado={(objetoSelecionado != null && objetoSelecionado.tipo==='local' && objetoSelecionado.id === item.id)}>
                        <Icon
                            name={item.simbolo ? item.simbolo : 'map marker alternate'}
                            style={{color: item.cor ? item.cor : '#808080'}}
                            size="large"
                        />
                    </DesenhoLocalPonto>
                    <TextoDireitaLocalPonto>
                        {item.nome}
                    </TextoDireitaLocalPonto>
                </Objeto>
            )
        });
    }

    render() {

        const { item, objetoSelecionado, zIndexOffset } = this.props;

        return (
            <Marker
                key={item.id}
                id={item.id}
                position={[item.lat, item.lng]}
                icon={this.getStyle(item, objetoSelecionado)}
                onClick={this.props.onClick}
                zIndexOffset={zIndexOffset}
            />
        )
    }
}