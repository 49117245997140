import React, { Component } from 'react';

import api from "services/api";
import { text } from 'services/locales';
import { Segment, Icon, Button, List, Label } from 'semantic-ui-react';
import { Link } from "react-router-dom"
import { getIcon } from 'util/eventos'
import UIfx from 'uifx';
import { notificar } from 'components/Notificador'

import EventoModal from 'components/EventoModal'

import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './styles.css'

export default class Eventos extends Component {

    state = {
        eventos: [],
        show: true,
        online: false,
        modalEvent: null,
        contDicaNotificacao: 0
    }

    componentDidMount() {
        this.handleUpdate();
        this.interval = setInterval(() => this.handleUpdate(), 60000);//Atualizo a cada 1 minuto só por garantia, caso não receba por ws
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleRX(msg) {

        //Atualiza o componente
        this.handleUpdate();

        if (msg) {

            //Se for novo, força abrir o componente
            if (msg.novo) {
                this.setState({ show: true })
            }

            //Se for uma mensagem 'importante', abro o modal
            if (msg.importante) {
                this.setState({ modalEvent: msg })
            }

            const alarm = new UIfx(
                process.env.PUBLIC_URL + '/sounds/event.ogg'
            )
            alarm.play()

            //Notifico no navegador
            var notificou = notificar(msg.titulo, msg.descricao, process.env.PUBLIC_URL + '/images/sis-care-icon.png', false)

            //Se não notificou, provavelmente por conta da falta de permissão do usuário no seu navegador, notifico dentro da pagina
            if (!notificou) {
                //notifico na pagina
                var detail = msg.descricao
                if (detail.length > 80) {
                    detail = detail.substring(0, 80) + "..."
                }

                NotificationManager.warning(detail, msg.titulo, 3000);

                const { contDicaNotificacao } = this.state;
                if (contDicaNotificacao % 5 === 0) {
                    NotificationManager.info(detail, msg.titulo, 3000);
                }
                this.setState({ contDicaNotificacao: contDicaNotificacao + 1 });
            }
        }
    }

    handleStateChange(state) {
        this.setState({ online: state })
    }

    handleUpdate = () => {
        this.setState({ loading: true });
        api.get('/eventos?size=999&e=ATIVO,RECONHECIDO&sort=estado,asc')
            .then((response => {
                this.setState({ loading: false, eventos: response.data.content });
            }))
            .catch(() => {
                this.setState({ loading: false, eventos: [] });
            })
    }

    handleShowHide = () => {
        this.setState({ show: !this.state.show })
    }

    renderEstado(item) {
        switch (item.estado.codigo) {
            case 'ativo':
                return (<Label circular empty size="tiny" color='green' title={text("eventos.ativo")} />)
            case 'reconhecido':
                return (<Label circular empty size="tiny" color='yellow' title={text("eventos.reconhecido")} />)
            case 'inativo':
                return (<Label circular empty size="tiny" color='gray' title={text("eventos.inativo")} />)
            default: return null;
        }
    }

    render() {

        const { eventos, loading, show, online, modalEvent } = this.state;

        return (
            <>
                <div className="painel-eventos">

                    <div className="header" >
                        <div className="titulo">
                            <Link to="/eventos">

                                <Icon.Group  >
                                    {online ? (<Icon name='alarm' />) : (<Icon name='alarm' color="grey" />)}
                                    {eventos.length > 0 &&
                                        <Icon corner color='red' name='circle' />
                                    }
                                </Icon.Group>

                            &nbsp; {text("eventos.eventos")}{loading ? "..." : ""}

                            </Link>
                        </div>
                        <div className="botoes" >
                            <Button
                                size="mini"
                                icon
                                inverted
                                onClick={this.handleShowHide}
                                title={text("geral.exibir-ocultar")}
                            >
                                <Icon name={show ? 'window minimize outline' : 'window maximize outline'} />
                            </Button>
                            {/* <Button
                                size="mini"
                                icon
                                inverted
                                loading={loading}
                                onClick={this.handleUpdate}
                                title={text("geral.atualizar")}
                            >
                                <Icon name='refresh' />
                            </Button> */}
                            <Button
                                size="mini"
                                icon
                                inverted
                                color="blue"
                                as={Link}
                                to="/eventos/novo"
                                title={text("novo-evento.novo-evento")}
                            >
                                <Icon name='plus' />
                            </Button>
                        </div>
                    </div>
                    {show &&
                        <Segment className="segment dark-scrollbar" attached inverted style={{ overflow: 'auto', maxHeight: "200px" }}>
                            {eventos.length === 0 &&
                                <div className="nenhum">
                                    {text("eventos.nenhum-ativo")}
                                </div>
                            }

                            {eventos.length > 0 &&
                                <List divided relaxed inverted selection >
                                    {eventos.map((item) =>
                                        <List.Item title={item.titulo + " (" + item.tipo.nome + ")"} key={item.id} as={Link} to={"/evento/" + item.id} >

                                            <List.Content floated='right'>
                                                {this.renderEstado(item)}
                                            </List.Content>
                                            <Icon
                                                style={{ color: item.tipo.severidade.cor ? item.tipo.severidade.cor : '#808080' }}
                                                name={getIcon(item.tipo.severidade.codigo)}
                                            />
                                            <List.Content >
                                                <span className="content">{item.titulo}</span>
                                            </List.Content>

                                        </List.Item>
                                    )}
                                </List>
                            }

                        </Segment>
                    }
                </div>

                {modalEvent &&
                    <EventoModal evento={modalEvent} />
                }

            </>
        )
    }
}