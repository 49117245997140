import React, { Component } from 'react';

import { Button, Segment, Icon, Header, List, Form, Grid } from "semantic-ui-react"

import { text, html } from 'services/locales'
import api from 'services/api';

export default class SidebarLocais extends Component {

    state = {
        itens: [],
        busca: null,
        loading: true,
        error: null,
        pageNumber: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        lastPage: true,
        firstPage: true,
        pageSize: 50,
        sort: null,
        sortDirection: 'ascending'
    }

    componentDidMount() {
        this.loadItens();
    }

    loadItens = (page = 0, busca = null) => {
        this.setState({ loading: true, error: null });

        const { sort, sortDirection, pageSize } = this.state;

        let url = '/hidra/locais?page=' + page + "&size=" + pageSize;
        if (busca != null) {
            url += '&q=' + busca
        }

        if (sort != null) {
            url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
        }
        api.get(url)
            .then((response) => {
                if (response.data.content != null) {
                    this.setState({
                        loading: false,
                        itens: response.data.content,
                        totalPages: response.data.totalPages,
                        totalElements: response.data.totalElements,
                        lastPage: response.data.last,
                        firstPage: response.data.first,
                        pageNumber: response.data.number,
                        numberOfElements: response.data.numberOfElements
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    itens: [],
                    error: text("formularios.erro_request")
                });
            });
    };

    handleRefresh = () => {
        const { pageNumber, busca } = this.state
        this.loadItens(pageNumber, busca);
    }

    handleSearch = e => {
        e.preventDefault();
        const { busca } = this.state;
        this.loadItens(0, busca);
    }

    handleNextPage = () => {
        const { pageNumber, lastPage, busca } = this.state;
        if (lastPage === true) return;

        const novoPageNumber = pageNumber + 1;
        this.loadItens(novoPageNumber, busca);
    }
    handlePreviousPage = () => {
        const { pageNumber, firstPage, busca } = this.state;
        if (firstPage === true) return;

        const novoPageNumber = pageNumber - 1;
        this.loadItens(novoPageNumber, busca);
    }

    handleClose = (e, { name, value }) => {
        this.props.onClose(value)
    }

    localSelecionado = (item) => {
        if (item) {
            this.props.onGoto([item.lat, item.lng])
            this.props.onSelectLocal(item.id)
        }
    }

    render() {

        const { itens, loading, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements } = this.state;

        return (
            <>
                <Grid columns={2} >
                    <Grid.Column>
                        <Header as="h2" inverted >{text("local.titulo")}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button
                            inverted
                            icon
                            size='tiny'
                            onClick={this.handleRefresh}
                            loading={loading}
                            title={text("geral.atualizar")}
                        >
                            <Icon name='refresh' />
                        </Button>
                        <Button
                            onClick={this.handleClose}
                            inverted
                            size='tiny'
                            icon="close"
                            color="red"
                            title={text("geral.fechar")}
                        />
                    </Grid.Column>
                </Grid>

                <br />

                <Form size='tiny' className="form" inverted onSubmit={this.handleSearch}>
                    <Form.Group widths='equal'>
                        <Form.Input
                            inverted
                            fluid
                            action={{ icon: 'search' }}
                            iconPosition='left'
                            name="busca"
                            placeholder={text('local.buscar')}
                            value={this.state.busca ? this.state.busca : ""}
                            onChange={e => this.setState({ busca: e.target.value })}
                        />
                    
                    </Form.Group>
                </Form>


                <Segment className="dark-scrollbar" inverted style={{ overflow: 'auto', height: "65vh" }}>
                    <List divided relaxed inverted selection >
                        {itens.map((item) =>
                            <List.Item title={item.nome + " (" + item.tipo.nome + ")"} key={item.id} onClick={() => this.localSelecionado(item)}>
                                <Icon
                                    name={item.tipo.simbolo ? item.tipo.simbolo : 'map marker alternate'}
                                    style={{color: item.tipo.cor ? item.tipo.cor : '#808080'}}
                                    size="large"
                                />
                                <List.Content >
                                    <List.Header>{item.sigla}</List.Header>
                                    <List.Description><small>{item.nome}</small></List.Description>
                                </List.Content>

                            </List.Item>
                        )}
                    </List>
                </Segment>

                <Segment inverted textAlign="center">

                    {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
                    <br /><br />

                    <Button.Group size='medium'>
                        <Button
                            inverted
                            disabled={firstPage}
                            icon labelPosition='left'
                            onClick={this.handlePreviousPage}
                        >
                            <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                        </Button>
                        <Button
                            inverted
                            disabled={lastPage}
                            icon labelPosition='right'
                            onClick={this.handleNextPage}
                        >
                            <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                        </Button>
                    </Button.Group>

                </Segment>
            </>
        )
    }
}