import React, { Component } from 'react';

import api from "services/api";
import { text } from 'services/locales';
import { setServerTime, getServerTime } from 'services/time';
import { Icon, Popup, Menu } from 'semantic-ui-react';
import moment from 'moment'

export default class Ping extends Component {

    state = {
        online: false,
        carregou: false
    }

    componentDidMount() {
        this.handlePing();
        this.interval = setInterval(() => this.handlePing(), 30000);
        this.intervalAjustServerTime = setInterval(() => this.handleAjustServerTime(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.intervalAjustServerTime);
    }

    handlePing = e => {
        const axiosConfig = {
            headers: {
                'removeAuthorization': true
            }
        };
        api.get("/ping", axiosConfig)
            .then(response => {
                setServerTime(response.data.timestamp);
                if (response.data.ping === "hidra") {
                    this.setState({ online: true, carregou: true })
                } else {
                    this.setState({ online: false, carregou: true })
                }
            })
            .catch(() => {
                this.setState({ online: false, carregou: true })
            });
    }

    handleAjustServerTime = () =>{
        const serverTime = moment(getServerTime())
        serverTime.add(1,'seconds');
        setServerTime(serverTime.format());
    }

    render() {

        const { online, carregou } = this.state;
        const { menu } = this.props;

        return (
            <>
                {carregou && !online &&
                    <>
                        {menu &&
                            <Menu.Item>
                                <Popup content={text("ping.offline")} trigger={
                                    <Icon name='wifi' color='red' onClick={this.handlePing} />
                                } />
                            </Menu.Item>
                        }
                        {!menu &&
                            <Popup content={text("ping.offline")} trigger={
                                <Icon name='wifi' color='red' onClick={this.handlePing} />
                            } />
                        }
                    </>
                }
            </>
        )
    }
}