import React, { Component } from 'react';

import api from 'services/api';
import "./styles.css"
import 'leaflet/dist/leaflet.css';
import { comparaBounds, hasWebglSupport } from 'util/functions'
import PixiOverlay from 'react-leaflet-pixi-overlay';
import { desenharAcompanhamentoSVG } from 'util/svg'
import { renderToString } from 'react-dom/server';
import { text } from 'services/locales';
import { Polyline } from "react-leaflet";

var updated = -1;

const webglSupport = hasWebglSupport();

export default class CamadaAcompanhamentos extends Component {

    state = {
        objetos: [],
        historicoObjetoSelecionado: []
    }

    componentDidMount() {
        this.handleRefreshObjetos();
        this.intervalObjetos = setInterval(() => this.handleRefresh(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalObjetos);
    }

    //Controla o tempo de atualização da camada
    //Sempre marca o tempo da ultima atualização, 
    //Deste modo, mesmo se houver uma atualização da camada de forma manual, a frequencia será respeitada
    handleRefresh() {
        const { frequencia, objetoSelecionado } = this.props;

        const uuidSelecionado = (objetoSelecionado && objetoSelecionado.tipo === 'aco') ? objetoSelecionado.id : null

        const now = new Date().getTime();

        if (now - updated > frequencia) {
            this.handleRefreshObjetos(uuidSelecionado);
            this.handleRefreshHistoricoDeObjetoSelecionado(uuidSelecionado);
        }
    }

    componentDidUpdate(prevProps) {

        const currentProps = this.props;
        var update = false;

        if (!comparaBounds(prevProps.bounds, currentProps.bounds)) {
            update = true;
        }

        if (prevProps.filtro !== currentProps.filtro) {
            update = true;
        }

        if (prevProps.objetoSelecionado !== currentProps.objetoSelecionado) {
            update = true
        }

        if (update) {
            const uuidSelecionado = (currentProps.objetoSelecionado && currentProps.objetoSelecionado.tipo === 'aco') ? currentProps.objetoSelecionado.id : null
            this.handleRefreshObjetos(uuidSelecionado);
            this.handleRefreshHistoricoDeObjetoSelecionado(uuidSelecionado);
        }
    }

    handleRefreshObjetos(uuidSelecionado = null) {
        updated = new Date().getTime()

        const { bounds, filtro, autoUpdateDisabled } = this.props

        if (autoUpdateDisabled)//Não atualizo esta camada se este parametro estiver ativo, ou seja a atualização automatica das camadas foi desabilitada
            return false;

        if (bounds === null || bounds.latMin === null || bounds.lngMin === null || bounds.latMax === null || bounds.lngMax === null) {
            return false;
        }

        var url = "/objetos/v2/acompanhamentos/mapa?latMin=" + bounds.latMin + "&lngMin=" + bounds.lngMin + "&latMax=" + bounds.latMax + "&lngMax=" + bounds.lngMax;

        if (filtro) {
            if (!filtro.perdido) {
                url += "&p=0"
            }

            if (filtro.origens) {
                if (filtro.origens.length === 0) {
                    this.setState({ objetos: [] });
                    return;
                }
                url += "&origem="
                filtro.origens.forEach((item, index) => {
                    url += (index > 0 ? "," : "") + item
                });
            }

            if (filtro.ambiente) {
                if (filtro.ambiente.length === 0) {
                    this.setState({ objetos: [] });
                    return;
                }
                url += "&amb="
                filtro.ambiente.forEach((item, index) => {
                    url += (index > 0 ? "," : "") + item
                });
            }

            if (filtro.hostilidade) {
                if (filtro.hostilidade.length === 0) {
                    this.setState({ objetos: [] });
                    return;
                }
                url += "&host="
                filtro.hostilidade.forEach((item, index) => {
                    url += (index > 0 ? "," : "") + item
                });
            }

            if (filtro.simbologia) {
                url += "&s=" + filtro.simbologia;
            }
        }

        api.get(url)
            .then(response => {

                let objetos = []
                const data = response.data;

                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        const item = response.data[key];
                        if (key === "stats") {
                            this.props.onUpdateStats(item)
                        } else {
                            if (item.length === 9) {
                                const selecionado = uuidSelecionado === item[0];
                                const svg = desenharAcompanhamentoSVG(item[5], item[4], item[6], item[1], item[7], selecionado);
                                objetos.push({
                                    id: item[0],
                                    position: [item[2], item[3]],
                                    iconId: svg[0],
                                    customIcon: svg[1],
                                    markerSpriteAnchor: [0.4, 0.4],
                                    onClick: () => this.props.onClick(item[0], "aco"),
                                    tooltip: renderToString(
                                        <div>
                                            <b>{item[8] ? item[8] : item[0]}</b><br />
                                            {text("origens." + item[1].toLowerCase())}
                                        </div>
                                    )
                                })
                            }
                        }
                    }
                }

                this.setState(
                    {
                        objetos: objetos
                    }
                )
            })
            .catch(() => {
                this.setState({
                    objetos: []
                });
            })

    }

    handleClick = o => {
        this.props.onClick(o.target.options.id, "aco")
    }

    handleRefreshHistoricoDeObjetoSelecionado(uuidSelecionado = null) {

        if (uuidSelecionado) {

            const buscaObjeto = this.state.objetos.filter((item) => {
                return item.id === uuidSelecionado;
            })

            const ultimaPosicao = buscaObjeto && buscaObjeto.length > 0 ? buscaObjeto[0].position : null

            api.get("historico/acompanhamentos/" + uuidSelecionado + "/historico/recente?size=999&sort=data,desc")
                .then((response) => {
                    var historico = response.data.content.map((item) => (
                        [item.lat, item.lng]
                    ));

                    if (ultimaPosicao)
                        historico.unshift(ultimaPosicao)

                    this.setState({ historicoObjetoSelecionado: historico })
                })
                .catch(() => {
                    this.setState({ historicoObjetoSelecionado: [] })
                })
        } else {
            this.setState({ historicoObjetoSelecionado: [] })
        }

    }

    render() {
        const { objetos, historicoObjetoSelecionado } = this.state;

        return (
            <>
                {historicoObjetoSelecionado && historicoObjetoSelecionado.length > 0 &&
                    <Polyline weight="4" color="#2664E3" positions={historicoObjetoSelecionado} />
                }

                {webglSupport &&
                    <PixiOverlay markers={objetos} />
                }
            </>
        )
    }
}