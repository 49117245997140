import { MapControl, withLeaflet } from "react-leaflet";
import * as L from "leaflet";

import "./PolylineMeasure.css";
import "leaflet.polylinemeasure.latest/Leaflet.PolylineMeasure";

import { text } from 'services/locales';

class PolylineMeasure extends MapControl {
    createLeafletElement() {
        return L.control.polylineMeasure({
            position: "topright",
            unit: "metres",
            showBearings: false,
            clearMeasurementsOnStop: true,
            showClearControl: false,
            showUnitControl: true,
            tooltipTextDraganddelete: text("ferramenta-medidas.terminar"),
            tooltipTextResume: '',
            tooltipTextAdd:text("ferramenta-medidas.adicionar"),
            // language dependend labels for point's tooltips
            measureControlTitleOn: text("ferramenta-medidas.ativar"),   // Title for the control going to be switched on
            measureControlTitleOff: text("ferramenta-medidas.desativar"), // Title for the control going to be switched off
            measureControlLabel: '&#8737;', // Label of the Measure control (maybe a unicode symbol)
            measureControlClasses: [],      // Classes to apply to the Measure control   
            clearControlTitle: text("ferramenta-medidas.limpar"), // Title text to show on the clear measurements control button
            clearControlLabel: '&times',    // Label of the Clear control (maybe a unicode symbol)
            clearControlClasses: [],        // Classes to apply to clear control button    
            distanceShowSameUnit: false,    // Keep same unit in tooltips in case of distance less then 1 km/mi/nm
            unitControlTitle: {             // Title texts to show on the Unit Control button
                text: text("ferramenta-medidas.mudar-unidades"),
                metres: text("ferramenta-medidas.metros"),
                landmiles: text("ferramenta-medidas.milhas"),
                nauticalmiles: text("ferramenta-medidas.milhas-nauticas")
            },
            unitControlLabel: {             // Unit symbols to show in the Unit Control button and measurement labels
                metres: text("ferramenta-medidas.metros-label"),
                kilometres: text("ferramenta-medidas.quilometros-label"),
                feet: 'ft',
                landmiles: text("ferramenta-medidas.milhas-label"),
                nauticalmiles: text("ferramenta-medidas.milhasnauticas-label"),
            },
            tempLine: {                     // Styling settings for the temporary dashed line
                color: '#00f',              // Dashed line color
                weight: 2                   // Dashed line weight
            },
            fixedLine: {                    // Styling for the solid line
                color: '#006',              // Solid line color
                weight: 2                   // Solid line weight
            },
            startCircle: {                  // Style settings for circle marker indicating the starting point of the polyline
                color: '#000',              // Color of the border of the circle
                weight: 1,                  // Weight of the circle
                fillColor: '#0f0',          // Fill color of the circle
                fillOpacity: 1,             // Fill opacity of the circle
                radius: 3                   // Radius of the circle
            },
            intermedCircle: {               // Style settings for all circle markers between startCircle and endCircle
                color: '#000',              // Color of the border of the circle
                weight: 1,                  // Weight of the circle
                fillColor: '#ff0',          // Fill color of the circle
                fillOpacity: 1,             // Fill opacity of the circle
                radius: 3                   // Radius of the circle
            },
            currentCircle: {                // Style settings for circle marker indicating the latest point of the polyline during drawing a line
                color: '#000',              // Color of the border of the circle
                weight: 1,                  // Weight of the circle
                fillColor: '#f0f',          // Fill color of the circle
                fillOpacity: 1,             // Fill opacity of the circle
                radius: 3                   // Radius of the circle
            },
            endCircle: {                    // Style settings for circle marker indicating the last point of the polyline
                color: '#000',              // Color of the border of the circle
                weight: 1,                  // Weight of the circle
                fillColor: '#f00',          // Fill color of the circle
                fillOpacity: 1,             // Fill opacity of the circle
                radius: 3                   // Radius of the circle
            },
        });
    }

    componentDidMount() {
        const { map } = this.props.leaflet;
        const polylineMeasure = this.leafletElement;
        polylineMeasure.addTo(map);
    }
}

export default withLeaflet(PolylineMeasure);