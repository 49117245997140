import React, { Component } from 'react';

import { Header, Container, Segment, Button, Icon, Message, Table } from 'semantic-ui-react'
import api from 'services/api';
import { text } from 'services/locales';
import { Link } from 'react-router-dom'
import { jwtSubject } from 'services/auth'
import Footer from 'components/footer'
import { Helmet } from 'react-helmet'
import LocalLink from 'components/LocalLink';
import { formatarDataHoraCompleta, formatarDataHora } from 'util/dataHora'

import Fotos from 'components/objetos/entidades/fotos'

//Local
import InfoLocal from 'components/objetos/locais/info'

//Acompanhamento
import InfoAcompanhamento from 'components/objetos/acompanhamentos/info'
import MapaAcompanhamento from 'components/objetos/acompanhamentos/mapa'
import Contatos from 'components/objetos/entidades/contatos'

export default class Usuario extends Component {

    state = {
        item: null,
        meuPerfil: false,
        historico: [],
        loading: false,
        uuid: null,
        acompanhamento: null,
        historicoAcompanhamento: [],
        endereco: null
    };

    componentDidMount() {
        const { login } = this.props.match.params;

        if (login === jwtSubject()) {
            this.setState({ meuPerfil: true });
        }

        this.loadItem()

        this.intervalAutoLoading = setInterval(() => this.handleRefresh(), 30000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalAutoLoading);
    }

    loadItem() {
        if (this.props.match.params.login) {
            const { login } = this.props.match.params;

            this.setState({ loading: true });

            api.get("/hidra/usuarios/login/" + login)
                .then(response => {
                    this.setState({
                        item: response.data,
                        loading: false
                    }
                        , () => {
                            this.loadEndereco()
                        }
                    );
                    this.loadUUID()
                    this.loadHistoricoLogin()
                })
                .catch(error => {
                    var mensagemErro = text("formularios.erro_request");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    }
                    this.setState({
                        sending: false,
                        error: mensagemErro
                    });
                })
        }
    }

    loadHistoricoLogin() {
        const { login } = this.props.match.params;
        api.get("/hidra/usuarios/historico?size=5&sort=dataHora,desc&user=" + login)
            .then(response => {
                this.setState({
                    historico: response.data.content
                });
            })
            .catch(() => {
                this.setState({
                    historico: []
                });
            })
    }

    loadUUID() {
        const { login } = this.props.match.params;

        api.get("/objetos/acompanhamentos/uuids-usuario/" + login)
            .then(response => {
                this.setState({
                    uuid: response.data[0]
                },
                    () => {
                        this.loadAcompanhamento()
                    });
            })
            .catch(() => {
                this.setState({
                    uuid: null
                });
            })
    }

    loadAcompanhamento = () => {
        const { uuid } = this.state;

        if (uuid) {

            api.get("/objetos/acompanhamentos/" + uuid)
                .then((response) => {
                    this.setState({ acompanhamento: response.data });

                    this.loadHistorico(response.data.cinematica.latitude, response.data.cinematica.longitude)
                })
                .catch(() => {
                    this.setState({ historicoAcompanhamento: [], acompanhamento: null });
                })
        }
    }

    loadEndereco = () => {
        const { item } = this.state;

        if (item) {
            api.get("/hidra/entidades/" + item.id + "/endereco")
                .then((response) =>
                    this.setState({ endereco: response.data })
                )
                .catch(() =>
                    this.setState({ endereco: null })
                )
        }
    }

    loadHistorico = (lat, lng) => {
        const { uuid } = this.state;

        if (uuid === null) {
            return;
        }

        if (uuid) {
            api.get("historico/acompanhamentos/" + uuid + "?sa=600&size=100&sort=data,desc")
                .then((response) => {
                    var historico = response.data.content.map((item) => (
                        [item.lat, item.lng]
                    ));
                    historico.unshift([lat, lng])//add a posição atual do objeto na primeira posição do array

                    this.setState({ historicoAcompanhamento: historico })
                })
                .catch(() => {
                    this.setState({ historicoAcompanhamento: [] })
                })
        }
    }

    handleRefresh = () => {
        this.loadItem();
    }

    render() {
        const { login } = this.props.match.params;
        const { item, meuPerfil, historico, uuid, loading, acompanhamento, historicoAcompanhamento, endereco } = this.state

        return (

            <Container style={{ padding: '2em' }}>

                <Helmet title={`${item ? (item.nomeExibicao ? item.nomeExibicao : item.nome) : ''} ${text("geral.html-title")}`} />

                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>
                        <Header as="h1">
                            {item ? (item.nome + (item.nomeExibicao ? (' (' + item.nomeExibicao + ')') : '')) : jwtSubject()}
                            {meuPerfil &&
                                <Header.Subheader>{text("usuario.meu-perfil")}</Header.Subheader>
                            }
                        </Header>
                    </div>

                    <div style={{ flex: "350px", textAlign: "right" }}>

                        <Button.Group >

                            <Button icon size='large' onClick={this.handleRefresh} loading={loading}>
                                <Icon name='refresh' />
                            </Button>

                            {meuPerfil &&
                                <Button
                                    floated='right'
                                    icon labelPosition='right'
                                    size='large'
                                    color='red'
                                    as={Link}
                                    to="/logout"
                                >
                                    <Icon name='log out' />  {text("usuario.sair")}
                                </Button>
                            }

                            {!meuPerfil &&
                                <Button
                                    as={Link}
                                    to={"/chat/" + login}
                                    size="large"
                                    color="blue"
                                >
                                    <Icon name="chat" />
                                    {text("usuario.conversar-com-usuario")}
                                </Button>
                            }
                        </Button.Group>
                    </div>
                </div>

                <br />

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <div className="page-grid-2-columns">

                    <div className="box">
                        <Header as="h2" attached='top'>
                            {text("usuario.meus-dados")}
                        </Header>
                        <Segment attached>

                            {item ? (
                                <>
                                    <b>{text("usuario.nome")}:</b>&nbsp;{item.nome} <br />
                                    {item.nomeExibicao &&
                                        <><b>{text("usuario.nomeExibicao")}:</b> &nbsp;{item.nomeExibicao} <br /></>
                                    }
                                    <b>{text("usuario.local")}:</b>&nbsp;<LocalLink id={item.local.id} nome={item.local.nome} disabled /> <br />
                                    <b>{text("usuario.perfil")}:</b>&nbsp;{item.perfil.nome}<br />
                                    <b>{text("usuario.login")}:</b>&nbsp;{item.login} <br />
                                    <b>{text("usuario.criacao")}:</b>&nbsp;{formatarDataHoraCompleta(item.dataCriacao)} <br />
                                    <b>{text("usuario.habilitado")}:</b>&nbsp;{item.habilitado ? text("geral.sim") : text("geral.nao")}<br />
                                    {endereco &&
                                        <>
                                            <b>{text("entidade.endereco")}:</b> {endereco.enderecoFormatado} <br />
                                        </>
                                    }
                                </>
                            ) : null
                            }
                        </Segment>
                    </div>

                    {item &&
                        <>
                            <Fotos hash={item.imagem} />
                            <Contatos id={item.id} />
                        </>
                    }

                    {acompanhamento &&
                        <InfoAcompanhamento header='h2' titulo={text("acompanhamento.acompanhamento")} uuid={uuid} item={acompanhamento} link={true} />
                    }

                    {historico && historico.length > 0 &&
                        <div className="box">
                            <Header as="h2" attached='top'>
                                {text("usuario.historico-login")}
                            </Header>
                            <Table celled striped attached  >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{text("historico-login.data")}</Table.HeaderCell>
                                        <Table.HeaderCell>{text("historico-login.agente")}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        historico.map((item) =>
                                            <Table.Row key={item.id}>
                                                <Table.Cell>{formatarDataHora(item.dataHora)}</Table.Cell>
                                                <Table.Cell title={item.agente}>{item.agenteFormatado}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>

                            </Table>
                        </div>
                    }

                    {acompanhamento &&
                        <MapaAcompanhamento header='h2' uuid={uuid} item={acompanhamento} historico={historicoAcompanhamento} />
                    }

                    {item && item.local &&
                        <InfoLocal titulo={text("usuario.local")} header='h2' id={item.local.id} bntVerNoMapa={false} />
                    }
                </div>

                <Footer />
            </Container>
        )
    }
}