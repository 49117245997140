import UIfx from 'uifx';

export const init = () => {
    if (typeof Notification !== 'undefined' && Notification.permission !== 'denied') {
        Notification.requestPermission();
    }
}

export const notificar = (titulo, corpo, icone, som) => {

    if (corpo && corpo.length > 100) {
        corpo = corpo.substring(0, 100) + "..."
    }

    if (som) {
        const bell = new UIfx(
            process.env.PUBLIC_URL + '/sounds/notificacao.wav'
        )
        bell.play()
    }

    if ("Notification" in window) {
        if (typeof Notification !== 'undefined' && Notification.permission === "granted") {
            var opcoes = {
                body: corpo,
                icon: icone
            }
            new Notification(process.env.REACT_APP_NAME + " - " + titulo, opcoes);

            return true
        } else if (typeof Notification !== 'undefined' && Notification.permission !== 'denied') {
            Notification.requestPermission();
        }
    }

    return false

}