import packageJson from '../../package.json';

const VERSION_KEY = process.env.REACT_APP_BASE_KEY + ".version";
const MAP_KEY = process.env.REACT_APP_BASE_KEY + ".map";
const MAP_LAYER_KEY = process.env.REACT_APP_BASE_KEY + ".map.layer";
const CENARIO_TATICO_KEY = process.env.REACT_APP_BASE_KEY + ".ct";
const FILTER_OBJETO_KEY = process.env.REACT_APP_BASE_KEY + ".filter.obj";
const FILTER_LAYER_KEY = process.env.REACT_APP_BASE_KEY + ".filter.layer";

//Se a versão gravada no local storage for diferente da versão atual, limpo todo o local storage para evitar qualquer problema de valores inválidos
export const appUpdated = () => {

    //Obtenho a versão salva no local storage
    const version = localStorage.getItem(VERSION_KEY);

    //Obtenho a vsersão corrente salva no package.json
    const correntVersion = packageJson.version;

    //Se não há versão salva no storage local, gravo ela e continuo
    if (!version) {
        localStorage.setItem(VERSION_KEY, correntVersion);
        return false;
    }

    //Se a versão salva no storage local é diferente da versão corrente
    if (version !== correntVersion) {
        localStorage.clear();//Limpo o storagelocal, para remover tudo da versão anterior que poderia atrapalhar nessa nova versão
        localStorage.setItem(VERSION_KEY, correntVersion);//Salvo a versão corrente
        return true;//Retorno true para informar que houve uma atualização
    }
    
    return false;
}

//Removo dados possivelmente sensíveis
export const clear = () => {
    localStorage.removeItem(MAP_KEY);
    localStorage.removeItem(MAP_LAYER_KEY);
    localStorage.removeItem(CENARIO_TATICO_KEY);
    localStorage.removeItem(FILTER_OBJETO_KEY);
    localStorage.removeItem(FILTER_LAYER_KEY);
}

export const getVersion = () => localStorage.getItem(VERSION_KEY)

export const setMap = value => {
    localStorage.setItem(MAP_KEY, JSON.stringify(value));
}

export const getMap = () => {
    var value = localStorage.getItem(MAP_KEY);
    if (value)
        return JSON.parse(value)
    return null;
}

export const setMapLayer = value => {
    localStorage.setItem(MAP_LAYER_KEY, value);
}

export const getMapLayer = () => localStorage.getItem(MAP_LAYER_KEY)

export const setCTOptions = value => {
    localStorage.setItem(CENARIO_TATICO_KEY, JSON.stringify(value));
}

export const getCTOptions = () => {
    var value = localStorage.getItem(CENARIO_TATICO_KEY);
    if (value)
        return JSON.parse(value)
    return null;
}

export const setFilterObject = value => {
    localStorage.setItem(FILTER_OBJETO_KEY, JSON.stringify(value));
}

export const getFilterObject = () => {
    var value = localStorage.getItem(FILTER_OBJETO_KEY);
    if (value)
        return JSON.parse(value)
    return null;
}

export const setFilterLayer = value => {
    localStorage.setItem(FILTER_LAYER_KEY, JSON.stringify(value));
}

export const getFilterLayer = () => {
    var value = localStorage.getItem(FILTER_LAYER_KEY);
    if (value)
        return JSON.parse(value)
    return null;
}