import React, { Component } from 'react';

import { Button, Icon, Message, Container, Grid, Divider, Confirm } from 'semantic-ui-react'
import { text } from 'services/locales';

import api from 'services/api';

import Fotos from 'components/objetos/entidades/fotos'

export default class cadastroFoto extends Component {

    state = {
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: false,
        file: null,
        fileName: null,
        showRemoveDialog: false,
        itemRemove: null,
    }

    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    handleFileSelect = (e) => {
        if (e && e.target && e.target.files && e.target.files.length > 0)
            this.setState({
                fileName: e.target.files[0].name,
                file: e.target.files[0],
                disabled: false,
            });
    }

    handleRemove = item => () => {
        this.setState({ showRemoveDialog: true, itemRemove: item, error: null, sucesses: null })
    }

    handleConfirmRemove = () => {
        this.setState({ showRemoveDialog: false, loading: true })
        const { itemRemove } = this.state

        api.delete('/hidra/entidades/' + itemRemove + '/foto')
            .then((response) => {
                this.setState({
                    sucesses: text("entidade.foto-remover"),
                    loading: false,
                    itemRemove: null
                });
                this.props.onSubmit();
            })
            .catch((error) => {
                let errorMsg = text("tabelas.erro-remover");

                if (error.response.data && error.response.data.message && error.response.data.status && error.response.data.status >= 400 && error.response.data.status < 500) {
                    errorMsg = error.response.data.message
                }

                this.setState({
                    loading: false,
                    itemRemove: null,
                    error: errorMsg
                });
            });
    }

    handleCancelRemove = () => {
        this.setState({ showRemoveDialog: false, itemRemove: null })
    }

    handleEnviar = () => {

        const { file } = this.state;
        const { id } = this.props;


        this.setState({
            submitting: true,
            error: null
        });

        var bodyFormData = new FormData();
        if (file) bodyFormData.append('file', file);

        api({
            method: 'post',
            url: '/hidra/entidades/' + id + '/foto',
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                this.setState({
                    imagem: response.data.imagem,
                    sucesses: text("entidade.foto-atualizada"),
                    submitting: false,
                    error: null,
                    file: null,
                    fileName: null

                });
                this.props.onSubmit();
                window.scrollTo(0, 0)
            })
            .catch(error => {
                var mensagemErro = text("formularios.erro_request");
                if (error.response && error.response.data) {
                    window.scrollTo(0, 0)
                    mensagemErro = error.response.data.message;
                }
                this.setState({
                    submitting: false,
                    error: mensagemErro
                });
            });

    }

    render() {

        const { submitting } = this.state;
        const { imagem } = this.props
        const { id } = this.props

        return (

            <Container style={{ padding: '2em' }}>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <Grid columns={2}>
                    <Grid.Column>
                        <Fotos hash={imagem} header="h3" />
                    </Grid.Column>
                    <Grid.Column>

                        <b>{text("entidade.foto-form")}</b>

                        <br /><br />

                        <Button
                            icon
                            labelPosition="left"
                            onClick={() => this.fileInputRef.current.click()}
                        >
                            <Icon name="paperclip" /> {text("anexo.enviar-arquivo")}
                        </Button>
                        &nbsp;{this.state.fileName}
                        <input
                            ref={this.fileInputRef}
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={this.handleFileSelect}
                        />
                    </Grid.Column>

                </Grid>
                <Confirm
                    header={text("tabelas.dialogo-remover")}
                    content={null}
                    open={this.state.showRemoveDialog}
                    onCancel={this.handleCancelRemove}
                    onConfirm={this.handleConfirmRemove}
                    cancelButton={text("geral.nao")}
                    confirmButton={text("geral.sim")}
                />
                <br />
                <Divider />
                <Grid columns={2}>
                    <Grid.Column>
                        <Button
                            disabled={imagem === null || imagem === undefined}
                            onClick={this.handleRemove(id)}
                            color="red"
                            type='submit'
                            icon labelPosition='left'
                            size="big"
                        >
                            <Icon name='trash alternate' /> {text("tabelas.remover")}
                        </Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button
                            disabled={this.state.file === null}
                            primary
                            loading={submitting}
                            onClick={this.handleEnviar}
                            icon labelPosition='right'
                            size="big"
                        >
                            <Icon name='save' /> {text("formularios.salvar")}
                        </Button>
                    </Grid.Column>
                </Grid>

            </Container>
        )
    }
}