import moment from 'moment';
import 'moment/locale/pt-br';
import { getLocale, text } from 'services/locales'
import { getServerTime } from 'services/time';

//UTC Offset definido fixo, horario de Brasília
const timezone = "-03:00"

//Converte uma data em ISO-8601 em String no formato 'DD/MM/YYYY hh:mm:ss' no timeZone local
export const formatarDataHoraCompleta = (dataHora) => {
    if (!dataHora || dataHora === '')
        return "-";

    //Define o idioma
    moment.locale(getLocale().toLocaleLowerCase())

    const dataHoraMoment = moment(dataHora).utcOffset(timezone)

    return dataHoraMoment.format('DD/MM/YYYY HH:mm:ss');
}

//Converte uma data em ISO-8601 em String no timeZone local nos formatos:
//'hh:mm:ss', se o dia da dataHora for o mesmo que o momento atual
//'DD/MM hh:mm:ss', se o ano da dataHora for o mesmo que o momento atual
//'DD/MM/YYYY hh:mm:ss', qualquer outro caso
export const formatarDataHora = (dataHora) => {
    if (!dataHora || dataHora === '')
        return "-";

    //Define o idioma
    moment.locale(getLocale().toLocaleLowerCase())

    const dataHoraMoment = moment(dataHora).utcOffset(timezone)
    const now = moment(getServerTime()).utcOffset(timezone)

    var s;

    if (dataHoraMoment.isSame(now, 'day')) {
        s = 'HH:mm:ss'
    } else if (dataHoraMoment.isSame(now, 'year')) {
        s = 'DD/MM, HH:mm:ss'
    } else {
        s = 'DD/MM/YYYY, HH:mm:ss'
    }

    return dataHoraMoment.format(s);
}

//Converte uma data em ISO-8601 em String no timeZone local no formato '[tempo_atras], [data]'
//Onde, [tempo_atras] é a descrição em texto da diferença entre a dataHora e o tempo atual, ex: 'há 1 minuto', 'há 3 meses', etc
//e, [data], segue a lógica abaixo:
//'hh:mm:ss', se o dia da dataHora for o mesmo que o momento atual
//'DD/MM hh:mm:ss', se o ano da dataHora for o mesmo que o momento atual
//'DD/MM/YYYY hh:mm:ss', qualquer outro caso
export const formatarDataHoraComTempoAtras = (dataHora) => {
    if (!dataHora || dataHora === '')
        return "-";

    //Define o idioma
    moment.locale(getLocale().toLocaleLowerCase())

    const dataHoraMoment = moment(dataHora).utcOffset(timezone)
    const now = moment(getServerTime()).utcOffset(timezone)

    var s;

    if (dataHoraMoment.isSame(now, 'day')) {
        s = 'HH:mm:ss'
    } else if (dataHoraMoment.isSame(now, 'year')) {
        s = 'DD/MM, HH:mm:ss'
    } else {
        s = 'DD/MM/YYYY, HH:mm:ss'
    }

    return formatarTempoAtras(dataHora) + " (" + dataHoraMoment.format(s) + ")";
}

//Converte uma data em ISO-8601 em String no timeZone local no formato da diferença entre a dataHora e o tempo atual, ex: 'há 1 minuto', 'há 3 meses', etc
export const formatarTempoAtras = (dataHora) => {
    if (!dataHora || dataHora === '')
        return "-";

    //Define o idioma
    moment.locale(getLocale().toLocaleLowerCase())

    const dataHoraMoment = moment(dataHora).utcOffset(timezone)
    const now = moment(getServerTime()).utcOffset(timezone)

    const diff = Math.abs(dataHoraMoment.diff(now, 'seconds'));

    if (diff <= 5) {//Verifico essa diferença para evitar que o calculo baseado na hora do servidor dê errado, pode dar um tempo no futuro em alguns casos
        return text("geral.neste-instante")
    }

    return dataHoraMoment.from(now);
}