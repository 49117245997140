import React, { Component } from 'react';

import { Button, Form, Segment } from 'semantic-ui-react'

import api from 'services/api';
import { optionsOrigens } from 'util/filterOptions'

export default class SuporteTestesAcosCompleto extends Component {

    state = {
        loading: false,
        error: null,
        sucesses: null,
        latMin: -90,
        lngMin: -180,
        latMax:90,
        lngMax:180,
        quantidade: 10,
        identificador: "teste",
        origens: []
    }

    componentDidMount() {
        let todasOrigens = [];
        optionsOrigens.forEach((item) => {
            todasOrigens.push(item.value);
        });
        this.setState({ origens: todasOrigens })
    }

    handleSubmit = e => {
        e.preventDefault();

        const { latMin, lngMin, latMax, lngMax, quantidade, identificador, origens } = this.state;
        this.setState({ result: null, error: null, loading: true })

        api.post(
            "/objetos/acompanhamentos/testes/completo",
            { latMin, lngMin,latMax, lngMax, quantidade, identificador, origens }
        )
            .then(response => {
                this.setState({ result: response.data, loading: false })
            })
            .catch(error => {
                this.setState({ error: (error.response.data ? error.response.data : error.response), loading: false })
            });
    }


    handleOrigens = (valor) => {
        const { origens } = this.state;
        var temp = origens;

        if (temp.includes(valor)) {
            if (temp.length === 1) return; //Não permite desmarcar todos
            temp.splice(temp.indexOf(valor), 1);
        } else {
            temp.push(valor)
        }

        this.setState(
            {
                origens: temp
            }
        )
    }

    render() {

        return (
            <>

                <Form loading={this.state.loading} size='large' className="form">
                    <Form.Group widths='equal'>

                        <Form.Input
                            name="quantidade"
                            label="Quantidade a ser gerada"
                            value={this.state.quantidade}
                            onChange={e => this.setState({ quantidade: e.target.value })}
                        />

                        <Form.Input
                            name="identificador"
                            label="Identificador base"
                            value={this.state.identificador}
                            onChange={e => this.setState({ identificador: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Input
                            name="latMin"
                            label="Latitude inicial"
                            value={this.state.latMin}
                            onChange={e => this.setState({ latMin: e.target.value })}
                        />

                        <Form.Input
                            name="lngMin"
                            label="Longitude inicial"
                            value={this.state.lngMin}
                            onChange={e => this.setState({ lngMin: e.target.value })}
                        />

                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Input
                            name="latMax"
                            label="Latitude final"
                            value={this.state.latMax}
                            onChange={e => this.setState({ latMax: e.target.value })}
                        />

                        <Form.Input
                            name="lngMax"
                            label="Longitude final"
                            value={this.state.lngMax}
                            onChange={e => this.setState({ lngMax: e.target.value })}
                        />

                    </Form.Group>


                    <b>Origens</b>
                    <Form.Group grouped>
                        {optionsOrigens
                            .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
                            .map(item =>
                                <div>
                                <Form.Checkbox
                                    key={item.value}
                                    value={item.value}
                                    label={item.text}
                                    checked={this.state.origens.includes(item.value)}
                                    onChange={(e, { value }) => this.handleOrigens(value)}
                                />
                                
                                </div>
                            )}
                    </Form.Group>

                    <br />

                    <center>
                        <Button primary size='large' onClick={this.handleSubmit} >ENVIAR</Button>
                    </center>

                    <br />

                </Form>

                <Segment color={this.state.result ? "green" : (this.state.error ? "red" : "grey")}>
                    <pre>
                        {this.state.result ? JSON.stringify(this.state.result, null, 2) : ""}
                        {this.state.error ? JSON.stringify(this.state.error, null, 2) : ""}
                    </pre>
                </Segment>


            </>

        );
    }

}