import React, { Component } from 'react';

import { Header, Segment, Image, Divider } from "semantic-ui-react"
import { convertDMS } from 'util/functions'
import { isDev } from 'util/functions'

export default class DicaLatLng extends Component {

    render() {

        const { id } = this.props;

        return (
            <>
                <Header as='h2' attached='top' inverted fluid >
                    <Header.Content>
                        Ponto no mapa
                    </Header.Content>
                    <Header.Subheader>
                        {convertDMS(id.lat, id.lng)}
                    </Header.Subheader>
                </Header>
                <Segment attached inverted fluid>
                    <Image fluid src={
                        'http://187.32.148.35:8000/map/?center=' + id.lng + ',' + id.lat + '&zoom=15&width=1024&height=768&layers=enc,bsb,shp&enc_display=all&bg_color=217,217,217'
                    } />
                    <Divider/>
                    <Image fluid src={
                        'http://187.32.148.35:8000/map/?center=' + id.lng + ',' + id.lat + '&zoom=5&width=1024&height=768&layers=bsb,shp&bg_color=217,217,217'
                    } />

                    {isDev() &&
                        <>
                        <Divider/>
                            {id.lat} {id.lng}
                        </>
                    }
                </Segment>

            </>
        )
    }
}