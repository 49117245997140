import { text } from 'services/locales'

export const layers = [
    {
        name: text("cenario-tatico-camada.osm"),
        attribution: text("cenario-tatico.dados-cartograficos") + " ©<a href='http://osm.org/copyright' target='_blank'>OpenStreetMap</a> contributors",
        url: process.env.REACT_APP_OSM_URL,
        checked: true
    },
    {
        name: text("cenario-tatico-camada.osm-offline"),
        attribution: text("cenario-tatico.dados-cartograficos") + " ©<a href='http://osm.org/copyright' target='_blank'>OpenStreetMap</a> contributors",
        url: process.env.REACT_APP_OSM_OFFLINE_URL,
        checked: false
    },
    {
        name: text("cenario-tatico-camada.terreno"),
        attribution: text("cenario-tatico.dados-cartograficos") + " por <a href='https://www2.jpl.nasa.gov/srtm/' target='_blank'>Shuttle Radar Topography Mission (NASA)</a> e ©<a href='https://www.marinha.mil.br/dhn/' target='_blank' title='Diretoria de Hidrografia e Navegação'>DHN</a>",
        legend: "<div class='ct-legend-terrain'><div style='background-color: rgb(0, 157, 60)'>0-100m</div><div style='background-color: rgb(0, 163, 58)'>100-200m</div><div style='background-color: rgb(47, 172, 95)'>200-350m</div><div style='background-color: rgb(131, 199, 138)'>350-550m</div><div style='background-color: rgb(183, 220, 177)'>550-950m</div><div style='background-color: rgb(248, 209, 167)'>950-1350m</div><div style='background-color: rgb(241, 174, 131)'>1350-1750m</div><div style='background-color: rgb(236, 142, 80)'>1750-2150m</div><div style='background-color: rgb(229, 115, 45)'>2150-2950m</div><div style='background-color: rgb(225, 85, 23)'>2950-3750m</div><div style='background-color: rgb(218, 37, 28)'>>3750m</div></div>",
        url: process.env.REACT_APP_TERRAIN_URL,
        checked: false
    },
    {
        name: text("cenario-tatico-camada.stamen"),
        attribution: text("cenario-tatico.dados-cartograficos") + " por <a href='http://stamen.com' target='_blank'>Stamen Design</a>, sob a <a href='http://creativecommons.org/licenses/by/3.0' target='_blank'>CC BY 3.0</a>",
        url: process.env.REACT_APP_STAMEN_URL,
        checked: false
    },
    {
        name: text("cenario-tatico-camada.enc-base"),
        attribution: text("cenario-tatico.dados-cartograficos") + " ©<a href='https://www.marinha.mil.br/dhn/' target='_blank' title='Diretoria de Hidrografia e Navegação'>DHN</a>",
        url: process.env.REACT_APP_ENC_BASE_URL,
        checked: false
    },
    {
        name: text("cenario-tatico-camada.enc-standart"),
        attribution: text("cenario-tatico.dados-cartograficos") + " ©<a href='https://www.marinha.mil.br/dhn/' target='_blank' title='Diretoria de Hidrografia e Navegação'>DHN</a>",
        url: process.env.REACT_APP_ENC_STANDARD_URL,
        checked: false
    },
    {
        name: text("cenario-tatico-camada.enc-all"),
        attribution: text("cenario-tatico.dados-cartograficos") + " ©<a href='https://www.marinha.mil.br/dhn/' target='_blank' title='Diretoria de Hidrografia e Navegação'>DHN</a>",
        url: process.env.REACT_APP_ENC_ALL_URL,
        checked: false
    }

]
