import React, { Component } from 'react';

import { Button, Icon, Header, Table, Message, Grid, Confirm, Container, Form } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import api from 'services/api';
import { text, html } from 'services/locales';
import { Helmet } from 'react-helmet'

export default class AdminTipoLocalTabela extends Component {

    state = {
        itens: [],
        busca: null,
        loading: true,
        error: null,
        sucesses: null,
        pageNumber: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        lastPage: true,
        firstPage: true,
        sort: "nome",
        sortDirection: 'ascending',
        showRemoveDialog: false,
        itemRemove: null,
        icones: []
    }

    componentDidMount() {
        this.loadItens();

        if (this.props.location.state != null) {
            this.setState({ sucesses: this.props.location.state.sucesses })
        }
    }

    loadItens = (page = 0, busca = null) => {
        if (this.state.sucesses === text("tipo-local.excluido-sucesso")) {
            this.setState({ loading: true, error: null });
        } else {
            this.setState({ loading: true, error: null, sucesses: null });
        }

        let url = '/hidra/locais/tipos?page=' + page;

        const { sort, sortDirection } = this.state;

        if (sort != null) {
            url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
        }

        if (busca != null) {
            url += '&q=' + busca
        }

        api.get(url)
            .then((response) => {
                if (response.data.content != null) {
                    this.setState({
                        loading: false,
                        itens: response.data.content,
                        totalPages: response.data.totalPages,
                        totalElements: response.data.totalElements,
                        lastPage: response.data.last,
                        firstPage: response.data.first,
                        pageNumber: response.data.number,
                        numberOfElements: response.data.numberOfElements
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    itens: [],
                    error: text("formularios.erro_request")
                });
            });
    };

    handleSearch = e => {
        e.preventDefault();
        const { busca } = this.state;
        this.loadItens(0, busca);
    }

    handleNextPage = () => {
        const { pageNumber, lastPage } = this.state;
        if (lastPage === true) return;

        const novoPageNumber = pageNumber + 1;
        this.loadItens(novoPageNumber);
    }
    handlePreviousPage = () => {
        const { pageNumber, firstPage } = this.state;
        if (firstPage === true) return;

        const novoPageNumber = pageNumber - 1;
        this.loadItens(novoPageNumber);
    }

    handleRefresh = () => {
        const { pageNumber } = this.state
        this.loadItens(pageNumber);
    }

    handleSort = clickedColumn => () => {
        const { busca, sort, sortDirection } = this.state

        this.setState({
            sortDirection: clickedColumn === sort ? (sortDirection === 'ascending' ? 'descending' : 'ascending') : 'ascending', //Se for a mesma coluna clicada, inverte a ordenação, caso contrário, define como ascendente 
            sort: clickedColumn
        }, () => {
            this.loadItens(0, busca);
        })
    }


    handleRemove = item => () => {
        this.setState({ showRemoveDialog: true, itemRemove: item, error: null, sucesses: null })
    }

    handleConfirmRemove = () => {
        this.setState({ showRemoveDialog: false, loading: true })
        const { busca, itemRemove } = this.state

        api.delete('hidra/locais/tipos/' + itemRemove.id)
            .then((response) => {

                this.setState({
                    loading: false,
                    itemRemove: null,
                    sucesses: text("tipo-local.excluido-sucesso")
                });

                this.loadItens(0, busca);
            })
            .catch((error) => {
                let errorMsg = text("tabelas.erro-remover");

                if (error.response.data && error.response.data.message && error.response.data.status && error.response.data.status >= 400 && error.response.data.status < 500) {
                    errorMsg = error.response.data.message
                }

                this.setState({
                    loading: false,
                    itemRemove: null,
                    error: errorMsg
                });
            });
    }

    handleCancelRemove = () => {
        this.setState({ showRemoveDialog: false, itemRemove: null })
    }


    render() {

        const { itens, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements, sort, sortDirection } = this.state;

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`${text("menu.tipo-local")} ${text("geral.html-title")}`} />

                <Grid stackable columns={2}>
                    <Grid.Column>
                        <Header as="h1">{text("tipo-local.tipo-local")}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">

                        <Form >
                            <Form.Group widths='equal'>
                                <Form.Input
                                    fluid icon='search'
                                    iconPosition='left'
                                    name="busca"
                                    placeholder={text('tipo-local.buscar')}
                                    value={this.state.busca ? this.state.busca : ""}
                                    onChange={e => this.setState({ busca: e.target.value })}
                                />
                                <Button onClick={this.handleSearch} >
                                    {text('tabelas.buscar')}
                                </Button>
                                &nbsp;
                                <Button
                                    floated='right'
                                    icon labelPosition='right'
                                    color='blue'
                                    as={Link}
                                    to="/admin/tipo-local"
                                >
                                    <Icon name='plus' /> {text("tabelas.novo")}
                                </Button>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid>

                <Confirm
                    header={text("tabelas.dialogo-remover")}
                    content={this.state.itemRemove ? (this.state.itemRemove.nome) : ''}
                    open={this.state.showRemoveDialog}
                    onCancel={this.handleCancelRemove}
                    onConfirm={this.handleConfirmRemove}
                    cancelButton={text("geral.nao")}
                    confirmButton={text("geral.sim")}
                />

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <Table celled striped selectable sortable >
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={sort === 'nome' ? sortDirection : null}
                                onClick={this.handleSort('nome')}
                            >
                                {text("tipo-local.nome")}</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={sort === 'simbolo' ? sortDirection : null}
                                onClick={this.handleSort('simbolo')}
                            >
                                {text("tipo-local.icone")}</Table.HeaderCell>

                            <Table.HeaderCell collapsing>{text("tabelas.acoes")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            itens.map((item) =>
                                <Table.Row key={item.id} >
                                    <Table.Cell>
                                        {item.nome}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon
                                            name={item.simbolo ? item.simbolo : 'map marker alternate'}
                                            style={{ color: item.cor ? item.cor : '#808080' }}
                                            size="large"
                                        />
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <Button.Group size='mini'>
                                            <Button animated as={Link} to={"/admin/tipo-local/" + (item.id)} color="green" >
                                                <Button.Content hidden>{text("tabelas.editar")}</Button.Content>
                                                <Button.Content visible><Icon name='edit outline' /></Button.Content>
                                            </Button>
                                            <Button animated onClick={this.handleRemove(item)} color="red">
                                                <Button.Content hidden >{text("tabelas.remover")}</Button.Content>
                                                <Button.Content visible ><Icon name='trash alternate' /></Button.Content>
                                            </Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell >
                                {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='7'>
                                <Button
                                    disabled={lastPage}
                                    floated='right'
                                    icon labelPosition='right'
                                    size='medium'
                                    onClick={this.handleNextPage}
                                >
                                    <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                                </Button>
                                <Button
                                    disabled={firstPage}
                                    floated='right'
                                    icon labelPosition='left'
                                    size='medium'
                                    onClick={this.handlePreviousPage}
                                >
                                    <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>

                </Table>
            </Container>
        )
    }

}