const desenhoTriangulo = "2,18 16,18 9,0"
const desenhoLosangulo = "0,9 9,18 18,9 9,0"
const desenhoTrianguloCorte = "2,18 7,18 9,15 11,18 16,18 9,0"
const desenhoLosanguloCorte = "0,9 7,17 11,17 18,9 9,0"
const desenhoAviao = "9,0 10.5,2 10.5,7 17,12 10.5,10.5 10.5,15 12,18 9,17.5 6,18 7.5,15 7.5,10.5 1,12 7.5,7 7.5,2"

export const desenharAcompanhamentoSVG = (velocidade, rumo, hostilidade, origem, perdido, selecionado) => {

    const w = 23;
    const h = 23;
    const cx = w / 2;
    const cy = h / 2;
    const parado = (velocidade && velocidade >= 1) ? false : true
    var cor;
    var rotacao = 0;
    var pontos;
    var id = "aco"
    var opacidade = 1.0;
    var strokeWidth = 1;
    var strokeColor = 'black'

    if (parado) {
        rotacao = 0;
        id += "-p"
    }else {
        rotacao = Math.round(rumo);
        id += "-" + rotacao
    }

    if (perdido) {
        opacidade = 0.5
        id += "-p"
    }else{
        id += "-np"
    }

    if (selecionado) {
        strokeWidth = 1.5
        id += "-s"
    }else{
        id += "-ns"
    }

    switch (hostilidade) {
        case 'HOSTIL':
        case 'H':
            cor = "#ff0000"
            id += "-h";
            break;
        case 'SUSPEITO':
        case 'S':
            cor = "#ff7200"
            id += "-s";
            break;
        case 'AMIGO':
        case 'F':
            cor = "#00ff00"
            id += "-f";
            break;
        case 'NEUTRO':
        case 'N':
            cor = "#6b6b6b"
            id += "-n";
            break;
        default:
            cor = "#ffff00"
            id += "-u";
            break;
    }

    switch (origem) {
        case 'ADSB':
            pontos = desenhoAviao;
            id += '-av'
            break
        case 'FUN':
            pontos = parado ? desenhoLosanguloCorte : desenhoTrianguloCorte;
            id += '-fun'
            break
        default:
            pontos = parado ? desenhoLosangulo : desenhoTriangulo;
    }

    return ([
        id,
        `<svg xmlns='http://www.w3.org/2000/svg' width='${w}' height='${h}'>` +
        `<polygon transform='rotate(${rotacao},${cx},${cy})' points='${pontos}' style='fill:${cor};opacity:${opacidade};stroke:${strokeColor};stroke-width:${strokeWidth}' /> ` +
        `</svg>`
    ]);
}


export const desenharCalculoTaticoSVG = () => {

    var id = "ct"
    const x = 23;
    const y = 23;
    const cx = x / 2;
    const cy = y / 2;
    const r = (x / 2) -2;

    return ([
        id,
        `<svg xmlns='http://www.w3.org/2000/svg' width='${x}' height='${y}'>` +
        `<circle cx='${cx}' cy='${cy}' r='${r}' stroke='black' stroke-width='1' fill='red' /> ` +
        `</svg>`
    ]);

}