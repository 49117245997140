import React, { Component } from 'react';

import { Header, Container, Segment, List } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { text } from 'services/locales'
import './styles.css'

export default class Suporte extends Component {

    render() {

        return (
            <Container style={{ padding: '2em' }}>

                <Header as="h1"> {text("admin.admin")}</Header>

                <br />

                <div className="admin-grid">

                    <div>
                        <Header as='h3' attached='top'>
                            {text("admin.usuario-autenticacao")}
                        </Header>
                        <Segment attached>
                            <List bulleted >
                                <List.Item
                                    as={Link}
                                    to="/admin/usuarios"
                                >
                                    {text("admin.usuarios")}
                                </List.Item>
                                <List.Item
                                    as={Link}
                                    to="/admin/historico-login"
                                >
                                    {text("admin.historico-login")}
                                </List.Item>
                                <List.Item
                                    as={Link}
                                    to="/admin/usuarios-online"
                                >
                                    {text("admin.usuarios-online")}
                                </List.Item>
                            </List>
                        </Segment>
                    </div>

                    <div>
                        <Header as='h3' attached='top'>
                            {text("admin.eventos")}
                        </Header>
                        <Segment attached >
                            <List bulleted>
                                <List.Item
                                    as={Link}
                                    to="/admin/tipo-eventos"
                                >
                                    {text("admin.tipo-eventos")}
                                </List.Item>
                            </List>
                        </Segment>
                    </div>

                    <div>
                        <Header as='h3' attached='top'>
                            {text("admin.locais")}
                        </Header>
                        <Segment attached>
                            <List bulleted >
                                <List.Item
                                    as={Link}
                                    to="/admin/locais"
                                >
                                    {text("admin.locais")}
                                </List.Item>
                                <List.Item
                                    as={Link}
                                    to="/admin/tipo-locais"
                                >
                                    {text("admin.tipo-locais")}
                                </List.Item>
                            </List>
                        </Segment>
                    </div>                   

                </div>


            </Container>
        );
    }

}