import React, { Component } from 'react';

import L from 'leaflet';
import { Marker } from "react-leaflet";
import ReactDOMServer from 'react-dom/server';
import styled, { keyframes } from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { getIcon } from 'util/eventos'

const animation = keyframes`
    0% { opacity: 1 }
    50% { opacity: 0.5}
    100% { opacity: 1}
`;

const Objeto = styled.div`
    // animation-name: ${animation};
    // animation-duration: 0.5s;
    // animation-iteration-count: infinite;
`;

const DesenhoEvento = styled.div`
    padding: 2px;
    background-color:${props => props.selecionado ? '#c3c3c3' : ''};
    border:${props => props.selecionado ? 'solid 1px #FF0000' : 'none'};
    text-shadow:
    -1px -1px 0 #FFF,  
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
    width: 33px
`;

const TextoDireitaEvento = styled.div`
    padding: 5px;
    font-size: 12px;
    font-weight:bold;
    min-width: 40px ;
    min-width: 100px ;
    color:#FFF;
    background-color: #000;
    border: 1px solid #ff0000
`;

export default class SimbologiaEvento extends Component {

    getStyle = (item, objetoSelecionado) => {
        return L.divIcon({
            className: 'icone-simbologia-evento',
            html: ReactDOMServer.renderToString(
                <Objeto>
                    <DesenhoEvento selecionado={(objetoSelecionado != null && objetoSelecionado.id === item.id)}>
                        <Icon
                            name={item.simbolo ? getIcon(item.simbolo) : 'alarm'}
                            style={{ color: item.cor ? item.cor : '#808080' }}
                            size="big"
                        />
                    </DesenhoEvento>
                    <TextoDireitaEvento>
                        {item.nome}
                    </TextoDireitaEvento>
                </Objeto>
            )
        });
    }

    render() {

        const { item, objetoSelecionado } = this.props;

        return (
            <Marker
                key={item.id}
                id={item.id}
                position={[item.lat, item.lng]}
                icon={this.getStyle(item, objetoSelecionado)}
                onClick={this.props.onClick}
                zIndexOffset={9999}
            />
        )
    }
}