import React, { Component } from 'react';

import { Segment, Header } from 'semantic-ui-react'


export default class Observacoes extends Component {

    state = {

    }

    render() {

        const { inverted, header, titulo, observacoes } = this.props

        return (
            <>
                {observacoes &&
                    <div className="box">
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            {titulo}
                        </Header>
                        <Segment attached inverted={inverted} style={{ whiteSpace: 'pre-line', overflow: 'auto', maxHeight: "300px" }}>
                            {observacoes}
                        </Segment>
                    </div>
                }
            </>
        )
    }
}