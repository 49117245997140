import React, { Component } from 'react';

import { text } from 'services/locales'
import { Link } from 'react-router-dom'
import { formatarDataHoraComTempoAtras, formatarDataHora } from 'util/dataHora'

export default class DadoMeteorologico extends Component {

    state = {

    }

    renderDadoMeteorologico = (item) => {
        switch (item.tipo) {
            case 'soma':
                return this.renderDadoMeteorologicoSoma(item);
            case 'clima_tempo':
                return this.renderDadoMeteorologicoClimaTempo(item);
            case 'inpe':
                return this.renderDadoMeteorologicoInpe(item);
            default: return null
        }
    }

    renderDadoMeteorologicoSoma = (item) => {
        return (
            <>
                {item.dataHora && <><b>{text("dados-meteorologicos.atualizacao")}:</b> {formatarDataHoraComTempoAtras(item.dataHora)} <br /> <br /></>}

                {!isNaN(item.pressaoAtmosferica) && item.pressaoAtmosferica !== 0 && <><b>{text("dados-meteorologicos.pressao-atmosferica-media")}:</b> {Number(item.pressaoAtmosferica).toFixed(0)} hPa <br /></>}

                {!isNaN(item.precipitacaoAcumulada) && item.precipitacaoAcumulada !== 0 && <><b>{text("dados-meteorologicos.precipitacao-acumulada")}:</b> {Number(item.precipitacaoAcumulada).toFixed(0)} mm/5min <br /></>}

                <br /><b>{text("dados-meteorologicos.nivel-10m")}</b><br />

                {!isNaN(item.temperaturaAr10m) && item.temperaturaAr10m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.temperatura")}:</b> {Number(item.temperaturaAr10m).toFixed(0)}°C <br /></>}

                {!isNaN(item.umidadeRelativaAr10m) && item.umidadeRelativaAr10m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.umidade-ar")}:</b> {Number(item.umidadeRelativaAr10m).toFixed(0)}% <br /></>}

                {!isNaN(item.direcaoVento10m) && item.direcaoVento10m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.direcao-vento")}:</b> {Number(item.direcaoVento10m).toFixed(0)}° <br /></>}

                {!isNaN(item.velocidadeMediaVento10m) && item.velocidadeMediaVento10m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.intensidade-vento")}:</b> {Number(item.velocidadeMediaVento10m).toFixed(1)} m/s <br /></>}

                {!isNaN(item.direcaoRajadaVento10m) && item.direcaoRajadaVento10m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.direcao-rajada-vento")}:</b> {Number(item.direcaoRajadaVento10m).toFixed(0)}° <br /></>}

                {!isNaN(item.rajadaVento10m) && item.rajadaVento10m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.intensidade-rajada-vento")}:</b> {Number(item.rajadaVento10m).toFixed(1)} m/s <br /></>}

                {item.horarioRajadaVento10m && <>&nbsp; <b>{text("dados-meteorologicos.hora-rajada-vento")}:</b> {formatarDataHora(item.horarioRajadaVento10m)} <br /></>}

                <br /><b>{text("dados-meteorologicos.nivel-60m")}</b><br />

                {!isNaN(item.temperaturaAr60m) && item.temperaturaAr60m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.temperatura")}:</b> {Number(item.temperaturaAr60m).toFixed(0)}°C <br /></>}

                {!isNaN(item.umidadeRelativaAr60m) && item.umidadeRelativaAr60m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.umidade-ar")}:</b> {Number(item.umidadeRelativaAr60m).toFixed(0)}% <br /></>}

                {!isNaN(item.direcaoVento60m) && item.direcaoVento60m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.direcao-vento")}:</b> {Number(item.direcaoVento60m).toFixed(0)}° <br /></>}

                {!isNaN(item.velocidadeMediaVento60m) && item.velocidadeMediaVento60m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.intensidade-vento")}:</b> {Number(item.velocidadeMediaVento60m).toFixed(1)} m/s <br /></>}

                {!isNaN(item.direcaoRajadaVento60m) && item.direcaoRajadaVento60m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.direcao-rajada-vento")}:</b> {Number(item.direcaoRajadaVento60m).toFixed(0)}° <br /></>}

                {!isNaN(item.rajadaVento60m) && item.rajadaVento60m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.intensidade-rajada-vento")}:</b> {Number(item.rajadaVento60m).toFixed(1)} m/s <br /></>}

                {item.horarioRajadaVento60m && <>&nbsp; <b>{text("dados-meteorologicos.hora-rajada-vento")}:</b> {formatarDataHora(item.horarioRajadaVento60m)} <br /></>}

                <br /><b>{text("dados-meteorologicos.nivel-80m")}</b><br />

                {!isNaN(item.temperaturaAr80m) && item.temperaturaAr80m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.temperatura")}:</b> {Number(item.temperaturaAr80m).toFixed(0)}°C <br /></>}

                {!isNaN(item.umidadeRelativaAr80m) && item.umidadeRelativaAr80m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.umidade-ar")}:</b> {Number(item.umidadeRelativaAr80m).toFixed(0)}% <br /></>}

                {!isNaN(item.direcaoVento80m) && item.direcaoVento80m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.direcao-vento")}:</b> {Number(item.direcaoVento80m).toFixed(0)}° <br /></>}

                {!isNaN(item.velocidadeMediaVento80m) && item.velocidadeMediaVento80m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.intensidade-vento")}:</b> {Number(item.velocidadeMediaVento80m).toFixed(1)} m/s <br /></>}

                {!isNaN(item.direcaoRajadaVento80m) && item.direcaoRajadaVento80m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.direcao-rajada-vento")}:</b> {Number(item.direcaoRajadaVento80m).toFixed(0)}° <br /></>}

                {!isNaN(item.rajadaVento80m) && item.rajadaVento80m !== 0 && <>&nbsp; <b>{text("dados-meteorologicos.intensidade-rajada-vento")}:</b> {Number(item.rajadaVento80m).toFixed(1)} m/s <br /></>}

                {item.horarioRajadaVento80m && <>&nbsp; <b>{text("dados-meteorologicos.hora-rajada-vento")}:</b> {formatarDataHora(item.horarioRajadaVento80m)} <br /></>}
            </>
        )
    }

    renderDadoMeteorologicoClimaTempo = (item) => {
        return (
            <>
                {item.dataHora && <><b>{text("dados-meteorologicos.atualizacao")}:</b> {formatarDataHoraComTempoAtras(item.dataHora)} <br /> <br /></>}

                {item.descricaoTempo && <><b>{text("dados-meteorologicos.situacao-atual")}:</b> {item.descricaoTempo} <br /></>}

                {!isNaN(item.temperatura) && item.temperatura && <><b>{text("dados-meteorologicos.temperatura")}:</b> {Number(item.temperatura).toFixed(0)}°C <br /></>}

                {!isNaN(item.sensacaoTermica) && item.sensacaoTermica && <><b>{text("dados-meteorologicos.sensacao-termica")}:</b> {Number(item.sensacaoTermica).toFixed(0)}°C <br /></>}

                {!isNaN(item.pressaoAtmosferica) && item.pressaoAtmosferica && <><b>{text("dados-meteorologicos.pressao-atmosferica")}:</b> {Number(item.pressaoAtmosferica).toFixed(0)} mb <br /></>}

                {!isNaN(item.direcaoVento) && item.direcaoVento && <><b>{text("dados-meteorologicos.direcao-vento")}:</b> {Number(item.direcaoVento).toFixed(0)}° <br /></>}

                {!isNaN(item.intensidadeVento) && item.intensidadeVento && <><b>{text("dados-meteorologicos.intensidade-vento")}:</b> {Number(item.intensidadeVento).toFixed(1)} km/h <br /></>}

                {!isNaN(item.umidadeAr) && item.umidadeAr && <><b>{text("dados-meteorologicos.umidade-ar")}:</b> {Number(item.umidadeAr).toFixed(0)}% <br /></>}
            </>
        )
    }

    renderDadoMeteorologicoInpe = (item) => {
        return (
            <>
                {item.dataHora && <><b>{text("dados-meteorologicos.atualizacao")}:</b> {formatarDataHoraComTempoAtras(item.dataHora)} <br /> <br /></>}

                {item.descricaoTempo && <><b>{text("dados-meteorologicos.situacao-atual")}:</b> {item.descricaoTempo} <br /></>}

                {!isNaN(item.temperatura) && item.temperatura && <><b>{text("dados-meteorologicos.temperatura")}:</b> {Number(item.temperatura).toFixed(0)}°C <br /></>}

                {!isNaN(item.pressaoAtmosferica) && item.pressaoAtmosferica && <><b>{text("dados-meteorologicos.pressao-atmosferica")}:</b> {Number(item.pressaoAtmosferica).toFixed(0)} mb <br /></>}

                {!isNaN(item.direcaoVento) && item.direcaoVento && <><b>{text("dados-meteorologicos.direcao-vento")}:</b> {Number(item.direcaoVento).toFixed(0)}° <br /></>}

                {!isNaN(item.intensidadeVento) && item.intensidadeVento && <><b>{text("dados-meteorologicos.intensidade-vento")}:</b> {Number(item.intensidadeVento).toFixed(1)} km/h <br /></>}

                {!isNaN(item.visibilidade) && item.visibilidade && <><b>{text("dados-meteorologicos.visibilidade")}:</b> {Number(item.visibilidade).toFixed(0)} metros<br /></>}

                {!isNaN(item.umidadeAr) && item.umidadeAr && <><b>{text("dados-meteorologicos.umidade-ar")}:</b> {Number(item.umidadeAr).toFixed(0)}% <br /></>}
            </>
        )
    }

    render() {

        const { item, fonte, distancia, estacao, idEstacao, showLinks } = this.props

        return (
            <>
                {item &&
                    <>
                        {fonte && <><b>{text("dados-meteorologicos.fonte")}:</b> {fonte} <br /></>}

                        {estacao && <><b>{text("dados-meteorologicos.estacao")}:</b> {showLinks ? (<Link to={"/estacao-meteorologica/" + idEstacao}>{estacao}</Link>) : (estacao)} <br /></>}

                        {!isNaN(distancia) && distancia !== 0 && <><b>{text("dados-meteorologicos.distancia")}:</b> {Number(distancia).toFixed(1)} Km <br /></>}

                        {this.renderDadoMeteorologico(item)}
                    </>
                }
            </>
        )
    }
}