import React from "react";

import { Segment, Header, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export default class ErroPageApp extends React.Component {
    render() {
        return (
            <Segment textAlign="center" style={{ height: "100vh" }} inverted>
                <br /><br /><br />
                <Header icon>
                    <Icon name='exclamation triangle' />
                    Página não encontrada
                    <br/><br/>
                    <Link
                        to="/app"
                    >
                        Voltar
                    </Link>
                </Header>
            </Segment>
        )
    }
}