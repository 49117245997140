import React, { Component } from 'react';

import {
    Menu, Icon
} from 'semantic-ui-react';

import { Link } from 'react-router-dom'
import { text } from 'services/locales'
import { isHidden } from 'util/functions'
import { notificar } from 'components/Notificador'
import api from 'services/api';
import UIfx from 'uifx';

import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export default class ChatIcon extends Component {

    state = {
        newMsg: false,
        online: false,
        contDicaNotificacao: 0
    }

    componentDidMount() {
        this.loadNewMessagens()
    }

    loadNewMessagens() {
        if (!window.location.href.includes("chat")) {
            api.get("/chat/mensagens/unread/count")
                .then((response) => {
                    if (response.data._total > 0)
                        this.setState({ newMsg: true })
                })
                .catch(() => {
                    this.setState({ newMsg: false })
                })
        }
    }

    handleStateChange(state) {
        this.setState({ online: state })
    }

    handleRX(msg) {

        //Se não estiver na pagina do chat, ou a aba do navegador não estiver visivel, notifico a nova mensagem
        if (!window.location.href.includes("chat") || isHidden()) {
            const { newMsg } = this.state;

            if (!newMsg)
                this.setState({ newMsg: true })

            var tituloNotificacao = text("chat.notificacao-de") + " '" + msg.org + "'";
            var corpoNotificacao;

            if (msg.txt) {
                corpoNotificacao = msg.txt;
            } else {
                corpoNotificacao = text("chat.enviou-arquivo");
            }

            const sound = new UIfx(
                process.env.PUBLIC_URL + '/sounds/chat.ogg',
            )
            sound.play()

            var notificou = notificar(tituloNotificacao, corpoNotificacao, process.env.PUBLIC_URL + '/images/sis-care-icon.png', false)

            //Se não notificou, provavelmente por conta da falta de permissão do usuário no seu navegador, notifico dentro da pagina
            if (!notificou) {

                var detail = msg.txt
                if (detail.length > 50) {
                    detail = detail.substring(0, 50) + "..."
                }

                NotificationManager.success(detail, `[${text("chat.titulo")}] ${msg.org} ${text("chat.disse")} :`, 3000);

                const { contDicaNotificacao } = this.state;
                if (contDicaNotificacao % 5 === 0) {
                    NotificationManager.info(text("geral.dica-habilitar-notificacao-navegador"), null, 3000);
                }
                this.setState({ contDicaNotificacao: contDicaNotificacao + 1 })
            }
        }
    }

    handleNoNewMessage = () => {
        this.setState({ newMsg: false })
    }

    render() {

        const { newMsg, online } = this.state;

        return (
            <>
                <Menu.Item title={text("chat.titulo")} as={Link} to="/chat" onClick={this.handleNoNewMessage}>
                    <Icon.Group >

                        {online ? (<Icon name='chat' />) : (<Icon name='chat' color="grey" />)}

                        {newMsg &&
                            <Icon corner color='blue' name='circle' />
                        }
                    </Icon.Group>
                </Menu.Item>

            </>
        )
    }
}