import React, { Component } from 'react';

import { Button, Icon, Header, Form, Table, Message, Grid, Label, Container, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import api from 'services/api';
import { text, html } from 'services/locales';
import { getIcon } from 'util/eventos'
import Footer from 'components/footer'
import { Helmet } from 'react-helmet'
import { formatarDataHoraComTempoAtras } from 'util/dataHora'
import { isCoord } from 'services/auth'

const optionsEstados = [
  { text: text("eventos.ativo"), value: 'ATIVO' },
  { text: text("eventos.reconhecido"), value: 'RECONHECIDO' },
  { text: text("eventos.inativo"), value: 'INATIVO' }
]

export default class Tabela extends Component {

  state = {
    itens: [],
    busca: null,
    loading: false,
    error: null,
    sucesses: null,
    pageNumber: 0,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    lastPage: true,
    firstPage: true,
    pageSize: 30,
    sort: "ocorrencia",
    sortDirection: 'descending',
    showRemoveDialog: false,
    itemRemove: null,
    optionsTiposEventos: [],
    filtro: {
      ativo: false,
      estados: ['ATIVO', 'RECONHECIDO'],
      tipos: []
    }
  }

  componentDidMount() {
    this.loadTipoEventos();

    if (this.props.location.state != null) {
      this.setState({ sucesses: this.props.location.state.sucesses })
    }
  }

  loadTipoEventos() {
    api.get("/eventos/tipos?sort=nome,asc&size=999")
      .then((response) => {
        const optionsTiposEventos = response.data.content.map((item) => ({
          key: item.id,
          text: item.nome,
          value: item.id,
        }))
        const filtroTipos = response.data.content.map((item) => (
          item.id
        ))
        const { filtro } = this.state;
        this.setState(
          {
            optionsTiposEventos: optionsTiposEventos,
            filtro:
            {
              ...filtro,
              tipos: filtroTipos
            }
          }, () =>
          this.loadItens()
        );
      })
      .catch(() => {
        this.setState({
          loading: false,
          itens: [],
          error: text("formularios.erro_request")
        });
      })
  }

  loadItens = (page = 0, busca = null) => {
    this.setState({ loading: true, error: null, sucesses: null });

    let url = '/eventos?page=' + page;
    if (busca != null) {
      url += '&q=' + busca
    }

    const { sort, sortDirection, filtro, pageSize } = this.state;

    if (sort != null) {
      url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
    }

    url += "&size=" + pageSize;

    if (filtro) {
      if (filtro.estados) {
        url += "&e="
        filtro.estados.forEach((item, i, arr) => {
          url += item;
          if (arr.length - 1 !== i)
            url += ","
        });
      }
      if (filtro.tipos) {
        url += "&t="
        filtro.tipos.forEach((item, i, arr) => {
          url += item
          if (arr.length - 1 !== i)
            url += ","
        });
      }
    }

    api.get(url)
      .then((response) => {
        if (response.data.content != null) {
          this.setState({
            loading: false,
            itens: response.data.content,
            totalPages: response.data.totalPages,
            totalElements: response.data.totalElements,
            lastPage: response.data.last,
            firstPage: response.data.first,
            pageNumber: response.data.number,
            numberOfElements: response.data.numberOfElements
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          itens: [],
          error: text("formularios.erro_request")
        });
      });
  };

  handleSearch = e => {
    e.preventDefault();
    const { busca } = this.state;
    this.loadItens(0, busca);
  }

  handleNextPage = () => {
    const { pageNumber, lastPage, busca } = this.state;
    if (lastPage === true) return;

    const novoPageNumber = pageNumber + 1;
    this.loadItens(novoPageNumber, busca);
  }
  handlePreviousPage = () => {
    const { pageNumber, firstPage, busca } = this.state;
    if (firstPage === true) return;

    const novoPageNumber = pageNumber - 1;
    this.loadItens(novoPageNumber, busca);
  }

  handleRefresh = () => {
    const { pageNumber, busca } = this.state
    this.loadItens(pageNumber, busca);
  }

  handleSort = clickedColumn => () => {
    const { busca, sort, sortDirection } = this.state

    this.setState({
      sortDirection: clickedColumn === sort ? (sortDirection === 'ascending' ? 'descending' : 'ascending') : 'ascending', //Se for a mesma coluna clicada, inverte a ordenação, caso contrário, define como ascendente 
      sort: clickedColumn
    }, () => {
      this.loadItens(0, busca);
    })
  }

  handleChangeDropdown = (e, { name, value }) => {
    const { busca } = this.state
    this.setState({ [name]: value }, () => { this.loadItens(0, busca); });
  }

  handleRemove = item => () => {
    this.setState({ showRemoveDialog: true, itemRemove: item, error: null, sucesses: null })
  }

  renderEstado(item) {
    switch (item.estado.codigo) {
      case 'ativo':
        return (<Label tag size="small" color='green'>{text("eventos.ativo")}</Label>)
      case 'reconhecido':
        return (<Label tag size="small" color='yellow'>{text("eventos.reconhecido")}</Label>)
      case 'inativo':
        return (<Label tag size="small" color='grey'>{text("eventos.inativo")}</Label>)
      default: return null;
    }
  }

  handleNewEvent(item) {
    this.loadItens(0, null);
  }

  handleFiltro = () => {
    const { filtro } = this.state;
    this.setState({
      filtro:
      {
        ...filtro,
        ativo: !filtro.ativo
      }
    }
    )
  }

  handleFiltroEstados = (valor) => {
    const { filtro } = this.state;
    var temp = filtro.estados;

    if (temp.includes(valor)) {
      if (temp.length === 1) return; //Não permite desmarcar todos
      temp.splice(temp.indexOf(valor), 1);
    } else {
      temp.push(valor)
    }

    this.setState(
      {
        filtro:
        {
          ...filtro,
          estados: temp
        }
      },
      () => {
        this.loadItens(0);
      }
    )
  }

  handleFiltroTipos = (valor) => {
    const { filtro } = this.state;
    var temp = filtro.tipos;

    if (temp.includes(valor)) {
      if (temp.length === 1) return; //Não permite desmarcar todos
      temp.splice(temp.indexOf(valor), 1);
    } else {
      temp.push(valor)
    }

    this.setState(
      {
        filtro:
        {
          ...filtro,
          tipos: temp
        }
      },
      () => {
        this.loadItens(0);
      }
    )
  }


  render() {

    const { filtro, loading, itens, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements, sort, sortDirection } = this.state;

    const optionsPageSize = [
      { key: 10, text: '10', value: 10 },
      { key: 30, text: '30', value: 30 },
      { key: 50, text: '50', value: 50 },
      { key: 100, text: '100', value: 100 },
    ]

    return (
      <Container style={{ padding: '2em' }}>

        <Helmet title={`${text("menu.eventos")} ${text("geral.html-title")}`} />

        <Grid stackable columns={2} >
          <Grid.Column>
            <Header as="h1">{text("eventos.eventos")}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Form size='large' className="form">

              <Button
                icon labelPosition='right'
                color="blue"
                as={Link}
                to="/eventos/novo"
              >
                <Icon name='plus' />  {text("tabelas.novo")}
              </Button>

            </Form>
          </Grid.Column>
        </Grid>


        {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
        {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

        <Table celled striped selectable sortable >

          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>
                <Button
                  icon
                  basic compact
                  labelPosition='right'
                  onClick={this.handleFiltro}
                  toggle active={filtro.ativo}
                >
                  <Icon name='filter' />
                  {text("formularios.filtros")}
                </Button>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='4' textAlign="right">
                <Button compact basic icon onClick={this.handleRefresh} loading={loading} title={text("geral.atualizar")} >
                  <Icon name='refresh' />
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {filtro.ativo &&
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='6'>
                  <Grid divided columns={2} >
                    <Grid.Column width="5">
                      <b>{text("eventos.estado")}</b>

                      <Form.Group grouped>
                        {optionsEstados
                          .map(item =>
                            <Form.Checkbox
                              key={item.value}
                              value={item.value}
                              label={item.text}
                              checked={filtro.estados.includes(item.value)}
                              onChange={(e, { value }) => this.handleFiltroEstados(value)}
                              disabled={item.value === 'INATIVO' && !isCoord()}
                            />
                          )}
                      </Form.Group>

                    </Grid.Column>
                    <Grid.Column>
                      <b>{text("eventos.tipo")}</b>

                      <Form.Group grouped className="filter-checkbox-grid">
                        {this.state.optionsTiposEventos.map(item =>
                          <Form.Checkbox
                            key={item.value}
                            value={item.value}
                            label={item.text}
                            checked={filtro.tipos.includes(item.value)}
                            onChange={(e, { value }) => this.handleFiltroTipos(value)}
                          />
                        )}
                      </Form.Group>
                    </Grid.Column>
                  </Grid>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          }

          <Table.Header>
            <Table.Row>

              <Table.HeaderCell
                collapsing
                sorted={sort === 'estado' ? sortDirection : null}
                onClick={this.handleSort('estado')}
              >
                {text("eventos.estado")}</Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort === 'titulo' ? sortDirection : null}
                onClick={this.handleSort('titulo')}
              >
                {text("eventos.titulo")}</Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort === 'tipo.nome' ? sortDirection : null}
                onClick={this.handleSort('tipo.nome')}
              >
                {text("eventos.tipo")}</Table.HeaderCell>

              <Table.HeaderCell
                sorted={sort === 'ocorrencia' ? sortDirection : null}
                onClick={this.handleSort('ocorrencia')}
              >
                {text("eventos.ocorrencia")}</Table.HeaderCell>
              <Table.HeaderCell collapsing>{text("tabelas.detalhes")}</Table.HeaderCell>

            </Table.Row>
          </Table.Header>

          <Table.Body>
            {itens.map((item) =>
              <Table.Row key={item.id}>
                <Table.Cell>{this.renderEstado(item)}</Table.Cell>
                <Table.Cell>
                  <Link to={"/evento/" + (item.id)}>
                    {item.titulo}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Icon
                    style={{ color: item.tipo.severidade.cor ? item.tipo.severidade.cor : '#808080' }}
                    name={getIcon(item.tipo.severidade.codigo)}
                  />
                  <Link to={"/evento/" + (item.id)}>
                    {item.tipo.nome}
                  </Link>
                </Table.Cell>
                <Table.Cell className="primeiraLetraMaiuscula">{formatarDataHoraComTempoAtras(item.ocorrencia)}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Button icon size='mini' as={Link}
                    to={"/evento/" + (item.id)}
                  > <Icon name='search plus' />
                  </Button>
                </Table.Cell>
              </Table.Row>
            )
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>
                {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
                &nbsp; {text("tabelas.tamanho-pagina")}&nbsp;
                <Dropdown
                  compact
                  selection
                  value={this.state.pageSize}
                  options={optionsPageSize}
                  onChange={this.handleChangeDropdown}
                  name="pageSize"
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>
                <Button
                  disabled={lastPage}
                  floated='right'
                  icon labelPosition='right'
                  size='medium'
                  onClick={this.handleNextPage}
                >
                  <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                </Button>
                <Button
                  disabled={firstPage}
                  floated='right'
                  icon labelPosition='left'
                  size='medium'
                  onClick={this.handlePreviousPage}
                >
                  <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>

        </Table>


        <Footer />

      </Container >
    );
  }

}