import React from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import DefaultLayout from "layout"
import Login from 'pages/login';
import Logout from 'pages/logout';
import Reset from 'pages/reset';

import { isAuthenticated, getToken } from 'services/auth';

import "./styles.css";
import 'semantic-ui-offline/semantic.min.css';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: getToken() ? "/logout" : "/login", search: getToken() ? `?reason=not-auth` : '', state: { from: props.location } }} />
      )
    }
  />
);

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/reset" component={Reset} />
          <PrivateRoute path="*" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>

    </div>
  );
}

export default App;
