export const convertLatDMS = (lat) => {
    var latitude = latitudeToDMS(lat);
    var latitudeCardinal = lat >= 0 ? "N" : "S";

    return latitude + latitudeCardinal
}

export const convertLngDMS = (lng) => {
    var longitude = longitudeToDMS(lng);
    var longitudeCardinal = lng >= 0 ? "E" : "W";

    return longitude + longitudeCardinal
}

function latitudeToDMS(latitude) {
    let absolute = Math.abs(latitude);
    let degrees = Math.floor(absolute);
    let minutesNotTruncated = (absolute - degrees) * 60;
    let minutes = Math.floor(minutesNotTruncated);
    let seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    let deg = degrees < 10 ? '0' + degrees : degrees
    let min = minutes < 10 ? '0' + minutes : minutes
    let sec = seconds < 10 ? '0' + seconds : seconds

    return deg + "°" + min + "'" + sec + "''";
}

function longitudeToDMS(longitude) {
    var absolute = Math.abs(longitude);
    let degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    let deg = degrees < 10 ? '00' + degrees : degrees > 10 && degrees < 100 ? '0' + degrees : degrees
    let min = minutes < 10 ? '0' + minutes : minutes
    let sec = seconds < 10 ? '0' + seconds : seconds

    return deg + "°" + min + "'" + sec + "''";
}

export const convertDMS = (lat, lng) => {
    var latitude = latitudeToDMS(lat);
    var latitudeCardinal = lat >= 0 ? "N" : "S";

    var longitude = longitudeToDMS(lng);
    var longitudeCardinal = lng >= 0 ? "E" : "W";

    return latitude + latitudeCardinal + " " + longitude + longitudeCardinal;
}

export const convertDMSToDD = (dms) => {
    var parts = dms.split(/[^\d\w]+/);
    var degrees = parts[0];
    var minutes = parts[1];
    var seconds = parts[2];
    var direction = parts[3].toUpperCase();

    var deg = (Number(degrees) + Number(minutes) / 60 + Number(seconds) / 3600).toFixed(6);

    if (direction === "S" || direction === "W") {
        deg = deg * -1;
    }

    return deg;
}


export const comparaBounds = (b1, b2) => {
    if (b1 === null || b2 === null) {
        return false;
    }
    if (b1.latMin === null || b1.lngMin === null || b1.latMax === null || b1.lngMax === null) {
        return false;
    }
    if (b2.latMin === null || b2.lngMin === null || b2.latMax === null || b2.lngMax === null) {
        return false;
    }
    if (
        b1.latMin === b2.latMin &&
        b1.lngMin === b2.lngMin &&
        b1.latMax === b2.latMax &&
        b1.lngMax === b2.lngMax
    ) {
        return true;
    }
    return false;
}

export const isDev = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return true
    } else {
        return false
    }
}

export const humanFileSize = (size) => {
    if (size <= 0) return '0 B';
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const isHidden = () => {
    var hidden;
    if (typeof document.hidden !== "undefined") { // Suporte para Opera 12.10 e Firefox 18 em diante 
        hidden = "hidden";
    } else if (typeof document.mozHidden !== "undefined") {
        hidden = "mozHidden";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
    }

    return document[hidden];
}

export const forceDownload = (href, name) => {
    var anchor = document.createElement('a');
    anchor.href = href;
    anchor.target = '_blank';
    anchor.download = name;
    anchor.click();
}

export const isMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export const hasWebglSupport = () => {
    try {
        var canvas = document.createElement('canvas');
        return !!window.WebGLRenderingContext &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')) 
            ? true : false;
    } catch (e) {
        return false;
    }
};