import React, { Component, createRef } from 'react';

import { Segment, Icon } from 'semantic-ui-react'
import { Map, TileLayer, LayersControl, Polygon, Marker, ZoomControl } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { layers } from 'util/layersOptions';
import { getMapLayer, setMapLayer } from 'services/storage'

const { BaseLayer } = LayersControl

export default class Mapa extends Component {

    state = {
        posicaoMarker: null,
        polygon: [],
        zoom: 4,
        zoomLocal: 13,
        posicaoInicial: [-15, -45],
        statusPoligono: null
    }

    componentDidUpdate(prevProps) {

        if (this.props.center.length !== prevProps.center.length) {
            this.setState({ polygon: this.props.center })
        }

        if (this.props.poligono !== prevProps.poligono && prevProps.poligono === true) {
            this.setState({
                polygon: []
            })
        }

        if (this.props.poligono !== prevProps.poligono) {
            this.setState({
                statusPoligono: prevProps.poligono
            })
        }
    }

    getStyle = () => {
        return L.divIcon({
            className: '',
            html: ReactDOMServer.renderToString(
                <Icon
                    name={'map marker alternate'}
                    style={{ color: 'red' }}
                    size="big"
                />
            )
        });
    }

    handleClick = (e) => {
        this.setState({ posicaoMarker: e.latlng })
        this.props.onHandleClick(e.latlng)
    }

    handlePolygon = (e) => {
        const newPos = [e.latlng.lat, e.latlng.lng];
        this.setState(prevState => ({
            polygon: prevState.polygon.concat([newPos])
        }));
        this.props.onHandlePoly(e.latlng)
    }

    handleLocalização = () => {
        const map = this.refs.map.leafletElement
        this.setState({
            posicaoInicial: map.getCenter(),
            zoom: map.getZoom(),
            zoomLocal: map.getZoom()
        })
    }

    refmarker = createRef()

    updatePosition = () => {
        const marker = this.refmarker.current
        if (marker != null) {
            this.setState({
                posicaoMarker: marker.leafletElement.getLatLng(),
            })
        }
        this.props.onHandleClick(marker.leafletElement.getLatLng())
    }

    handleGoto = (latlong) => {
        const map = this.refs.map.leafletElement
        map.setView(latlong, 15)
        this.setState({
            posicaoInicial: map.getCenter()
        })
    }

    isLayerChecked = (layer) => {
        if (getMapLayer())
            return getMapLayer() === layer.url;
        else
            return layer.checked;
    }

    handleLayerChange = (e) => {
        if (e && e.layer)
            setMapLayer(e.layer._url)
    }

    render() {

        const { position, icon, color, poligono, center } = this.props;

        const positionMarker = this.state.posicaoMarker
        const zoomInicial = this.state.zoom
        const zoomLocal = this.state.zoomLocal
        const posicaoInicial = this.state.posicaoInicial
        const polygon = this.state.polygon

        return (
            <>
                <Segment attached>
                    <div className="mapa">
                            <Map
                                ref='map'
                                center={center.length === 0 ? posicaoInicial : position}
                                zoom={(center.length !== 0 && positionMarker === null && polygon.length === 0) ? zoomLocal : zoomInicial}
                                style={{ width: "100%", height: "350px" }}
                                touchZoom={false}
                                editable={true}
                                scrollWheelZoom={true}
                                onMoveEnd={this.handleLocalização}
                                onBaseLayerChange={this.handleLayerChange}
                                boxZoom
                                onClick={poligono ? this.handlePolygon : this.handleClick}
                                zoomControl={false}
                                maxBounds={[[-90, -180], [90, 180]]}
                            >
                                <ZoomControl position="topright" />
                                <LayersControl position="topright" >

                                    {layers.map(item =>
                                        <BaseLayer key={item.url} checked={this.isLayerChecked(item)} name={item.name}>
                                            <TileLayer
                                                attribution={item.attribution}
                                                url={item.url}
                                            />
                                        </BaseLayer>
                                    )}

                                    {poligono &&
                                        <Polygon
                                            color={'red'}
                                            positions={this.state.polygon}
                                        />
                                    }

                                    {position && !poligono ?
                                        <Marker
                                            position={position}
                                            icon={this.getStyle(icon, color)}
                                            draggable={true}
                                            onDragend={this.updatePosition}
                                            ref={this.refmarker}
                                        />
                                        : null
                                    }

                                    {positionMarker &&
                                        <Marker
                                            opacity={0}
                                            position={positionMarker}
                                            icon={this.getStyle()}
                                            draggable={true}
                                            onDragend={this.updatePosition}
                                            ref={this.refmarker}
                                        />
                                    }
                                </LayersControl>
                            </Map>
                    </div>
                </Segment>
            </>
        )
    }
}