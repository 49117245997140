import React, { Component } from 'react';

import { Header, Icon, Button, Segment, Feed, Divider } from 'semantic-ui-react'
import UserLink from 'components/UserLink';
import { text, html } from 'services/locales';
import api from 'services/api';
import Anexo from '../anexos'
import { formatarDataHoraComTempoAtras } from 'util/dataHora'

import './styles.css'

export default class HistoricoEvento extends Component {

    state = {
        itens: [],
        loading: false,
        pageNumber: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        lastPage: true,
        firstPage: true,
        pageSize: 10,
        sort: "ocorrencia",
        sortDirection: 'descending'
    }

    componentDidMount() {
        if (this.props.evento)
            this.loadItens();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.evento !== this.props.evento || prevProps.refresh !== this.props.refresh) {
            this.loadItens();
        }
    }

    loadItens = (page = 0) => {
        this.setState({ loading: true, error: null });

        const { evento } = this.props
        const { pageSize, sort, sortDirection } = this.state;

        if (evento) {
            let url = '/eventos/' + evento.id + '/acoes?page=' + page + "&size=" + pageSize;

            if (sort != null) {
                url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
            }

            api.get(url)
                .then((response) => {
                    if (response.data.content != null) {
                        this.setState({
                            loading: false,
                            itens: response.data.content,
                            totalPages: response.data.totalPages,
                            totalElements: response.data.totalElements,
                            lastPage: response.data.last,
                            firstPage: response.data.first,
                            pageNumber: response.data.number,
                            numberOfElements: response.data.numberOfElements
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        itens: [],
                        error: text("formularios.erro_request")
                    });
                });
        }
    }

    handleNextPage = () => {
        const { pageNumber, lastPage } = this.state;
        if (lastPage === true) return;

        const novoPageNumber = pageNumber + 1;
        this.loadItens(novoPageNumber);
    }
    handlePreviousPage = () => {
        const { pageNumber, firstPage } = this.state;
        if (firstPage === true) return;

        const novoPageNumber = pageNumber - 1;
        this.loadItens(novoPageNumber);
    }

    handleSort = () => {
        const { sortDirection } = this.state
        this.setState({
            sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending',
        },
            () => {
                this.loadItens();
            })

    }

    renderTextoAcao(tipo) {
        switch (tipo) {
            case 'REPORTADO':
                return (text("eventos.reportou"));
            case 'RECONHECIDO':
                return (text("eventos.reconheceu"));
            case 'ENCERRADO':
                return (text("eventos.encerrou"));
            case 'COMENTARIO':
                return (text("eventos.comentou"));
            case 'ANEXO':
                return (text("eventos.anexou"));
            case 'AUDIO':
                return (text("eventos.enviou-audio"));
            default:
                return null
        }
    }

    renderLabelTipoAcao(tipo) {
        switch (tipo) {
            case 'REPORTADO':
                return (
                    <Icon
                        circular
                        inverted
                        color='green'
                        name='send'
                        size="tiny"
                        title={text("eventos.reportado")}
                    />
                );
            case 'RECONHECIDO':
                return (
                    <Icon
                        circular
                        inverted
                        color='yellow'
                        name='check'
                        size="tiny"
                        title={text("eventos.reconhecido")}
                    />
                );
            case 'ENCERRADO':
                return (
                    <Icon
                        circular
                        inverted
                        color='red'
                        name='minus'
                        size="tiny"
                        title={text("eventos.encerrado")}
                    />
                );
            case 'COMENTARIO':
                return (
                    <Icon
                        circular
                        inverted
                        color='blue'
                        name='mail'
                        size="tiny"
                        title={text("eventos.comentario")}
                    />
                );
            case 'ANEXO':
                return (
                    <Icon
                        circular
                        inverted
                        color='blue'
                        name='paperclip'
                        size="tiny"
                        title={text("eventos.anexo")}
                    />
                );
            case 'AUDIO':
                return (
                    <Icon
                        circular
                        inverted
                        color='teal'
                        name='microphone'
                        size="tiny"
                        title={text("eventos.audio")}
                    />
                );
            default:
                return null
        }
    }

    renderFeed(item, isLast) {
        const { inverted, showLinks } = this.props
        const color = inverted ? '#FFF' : '#04040b';
        const showLabel = !inverted;
        return (

            <Feed.Event key={item.id}>
                {showLabel &&
                    <Feed.Label>
                        {this.renderLabelTipoAcao(item.tipo)}
                    </Feed.Label>
                }
                <Feed.Content>
                    <Feed.Summary>

                        {!showLabel ? this.renderLabelTipoAcao(item.tipo) : null}

                        <span style={{ color: color }}>
                            {showLinks ? (<UserLink login={item.usuario.login} />) : (item.usuario.login)}&nbsp;
                            {this.renderTextoAcao(item.tipo)}

                        </span>
                        <Feed.Date style={{ color: color }}>
                            {formatarDataHoraComTempoAtras(item.ocorrencia)}
                        </Feed.Date>
                    </Feed.Summary>
                    <Feed.Extra style={{ whiteSpace: 'pre-line', color: color }}>
                        {item.comentario}
                        {item.anexo &&
                            <Anexo hash={item.anexo} inverted={inverted} showDivider={item.comentario} />
                        }

                    </Feed.Extra>
                    {!isLast &&
                        <Divider style={{ marginTop: "20px" }} />
                    }
                </Feed.Content>
            </Feed.Event>
        )
    }

    render() {

        const { itens, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements, sortDirection } = this.state;
        const { inverted, header, buttonSize } = this.props

        return (
            <>
                {itens &&
                    <>
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "100%" }}>
                                    {text("eventos.historico")}
                                </div>

                                <div style={{ flex: "200px", textAlign: "right" }}>
                                    <Button
                                        icon={sortDirection === 'ascending' ? "sort numeric ascending" : "sort numeric descending"}
                                        size={buttonSize ? buttonSize : 'medium'}
                                        onClick={this.handleSort}
                                        title={text("tabelas.ordenacao")}
                                        inverted={inverted}
                                    />
                                    <Button.Group size={buttonSize ? buttonSize : 'medium'}>
                                        <Button
                                            disabled={firstPage}
                                            icon="angle left"
                                            onClick={this.handlePreviousPage}
                                            title={text("tabelas.pagina-anterior")}
                                            inverted={inverted}
                                        />
                                        <Button
                                            disabled={lastPage}
                                            icon="angle right"
                                            onClick={this.handleNextPage}
                                            title={text("tabelas.proxima-pagina")}
                                            inverted={inverted}
                                        />
                                    </Button.Group>
                                </div>

                            </div>
                        </Header>
                        <Segment attached inverted={inverted} >

                            {itens.length > 0 &&
                                <>
                                    <Feed>
                                        {itens.map((item, index) =>
                                            this.renderFeed(item, itens.length === index + 1)
                                        )}
                                    </Feed>

                                    {totalPages > 1 &&
                                        <div style={{ textAlign: "center" }} >
                                            <Divider />
                                            <small>{html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}</small>
                                        </div>
                                    }

                                </>
                            }

                        </Segment>

                    </>
                }
            </>
        )
    }
}