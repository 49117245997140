import React, { Component } from 'react';

import { Button, Form, Icon, Message, Divider, Container, Grid, Dropdown, Confirm } from 'semantic-ui-react'
import { Formik } from "formik";
import api from 'services/api';
import { text } from 'services/locales';

export default class cadastroContato extends Component {

    state = {
        loading: true,
        redirect: false,
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        contatos: [{
            tipo: '',
            contato: '',
            descricao: ''
        }],
        tiposcontato: [
            { text: text('entidade.telefone'), value: "TELEFONE" },
            { text: text('entidade.email'), value: "EMAIL" },
            { text: text('entidade.site'), value: "SITE" }],
        showRemoveDialog: false,
        itemRemove: null,
        disabled: true,
    };

    componentDidMount() {
        this.loadItens()
    }

    loadItens = () => {
        this.setState({ loading: true })

        if (this.props) {
            const { id } = this.props;
            api.get("/hidra/entidades/" + id + "/contatos")
                .then((response) => {
                    if (response.data.length !== 0) {
                        const contatos = response.data.map((item) => ({
                            tipo: item.tipo,
                            contato: item.contato,
                            descricao: item.descricao
                        }))
                        this.setState({
                            item: response.data,
                            loading: false,
                            contatos: contatos,
                            disabled: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        loading: false,
                        disabled: true
                    });
                });
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    handleRemove = item => () => {
        this.setState({ showRemoveDialog: true, itemRemove: item, error: null, sucesses: null })
    }

    handleConfirmRemove = () => {
        this.setState({ showRemoveDialog: false, loading: true })
        const { itemRemove } = this.state


        api.post('/hidra/entidades/' + itemRemove + '/contatos', [])
            .then((response) => {
                if (response.data.error != null) {
                    this.setState({ submitting: false, error: response.data });
                    window.scrollTo(0, 0)
                } else {
                    this.setState({
                        submitting: false,
                        error: null,
                        sucesses: text("entidade.contato-remover"),
                        loading: false,
                        itemRemove: null,
                        disabled: true,
                        item: null,
                        contatos: [{
                            tipo: '',
                            contato: '',
                            descricao: ''
                        }]
                    });
                    window.scrollTo(0, 0)
                }
            })
            .catch((error) => {
                let errorMsg = text("tabelas.erro-remover");

                if (error.response.data && error.response.data.message && error.response.data.status && error.response.data.status >= 400 && error.response.data.status < 500) {
                    errorMsg = error.response.data.message
                    window.scrollTo(0, 0)
                }

                this.setState({
                    loading: false,
                    itemRemove: null,
                    error: errorMsg
                });
            });
    }

    handleCancelRemove = () => {
        this.setState({ showRemoveDialog: false, itemRemove: null, submitting: false })
    }

    handleAddPosicao = (e, { index }) => {
        e.preventDefault();
        var { contatos } = this.state
        contatos.splice(index + 1, 0, { tipo: '', contato: '', descricao: '' })
        this.setState({
            contatos: contatos,
        })
    }

    handleRemovePosicao = (e, { index }) => {
        e.preventDefault();
        var { contatos } = this.state
        if (index === 0 && contatos.length !== 1) {
            contatos.splice(index, 1)
            this.setState({ contatos: contatos })
        } else if (index === 0) {
            contatos[index].contato = ''
            contatos[index].tipo = ''
            contatos[index].descricao = ''
            this.setState({ contatos: contatos })
        } else {
            contatos.splice(index, 1)
            this.setState({ contatos: contatos })
        }
    }

    handleChangeContato = (e, { value, index }) => {
        var { contatos } = this.state
        contatos[index].contato = value
        this.setState({ contatos: contatos })
    }

    handleChangeTipo = (e, { value, index }) => {
        var { contatos } = this.state
        contatos[index].tipo = value
        this.setState({
            contatos: contatos
        })
    }

    handleChangeDescricao = (e, { value, index }) => {
        var { contatos } = this.state
        contatos[index].descricao = value
        this.setState({ contatos: contatos })
    }

    renderFormContato(item, index) {

        return (
            < Form.Group key={index} >
                <Form.Field required>
                    <label><b>{text("entidade.tipo")}</b></label>
                    <Dropdown
                        placeholder={text("entidade.tipo")}
                        index={index}
                        onChange={this.handleChangeTipo}
                        value={this.state.contatos[index].tipo}
                        name="tipo"
                        search
                        selection
                        options={this.state.tiposcontato}
                    />
                </Form.Field>
                <Form.Field required style={{ width: '200px' }}>
                    <label><b>{text("entidade.contato")}</b></label>
                    <Form.Input
                        placeholder={text("entidade.contato")}
                        index={index}
                        onChange={this.handleChangeContato.bind(this)}
                        value={item.contato}
                        name="contato"
                    />
                </Form.Field>

                <Form.Field style={{ width: '200px' }}>
                    <label><b>{text("entidade.descricao")}</b></label>
                    <Form.Input
                        placeholder={text("entidade.descricao")}
                        index={index}
                        onChange={this.handleChangeDescricao}
                        value={item.descricao}
                        name="descricao"
                    />
                </Form.Field>

                <Button
                    style={{ height: '40px', width: '50px', margin: '22px 0px 0px' }}
                    index={index}
                    onClick={this.handleAddPosicao}
                ><Icon style={{ padding: '0 3px 12px 0' }} name='plus' /></Button>

                {
                    index === 0 &&

                    <Button
                        style={{ height: '40px', width: '50px', margin: '22px 5px 0px' }}
                        index={index}
                        onClick={this.handleRemovePosicao}
                    > <Icon style={{ padding: '0 3px 12px 0' }} name='trash alternate' /> </Button>
                }

                {
                    index > 0 &&

                    <Button
                        style={{ height: '40px', width: '50px', margin: '22px 5px 0px' }}
                        index={index}
                        onClick={this.handleRemovePosicao}
                    > <Icon style={{ padding: '0 3px 12px 0' }} name='trash alternate' /> </Button>
                }
            </Form.Group >
        )

    }

    render() {

        const { item, loading, submitting, contatos, disabled } = this.state;
        const { id } = this.props

        return (
            <Container style={{ padding: '0.5em', backgroundColor: "#fff" }}>
                <Formik
                    initialValues={{
                        tipo: item ? item.tipo : '',
                        contato: item ? item.contato : '',
                        descricao: item ? item.descricao : '',
                    }}
                    onSubmit={() => {
                        this.setState({ error: null, submitting: true, sucesses: null });
                        if (item && contatos.length === 1 && contatos[0].tipo === '' && contatos[0].contato === '') {
                            this.setState({ showRemoveDialog: true, itemRemove: id, error: null, sucesses: null })
                        } else {
                            api.post('/hidra/entidades/' + id + '/contatos', this.state.contatos)
                                .then((response) => {
                                    if (response.data.error != null) {
                                        this.setState({ submitting: false, error: response.data });
                                    } else {
                                        this.setState({ submitting: false, error: null, sucesses: text("entidade.contato-atualizado"), disabled: false }, this.loadItens());
                                        this.props.onSubmit();
                                    }
                                })
                                .catch((error) => {
                                    var mensagemErro = text("formularios.erro");
                                    if (error.response && error.response.data.message) {
                                        mensagemErro = error.response.data.message;
                                    } else if (error.request) {
                                        mensagemErro = text("formularios.erro_request")
                                    }
                                    this.setState({
                                        submitting: false,
                                        error: mensagemErro
                                    });
                                });
                        }
                    }}
                    render={({
                        handleSubmit,
                    }) => (
                            <Form
                                onSubmit={handleSubmit}
                                loading={loading}
                            >
                                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                                <Form.Field>
                                    <Grid.Column>
                                        {
                                            contatos.map((item, index) =>
                                                this.renderFormContato(item, index)
                                            )

                                        }
                                    </Grid.Column>
                                </Form.Field>
                                <Confirm
                                    header={text("entidade.contato-confirmar")}
                                    content={null}
                                    open={this.state.showRemoveDialog}
                                    onCancel={this.handleCancelRemove}
                                    onConfirm={this.handleConfirmRemove}
                                    cancelButton={text("geral.nao")}
                                    confirmButton={text("geral.sim")}
                                />
                                <br />
                                <Divider />
                                <br />
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Button
                                            onClick={this.handleRemove(id)}
                                            color="red"
                                            type="button"
                                            icon labelPosition='left'
                                            size="big"
                                            disabled={disabled}
                                        >
                                            <Icon name='trash alternate' /> {text("tabelas.remover")}
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            primary
                                            loading={submitting}
                                            type='submit'
                                            icon labelPosition='right'
                                            size="big"
                                            disabled={loading || submitting}
                                        >
                                            <Icon name='save' /> {text("formularios.salvar")}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        )
                    }
                />

            </Container >
        );
    }

}