import React, { Component } from 'react';

import { text } from 'services/locales'
import api from "services/api";

import {
    Modal,
    Icon,
    Button
} from 'semantic-ui-react';

import { forceDownload, isMobile } from 'util/functions'

import './styles.css'

export default class AppDownload extends Component {

    state = {
        loading: false,
        arquivo: null,
        open: false
    }

    componentDidMount() {
        //Se o componente não ira exibir o link para acionar o popup, testo se é um acesso de um dispositivo movel, assim forço a exibição do popup
        const { showLink } = this.props
        if (!showLink && isMobile()) {
            this.loadApk()
        }
    }

    loadApk = () => {

        this.setState({ loading: true, open: true })

        api.get("/hidra/config")
            .then(response => {
                if (response.data && response.data.apk) {
                    api.get("/arquivos/" + response.data.apk)
                        .then(response => {
                            if (response.data.files && response.data.files.length > 0) {
                                this.setState({
                                    arquivo: response.data.files[0].url,
                                    loading: false
                                });
                            }
                        })
                        .catch(() => {
                            this.setState({
                                arquivo: null,
                                loading: false
                            });
                        })
                }
            })
            .catch(() => {
                this.setState({
                    arquivo: null,
                    loading: false
                });
            })

        this.setState({ loading: false });

    }

    handleClose = () => {
        this.setState({ open: false })
    }

    render() {

        const { arquivo, loading } = this.state;
        const { showLink } = this.props

        return (
            <>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onOpen={this.loadApk}
                    closeIcon
                    centered={false}
                    trigger={showLink ? (
                        <span className="link">{text("menu.app")}</span>
                    ) : null
                    }
                >
                    <Modal.Header>
                        <Icon name="mobile alternate" />

                        {text("menu.app")}

                    </Modal.Header>
                    <Modal.Content >
                        <Modal.Description>

                            {loading &&
                                <>
                                    <Icon name="spinner" loading />
                                </>
                            }

                            {!loading && !arquivo &&
                                <>
                                    {text("geral.aplicativo-nao-disponivel")}
                                </>
                            }

                            {arquivo &&
                                <>
                                    <p>
                                        Para atender por completo as demandas do SIS-CARE, foi desenvolvido um aplicativo para dispositivos móveis. Esse aplicativo é o mais adequado para utilização do sistema em celulares.
                                    </p>
                                    <p>
                                        Para executar o aplicativo é necessário um telefone celular com o sistema operacional Android 10 ou superior. Além disso, é necessário conceder permissão nas configurações do dispositivo para instalação de softwares de terceiros.
                                    </p>
                                    <p>
                                        Para obter o aplicativo, clique no botão abaixo. Um arquivo (.apk) será baixado, devendo ser copiado e executado no celular.
                                    </p>
                                </>
                            }

                        </Modal.Description>
                    </Modal.Content>

                    <Modal.Actions>
                        {arquivo &&
                            <Button
                                onClick={() => forceDownload(process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_SLASH + "arquivos/" + arquivo, arquivo)}
                            >
                                <Icon name='download' />  {text("menu.download-app")}
                            </Button>
                        }
                    </Modal.Actions>

                </Modal>

            </>
        )
    }
}
