import React, { Component } from 'react';

import { Segment, Header, Button } from 'semantic-ui-react'

import api from 'services/api';
import { text } from 'services/locales';
import DadoMeteorologico from 'components/objetos/meteorologia/dadosMeteorologicos'

export default class EstacoesMeteorologicasProximas extends Component {

    state = {
        itens: [],
        atual: 0
    }

    componentDidMount() {
        this.loadItens();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng || prevProps.refresh !== this.props.refresh) {
            this.loadItens();
        }
    }

    loadItens = () => {
        const { lat, lng, raio } = this.props;

        if (lat && lng) {
            api.get("/meteorologia/estacoes/proximas?lat=" + lat + "&lng=" + lng + "&r=" + raio)
                .then((response) => {
                    this.setState({ itens: response.data });
                })
                .catch(() => {
                    this.setState({ itens: [] });
                })
        }
    }

    handleNextPage = () => {
        const { itens, atual } = this.state;
        if (atual === itens.length - 1) return;
        this.setState({ atual: atual + 1 })
    }

    handlePreviousPage = () => {
        const { atual } = this.state;
        if (atual === 0) return;
        this.setState({ atual: atual - 1 })
    }

    render() {

        const { itens, atual } = this.state;
        const { inverted, header, buttonSize, showLinks } = this.props

        return (
            <>
                {itens && itens.length > 0 &&
                    <div className="box">
                        <Header inverted={inverted} as={header ? header : 'h2'} attached='top'>
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "100%" }}>
                                    {text("dados-meteorologicos.dados-meteorologicos")}<br />
                                    <Header.Subheader>
                                        {itens[atual].nome}
                                    </Header.Subheader>
                                </div>

                                {itens.length > 1 &&
                                    <div style={{ flex: "150px", textAlign: "right" }}>
                                        <Button
                                            disabled={atual === 0}
                                            icon="angle left"
                                            size={buttonSize ? buttonSize : 'medium'}
                                            onClick={this.handlePreviousPage}
                                            title={text("tabelas.pagina-anterior")}
                                            inverted={inverted}
                                        />
                                        <Button
                                            disabled={atual === itens.length - 1}
                                            icon="angle right"
                                            size={buttonSize ? buttonSize : 'medium'}
                                            onClick={this.handleNextPage}
                                            title={text("tabelas.proxima-pagina")}
                                            inverted={inverted}
                                        />
                                    </div>
                                }
                            </div>
                        </Header>
                        <Segment attached inverted={inverted}>
                            <DadoMeteorologico
                                item={itens[atual].dados}
                                header={header}
                                inverted={inverted}
                                fonte={itens[atual].siglaFonte}
                                distancia={itens[atual].distancia}
                                estacao={itens[atual].nome}
                                idEstacao={itens[atual].id}
                                showLinks={showLinks}
                            />
                        </Segment>
                    </div>
                }
            </>
        )
    }
}