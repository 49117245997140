import decode from "jwt-decode";
import jwt_decode from "jwt-decode";
import { getServerTime } from 'services/time';
import moment from 'moment';

export const ACCESS_TOKEN_KEY = process.env.REACT_APP_BASE_KEY + ".token.access";
export const RENEW_TOKEN_KEY = process.env.REACT_APP_BASE_KEY + ".token.renew";

//Verifica se o token existe, se é valido e se não esta expirado
export const isAuthenticated = () => {
  const token = getToken();

  if (!token)
    return false;

  var decodedHeader = jwt_decode(token);
  if (decodedHeader.iss === 'hidra') {//verifico se é um tokem emitido pelo nosso backend
    return !isTokenExpired(token);
  }

  return false;
}

//Obtém o token de acesso
export const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

//Obtém o token de renovação
export const getRenewToken = () => localStorage.getItem(RENEW_TOKEN_KEY);

//Realiza login, salva o token de acesso e o de renovação do local storage
export const login = (token, renewToken) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
  localStorage.setItem(RENEW_TOKEN_KEY, renewToken);
};

//Realiza logout, remove o token de acesso e o de renovação do local storage
export const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(RENEW_TOKEN_KEY);
};

//Verifica se esta expirado
export const isTokenExpired = token => {
  try {
    const decoded = decode(token);
    const exp = moment(decoded.exp)
    const now = moment(getServerTime())
    return exp.isAfter(now);
  } catch (err) {
    return false;
  }
};

//Obtém os segundos restantes para o token expirar
export const tokenExpSeconds = () => {
  const decoded = decode(getToken());
  const exp = moment.unix(decoded.exp)
  const now = moment(getServerTime())
  const diff = exp.diff(now, 'seconds');
  return diff;
}

//Obtém o 'subject' do token, o login do usuário
export const jwtSubject = () => {
  try {
    const decoded = decode(getToken());
    return decoded.sub;
  } catch (err) {
    return false;
  }
};

//Obtem o perfl de usuario do token
export const jwtAuthorities = () => {
  try {
    const decoded = decode(getToken());
    return decoded.authorities;
  } catch (err) {
    return false;
  }
};

//Verifica pelo perfil de acesso se o token pertence a um nivel de administrador ou acima
export const isAdmin = () => {
  const profile = jwtAuthorities()
  if (profile === 'admin')
    return true
  return false
}

//Verifica pelo perfil de acesso se o token pertence a um nivel de coordenador ou acima
export const isCoord = () => {
  const profile = jwtAuthorities()
  if (profile === 'coord' || profile === 'admin')
    return true
  return false
}