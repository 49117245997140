import React, { Component } from 'react';

import { Button, Icon, Header, Form, Table, Message, Grid, Dropdown, Container, Divider } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import api from 'services/api';
import { text, html } from 'services/locales';
import { convertDMS } from 'util/functions'
import Footer from 'components/footer'
import { Helmet } from 'react-helmet'
import { optionsOrigens, optionsHostilidade, optionsAmbiente } from 'util/filterOptions'
import { nosParaQuilometrosHora } from 'util/conversores'
import UserLink from 'components/UserLink';
import { formatarDataHora } from 'util/dataHora'
import { desenharAcompanhamentoSVG } from 'util/svg'

export default class Tabela extends Component {

    state = {
        itens: [],
        busca: null,
        loading: true,
        error: null,
        pageNumber: 0,
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        lastPage: true,
        firstPage: true,
        pageSize: 30,
        sort: null,
        sortDirection: 'ascending',
        filtro: {
            ativo: false,
            perdido: true,
            origens: [],
            hostilidade: [],
            ambiente: []
        }
    }

    componentDidMount() {
        this.defaultState();
        // this.interval = setInterval(() => this.loadItens(this.state.pageNumber, this.state.busca), 5000);
    }

    // componentWillUnmount() {
    //     clearInterval(this.interval);
    // }

    defaultState = () => {
        let todasOrigens = [];
        optionsOrigens.forEach((item) => {
            todasOrigens.push(item.value);
        });

        let todosAmbientes = [];
        optionsAmbiente.forEach((item) => {
            todosAmbientes.push(item.value);
        });

        let todasHostilidades = [];
        optionsHostilidade.forEach((item) => {
            todasHostilidades.push(item.value);
        });

        this.setState(
            {
                filtro:
                {
                    ativo: false,
                    perdido: true,
                    origens: todasOrigens,
                    ambiente: todosAmbientes,
                    hostilidade: todasHostilidades,
                }
            }, () => {
                this.loadItens(0, null);
            }
        )
    }

    loadItens = (page = 0, busca = null) => {
        this.setState({ loading: true, error: null });

        const { sort, sortDirection, pageSize, filtro } = this.state;

        let url = '/objetos/acompanhamentos?page=' + page + "&size=" + pageSize;
        if (busca != null) {
            url += '&q=' + busca
        }

        if (sort != null) {
            url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
        }

        if (filtro) {
            if (!filtro.perdido) {
                url += "&p=0"
            }

            if (filtro.origens) {
                url += "&origem="
                filtro.origens.forEach((item, index) => {
                    url += (index > 0 ? "," : "") + item
                });
            }

            if (filtro.ambiente) {
                url += "&amb="
                filtro.ambiente.forEach((item, index) => {
                    url += (index > 0 ? "," : "") + item
                });
            }

            if (filtro.hostilidade) {
                url += "&host="
                filtro.hostilidade.forEach((item, index) => {
                    url += (index > 0 ? "," : "") + item
                });
            }

        }

        api.get(url)
            .then((response) => {
                if (response.data.content != null) {
                    this.setState({
                        loading: false,
                        itens: response.data.content,
                        totalPages: response.data.totalPages,
                        totalElements: response.data.totalElements,
                        lastPage: response.data.last,
                        firstPage: response.data.first,
                        pageNumber: response.data.number,
                        numberOfElements: response.data.numberOfElements
                    });
                }
            })
            .catch(() => {
                this.setState({
                    loading: false,
                    itens: [],
                    error: text("formularios.erro_request")
                });
            });
    };

    handleChangeDropdown = (e, { name, value }) => {
        const { busca } = this.state
        this.setState({ [name]: value }, () => { this.loadItens(0, busca); });
    }

    handleRefresh = () => {
        const { pageNumber, busca } = this.state
        this.loadItens(pageNumber, busca);
    }

    handleSearch = e => {
        e.preventDefault();
        const { busca } = this.state;
        this.loadItens(0, busca);
    }

    handleNextPage = () => {
        const { pageNumber, lastPage, busca } = this.state;
        if (lastPage === true) return;

        const novoPageNumber = pageNumber + 1;
        this.loadItens(novoPageNumber, busca);
    }
    handlePreviousPage = () => {
        const { pageNumber, firstPage, busca } = this.state;
        if (firstPage === true) return;

        const novoPageNumber = pageNumber - 1;
        this.loadItens(novoPageNumber, busca);
    }

    handleSort = clickedColumn => () => {
        const { busca, sort, sortDirection } = this.state

        if (clickedColumn === sort) {
            this.setState({
                sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending',
                sort: clickedColumn
            })
        } else {
            this.setState({
                sortDirection: 'ascending',
                sort: clickedColumn
            })
        }


        this.loadItens(0, busca);
    }

    handleFiltro = (e) => {
        e.preventDefault();

        const { filtro } = this.state;
        this.setState({
            filtro:
            {
                ...filtro,
                ativo: !filtro.ativo
            }
        }
        )
    }

    handlePerdido = () => {

        const { filtro } = this.state;

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    perdido: !filtro.perdido
                }
            },
            () => {
                this.loadItens(0, this.state.busca);
            }
        )
    }

    handleOrigens = (valor, desmarcarTodos = false) => {
        const { filtro } = this.state;
        var temp = filtro.origens;

        if (desmarcarTodos) {
            temp.length = 0;
            temp.push(valor)
        } else {
            if (temp.includes(valor)) {
                if (temp.length === 1) return; //Não permite desmarcar todos
                temp.splice(temp.indexOf(valor), 1);
            } else {
                temp.push(valor)
            }
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    origens: temp
                }
            },
            () => {
                this.loadItens(0, this.state.busca);
            }
        )
    }

    handleAmbiente = (valor) => {
        const { filtro } = this.state;
        var temp = filtro.ambiente;

        if (temp.includes(valor)) {
            if (temp.length === 1) return; //Não permite desmarcar todos
            temp.splice(temp.indexOf(valor), 1);
        } else {
            temp.push(valor)
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    ambiente: temp
                }
            },
            () => {
                this.loadItens(0, this.state.busca);
            }
        )
    }

    handleHostilidade = (valor) => {
        const { filtro } = this.state;
        var temp = filtro.hostilidade;

        if (temp.includes(valor)) {
            if (temp.length === 1) return; //Não permite desmarcar todos
            temp.splice(temp.indexOf(valor), 1);
        } else {
            temp.push(valor)
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    hostilidade: temp
                }
            },
            () => {
                this.loadItens(0, this.state.busca);
            }
        )
    }

    render() {

        const { itens, loading, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements, filtro } = this.state;

        const optionsPageSize = [
            { key: 10, text: '10', value: 10 },
            { key: 30, text: '30', value: 30 },
            { key: 50, text: '50', value: 50 },
            { key: 100, text: '100', value: 100 },
        ]

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`${text("menu.acompanhamentos")} (${totalElements}) ${text("geral.html-title")}`} />

                <Grid stackable columns={2} >
                    <Grid.Column>
                        <Header as="h1" >
                            {text("acompanhamento.acompanhamentos")}
                            <Header.Subheader>
                                {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>

                    <Grid.Column textAlign="right">
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Input
                                    icon='search'
                                    iconPosition='left'
                                    name="busca"
                                    placeholder={text('acompanhamento.buscar')}
                                    value={this.state.busca ? this.state.busca : ""}
                                    onChange={e => this.setState({ busca: e.target.value })}
                                />
                                <Button primary color='blue' onClick={this.handleSearch} >
                                    {text('tabelas.buscar')}
                                </Button>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <Table celled striped selectable >
                    <Table.Header >
                        <Table.Row>
                            <Table.HeaderCell colSpan='1'>
                                <Button
                                    icon
                                    basic compact
                                    labelPosition='right'
                                    onClick={this.handleFiltro}
                                    toggle active={filtro.ativo}
                                >
                                    <Icon name='filter' />
                                    {text("formularios.filtros")}
                                </Button>
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='5' textAlign="right">
                                <Button
                                    disabled={firstPage}
                                    basic compact
                                    icon labelPosition='left'
                                    size='medium'
                                    onClick={this.handlePreviousPage}
                                >
                                    <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                                </Button>
                                <Button
                                    disabled={lastPage}
                                    basic compact
                                    icon labelPosition='right'
                                    size='medium'
                                    onClick={this.handleNextPage}
                                >
                                    <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                                </Button>
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='1' textAlign="center">
                                <Button basic compact icon onClick={this.handleRefresh} loading={loading} title={text("geral.atualizar")}>
                                    <Icon name='refresh' />
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    {filtro.ativo &&
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='7'>
                                    <Grid divided columns={4} >
                                        <Grid.Column>
                                            <b>{text("filtro.origens")}</b>
                                            <Form.Group grouped>
                                                {optionsOrigens
                                                    .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
                                                    .map(item =>
                                                        <Form.Checkbox
                                                            key={item.value}
                                                            value={item.value}
                                                            checked={filtro.origens.includes(item.value)}
                                                            onChange={(e, { value }) => this.handleOrigens(value)}
                                                            label={
                                                                <label>
                                                                    {item.text}
                                                                    &nbsp;<Link title={text("filtro.marcar-apenas-este")} onClick={() => this.handleOrigens(item.value, true)} style={{fontSize:12}}>[+]</Link>
                                                                </label>
                                                            }
                                                        />
                                                    )}
                                            </Form.Group>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <b>{text("classificacao.ambiente")}</b>
                                            <Form.Group grouped>
                                                {optionsAmbiente.map(item =>
                                                    <Form.Checkbox
                                                        key={item.value}
                                                        value={item.value}
                                                        label={item.text}
                                                        checked={filtro.ambiente.includes(item.value)}
                                                        onChange={(e, { value }) => this.handleAmbiente(value)}
                                                    />
                                                )}
                                            </Form.Group>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <b>{text("classificacao.hostilidade")}</b>
                                            <Form.Group grouped >
                                                {optionsHostilidade.map(item =>
                                                    <Form.Checkbox
                                                        key={item.value}
                                                        value={item.value}
                                                        label={<label>{item.text} <Icon size="small" name="minus" style={{ color: item.color ? item.color : '#f3f3f3' }} /></label>}
                                                        checked={filtro.hostilidade.includes(item.value)}
                                                        onChange={(e, { value }) => this.handleHostilidade(value)}
                                                    />
                                                )}
                                            </Form.Group>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <b>{text("filtro.outros")}</b>
                                            <Form>
                                                <Form.Checkbox
                                                    onChange={this.handlePerdido}
                                                    checked={filtro.perdido}
                                                    label={text("filtro.perdido")}
                                                />
                                            </Form>

                                            <br />
                                            <Divider />
                                            <br />

                                            <center>
                                                <Button
                                                    onClick={this.defaultState}
                                                    icon labelPosition='left'
                                                >
                                                    <Icon name="undo" />
                                                    {text("filtro.reset")}
                                                </Button>
                                            </center>

                                        </Grid.Column>

                                    </Grid>


                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                    }

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {text("acompanhamento.identificacao")}</Table.HeaderCell>
                            <Table.HeaderCell>
                                {text("acompanhamento.origem")}</Table.HeaderCell>
                            <Table.HeaderCell>
                                {text("acompanhamento.cinematica")}</Table.HeaderCell>
                            <Table.HeaderCell collapsing>
                                {text("acompanhamento.icone-mapa")}</Table.HeaderCell>
                            <Table.HeaderCell>
                                {text("acompanhamento.visto-em")}</Table.HeaderCell>
                            <Table.HeaderCell collapsing>{text("acompanhamento.perdido")}</Table.HeaderCell>
                            <Table.HeaderCell collapsing>{text("tabelas.detalhes")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            itens.map((item) =>
                                <Table.Row key={item.uuid}>
                                    <Table.Cell>
                                        {item.identificacao.nome &&
                                            <>{text("acompanhamento.nome")}: <Link to={'/acompanhamento/uuid/' + item.uuid}>{item.identificacao.nome}</Link><br /></>
                                        }
                                        {item.identificacao.na &&
                                            <>{text("acompanhamento.na")}: <Link to={'/acompanhamento/uuid/' + item.uuid}>{item.identificacao.na}</Link><br /></>
                                        }
                                        {item.identificacao.mmsi &&
                                            <>{text("acompanhamento.mmsi")}: <Link to={'/acompanhamento/uuid/' + item.uuid}>{item.identificacao.mmsi}</Link><br /></>
                                        }
                                        {item.identificacao.imo &&
                                            <>{text("acompanhamento.imo")}: <Link to={'/acompanhamento/uuid/' + item.uuid}>{item.identificacao.imo}</Link><br /></>
                                        }
                                        {item.identificacao.irin &&
                                            <>{text("acompanhamento.irin")}: <Link to={'/acompanhamento/uuid/' + item.uuid}>{item.identificacao.irin}</Link><br /></>
                                        }
                                        {item.identificacao.costado &&
                                            <>{text("acompanhamento.costado")}: <Link to={'/acompanhamento/uuid/' + item.uuid}>{item.identificacao.costado}</Link><br /></>
                                        }
                                        {item.identificacao.callsign &&
                                            <>{text("acompanhamento.callsign")}: <Link to={'/acompanhamento/uuid/' + item.uuid}>{item.identificacao.callsign}</Link><br /></>
                                        }
                                        {item.identificacao.identificador &&
                                            <>{text("acompanhamento.identificador")}: <Link to={'/acompanhamento/uuid/' + item.uuid}>{item.identificacao.identificador}</Link><br /></>
                                        }
                                        {item.identificacao.usuario &&
                                            <>{text("acompanhamento.usuario")}: <UserLink login={item.identificacao.usuario} /><br /></>
                                        }
                                        <Link to={'/acompanhamento/uuid/' + item.uuid} title={text("acompanhamento.uuid")}>
                                            <small>{item.uuid}</small>
                                        </Link>

                                    </Table.Cell>

                                    <Table.Cell>
                                        {text("origens." + item.origem.toLowerCase())}
                                        {item.descricaoOrigem &&
                                            <>
                                                <br />
                                                <small><em>{item.descricaoOrigem}</em></small>
                                            </>
                                        }
                                    </Table.Cell>

                                    <Table.Cell>

                                        {item.cinematica.posicao.geo.lat && item.cinematica.posicao.geo.lng ?
                                            convertDMS(item.cinematica.posicao.geo.lat, item.cinematica.posicao.geo.lng)
                                            : "N/D"
                                        }
                                        <br />

                                        {item.cinematica.velocidade.fundo && item.cinematica.rumo.fundo ?

                                            (item.ambiente && (item.ambiente === "SUPTERRA") ? (
                                                (item.cinematica.velocidade.fundo === 0
                                                    ? text("pagina-acompanhamentos.parado")
                                                    : (Number(item.cinematica.rumo.fundo).toFixed(0) + "°")

                                                    + "/" + Number(nosParaQuilometrosHora(item.cinematica.velocidade.fundo)).toFixed(1) + " " + text("cinematica.kmh"))
                                            ) : (
                                                (item.cinematica.velocidade.fundo === 0
                                                    ? text("pagina-acompanhamentos.parado")
                                                    : (Number(item.cinematica.rumo.fundo).toFixed(0) + "°")

                                                    + "/" + Number(item.cinematica.velocidade.fundo).toFixed(1) + " " + text("cinematica.nos"))

                                            )
                                            )

                                            : ""
                                        }

                                    </Table.Cell>

                                    <Table.Cell textAlign="center">
                                        <center>
                                            <Link to={'/acompanhamento/uuid/' + item.uuid}>
                                                <div
                                                    dangerouslySetInnerHTML={
                                                        { __html: desenharAcompanhamentoSVG(item.cinematica.velocidade.fundo, item.cinematica.rumo.fundo, item.hostilidade, item.origem, item.perdido)[1] }
                                                    }
                                                />
                                            </Link>
                                        </center>
                                    </Table.Cell>

                                    <Table.Cell className="primeiraLetraMaiuscula">{formatarDataHora(item.timestamp)}</Table.Cell>

                                    <Table.Cell>{item.perdido ? text("geral.sim") : text("geral.nao")}</Table.Cell>

                                    <Table.Cell textAlign="center">
                                        <Button icon size='mini' as={Link}
                                            to={"/acompanhamento/uuid/" + (item.uuid)}
                                        > <Icon name='search plus' />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2'>
                                {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
                                &nbsp;{text("tabelas.tamanho-pagina")}&nbsp;
                                <Dropdown
                                    compact
                                    selection
                                    value={this.state.pageSize}
                                    options={optionsPageSize}
                                    onChange={this.handleChangeDropdown}
                                    name="pageSize"
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='5'>
                                <Button
                                    disabled={lastPage}
                                    floated='right'
                                    icon labelPosition='right'
                                    size='medium'
                                    onClick={this.handleNextPage}
                                >
                                    <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                                </Button>
                                <Button
                                    disabled={firstPage}
                                    floated='right'
                                    icon labelPosition='left'
                                    size='medium'
                                    onClick={this.handlePreviousPage}
                                >
                                    <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>

                </Table>

                <Footer />

            </Container>
        );
    }

}