import React, { Component } from 'react';

import { Link } from 'react-router-dom'
import { Header, Button, List, Icon, Segment } from "semantic-ui-react"
import api from 'services/api';
import { text } from 'services/locales'

import Fotos from 'components/objetos/entidades/fotos'
import Info from 'components/objetos/locais/info'
import Contatos from 'components/objetos/entidades/contatos'
import EstacoesMeteorologicasProximas from 'components/objetos/meteorologia/estacoesProximas'

export default class DicaLocal extends Component {

    state = {
        objeto: null,
        filhos: null
    }

    componentDidMount() {
        this.handleRefreshObjetos();
        this.handleFilhos();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState(
                {
                    objeto: null,
                    filhos: null
                }
                , () => {
                    this.handleRefreshObjetos();
                    this.handleFilhos();
                }
            )
        }
    }

    handleRefreshObjetos() {
        api.get("/hidra/locais/" + this.props.id)
            .then((response) => {
                this.setState({ objeto: response.data, refresh: new Date().getTime() })
            })
            .catch(() => {
                this.setState({ objeto: null, refresh: -1 })
            })
    }

    handleFilhos() {
        api.get("/hidra/locais/" + this.props.id + "/filhos")
            .then((response) => {
                this.setState({ filhos: response.data.content })
            })
            .catch(() => {
                this.setState({ filhos: null })
            })
    }

    handleGoto = () => {
        const { objeto } = this.state;
        const latLong = [objeto.lat, objeto.lng];
        this.props.onGoto(latLong)
    }

    localSelecionado = (item) => {
        if (item) {
            this.props.onGoto([item.lat, item.lng])
            this.props.onSelectLocal(item.id)
        }
    }

    render() {

        const { objeto, refresh, filhos } = this.state;
        const { id, showLinks } = this.props;

        return (
            <>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>
                        <Header inverted as="h2">
                            {objeto ? objeto.sigla : ''}
                        </Header>
                    </div>

                    <div style={{ flex: "200px", textAlign: "right" }}>

                        {showLinks &&
                            <Button
                                as={Link}
                                to={"/local/" + (objeto ? objeto.id : 0)}
                                disabled={!objeto}
                                inverted
                                size='tiny'
                                icon="search plus"
                                title={text("geral.mais-detalhes")}
                            />
                        }

                        {showLinks &&
                            <Button
                                onClick={this.handleGoto}
                                inverted
                                size='tiny'
                                icon="crosshairs"
                                title={text("geral.centralizar")}
                            />
                        }

                        <Button
                            onClick={this.props.onClose}
                            inverted
                            size='tiny'
                            icon="close"
                            color="red"
                            title={text("geral.fechar")}
                        />
                    </div>
                </div>

                <br />

                {objeto &&
                    <div className="dica-objeto-grid">
                        <Info id={id} refresh={refresh} inverted header='h5' />

                        <EstacoesMeteorologicasProximas inverted header='h5' lat={objeto.lat} lng={objeto.lng} raio={20} buttonSize="mini" />

                        {(objeto.pai || (filhos && filhos.length > 0)) &&
                            <div>
                                <Header inverted as='h5' attached='top'>
                                    {text("local.hierarquia")}
                                </Header>
                                <Segment attached inverted style={{ padding: "2px" }}>
                                    <List divided relaxed inverted selection>

                                        {objeto.pai &&
                                            <List.Item title={text("local.pai")} key={objeto.pai.id} onClick={() => this.localSelecionado(objeto.pai)}>
                                                <List.Content floated="right">
                                                    <Icon name="arrow up" />
                                                </List.Content>
                                                <Icon
                                                    name={objeto.pai.tipo.simbolo ? objeto.pai.tipo.simbolo : 'map marker alternate'}
                                                    color={objeto.pai.tipo.cor ? objeto.pai.tipo.cor : 'red'}
                                                    size="large"
                                                />
                                                <List.Content >
                                                    <List.Header>{objeto.pai.sigla}</List.Header>
                                                    <List.Description><small>{objeto.pai.nome}</small></List.Description>
                                                </List.Content>
                                            </List.Item>
                                        }

                                        {filhos && filhos.length > 0 &&

                                            filhos.map((item) =>
                                                <List.Item title={text("local.filho")} key={item.id} onClick={() => this.localSelecionado(item)}>
                                                    <List.Content floated="right">
                                                        <Icon name="arrow down" />
                                                    </List.Content>
                                                    <Icon
                                                        name={item.tipo.simbolo ? item.tipo.simbolo : 'map marker alternate'}
                                                        color={item.tipo.cor ? item.tipo.cor : 'red'}
                                                        size="large"
                                                    />
                                                    <List.Content >
                                                        <List.Header>{item.sigla}</List.Header>
                                                        <List.Description><small>{item.nome}</small></List.Description>
                                                    </List.Content>

                                                </List.Item>
                                            )}
                                    </List>
                                </Segment>
                            </div>
                        }

                        <Fotos hash={objeto.imagem} inverted header="h5" buttonSize="mini" />

                        <Contatos id={id} refresh={refresh} inverted header='h5' />
                    </div>

                }
            </>
        )
    }
}