import React, { Component } from 'react';

import { Link } from 'react-router-dom'
import { Header, Button, Segment } from "semantic-ui-react"
import api from 'services/api';
import { text } from 'services/locales'

import DadosMeteorologicos from 'components/objetos/meteorologia/dadosMeteorologicos'

import { convertDMS } from 'util/functions'

export default class DicaEstacaoMeteorologica extends Component {

    state = {
        objeto: null
    }

    componentDidMount() {
        this.handleRefreshObjetos()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState(
                {
                    objeto: null
                }
                , () => {
                    this.handleRefreshObjetos()
                }
            )
        }
    }

    handleRefreshObjetos() {
        api.get("/meteorologia/estacoes/" + this.props.id)
            .then((response) => {
                this.setState({ objeto: response.data, refresh: new Date().getTime() })
            })
            .catch(() => {
                this.setState({ objeto: null, refresh: -1 })
            })
    }

    handleGoto = () => {
        const { objeto } = this.state;
        const latLong = [objeto.lat, objeto.lng];
        this.props.onGoto(latLong)
    }

    localSelecionado = (item) => {
        if (item) {
            this.props.onGoto([item.lat, item.lng])
        }
    }

    render() {

        const { objeto } = this.state;
        const { showLinks } = this.props;

        return (
            <>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>
                        <Header inverted as="h2">
                            {objeto ? objeto.nome : ''}
                            <Header.Subheader>
                                {objeto ? objeto.fonte.sigla : ''}
                            </Header.Subheader>
                        </Header>
                    </div>

                    <div style={{ flex: "200px", textAlign: "right" }}>

                        {showLinks &&
                            <Button
                                as={Link}
                                to={"/estacao-meteorologica/" + (objeto ? objeto.id : 0)}
                                disabled={!objeto}
                                inverted
                                size='tiny'
                                icon="search plus"
                                title={text("geral.mais-detalhes")}
                            />
                        }

                        {showLinks &&
                            <Button
                                onClick={this.handleGoto}
                                inverted
                                size='tiny'
                                icon="crosshairs"
                                title={text("geral.centralizar")}
                            />
                        }

                        <Button
                            onClick={this.props.onClose}
                            inverted
                            size='tiny'
                            icon="close"
                            color="red"
                            title={text("geral.fechar")}
                        />
                    </div>
                </div>

                <br />

                {objeto &&
                    <div className="dica-objeto-grid">
                        <div className="box">
                            <Header as="h5" attached="top" inverted>
                                {text("geral.informacoes-gerais")}
                            </Header>
                            <Segment attached inverted>
                                <b>{text("dados-meteorologicos.estacao")}:</b> {objeto.nome}<br />
                                <b>{text("dados-meteorologicos.fonte")}:</b> {objeto.fonte.nome} {objeto.fonte.sigla !== objeto.fonte.nome ? ("(" + objeto.fonte.sigla + ")") : null}<br />
                                <b>{text("geo.posicao")}:</b> {convertDMS(objeto.lat, objeto.lng)}<br />
                            </Segment>
                        </div>


                        <div className="box">
                            <Header inverted as='h5' attached='top'>
                                {text("dados-meteorologicos.dados-meteorologicos")}
                            </Header>
                            <Segment attached inverted>
                                {objeto.dadoMeteorologicoRecente ? (
                                    <DadosMeteorologicos
                                        item={objeto.dadoMeteorologicoRecente}
                                    />
                                ) : (
                                        <>
                                            <br />
                                            {text("dados-meteorologicos.nenhum-dados-meteorologicos")}
                                            <br /><br />
                                        </>
                                    )
                                }

                            </Segment>
                        </div>


                    </div>
                }
            </>
        )
    }
}