import React, { Component } from 'react';

import { Icon, Modal, Button, Header, Grid, Confirm, Form, Message, Divider } from 'semantic-ui-react';
import { Formik } from "formik";
import api from 'services/api';
import { text } from 'services/locales';
import InputMask from 'react-input-mask'

import { convertLatDMS, convertLngDMS, convertDMSToDD } from 'util/functions'

export default class DialogoEditarAreaCircular extends Component {

    state = {
        item: null,
        showRemoveDialog: false,
        error: null,
        sucesses: null,
        submitting: false,
        latitude: null,
        longitude: null,
        raio: null,
        cor: null,
        nome: null,
        redirect: null
    }

    componentDidMount() {
        api.get("/hidra/areas/circular/" + this.props.id)
            .then((response) => {
                this.setState({
                    item: response.data,
                    latitude: convertLatDMS(response.data.latitude),
                    longitude: convertLngDMS(response.data.longitude),
                })
            })
            .catch(() => {
                this.setState({ item: null })
            })
    }

    handleCancelar = () => {
        this.setState({ item: null })
        this.props.onClose();
    }

    handleRemove = () => {
        this.setState({ showRemoveDialog: true, error: null, sucesses: null })
    }

    handleChangeLat = (event) => {
        this.setState({
            latitude: event.target.value
        });
    }

    handleChangeLng = (event) => {
        this.setState({
            longitude: event.target.value
        });
    }

    handleConfirmRemove = () => {
        this.setState({ showRemoveDialog: false, loading: true })
        const { item } = this.state

        api.delete('/hidra/areas/circular/' + item.id)
            .then((response) => {
                this.setState({
                    loading: false,
                    item: null
                },
                    () => {
                        this.props.onClose();
                    });
            })
            .catch((error) => {
                let errorMsg = text("tabelas.erro-remover");

                if (error.response && error.response.data && error.response.data.message && error.response.data.status && error.response.data.status >= 400 && error.response.data.status < 500) {
                    errorMsg = error.response.data.message
                }

                this.setState({
                    loading: false,
                    error: errorMsg
                });
            });
    }

    handleCancelRemove = () => {
        this.setState({ showRemoveDialog: false })
    }

    render() {
        const { item, submitting, loading } = this.state;
        return (

            <>
                {item &&
                    <>
                        <Modal
                            mini
                            closeOnEscape={false}
                            closeOnDimmerClick={false}
                            centered={false}
                            open={item !== null}
                        >
                            <Header as="h1" >
                                {item.nome}
                            </Header>
                            <Modal.Content scrolling>
                                <Formik
                                    initialValues={{
                                        nome: item ? item.nome : '',
                                        raio: item ? item.raio : '',
                                        cor: item ? item.cor : '#FF0000',
                                        latitude: item ? this.state.latitude : '',
                                        longitude: item ? this.state.longitude : ''
                                    }}
                                    onSubmit={(values) => {
                                        this.setState({ error: null, submitting: true, sucesses: null });

                                        if (item) {
                                            api.put('/hidra/areas/circular/' + item.id,
                                                {
                                                    "nome": values.nome,
                                                    "raio": values.raio,
                                                    "cor": values.cor,
                                                    "lat": convertDMSToDD(this.state.latitude),
                                                    "lng": convertDMSToDD(this.state.longitude)
                                                }
                                            )
                                                .then((response) => {
                                                    if (response.data.error != null) {
                                                        this.setState({ submitting: false, error: response.data });
                                                        window.scrollTo(0, 0)
                                                    } else {
                                                        this.setState({ submitting: false, error: null, sucesses: text("areas.editada-sucesso") }, this.props.onClose());
                                                        window.scrollTo(0, 0)
                                                    }
                                                })
                                                .catch((error) => {
                                                    var mensagemErro = text("formularios.erro");
                                                    if (error.response && error.response.data.message) {
                                                        mensagemErro = error.response.data.message;
                                                        window.scrollTo(0, 0)
                                                    } else if (error.request) {
                                                        mensagemErro = text("formularios.erro_request")
                                                        window.scrollTo(0, 0)
                                                    }
                                                    this.setState({
                                                        submitting: false,
                                                        error: mensagemErro
                                                    });
                                                });
                                        }
                                    }}
                                    render={({
                                        values,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    }) => (

                                            <Form
                                                onSubmit={handleSubmit}
                                                loading={loading}
                                                submitting={submitting}
                                            >
                                                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                                                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                                                <Form.Group>
                                                    <Form.Field required style={{ width: '200px' }}>
                                                        <label>{text("geral.nome")}</label>
                                                        <Form.Input
                                                            placeholder={text("geral.nome")}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.nome}
                                                            name="nome"
                                                        />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>{text("tipo-local.cor")}</label>
                                                        <input
                                                            type="color"
                                                            name="cor"
                                                            value={values.cor}
                                                            defaultValue="#FF0000"
                                                            onChange={handleChange}
                                                            //style={{ height: '38px', width: '80px'}}
                                                        />
                                                    </Form.Field>
                                                </Form.Group>

                                                <Form.Group>
                                                    <Form.Field required style={{ width: '200px' }}>
                                                        <label>{text("local.latitude")}</label>
                                                        <InputMask
                                                            mask={"99º99'99''a"}
                                                            formatChars={{ "9": "[0-9]", "a": "[S|N|s|n]" }}
                                                            maskChar={null}
                                                            onChange={this.handleChangeLat}
                                                            value={this.state.latitude}
                                                            name="latitude"
                                                        />
                                                    </Form.Field>

                                                    <Form.Field required style={{ width: '200px' }}>
                                                        <label>{text("local.longitude")}</label>
                                                        <InputMask
                                                            mask={"999º99'99''a"}
                                                            formatChars={{ "9": "[0-9]", "a": "[E|W|e|w]" }}
                                                            maskChar={null}
                                                            onChange={this.handleChangeLng}
                                                            value={this.state.longitude}
                                                            name="longitude"
                                                        />
                                                    </Form.Field>

                                                    <Form.Field required style={{ width: '150px' }}>
                                                        <label>{text("areas.raio")} (m)</label>
                                                        <Form.Input
                                                            placeholder={text("areas.raio")}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.raio}
                                                            name="raio"
                                                        />
                                                    </Form.Field>
                                                </Form.Group>

                                                <Divider />
                                                <Grid columns={2}>
                                                    <Grid.Column textAlign="left">
                                                        <Button
                                                            icon labelPosition='left'
                                                            size='large'
                                                            color="red"
                                                            type='button'
                                                            disabled={loading}
                                                            onClick={this.handleRemove}
                                                        >
                                                            <Icon name='trash' /> {text("formularios.remover")}
                                                        </Button>
                                                    </Grid.Column>
                                                    <Grid.Column textAlign="right">
                                                        <Button
                                                            icon labelPosition='left'
                                                            size='large'
                                                            type='button'
                                                            disabled={loading}
                                                            onClick={this.handleCancelar}
                                                        >
                                                            <Icon name='cancel' /> {text("formularios.cancelar")}
                                                        </Button>
                                                        <Button
                                                            primary
                                                            loading={submitting}
                                                            type='submit'
                                                            icon labelPosition='right'
                                                            size="large"
                                                            disabled={loading || submitting}
                                                        >
                                                            <Icon name='save' /> {text("formularios.atualizar")}
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid>
                                            </Form>
                                        )
                                    }
                                />
                            </Modal.Content>
                        </Modal>

                        <Confirm
                            header={text("tabelas.dialogo-remover")}
                            content={item.nome}
                            open={this.state.showRemoveDialog}
                            onCancel={this.handleCancelRemove}
                            onConfirm={this.handleConfirmRemove}
                            cancelButton={text("geral.nao")}
                            confirmButton={text("geral.sim")}
                        />
                    </>
                }
            </>
        )
    }
}