import React, { Component } from 'react';

import { Header } from 'semantic-ui-react'

export default class Cabecalho extends Component {

    render() {

        const { inverted, header, attached, titulo, subtitulo } = this.props

        return (
            <Header attached={attached} inverted={inverted} as={header ? header : 'h1'}>
                <Header.Content>
                    {titulo}
                    <Header.Subheader>{subtitulo}</Header.Subheader>
                </Header.Content>
            </Header>
        )
    }
}