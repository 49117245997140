import React, { Component } from 'react';

import { Button, Header, Message, Icon, Segment, Container } from 'semantic-ui-react'

import { text } from 'services/locales';
import api from 'services/api';
import { Helmet } from 'react-helmet'
import { isAdmin } from 'services/auth'
import { Redirect } from 'react-router-dom'

export default class SuporteConfiguracoes extends Component {

    state = {
        loading: false,
        error: null,
        sucesses: null,
    }

    constructor(props) {
        super(props);
        this.apkInputRef = React.createRef();
        this.ajudaInputRef = React.createRef();
    }

    handleApkSelect = (e) => {
        if (e && e.target && e.target.files && e.target.files.length > 0)
            this.setState({
                apkFileName: e.target.files[0].name,
                apkFile: e.target.files[0],
            });
    }

    handleEnviarApk = () => {

        const { apkFile } = this.state;

        this.setState({
            submitting: true,
            error: null
        });

        var bodyFormData = new FormData();
        if (apkFile) bodyFormData.append('file', apkFile);

        api({
            method: 'post',
            url: '/hidra/config/apk',
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                this.setState({
                    sucesses: 'Aplicativo atualizado',
                    submitting: false,
                    error: null,
                    apkFile: null,
                    apkFileName: null
                });
            })
            .catch(error => {
                var mensagemErro = text("formularios.erro_request");
                if (error.response && error.response.data) {
                    mensagemErro = error.response.data.message;
                }
                this.setState({
                    submitting: false,
                    error: mensagemErro
                });
            });

    }

    handleAjudaSelect = (e) => {
        if (e && e.target && e.target.files && e.target.files.length > 0)
            this.setState({
                ajudaFileName: e.target.files[0].name,
                ajudaFile: e.target.files[0],
            });
    }

    handleEnviarAjuda = () => {

        const { ajudaFile } = this.state;

        this.setState({
            submitting: true,
            error: null
        });

        var bodyFormData = new FormData();
        if (ajudaFile) bodyFormData.append('file', ajudaFile);

        api({
            method: 'post',
            url: '/hidra/config/ajuda',
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                this.setState({
                    sucesses: 'Ajuda atualizada',
                    submitting: false,
                    error: null,
                    ajudaFile: null,
                    ajudaFileName: null
                });
            })
            .catch(error => {
                var mensagemErro = text("formularios.erro_request");
                if (error.response && error.response.data) {
                    mensagemErro = error.response.data.message;
                }
                this.setState({
                    submitting: false,
                    error: mensagemErro
                });
            });

    }

    render() {

        const { submitting } = this.state;

        return (
            <Container style={{ padding: '2em' }}>

                {!isAdmin() &&
                    <Redirect to='/' />
                }

                <Helmet title={`Configurações ${text("geral.html-title")}`} />

                <Header as="h1">Configurações</Header>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <Header as="h3" attached="top">
                    Ajuda
                </Header>
                <Segment attached>
                    <Button
                        icon
                        labelPosition="left"
                        onClick={() => this.ajudaInputRef.current.click()}
                    >
                        <Icon name="paperclip" /> {text("anexo.enviar-arquivo")}
                    </Button>
                        &nbsp;{this.state.ajudaFileName}
                    <input
                        ref={this.ajudaInputRef}
                        type="file"
                        hidden
                        onChange={this.handleAjudaSelect}
                    />

                    <Button
                        primary
                        loading={submitting}
                        onClick={this.handleEnviarAjuda}
                        icon labelPosition='right'
                        floated="right"
                    >
                        <Icon name='upload' /> Upload
                    </Button>
                </Segment>

                <Header as="h3" attached="top">
                    Aplicativo móvel
                </Header>
                <Segment attached>
                    <Button
                        icon
                        labelPosition="left"
                        onClick={() => this.apkInputRef.current.click()}
                    >
                        <Icon name="paperclip" /> {text("anexo.enviar-arquivo")}
                    </Button>
                        &nbsp;{this.state.apkFileName}
                    <input
                        ref={this.apkInputRef}
                        type="file"
                        hidden
                        onChange={this.handleApkSelect}
                    />

                    <Button
                        primary
                        loading={submitting}
                        onClick={this.handleEnviarApk}
                        icon labelPosition='right'
                        floated="right"
                    >
                        <Icon name='upload' /> Upload
                    </Button>
                </Segment>

            </Container>
        );
    }

}