import React, { Component } from 'react';

import { Button, Form, Header, Icon, Message, Container, Divider, Grid, Radio } from 'semantic-ui-react'
import { Formik } from "formik";
import api from 'services/api';
import { text } from 'services/locales';
import { Link, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { getIcon } from 'util/eventos.js'

export default class Formulario extends Component {

    state = {
        redirect: false,
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true
    };

    componentDidMount() {

        this.setState({ loading: true });

        if (this.props.match.params.id) {
            const { id } = this.props.match.params;
            api.get("/eventos/tipos/" + id)
                .then((response) => {
                    this.setState({
                        item: response.data,
                        severidade: response.data.severidade.codigo
                    });
                    this.setState({ loading: false })
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error: text("formularios.erro_request")
                    });
                });
        }

        this.setState({ loading: false })

    }


    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }


    render() {

        const { item, loading, submitting, redirect, sucesses } = this.state;
        const { id } = this.props.match.params;

        if (redirect === true) {
            return <Redirect to={{
                pathname: '/admin/tipo-eventos',
                state: { sucesses: sucesses }
            }}
            />
        }

        return (
            <Container style={{ padding: '2em', backgroundColor: "#fff" }}>

                <Helmet title={`
            ${id ?
                        (
                            text("eventos.atualizar-tipo") + " '" + (item ? item.nome : '') + "'"
                        )
                        :
                        text("eventos.novo-tipo")
                    } ${text("geral.html-title")}`} />

                <Formik
                    initialValues={{
                        nome: item ? item.nome : '',
                        descricao: item ? item.descricao : ''
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        this.setState({ error: null, submitting: true, sucesses: null });

                        if (item) {//editando um item
                            api.put('/eventos/tipos/' + item.id,
                                {
                                    "nome": values.nome,
                                    "descricao": values.descricao,
                                    "severidade": this.state.severidade
                                }
                            )
                                .then((response) => {
                                    if (response.data.error != null) {
                                        this.setState({ submitting: false, error: response.data });
                                        window.scrollTo(0, 0)
                                    } else {
                                        this.setState({ submitting: false, error: null, sucesses: text("eventos.atualizado-sucesso"), redirect: true });
                                        window.scrollTo(0, 0)
                                    }
                                })
                                .catch((error) => {
                                    var mensagemErro = text("formularios.erro");
                                    if (error.response && error.response.data.message) {
                                        window.scrollTo(0, 0)
                                        mensagemErro = error.response.data.message;
                                    } else if (error.request) {
                                        window.scrollTo(0, 0)
                                        mensagemErro = text("formularios.erro_request")
                                    }
                                    this.setState({
                                        submitting: false,
                                        error: mensagemErro
                                    });
                                });
                        } else {//novo item
                            api.post('/eventos/tipos',
                                {
                                    "nome": values.nome,
                                    "descricao": values.descricao,
                                    "severidade": this.state.severidade
                                }
                            )
                                .then((response) => {
                                    if (response.data.error != null) {
                                        this.setState({ submitting: false, error: response.data });
                                        window.scrollTo(0, 0)
                                    } else {
                                        resetForm()
                                        this.setState({ submitting: false, error: null, sucesses: text("eventos.criado-sucesso"), redirect: true });
                                        window.scrollTo(0, 0)
                                    }
                                })
                                .catch((error) => {
                                    var mensagemErro = text("formularios.erro");
                                    if (error.response && error.response.data.message) {
                                        mensagemErro = error.response.data.message;
                                        window.scrollTo(0, 0)
                                    } else if (error.request) {
                                        mensagemErro = text("formularios.erro_request")
                                        
                                    }
                                    this.setState({
                                        submitting: false,
                                        error: mensagemErro
                                    });
                                });
                        }
                    }}
                    render={({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (

                            <Form
                                onSubmit={handleSubmit}
                                loading={loading}
                            >
                                <Header as="h1">
                                    {id ?
                                        (
                                            text("eventos.atualizar-tipo") + " '" + (item ? item.nome : '') + "'"
                                        )
                                        :
                                        text("eventos.novo-tipo")
                                    }
                                </Header>

                                
                                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}

                                <Form.Field required>
                                    <label>{text("eventos.nome")}</label>
                                    <Form.Input
                                        placeholder={text("eventos.nome")}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nome}
                                        name="nome"
                                        width={5}
                                    />
                                </Form.Field>

                                <Form.Field required>
                                    <label>{text("geral.descricao")}</label>
                                    <Form.Input
                                        placeholder={text("geral.descricao")}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.descricao}
                                        name="descricao"
                                        width={10}
                                    />
                                </Form.Field>

                                <Form.Field required>
                                    <label>
                                        {text("eventos.severidade")}
                                    </label>
                                    {/* <Dropdown
                                        placeholder={text("eventos.tipo-severidade")}
                                        onChange={this.handleChange}
                                        value={this.state.severidade}
                                        name="severidade"
                                        search
                                        selection
                                        options={optionsSeveridades}
                                    /> */}
                                    <Grid columns={3}>
                                        <Grid.Row>
                                            <Grid.Column width={2}>
                                                <Radio
                                                    label={text('severidade-evento.alarme')}
                                                    name='severidade'
                                                    value='alarme'
                                                    checked={this.state.severidade === 'alarme'}
                                                    onChange={this.handleChange}
                                                    icon={getIcon('alarme')}
                                                /> &nbsp;
                                                    <Icon name={getIcon('alarme')}
                                                    size='large'
                                                    style={{ color: '#FF0000' }}
                                                />

                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Radio
                                                    label={text('severidade-evento.alerta')}
                                                    name='severidade'
                                                    value='alerta'
                                                    checked={this.state.severidade === 'alerta'}
                                                    onChange={this.handleChange}
                                                /> &nbsp;
                                                <Icon name={getIcon('alerta')}
                                                    size='large'
                                                    style={{ color: '#FFA500' }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <Radio
                                                    label={text('severidade-evento.informacao')}
                                                    name='severidade'
                                                    value='informacao'
                                                    checked={this.state.severidade === 'informacao'}
                                                    onChange={this.handleChange}
                                                /> &nbsp;
                                                    <Icon name={getIcon('informacao')}
                                                    size='large'
                                                    style={{ color: '#5472C0' }}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form.Field>
                                <Divider />
                                <br />
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Button
                                            icon labelPosition='left'
                                            size='large'
                                            as={Link}
                                            to="/admin/tipo-eventos"
                                            disabled={loading}
                                        >
                                            <Icon name={item ? 'cancel' : 'angle left'} /> {item ? text("formularios.cancelar") : text("formularios.voltar")}
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            primary
                                            loading={submitting}
                                            type='submit'
                                            icon labelPosition='right'
                                            size="big"
                                            disabled={loading || submitting}
                                        >
                                            <Icon name='save' /> {item ? text("formularios.atualizar") : text("formularios.salvar")}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        )}
                />
            </Container>
        );
    }

}