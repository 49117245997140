import React, { Component } from 'react';

import { Header, Container, Tab, Message } from 'semantic-ui-react'
import api from 'services/api';
import { text } from 'services/locales';
import { Helmet } from 'react-helmet'

import CadastroContato from 'components/objetos/entidades/cadastroContato'
import CadastroUsuario from 'components/objetos/usuarios/cadastro'

export default class AdminUsuarioFormulario extends Component {

    state = {
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true,
        locais: []
    };

    componentDidMount() {

        this.loadItens();

        if (this.props.location.state != null) {
            this.setState({ sucesses: this.props.location.state.sucesses })
        }

    }

    loadItens = () => {
        
        this.setState({ sucesses: null })

        if (this.props.match.params.id) {
            const { id } = this.props.match.params;

            api.get("/hidra/usuarios/" + id)
                .then((response) => {
                    this.setState({
                        item: response.data,
                    });
                }).catch((error) => {
                    this.setState({
                        loading: false,
                        posicoes: [],
                        error: text("formularios.erro_request")
                    });
                });
        }

        api.get("/hidra/locais/all")
            .then((response) => {
                const locais = response.data.map((item) => ({
                    key: item.id,
                    text: item.nome,
                    value: item.id,
                }));

                this.setState({ loading: false, locais: locais });
            }).catch((error) => {
                this.setState({
                    loading: false,
                    posicoes: [],
                    error: text("formularios.erro_request")
                });
            });

    }

    render() {

        const { item } = this.state;
        const { id } = this.props.match.params;

        const panes = [
            {
                menuItem: { key: 'home', icon: 'user', content: text("entidade.geral") },
                render: () => <Tab.Pane>
                    <CadastroUsuario
                        id={id}
                        onSubmit={() => this.loadItens()}
                    />
                </Tab.Pane>,
            },
            {
                menuItem: { key: 'contato', icon: 'phone', content: text("entidade.contatos") },
                render: () =>
                    <Tab.Pane>
                        <CadastroContato
                            id={id}
                            onSubmit={() => this.loadItens()}
                        />
                    </Tab.Pane>,
            }
        ]



        return (
            <Container style={{ padding: '2em', backgroundColor: "#fff" }}>

                <Helmet title={text("usuario.atualizar-usuario") + (item ? " '" + item.nome + "'" : '')} />

                <Header as="h1">
                    {text("usuario.atualizar-usuario") + (item ? " '" + item.nome + "'" : '')}
                </Header>

                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <Tab
                    panes={panes}
                />

                <br />
            </Container>
        );
    }

}