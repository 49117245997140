import React, { Component } from 'react';

import { Button, Header, Message, Container } from 'semantic-ui-react'

import { text } from 'services/locales';

import { Helmet } from 'react-helmet'
import { isDev } from 'util/functions'

import Tabs from './tabs.js'

export default class SuporteTestes extends Component {

    state = {
        show: isDev()
    }

    show = () => {
        this.setState({ show: true });
    }

    render() {

        const { show } = this.state;

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`Testes ${text("geral.html-title")}`} />

                <Header as="h1">Testes</Header>

                {!show &&
                    <Message warning size="big" style={{ textAlign: "center" }}>
                        <br />
                        <b>ATENÇÃO!</b>
                        <br /><br />
                    Alterações aqui podem ser permanentes. Só prossiga se souber o que esta fazendo.
                    <br /><br />
                        <Button
                            color="red"
                            onClick={this.show}
                        >
                            Estou ciente dos riscos
                    </Button>
                        <br /><br />
                    </Message>
                }

                {show &&
                    <Tabs />
                }
            </Container>
        );
    }

}