import React, { Component } from 'react';

import { Button, Form, Segment } from 'semantic-ui-react'

import api from 'services/api';
import { jwtSubject } from 'services/auth'

const acaoOptions = [
    {
        key: 'msg',
        text: 'Mensagem',
        value: 'msg'
    },
    {
        key: 'update',
        text: 'Atualização do sistema',
        value: 'update'
    }
]

export default class SuporteTestesMensagemWebSocket extends Component {

    state = {
        loading: false,
        error: null,
        sucesses: null,
        topico: "/hidra/suporte/" + jwtSubject(),
        acao: "msg"
    }

    handleSubmit = e => {
        e.preventDefault();

        const { topico, mensagem, acao } = this.state;
        this.setState({ result: null, error: null, loading: true })

        api.post(
            "/suporte/mensagem",
            { topico, mensagem, acao }
        )
            .then(response => {
                this.setState({ result: response.data, loading: false })
            })
            .catch(error => {
                this.setState({ error: (error.response.data ? error.response.data : error.response), loading: false })
            });
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    render() {

        return (
            <>

                <Form loading={this.state.loading} size='large' className="form">
                    <Form.Group widths='equal'>

                        <Form.Input
                            name="topico"
                            label="Tópico"
                            value={this.state.topico}
                            onChange={e => this.setState({ topico: e.target.value })}
                        />

                        <Form.Dropdown
                            label="Ação"
                            name="acao"
                            fluid
                            selection
                            options={acaoOptions}
                            value={this.state.acao}
                            onChange={this.handleChange}
                        />
                    </Form.Group>

                    {this.state.acao === 'msg' &&
                        <Form.Input
                            name="mensagem"
                            label="Mensagem"
                            value={this.state.mensagem}
                            onChange={e => this.setState({ mensagem: e.target.value })}
                        />
                    }

                    <br />

                    <center>
                        <Button primary size='large' onClick={this.handleSubmit} >ENVIAR</Button>
                    </center>

                    <br />

                </Form>

                <Segment color={this.state.result ? "green" : (this.state.error ? "red" : "grey")}>
                    <pre>
                        {this.state.result ? JSON.stringify(this.state.result, null, 2) : ""}
                        {this.state.error ? JSON.stringify(this.state.error, null, 2) : ""}
                    </pre>
                </Segment>


            </>

        );
    }

}