import React from "react";

import CenarioTatico from 'pages/cenarioTatico/main'

export default class Mapa extends React.Component {
    render() {
        return (
            <>
                <CenarioTatico mobile />
            </>
        )
    }
}