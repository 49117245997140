import React, { Component } from 'react';

import DicaObjetoAco from '../acompanhamento'
import DicaObjetoLocal from '../local'
import DicaObjetoEvento from '../evento'
import DicaObjetoLatLng from '../latlng'
import DicaObjetoAreaCircular from '../areas/circular'
import DicaEstacaoMeteorologica from '../estacao-meteorologica';

export default class DicaObjeto extends Component {

    renderDica() {
        const { id, tipo, showLinks } = this.props;

        if (!id || !tipo)
            return;

        switch (tipo) {
            case 'aco':
                return (
                    <DicaObjetoAco
                        uuid={id}
                        onGoto={(e) => { this.props.onGoto(e) }}
                        onClose={(e) => { this.props.onClose(e) }}
                        showLinks={showLinks}
                    />
                )
            case 'area-circular':
                return (
                    <DicaObjetoAreaCircular
                        id={id}
                        onGoto={(e) => { this.props.onGoto(e) }}
                        onClose={(e) => { this.props.onClose(e) }}
                        onRefresh={(e) => { this.props.onRefresh(e) }}
                        showLinks={showLinks}
                    />
                )
            case 'local':
                return (
                    <DicaObjetoLocal
                        id={id}
                        onGoto={(e) => { this.props.onGoto(e) }}
                        onClose={(e) => { this.props.onClose(e) }}
                        onSelectLocal={(id) => { this.props.onSelectLocal(id) }}
                        showLinks={showLinks}
                    />
                )
            case 'estacao':
                return (
                    <DicaEstacaoMeteorologica
                        id={id}
                        onGoto={(e) => { this.props.onGoto(e) }}
                        onClose={(e) => { this.props.onClose(e) }}
                        showLinks={showLinks}
                    />
                )
            case 'evento':
                return (
                    <DicaObjetoEvento
                        id={id}
                        onGoto={(e) => { this.props.onGoto(e) }}
                        onClose={(e) => { this.props.onClose(e) }}
                        showLinks={showLinks}
                    />
                )
            case 'latlng':
                return (
                    <DicaObjetoLatLng
                        id={id}
                        onColse={(e) => { this.props.onClose(e) }}
                        showLinks={showLinks}
                    />
                )
            default:
                return null
        }
    }

    render() {
        return this.renderDica()
    }
}