import React, { Component } from 'react';

import { Button, Header, Form, Grid } from "semantic-ui-react"
import { text } from 'services/locales'

import { getFilterLayer, setFilterLayer } from 'services/storage'

const optionsOpenweathermap = [
    { text: text('filtro.nenhum'), value: "none" },
    { text: text('filtro.nuvens'), value: 'clouds_new' },
    { text: text('filtro.precipitacao'), value: 'precipitation_new' },
    { text: text('filtro.pressao'), value: 'pressure_new' },
    { text: text('filtro.vento'), value: 'wind_new' },
    { text: text('filtro.temperatura'), value: 'temp_new' }
]

export default class FiltroCamadas extends Component {

    state = {
        filtro: {
            radarMeteorologico: false,
            cina: false,
            openweathermap: "none"
        }
    }

    componentDidMount() {
        var filter = getFilterLayer()

        if (filter) {
            this.setState({ filtro: filter })
        } else {
            this.defaultState();
        }
    }

    defaultState = () => {
        this.setState(
            {
                filtro:
                {
                    radarMeteorologico: false,
                    cina: false,
                    openweathermap: "none"
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleClose = (e, { name, value }) => {
        this.props.onClose(value)
    }

    handleUpdateFilter = () => {
        this.props.onUpdateFilter(this.state.filtro);
        setFilterLayer(this.state.filtro)
    }

    handleRadarMeteorologico = () => {
        const { filtro } = this.state;
        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    radarMeteorologico: !filtro.radarMeteorologico
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleCina = () => {
        const { filtro } = this.state;
        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    cina: !filtro.cina
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleOpenweathermap = (valor) => {
        const { filtro } = this.state;
        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    openweathermap: valor
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    render() {
        const { filtro } = this.state;

        return (
            <>
                <Grid columns={2} >
                    <Grid.Column>
                        <Header inverted as="h2">{text("filtro.camadas")}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">

                        <Button
                            onClick={this.defaultState}
                            inverted
                            size='tiny'
                            icon="undo"
                            title={text("filtro.reset")}
                        />
                        <Button
                            onClick={this.handleClose}
                            inverted
                            size='tiny'
                            icon="close"
                            color="red"
                            title={text("geral.fechar")}
                        />
                    </Grid.Column>
                </Grid>

                <br />

                <Form inverted>
                    <Form.Field >
                        {text("filtro.alerta-rio")}
                    </Form.Field>

                    <Form.Checkbox
                        onChange={this.handleRadarMeteorologico}
                        checked={filtro.radarMeteorologico}
                        label={text("filtro.radar-meteorologico-sumare")}
                    />
                    <br />
                    {/*
                    <Form.Checkbox
                        onChange={this.handleCina}
                        checked={filtro.cina}
                        label={text("filtro.cina")}
                    /> */}

                    <Form.Field >
                        {text("filtro.openweathermap")}
                    </Form.Field>
                    <Form.Group grouped>
                        {optionsOpenweathermap.map(item =>
                            <Form.Radio
                                key={item.value}
                                value={item.value}
                                label={item.text}
                                name="openweathermapGroup"
                                checked={filtro.openweathermap === item.value}
                                onChange={(e, { value }) => this.handleOpenweathermap(value)}
                            />
                        )}
                    </Form.Group>
                </Form>

            </>
        )
    }
}