import React, { Component } from 'react';

import { Button, Icon, Container, Header } from 'semantic-ui-react'

import api from 'services/api';
import Footer from 'components/footer'
import { Helmet } from 'react-helmet'
import { text } from 'services/locales'

import Fotos from 'components/objetos/entidades/fotos'
import Info from 'components/objetos/locais/info'
import AcompanhamentosProximos from 'components/objetos/locais/acompanhamentos-proximos'
import UsuariosLocal from 'components/objetos/locais/usuarios'
import Contatos from 'components/objetos/entidades/contatos'
import Hierarquia from 'components/objetos/locais/hierarquia'
import EstacoesMeteorologicasProximas from 'components/objetos/meteorologia/estacoesProximas'
import Observacoes from 'components/objetos/entidades/observacoes'

export default class Local extends Component {

    state = {
        item: null,
        loading: false,
        id: null,
        refresh: -1
    };

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState(
                {
                    item: null,
                    loading: true,
                    id: this.props.match.params.id,
                    refresh: -1
                }
                , () => {
                    this.loadItem()
                }
            )
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState(
                { id: this.props.match.params.id }
                , () => { this.loadItem(); }
            );
        }
    }

    loadItem = () => {
        this.setState({ loading: true, error: null });

        const { id } = this.state;

        let url = '/hidra/locais/' + id;

        api.get(url)
            .then((response) => {
                if (response.data != null) {
                    this.setState({
                        loading: false,
                        item: response.data
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    item: null,
                    error: text("formularios.erro_request")
                });
            });
    }

    handleRefresh = () => {
        this.loadItem()
        this.setState({ refresh: new Date().getTime() })
    }

    render() {

        const { item, id, loading, refresh } = this.state;

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`${item ? item.nome : id} ${text("geral.html-title")}`} />

                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>

                        <Header as="h1">
                            {item && item.tipo &&
                                <Icon
                                    name={item.tipo.simbolo ? item.tipo.simbolo : 'map marker alternate'}
                                    style={{ color: item.tipo.cor ? item.tipo.cor : '#808080' }}
                                />
                            }
                            <Header.Content>
                                {item ? item.sigla : ''}
                                <Header.Subheader>
                                    {item ? item.nome : ''}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </div>

                    <div style={{ flex: "100px", textAlign: "right" }}>
                        <Button.Group icon>
                            <Button loading={loading} toggle icon onClick={this.handleRefresh}>
                                <Icon name='refresh' />
                            </Button>
                        </Button.Group>
                    </div>
                </div>

                <br />

                <div className="page-grid-2-columns">

                    <Info id={id} refresh={refresh} bntVerNoMapa={true} />

                    <Observacoes titulo={text("local.observacoes")} observacoes={item && item.observacoes} />

                    <Contatos id={id} refresh={refresh} />

                    <Hierarquia id={id} pai={item ? item.pai : null} refresh={refresh} />

                    {item &&
                        <EstacoesMeteorologicasProximas lat={item.lat} lng={item.lng} raio={20} showLinks={true} />
                    }

                    <UsuariosLocal id={id} refresh={refresh} />

                    <AcompanhamentosProximos id={id} refresh={refresh} center={item ? [item.lat, item.lng] : null} />

                    {item &&
                        <Fotos hash={item.imagem} />
                    }

                </div>

                <Footer />
            </Container>
        );
    }

}