import React, { Component } from 'react';

import { Button, Message, List } from 'semantic-ui-react'

import { text } from 'services/locales';
import api from 'services/api';


export default class SuporteTestesMeteorologia extends Component {

    state = {
        loading: false,
        error: null,
        success: null
    }

    enviar = url => () => {
        this.setState({ loading: true, error: null, success: null });

        api.get(url)
            .then((response) => {
                if (response.data != null) {
                    this.setState({
                        loading: false,
                        success: "Requisição enviada com sucesso: " + JSON.stringify(response.data)
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: text("formularios.erro_request")
                });
            });
    }

    delete = url => () => {
        this.setState({ loading: true, error: null, success: null });

        api.delete(url)
            .then((response) => {
                if (response != null) {
                    this.setState({
                        loading: false,
                        success: "Requisição enviada com sucesso"
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: text("formularios.erro_request")
                });
            });
    }



    render() {

        const { loading } = this.state;

        return (
            <>

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                {this.state.success && <Message positive onDismiss={e => this.setState({ success: null })}>{this.state.success}</Message>}

                <List divided loading={loading}>

                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                loading={loading}
                                color="blue"
                                onClick={this.enviar('/meteorologia/dados-meteorologicos/consultar')}
                            >
                                Consultar
                            </Button>
                        </List.Content>
                        <List.Content><b>Força a consulta dos dados meteorologicos</b></List.Content>
                        <List.Description>Realiza uma consulta forçada a todas as estações</List.Description>
                    </List.Item>

                    <List.Item>
                        <List.Content floated='right'>
                            <Button
                                loading={loading}
                                color="red"
                                onClick={this.delete('/meteorologia/dados-meteorologicos')}
                            >
                                Remover
                            </Button>
                        </List.Content>
                        <List.Content><b>Remover todos os dados meteorologicos</b></List.Content>
                        <List.Description>Remove todos os dados meteorologicos</List.Description>
                    </List.Item>

                </List>

            </>

        );
    }

}