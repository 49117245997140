import React, { Component } from 'react';

import { text } from 'services/locales'
import api from "services/api";

import {
    Modal,
    Icon,
    Button,
    Menu,
} from 'semantic-ui-react';

import { forceDownload } from 'util/functions'

import './styles.css'

export default class Help extends Component {

    state = {
        loading: false,
        arquivo: null
    }

    loadHelp = () => {

        this.setState({ loading: true })

        api.get("/hidra/config")
            .then(response => {
                if (response.data && response.data.ajuda) {
                    api.get("/arquivos/" + response.data.ajuda)
                        .then(response => {
                            if (response.data.files && response.data.files.length > 0) {
                                this.setState({
                                    arquivo: response.data.files[0].url,
                                    loading: false
                                });
                            }
                        })
                        .catch(() => {
                            this.setState({
                                arquivo: null,
                                loading: false
                            });
                        })
                }
            })
            .catch(() => {
                this.setState({
                    arquivo: null,
                    loading: false
                });
            })

        this.setState({ loading: false });

    }

    render() {

        const { arquivo, loading } = this.state;

        return (
            <>
                <Modal
                    onOpen={this.loadHelp}
                    closeIcon
                    centered={false}
                    trigger={
                        <Menu.Item
                            title={text("menu.ajuda-contexto")}
                        >
                            <Icon name="help" />
                        </Menu.Item>
                    }
                >
                    <Modal.Header>
                        <Icon name="help circle" />

                        {text("ajuda.ajuda")}

                    </Modal.Header>
                    <Modal.Content >
                        <Modal.Description>

                            {loading &&
                                <>
                                    <Icon name="spinner" loading />
                                </>
                            }

                            {!loading && !arquivo &&
                                <>
                                    {text("ajuda.nao-disponivel")}
                                </>
                            }

                            {arquivo &&
                                <>
                                    <p>
                                        O Manual do Usuário trata da aplicação do SIS-CARE, o sistema do Centro de Acompanhamento de Respostas a Emergências Nucleares e Radiológicas Navais (CARE), localizado na Agência Naval de Segurança Nuclear e Qualidade (AgNSNQ).
                                    </p>
                                    <p>
                                        O principal objetivo do referido sistema será permitir o acompanhamento das medidas de respostas as emergências nucleares e radiológicas que envolvam a MB, seja em terra ou a bordo de um meio naval com propulsão nuclear (ex.: o submarino nuclear brasileiro).
                                    </p>
                                    <p>
                                        Este manual tem por objetivo apresentar todas as funcionalidades existentes no sistema, de forma que seu conteúdo seja um guia de como realizar determinadas atividades relacionadas à aplicação desse sistema no âmbito da segurança nuclear.
                                    </p>
                                    <p>
                                        Para baixar o Manual do usuário, clique no botão abaixo.
                                    </p>
                                </>
                            }

                        </Modal.Description>
                    </Modal.Content>

                    <Modal.Actions>
                        {arquivo &&
                            <Button
                                onClick={() => forceDownload(process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_SLASH + "arquivos/" + arquivo, arquivo)}
                            >
                                <Icon name='download' />  {text("ajuda.baixar")}
                            </Button>
                        }
                    </Modal.Actions>

                </Modal>
            </>
        )
    }
}
