import React, { Component } from 'react';

import { Segment, Header, Progress, Statistic, Divider } from 'semantic-ui-react'

import { text } from 'services/locales';

export default class Telemetria extends Component {


    render() {

        const { item, inverted, header } = this.props
        
        return (
            <>
                {item && (item.bateria || item.bpm || item.temperatura) &&
                    <div className="box">
                        <Header attached="top" inverted={inverted} as={header ? header : 'h2'}>
                            {text("acompanhamento.telemetria")}
                        </Header>
                        <Segment attached inverted={inverted} >

                            {item.bateria &&
                                <>
                                    <Progress inverted={inverted} color={item.bateria < 15 ? "red" : "blue"} percent={Number(item.bateria).toFixed(0)}  >
                                        {text("acompanhamento.bateria")}: {Number(item.bateria).toFixed(0)}%
                                    </Progress>
                                </>
                            }
                            <Divider />
                            <Statistic.Group widths='two' inverted={inverted}>
                                {item.temperatura &&
                                    <>
                                        <Statistic color={item.temperatura > 37 ? "red" : "blue"} value={Number(item.temperatura).toFixed(1)} label={text("acompanhamento.temperatura")} />
                                    </>
                                }
                                {item.bpm &&
                                    <>
                                        <Statistic color={(item.bpm > 120 || item.bpm < 50) ? "red" : "blue"} value={Number(item.bpm).toFixed(0)} label={text("acompanhamento.batimentos")} />
                                    </>
                                }
                            </Statistic.Group>
                        </Segment>
                    </div>
                }
            </>
        )
    }
}