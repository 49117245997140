import React, { Component } from 'react';

import { Button, Form, Icon, Message, Divider, Container, Grid, Dropdown, Popup } from 'semantic-ui-react'
import { Formik } from "formik";
import api from 'services/api';
import { text } from 'services/locales';
import { Link, Redirect } from 'react-router-dom'

import Mapa from 'pages/admin/locais/mapa'

export default class CadastroLocal extends Component {

    state = {
        redirect: false,
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true,
        tipos: [],
        posicoes: [],
        poligono: false,
        coordenada: null,
        locaisPai: [],
        pai: null,
        idLocal: null
    };

    constructor(props) {
        super(props);
        this._mapa = React.createRef();
    }

    componentDidMount() {
        // if (this.props.location.state != null) {
        //     this.setState({ sucesses: this.props.location.state.sucesses })
        //   }
        this.loadItens()
        window.scrollTo(0, 0)
    }

    loadItens = () => {
        this.setState({ loading: true });
        if (this.props.id !== undefined) {
            const { id } = this.props;

            api.get("/hidra/locais/" + id)
                .then((response) => {
                    const posicao = response.data.posicoes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)) //ordenação para garantir o correto desenho
                        .map((item) => ({
                            lat: item.lat,
                            lng: item.lng
                        }));

                    const poligono = response.data.poligono

                    if (!poligono) {
                        posicao.push({ lat: response.data.lat, lng: response.data.lng })
                    }

                    this.setState({
                        item: response.data,
                        imagem: response.data.imagem,
                        posicoes: posicao,
                        tipo: response.data.tipo.id,
                        simbolo: response.data.tipo.simbolo,
                        cor: response.data.tipo.cor,
                        poligono: response.data.poligono,
                        coordenada: [response.data.lat, response.data.lng],
                        pai: response.data.pai ? response.data.pai.id : null
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error: text("formularios.erro_request")
                    });
                });
        }

        api.get("/hidra/locais/tipos?size=999&sort=nome,asc")
            .then((response) => {
                const tipos = response.data.content.map((item) => ({
                    key: item.id,
                    icon: item.simbolo,
                    text: item.nome,
                    value: item.id,
                }));

                this.setState({ loading: false, tipos: tipos });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    posicoes: [],
                    error: text("formularios.erro_request")
                });
            });

        api.get("/hidra/locais/all")
            .then((response) => {
                const locaisPai = response.data
                .sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))
                .map((item) => ({
                    key: item.id,
                    text: item.nome,
                    value: item.id,
                    coords: [item.lat, item.lng]
                }));

                this.setState({ loading: false, locaisPai: locaisPai });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    posicoes: [],
                    error: text("formularios.erro_request")
                });
            });
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    onChangePoligono() {
        this.setState({
            poligono: !this.state.poligono,
            posicoes: []
        });
    }

    onHandleClick(element) {
        var coords = [{
            lat: element.lat,
            lng: element.lng
        }]

        this.setState({ posicoes: coords })
    }

    onHandlePoly(element) {
        let coords = { lat: element.lat, lng: element.lng }
        this.setState({
            posicoes: [...this.state.posicoes, coords]
        });
    }

    getLatMarker(posicoes) {
        if (posicoes.length >= 1) {
            var lat = posicoes[0].lat
        }
        return lat
    }

    getLngMarker(posicoes) {
        if (posicoes.length >= 1) {
            var lng = posicoes[0].lng
        }
        return lng
    }

    buildPolygon(posicoes) {
        let poligono = posicoes.map((item) => (
            [item.lat, item.lng]
        ))
        return poligono
    }

    getLat(posicoes) {
        let lat = ""
        posicoes.forEach((element, index) => {
            if (index === 0) {
                lat = element.lat
            } else {
                lat = lat + "," + element.lat
            }
        });
        return lat;
    }

    getLng(posicoes) {
        let lng = ""
        posicoes.forEach((element, index) => {
            if (index === 0) {
                lng = element.lng
            } else {
                lng = lng + "," + element.lng
            }
        });
        return lng;
    }

    handleCoordPai(idPai) {
        var localPai = this.state.locaisPai
        let posicaoPai = ''
        let arrayPai = localPai.filter((local) => {
            return local.key === idPai
        })
        if (arrayPai.length !== 0) {
            arrayPai.forEach((element) => {
                posicaoPai = [element.coords[0], element.coords[1]]
            })

            if (this._mapa && this._mapa.current)
                this._mapa.current.handleGoto(posicaoPai);
        }
    }

    handleChangePai = (e, { name, value }) => {
        this.setState({ [name]: value });

        this.handleCoordPai(value)
    }

    render() {

        const { item, loading, submitting, redirect, sucesses, posicoes, poligono } = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: '/admin/local/' + redirect,
                state: { sucesses: sucesses }
            }}
            />
        }

        return (
            <Container style={{ padding: '0.5em', backgroundColor: "#fff" }}>
                <Formik
                    initialValues={{
                        nome: item ? item.nome : '',
                        sigla: item ? item.sigla : '',
                        poligono: item ? item.poligono : true,
                        observacoes: item ? item.observacoes : '',
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        this.setState({ error: null, submitting: true, sucesses: null });

                        if (item) { //editando um item
                            api.put('/hidra/locais/' + item.id,
                                {
                                    "nome": values.nome,
                                    "sigla": values.sigla,
                                    "tipo": this.state.tipo,
                                    "pai": this.state.pai ? this.state.pai : null,
                                    "lat": !poligono ? this.getLatMarker(posicoes) : this.getLat(posicoes),
                                    "lng": !poligono ? this.getLngMarker(posicoes) : this.getLng(posicoes),
                                    "observacoes": values.observacoes
                                }
                            )
                                .then((response) => {
                                    if (response.data.error != null) {
                                        this.setState({ submitting: false, error: response.data });
                                    } else {
                                        this.setState({ submitting: false, error: null, sucesses: text("local.atualizado-sucesso") });
                                    }
                                    window.scrollTo(0, 0)
                                    this.props.onSubmit()
                                })
                                .catch((error) => {
                                    var mensagemErro = text("formularios.erro");
                                    if (error.response && error.response.data.message) {
                                        mensagemErro = error.response.data.message;
                                    } else if (error.request) {
                                        mensagemErro = text("formularios.erro_request")
                                    }
                                    window.scrollTo(0, 0)
                                    this.setState({
                                        submitting: false,
                                        error: mensagemErro
                                    });
                                });
                        } else { // novo item
                            api.post('/hidra/locais',
                                {
                                    "nome": values.nome,
                                    "sigla": values.sigla,
                                    "tipo": this.state.tipo,
                                    "pai": this.state.pai ? this.state.pai : null,
                                    "lat": !this.state.poligono ? this.getLatMarker(posicoes) : this.getLat(posicoes),
                                    "lng": !this.state.poligono ? this.getLngMarker(posicoes) : this.getLng(posicoes),
                                    "observacoes": values.observacoes
                                }
                            )
                                .then((response) => {
                                    if (response.data.error != null) {
                                        this.setState({ submitting: false, error: response.data });
                                        window.scrollTo(0, 0)
                                    } else {
                                        this.setState({ submitting: false, error: null, sucesses: text("local.criado-sucesso"), redirect: response.data.id });
                                    }
                                })
                                .catch((error) => {
                                    var mensagemErro = text("formularios.erro");
                                    if (error.response && error.response.data.message) {
                                        mensagemErro = error.response.data.message;
                                    } else if (error.request) {
                                        mensagemErro = text("formularios.erro_request")
                                    }
                                    window.scrollTo(0, 0)
                                    this.setState({
                                        submitting: false,
                                        error: mensagemErro
                                    });
                                });
                        }
                    }}

                    render={({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (

                            <Form
                                onSubmit={handleSubmit}
                                loading={loading}
                            >
                                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                                <Form.Field required style={{ width: '500px' }}>
                                    <label>{text("local.nome")}</label>
                                    <Form.Input
                                        placeholder={text("local.nome")}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nome}
                                        name="nome"
                                    />
                                </Form.Field>

                                <Form.Field style={{ width: '500px' }}>
                                    <label>{text("local.pai")}</label>
                                    <Dropdown
                                        clearable
                                        style={{ zIndex: "999" }}
                                        placeholder={text("local.pai-descricao")}
                                        onChange={this.handleChangePai}
                                        value={this.state.pai}
                                        name="pai"
                                        search
                                        selection
                                        options={this.state.locaisPai}
                                    />
                                </Form.Field>

                                <Form.Field required style={{ width: '200px' }}>
                                    <label>{text("local.tipo")}</label>
                                    <Dropdown
                                        style={{ zIndex: "998" }}
                                        placeholder={text("local.tipo-descricao")}
                                        onChange={this.handleChangePai}
                                        value={this.state.tipo}
                                        name="tipo"
                                        search
                                        selection
                                        options={this.state.tipos}
                                    />
                                </Form.Field>

                                <Form.Field required style={{ width: '200px' }}>
                                    <label>{text("local.sigla")}</label>
                                    <Form.Input
                                        placeholder={text("local.sigla")}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.sigla}
                                        name="sigla"
                                    />
                                </Form.Field>

                                <Grid columns={2} style={{paddingBottom:'5px'}}>
                                    <Grid.Column >
                                        <label><b>{text("local.posicao")}</b></label>&nbsp;
                                        <Popup
                                            trigger={<Icon color='grey' size="large" name='question circle' />}
                                            content={text("local.ajuda-posicao")}
                                            style={{ opacity: 0.7 }}
                                            position='right center'
                                            inverted
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Form.Checkbox
                                            toggle
                                            label={text("local.poligono")}
                                            checked={this.state.poligono}
                                            onChange={this.onChangePoligono.bind(this)}
                                            name="poligono"
                                        />
                                    </Grid.Column>
                                </Grid>

                                <Mapa
                                    show={true}
                                    ref={this._mapa}
                                    title={text("local.posicao")}
                                    position={!poligono && posicoes.length === 1 ? [this.getLat(posicoes), this.getLng(posicoes)] : !poligono && posicoes.length > 1 ? [this.getLatMarker(posicoes), this.getLngMarker(posicoes)] : item && poligono ? [this.getLatMarker(posicoes), this.getLngMarker(posicoes)] : null}
                                    center={posicoes}
                                    color={this.state.cor}
                                    zoom={13}
                                    poligono={this.state.poligono}
                                    onHandlePoly={(latlng) => this.onHandlePoly(latlng)}
                                    onHandleClick={(latlng) => this.onHandleClick(latlng)}
                                ></ Mapa>

                                <br />
                                <Form.Field>
                                    <label> {text("local.observacoes")}</label>
                                    <Form.TextArea
                                        placeholder={text("local.observacoes")}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.observacoes}
                                        name="observacoes"
                                    />
                                </Form.Field >
                                <Divider />
                                <br />
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Button
                                            icon labelPosition='left'
                                            size='large'
                                            as={Link}
                                            to="/admin/locais"
                                            disabled={loading}
                                        >
                                            <Icon name={'angle left'} /> {text("formularios.voltar")}
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            primary
                                            loading={submitting}
                                            type='submit'
                                            icon labelPosition='right'
                                            size="big"
                                            disabled={loading || submitting}
                                        >
                                            <Icon name='save' /> {text("formularios.salvar")}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        )
                    }
                />

            </Container >
        );
    }

}