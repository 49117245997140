import React, { Component } from 'react';

import {
    Dropdown,
    Icon
} from 'semantic-ui-react';

import { jwtSubject } from 'services/auth';
import { Link } from 'react-router-dom'
import { text } from 'services/locales'
import api from 'services/api';

export default class UserIcon extends Component {

    state = {
        username: ""
    }

    componentDidMount() {

        const user = jwtSubject();

        this.setState({ username: user })

        api.get("/hidra/usuarios/login/" + user)
            .then(response => {
                var username

                if (response.data.nomeExibicao)
                    username = response.data.nomeExibicao
                else
                    username = response.data.nome

                this.setState({
                    username: username
                }
                )
            })
    }

    render() {

        const { showText } = this.props;
        const { username } = this.state

        return (
            <>
                <Dropdown
                    item
                    style={{ zIndex: "999" }}
                    trigger={
                        <>
                            <Icon.Group>
                                <Icon name='user' />
                            </Icon.Group>
                            {showText ? (<>&nbsp;{username.length > 30 ? (username.substring(0, 30) + "...") : username}</>) : null}
                        </>
                    }
                    title={text("usuario.meu-perfil") + " (" + username + ")"}
                >
                    <Dropdown.Menu >
                        <Dropdown.Item icon="user circle" text={text("menu.perfil")} as={Link} to='/perfil' />
                        <Dropdown.Item icon="edit" text={text("menu.meus-dados")} as={Link} to='/meus-dados' />
                        <Dropdown.Item icon="sign-out alternate" text={text("menu.logout")} as={Link} to='/logout?reason=user' />
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }
}