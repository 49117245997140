import React from 'react';

import { Menu, Image, Icon, Message } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import Routes from './routes';

import Ping from 'components/Ping';
import api from "services/api";
import UserIcon from 'components/UserIcon';
import WebsocketClient from 'components/WebsocketClient';
import Chat from 'components/Chat';
import Eventos from 'components/Eventos';
import Help from 'components/Help'
import AppDownload from 'components/AppDownload'
import { hasWebglSupport } from 'util/functions'

import { isAuthenticated, isAdmin, tokenExpSeconds, getRenewToken, login } from 'services/auth';
import { text } from 'services/locales'
import { notificar } from 'components/Notificador';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import "./styles.css"

const webglSupport = hasWebglSupport();

class DafaultLayout extends React.Component {

    state = {
        isMobileDevice: false,
        busca: null
    }

    constructor(props) {
        super(props);
        this._routes = React.createRef();
        this._componenteChat = React.createRef();
        this._componenteEventos = React.createRef();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        clearInterval(this.intervalAuthenticated);
        clearInterval(this.intervalRenewToken);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        this.handleWindowSizeChange();

        this.intervalAuthenticated = setInterval(() => this.handleAuthenticated(), 5000);
        this.handleAuthenticated();
    }

    handleWindowSizeChange = () => {
        const isMobileDevice = window.innerWidth < 500;
        const { sidebarVisible } = this.state;
        if (isMobileDevice && sidebarVisible) {
            this.handleSidebar();
        }
        this.setState({
            isMobileDevice: isMobileDevice
        })
    };

    handleAuthenticated = () => {
        if (!isAuthenticated())//Se o token de acesso esta vencido ou inválido
            this.handleRenewToken(true);//Tento renovar o token de acesso
        else  //Porém, se o token de acesso é válido
            if (tokenExpSeconds() < 300) //Verifico se o mesmo vence em menos de 5 minutos
                this.handleRenewToken(false); //Tento renovar o token de acesso
    }

    handleRenewToken = (redirect) => {
        if (getRenewToken()) {
            const axiosConfig = {
                headers: {
                    'token': getRenewToken(),
                    'removeAuthorization': true
                }
            };
            api.post("/token/renew", {}, axiosConfig)
                .then(response => {
                    if (response.data.token != null) {
                        login(response.data.token, response.data.renewToken);
                        if (redirect)
                            document.location.reload(true);
                    } else {
                        this.redirectToLogout()
                    }
                })
                .catch(error => {
                    var mensagemErro = "Erro ao renovar token";
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    }
                    console.log(mensagemErro)
                    this.redirectToLogout()
                });
        } else {
            this.redirectToLogout()
        }
    }

    redirectToLogout() {
        window.location.href = "/siscare/logout?reason=auto";
    }

    handleNewWebsocketMessage(topic, msg) {

        if (this._routes && this._routes.current)
            this._routes.current.handleNewWebsocketMessage(topic, msg);

        if (topic.includes("eventos")) {
            if (this._componenteEventos && this._componenteEventos.current)
                this._componenteEventos.current.handleRX(msg);
        } else if (topic.includes("chat")) {
            if (this._componenteChat && this._componenteChat.current)
                this._componenteChat.current.handleRX(msg);
        } else if (topic.includes("/hidra/suporte")) {
            this.handleMensagemSuporteWS(topic, msg);
        }
    }

    handleWebsocketStateChange(state) {
        if (this._componenteEventos && this._componenteEventos.current)
            this._componenteEventos.current.handleStateChange(state);
        if (this._componenteChat && this._componenteChat.current)
            this._componenteChat.current.handleStateChange(state);
    }

    handleMensagemSuporteWS = (topic, msg) => {
        switch (msg.acao) {
            case 'msg':
                var notificou = notificar(text("geral.suporte"), msg.mensagem, process.env.PUBLIC_URL + '/images/siscare-icon.png', true)
                if (!notificou)//Se não notificou, provavelmente por conta da falta de permissão do usuário no seu navegador, notifico dentro da pagina
                    NotificationManager.info(msg.mensagem, `${process.env.REACT_APP_NAME} - ${text("geral.suporte")}`, 5000);
                break;
            case 'update':
                NotificationManager.warning(text("geral.atualizacao-sistema-descricao"), `${process.env.REACT_APP_NAME} - ${text("geral.atualizacao-sistema-titulo")}`, 0);
                break;
            default:
                return;
        }
    }

    render() {

        const { isMobileDevice } = this.state;

        if (!isAuthenticated()) return null;

        return (
            <>
                <div className="menu-placeholder"></div>

                <Menu icon={isMobileDevice} inverted className="main-menu" style={{ margin: "0", borderRadius: "0" }} >

                    <Menu.Item header as={Link} to='/' title={process.env.REACT_APP_NAME}>
                        {isMobileDevice ?
                            (
                                <>
                                    <Image size='mini' src={process.env.PUBLIC_URL + '/images/sis-care-icon.png'} style={{ width: '25px' }} />
                                </>
                            )
                            :
                            (
                                <>
                                    <Image size='mini' src={process.env.PUBLIC_URL + '/images/sis-care-icon.png'} style={{ width: '25px', marginRight: '1em' }} />
                                    {process.env.REACT_APP_NAME}
                                </>
                            )
                        }
                    </Menu.Item>

                    <Menu.Item as={NavLink} to='/mapa' activeClassName="active" title={text("menu.mapa")}>
                        <Icon name="map" />
                        {isMobileDevice ? null : text("menu.mapa")}
                    </Menu.Item>
                    <Menu.Item as={NavLink} to='/eventos' activeClassName="active" title={text("menu.eventos")}>
                        <Icon name="alarm" />
                        {isMobileDevice ? null : text("menu.eventos")}
                    </Menu.Item>
                    <Menu.Item as={NavLink} to='/acompanhamentos' activeClassName="active" title={text("menu.acompanhamentos")}>
                        <Icon name="location arrow" />
                        {isMobileDevice ? null : text("menu.acompanhamentos")}
                    </Menu.Item>
                    <Menu.Item as={NavLink} to='/locais' activeClassName="active" title={text("menu.locais")}>
                        <Icon name="map marker alternate" />
                        {isMobileDevice ? null : text("menu.locais")}
                    </Menu.Item>

                    {isAdmin() &&
                        <Menu.Item as={NavLink} to='/admin' activeClassName="active" title={text("menu.admin")}>
                            <Icon name="unlock alternate" />
                            {isMobileDevice ? null : text("menu.admin")}
                        </Menu.Item>
                    }

                    <Menu.Menu position={isMobileDevice ? 'left' : "right"}>

                        <Chat ref={this._componenteChat} />

                        <Ping menu />

                        <Help />

                        <UserIcon showText={!isMobileDevice} />

                    </Menu.Menu>
                </Menu>

                <Routes ref={this._routes} />

                <Eventos ref={this._componenteEventos} />

                <WebsocketClient
                    onNewMessage={
                        (topic, msg) => { this.handleNewWebsocketMessage(topic, msg) }
                    }
                    onChangeState={
                        (state) => { this.handleWebsocketStateChange(state) }
                    }
                />

                {/**
                Chamo o componente de popup para baixar o dispositivo móvel sem o link para abrir, mas internamente ele vai abrir se o cliente estiver acessando de um dispositivo movel
                */}
                <AppDownload showLink={false} />

                <NotificationContainer style={{ bottom: '0' }} />

                {!webglSupport &&
                    <Message
                        color='red'
                        style={{ position: 'fixed', bottom: '0px', width: '100%' }}
                    >
                        {text("geral.sem-suporte-webgl")}
                    </Message>
                }
            </>
        );
    }
}

export default DafaultLayout;