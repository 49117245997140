import React, { Component } from 'react';

import api from "services/api";
import { login, logout, isAuthenticated } from 'services/auth';

import { Button, Form, Grid, Header, Image, Message, Divider } from 'semantic-ui-react';

import "./styles.css";

import { text, html } from 'services/locales'
import { getVersion, clear } from 'services/storage'

//import LocaleSwitcher from 'components/LocaleSwitcher'
import Ping from 'components/Ping';
import { Helmet } from 'react-helmet'
import { isDev } from 'util/functions'

export default class Login extends Component {

    state = {
        isMobileDevice: false,
        loading: false,
        username: "",
        password: "",
        error: "",
        trocarSenha: false
    }

    constructor(props) {
        super(props);
        logout();
        clear();
    }

    componentDidMount() {
        if (isDev()) {
            this.setState({ username: "admin", password: "1234" })
        }

        const authResult = new URLSearchParams(window.location.search);
        const logoutReason = authResult.get('logout');
        if (logoutReason)
            this.setState({ logoutReason: logoutReason })

        window.addEventListener('resize', this.handleWindowSizeChange);
        this.handleWindowSizeChange();

        this.intervalAuthenticated = setInterval(() => this.handleAuthenticated(), 2000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        clearInterval(this.intervalAuthenticated);
    }

    handleAuthenticated = () => {
        if (isAuthenticated()) {
            window.location.href = "/siscare";
        }
    }

    handleWindowSizeChange = () => {
        const isMobileDevice = window.innerWidth < 500;
        const { sidebarVisible } = this.state;
        if (isMobileDevice && sidebarVisible) {
            this.handleSidebar();
        }
        this.setState({
            isMobileDevice: isMobileDevice
        })
    };

    handleSignIn = e => {
        if (e) e.preventDefault();

        const { username, password } = this.state;
        this.setState({
            loading: true,
            error: null,
            logoutReason: null
        });
        if (!username || !password) {
            this.setState({
                loading: false,
                error: text("login.erro_form")
            });
        } else {
            const axiosConfig = {
                headers: {
                    'removeAuthorization': true
                }
            };
            api.post("/login", { username, password }, axiosConfig)
                .then(response => {
                    if (response.data.token != null) {
                        if (response.data.changePassword) {
                            this.setState({
                                loading: false,
                                trocarSenha: true,
                                token: response.data.token
                            });
                        } else {
                            login(response.data.token, response.data.renewToken);
                            if (isAuthenticated()) {
                                this.props.history.push("/");
                            } else {
                                this.props.history.push("/login");
                            }
                        }
                    } else {
                        this.setState({
                            loading: false,
                            error: response.data.message
                        });
                    }
                })
                .catch(error => {
                    var mensagemErro = text("login.erro");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    } else if (error.request) {
                        mensagemErro = text("login.erro_request")
                    }
                    this.setState({
                        loading: false,
                        error: mensagemErro
                    });
                });
        }
    }

    handleChangePassword = e => {
        if (e) e.preventDefault();

        const { password, passwordCh1, passwordCh2 } = this.state;
        this.setState({
            loading: true,
            error: null
        });

        if (!passwordCh1 || !passwordCh2) {
            this.setState({
                loading: false,
                error: text("login.erro_form_senha")
            });
        } else {

            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.state.token
                }
            };

            api.put('/hidra/usuarios/senha',
                {
                    "senhaAtual": password,
                    "senhaNova": passwordCh1,
                    "senhaNova2": passwordCh2
                },
                axiosConfig
            )
                .then((response) => {
                    if (response.data.error != null) {
                        this.setState({ loading: false, error: response.data });
                    } else {
                        this.setState(
                            { loading: false, error: null, password: passwordCh1 }
                            , () => {
                                this.handleSignIn()
                            }
                        );
                    }
                })
                .catch((error) => {
                    var mensagemErro = text("formularios.erro");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    } else if (error.request) {
                        mensagemErro = text("formularios.erro_request")
                    }
                    this.setState({
                        loading: false,
                        error: mensagemErro
                    });
                });
        }
    }

    renderLogoutReason = () => {
        switch (this.state.logoutReason) {
            case 'auto':
                return text("logout.reason-auto");
            case 'user':
                return text("logout.reason-user");
            case 'not-auth':
                return text("logout.reason-not-auth");
            case 'update':
                return text("logout.reason-update");
            default:
                return text("logout.encerrado");
        }
    }

    render() {
        const { isMobileDevice, loading, trocarSenha } = this.state;
        return (

            <div className="fundo-login" style={{
                backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/bg.png)",
            }}>

                <Helmet title={`Login ${text("geral.html-title")}`} />

                <Grid padded style={{ height: '100vh', width: '100vw' }} textAlign='center' verticalAlign='middle'>

                    <Grid.Column style={{ background: '#FFFFFF', width: isMobileDevice ? '100vw' : '400px', }}>

                        <Header as="h1" icon >

                            <Image src={process.env.PUBLIC_URL + '/images/sis-care-icon.png'} />

                            <Header.Content>
                                {process.env.REACT_APP_NAME}
                                <Header.Subheader style={{ fontSize: "12px" }}>
                                    {process.env.REACT_APP_DESCRIPTION}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>

                        {this.state.logoutReason ? (<Message warning onDismiss={e => this.setState({ logoutReason: null })}>{this.renderLogoutReason()}</Message>) : (<><br /><br /></>)}

                        {!trocarSenha &&
                            <Form autoComplete="off" loading={loading} size={isMobileDevice ? 'big' : 'small'}>

                                <Form.Field>
                                    <div class="ui left icon input">
                                        <input
                                            placeholder={text("login.login-placeholder")}
                                            name="login"
                                            autocomplete="new-password"
                                            value={this.state.username}
                                            onChange={e => this.setState({ username: e.target.value, error: null })}
                                        />
                                        <i aria-hidden="true" class="user icon"></i>
                                    </div>
                                </Form.Field>

                                <Form.Field>
                                    <div class="ui left icon input">
                                        <input
                                            placeholder={text("login.senha")}
                                            type='password'
                                            value={this.state.password}
                                            onChange={e => this.setState({ password: e.target.value, error: null })}
                                            name="password"
                                            autocomplete="new-password"
                                        />
                                        <i aria-hidden="true" class="lock icon"></i>
                                    </div>
                                </Form.Field>

                                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}

                                <Button
                                    size={isMobileDevice ? 'big' : 'large'}
                                    color='blue'
                                    fluid
                                    onClick={this.handleSignIn} >
                                    {text('login.bnt-login')}
                                </Button>

                            </Form>
                        }

                        {trocarSenha &&
                            <>

                                <Message attached color='yellow'>
                                    <Message.Header>{text('login.trocar-senha-titulo')}</Message.Header>
                                    <p>{text('login.trocar-senha-descricao')}. {text("usuario.senha-descricao")}.</p>
                                </Message>

                                <Form autoComplete="off" className='attached fluid segment' loading={loading} size={isMobileDevice ? 'big' : 'small'}>

                                    <Form.Field>
                                        <div class="ui left icon input">
                                            <input
                                                placeholder={text("usuario.senha-nova")}
                                                type='password'
                                                value={this.state.passwordCh1}
                                                onChange={e => this.setState({ passwordCh1: e.target.value, error: null })}
                                                name="passwordCh1"
                                                autocomplete="new-password"
                                            />
                                            <i aria-hidden="true" class="lock icon"></i>
                                        </div>
                                    </Form.Field>

                                    <Form.Field>
                                        <div class="ui left icon input">
                                            <input
                                                placeholder={text("usuario.senha-nova2")}
                                                type='password'
                                                value={this.state.passwordCh2}
                                                onChange={e => this.setState({ passwordCh2: e.target.value, error: null })}
                                                name="passwordCh2"
                                                autocomplete="new-password"
                                            />
                                            <i aria-hidden="true" class="lock icon"></i>
                                        </div>
                                    </Form.Field>

                                    {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}

                                    <Button
                                        size={isMobileDevice ? 'big' : 'large'}
                                        color='blue'
                                        fluid
                                        onClick={this.handleChangePassword} >
                                        {text('login.trocar-senha')}
                                    </Button>

                                </Form>
                            </>
                        }
                        <br />

                        <Divider />

                        <small>
                            <b>Powered by &copy;Hidra</b> <Ping />   {/* <LocaleSwitcher /> */}
                            <br />
                            {html("geral.direitos1")}.
                            <br />
                            {html("geral.direitos2")}.
                            <br />
                            <em>{html("geral.versao", { v: getVersion() })}</em>
                        </small>

                    </Grid.Column>

                </Grid>

                <div className="attribute">
                    <a href="https://unsplash.com/@vimarethomas?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" rel="noopener noreferrer" target="_blank">Thomas Vimare</a> - <a href="https://unsplash.com" rel="noopener noreferrer" target="_blank">Unsplash</a>
                </div>

            </div>
        );
    }
}