import React, { Component } from 'react';

import { LayerGroup, Circle, Tooltip } from "react-leaflet";
import api from 'services/api';
import 'leaflet/dist/leaflet.css';
import { comparaBounds } from 'util/functions'
import {text} from 'services/locales'

var updated= -1;

export default class CamadaAreas extends Component {

    state = {
        circulares: []
    }

    componentDidMount() {
        this.handleRefreshObjetos();
        this.intervalObjetos = setInterval(() => this.handleRefresh(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalObjetos);
    }

      //Controla o tempo de atualização da camada
    //Sempre marca o tempo da ultima atualização, 
    //Deste modo, mesmo se houver uma atualização da camada de forma manual, a frequencia será respeitada
    handleRefresh() {
        const { frequencia } = this.props;
        
        const now = new Date().getTime();

        if (now - updated > frequencia) {
            this.handleRefreshObjetos()
        }
    }

    componentDidUpdate(prevProps) {
        var update = false;

        if (!comparaBounds(prevProps.bounds, this.props.bounds)) {
            update = true;
        }

        if (prevProps.filtro !== this.props.filtro) {
            update = true;
        }

        if (update) {
            this.handleRefreshObjetos();
        }
    }

    handleRefreshObjetos() {
        updated = new Date().getTime()

        const { bounds, filtro, autoUpdateDisabled } = this.props

        if (autoUpdateDisabled)//Não atualizo esta camada se este parametro estiver ativo, ou seja a atualização automatica das camadas foi desabilitada
            return false;

        if (bounds === null || bounds.latMin === null || bounds.lngMin === null || bounds.latMax === null || bounds.lngMax === null) {
            return false;
        }

        var url = "/hidra/areas/circular/mapa?latMin=" + bounds.latMin + "&lngMin=" + bounds.lngMin + "&latMax=" + bounds.latMax + "&lngMax=" + bounds.lngMax;

        if (filtro) {
            if (filtro.areas) {
                if (!filtro.areas.includes('circular')) {
                    this.setState({ circulares: [] });
                    return;
                }
            }
        }

        api.get(url)
            .then(response => {
                this.setState(
                    {
                        circulares: response.data
                    }
                )
            })
            .catch(() => {
                this.setState({
                    circulares: []
                });
            })

    }

    handleClick = o => {
        this.props.onClick(o.target.options.id, "area-circular")
    }

    renderAreaCircular(item) {
        const { objetoSelecionado } = this.props
        const selecionado = (objetoSelecionado && objetoSelecionado.tipo === 'area-circular' && objetoSelecionado.id === item.id) ? true : false

        return (
            <Circle
                key={item.id}
                id={item.id}
                center={[item.lat, item.lng]}
                fillColor={'none'}
                color={item.cor ? item.cor : 'gray'}
                radius={item.raio}
                opacity={selecionado ? 1 : 0.5}
                zIndexOffset={990}
                onClick={this.handleClick}
            >
                <Tooltip className="tooltip" direction="center" opacity={0.8} >
                    <b>{item.nome}</b>
                    <br/>
                    <i>{text("areas.area-circular")}</i>
                </Tooltip>
            </Circle>
        )
    }

    render() {
        const { circulares } = this.state;
        return (

            <LayerGroup>
                {circulares.map((item) =>
                    this.renderAreaCircular(item)
                )}
            </LayerGroup>
        )
    }

}