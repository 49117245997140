import React from "react";

import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Main from 'pages/app/main';
import Mapa from 'pages/app/cenarioTatico';
import Error from 'pages/app/error';
import { Header, Icon, Modal } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { isAuthenticated } from 'services/auth';

export default class MobileApp extends React.Component {

  state = {
    isAuthenticated: false
  }

  componentDidMount() {
    this.intervalAuthenticated = setInterval(() => this.handleAuthenticated(), 2000);
    this.handleAuthenticated();
  }

  componentWillUnmount() {
    clearInterval(this.intervalAuthenticated);
  }

  handleAuthenticated = () => {
    this.setState({ isAuthenticated: isAuthenticated() })
  }

  render() {

    const { isAuthenticated } = this.state;

    return (

      <div className="App">
        <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
          <Switch>
            <Route exact path="/app" component={Main} />
            <Route exact path="/app/mapa" component={Mapa} />
            <Route path="*" render={props => <Error {...props} footer={false} />} />
          </Switch>

          <Modal
            basic
            open={!isAuthenticated}
            size='small'
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Header icon>
              <Icon name='user times' />
                    Usuário não autenticado
                    <br /><br />
              <Link
                to="/app"
              >
                Tentar novamente
                    </Link>
            </Header>
          </Modal>

        </BrowserRouter>



      </div>
    );
  }
}