import React, { Component } from 'react';

import { Header, Container, Tab } from 'semantic-ui-react'
import api from 'services/api';
import { text } from 'services/locales';
import { Helmet } from 'react-helmet'

import { jwtSubject } from 'services/auth'

import CadastroContato from 'components/objetos/entidades/cadastroContato'
import AlterarSenha from 'pages/usuarios/alterar-senha'

export default class UsuarioMeusDados extends Component {

    state = {
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true,
    };

    componentDidMount() {
        this.loadItens()
    }

    loadItens = () => {

        this.setState({ loading: true });

        api.get("/hidra/usuarios/login/" + jwtSubject())
            .then(response => {
                this.setState({
                    id: response.data.id,
                    item: response.data,
                    loading: false
                })
            })
            .catch(error => {
                var mensagemErro = text("formularios.erro_request");
                if (error.response && error.response.data) {
                    mensagemErro = error.response.data.message;
                }
                this.setState({
                    sending: false,
                    error: mensagemErro
                });
            })


    }

    handleRefresh = () => {
        this.loadItens();
    }

    render() {

        const { id } = this.state;

        const panes = [
            {
                menuItem: { key: 'alterar-senha', icon: 'lock', content: text("usuario.alterar-senha") },
                render: () =>
                    <Tab.Pane>
                        <AlterarSenha
                            id={id}
                        />
                    </Tab.Pane>
            },
            {
                menuItem: { key: 'contato', icon: 'phone', content: text("entidade.contatos") },
                render: () =>
                    <Tab.Pane>
                        <CadastroContato
                            id={id}
                            onSubmit={() => this.loadItens()}
                        />
                    </Tab.Pane>,
            },
        ]

        return (
            <Container style={{ padding: '2em', backgroundColor: "#fff" }}>

                <Helmet title={`${text("usuario.alterar-meus-dados")} ${text("geral.html-title")}`} />

                <Header as="h1">
                    {text("usuario.alterar-meus-dados")}
                </Header>

                <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />

            </Container >
        );
    }

}