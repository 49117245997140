import { Icon } from "leaflet"

const inimigo = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/inimigo.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

const amigo = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/amigo.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});
const inimigoP = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/inimigoParado.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

const amigoP = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/amigoParado.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

const desconhecido = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/desconhecido.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

const desconhecidoP = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/desconhecidoParado.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

const neutro = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/neutro.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

const neutroP = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/neutroParado.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

const suspeito = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/suspeito.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

const suspeitoP = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/acos/suspeitoParado.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

const aviao = new Icon({
    iconUrl: process.env.PUBLIC_URL + "/images/aviao/desconhecido.png",
    popupAnchor: [0, -10],
    iconSize: [18, 18]
});

export const getStyle = (velocidade, hostilidade, origem) => {

    if (origem === "ADSB") {
        return aviao
    }

    switch (hostilidade) {
        case 'HOSTIL':
        case 'H':
            if (velocidade > 0)
                return inimigo;
            else
                return inimigoP;
        case 'AMIGO':
        case 'F':
            if (velocidade > 0)
                return amigo;
            else
                return amigoP;
        case 'SUSPEITO':
        case 'S':
            if (velocidade > 0)
                return suspeito;
            else
                return suspeitoP;
        case 'NEUTRO':
        case 'N':
            if (velocidade > 0)
                return neutro;
            else
                return neutroP;
        default:
            if (velocidade > 0)
                return desconhecido;
            else
                return desconhecidoP;
    }
};