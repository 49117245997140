import React, { Component } from 'react';

import {
    Popup,
    Placeholder,
    Button,
    Icon
} from 'semantic-ui-react';

import api from 'services/api';
import { Link } from 'react-router-dom'
import { text } from 'services/locales'
import { convertDMS } from 'util/functions'

export default class LocalLink extends Component {

    state = {
        local: null
    }

    renderContent() {
        const { local } = this.state;

        if (local) {
            return (
                <>
                    <b>{local.nome}</b><br />

                    <b>{text("local.sigla")}:</b>&nbsp;{local.sigla} <br />
                    <b>{text("local.tipo")}:</b>&nbsp;{local.tipo.nome} <br />
                    <b>{text("local.posicao")}:</b>&nbsp;{convertDMS(local.lat, local.lng)}
                    <Button
                        size="tiny"
                        fluid
                        as={Link}
                        to={{
                            pathname: '/mapa',
                            state: {
                                goto: [local.lat, local.lng]
                            }
                        }}>
                        <Icon name="map marker alternate" />
                        {text("cenario-tatico.ver-no-mapa")}
                    </Button>
                </>
            )
        } else {
            return (
                <>
                    <Placeholder style={{ width: 150 }}>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder>
                </>
            )
        }
    }

    async loadInfo() {
        const { id } = this.props;
        const response = await api.get("/hidra/locais/" + id);
        this.setState({ local: response.data });
    }

    handleOpen = () => {
        this.loadInfo()
    }

    render() {
        const { nome, id, disabled } = this.props;

        return (
            <>
                <Popup
                    trigger={
                        <Link className={!disabled ? "link-prev" : ""} to={"/local/" + id}>{nome}</Link>
                    }
                    disabled={disabled}
                    hoverable
                    content={this.renderContent()}
                    onOpen={this.handleOpen}
                    mouseEnterDelay={500}
                    mouseLeaveDelay={500}
                />
            </>
        )
    }
}