import React, { Component } from 'react';

import { Button, Icon, Header, Table, Message, Grid, Confirm, Container, Segment, Form } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import api from 'services/api';
import { text, html } from 'services/locales';
import { Helmet } from 'react-helmet'

export default class AdminLocalTabela extends Component {

  state = {
    itens: [],
    busca: null,
    loading: true,
    error: null,
    sucesses: null,
    pageNumber: 0,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    lastPage: true,
    firstPage: true,
    sort: "nome",
    sortDirection: 'ascending',
    showRemoveDialog: false,
    itemRemove: null,
    optionsTiposLocais: [],
    filtro: {
      tipos: []
    }
  }

  componentDidMount() {
    this.loadItens();
    this.loadTipoLocais();

    if (this.props.location.state != null) {
      this.setState({ sucesses: this.props.location.state.sucesses })
    }
  }

  loadTipoLocais() {
    api.get("hidra/locais/tipos?size=999&sort=nome,asc")
      .then((response) => {
        const optionsTiposLocais = response.data.content.map((item) => ({
          key: item.id,
          text: item.nome,
          value: item.id,
        }))
        const filtroTipos = response.data.content.map((item) => (
          item.id
        ))
        const { filtro } = this.state;
        this.setState(
          {
            optionsTiposLocais: optionsTiposLocais,
            filtro:
            {
              ...filtro,
              tipos: filtroTipos
            }
          }
        );
      })
  }

  loadItens = (page = 0, busca = null) => {

    if (this.state.sucesses === text("local.excluido-sucesso")) {
      this.setState({ loading: true, error: null });
    } else {
      this.setState({ loading: true, error: null, sucesses: null });
    }

    let url = '/hidra/locais?page=' + page;
    if (busca != null) {
      url += '&q=' + busca
    }

    const { sort, sortDirection, filtro } = this.state;

    if (sort != null) {
      url += '&sort=' + sort + "," + (sortDirection === 'ascending' ? 'asc' : 'desc')
    }

    if (filtro) {
      if (filtro.tipos) {
        url += "&t="
        filtro.tipos.forEach((item, i, arr) => {
          url += item
          if (arr.length - 1 !== i)
            url += ","
        });
      }
    }
    api.get(url)
      .then((response) => {
        if (response.data.content != null) {
          this.setState({
            loading: false,
            itens: response.data.content,
            totalPages: response.data.totalPages,
            totalElements: response.data.totalElements,
            lastPage: response.data.last,
            firstPage: response.data.first,
            pageNumber: response.data.number,
            numberOfElements: response.data.numberOfElements
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          itens: [],
          error: text("formularios.erro_request")
        });
      });
  };


  handleSearch = e => {
    e.preventDefault();
    const { busca } = this.state;
    this.loadItens(0, busca);
  }

  handleNextPage = () => {
    const { pageNumber, lastPage, busca } = this.state;
    if (lastPage === true) return;

    const novoPageNumber = pageNumber + 1;
    this.loadItens(novoPageNumber, busca);
  }
  handlePreviousPage = () => {
    const { pageNumber, firstPage, busca } = this.state;
    if (firstPage === true) return;

    const novoPageNumber = pageNumber - 1;
    this.loadItens(novoPageNumber, busca);
  }

  handleRefresh = () => {
    const { pageNumber, busca } = this.state
    this.loadItens(pageNumber, busca);
  }

  handleSort = clickedColumn => () => {
    const { busca, sort, sortDirection } = this.state

    this.setState({
      sortDirection: clickedColumn === sort ? (sortDirection === 'ascending' ? 'descending' : 'ascending') : 'ascending', //Se for a mesma coluna clicada, inverte a ordenação, caso contrário, define como ascendente 
      sort: clickedColumn
    }, () => {
      this.loadItens(0, busca);
    })
  }

  handleRemove = item => () => {
    this.setState({ showRemoveDialog: true, itemRemove: item, error: null, sucesses: null })
  }

  handleConfirmRemove = () => {
    this.setState({ showRemoveDialog: false, loading: true })
    const { busca, itemRemove } = this.state

    api.delete('hidra/locais/' + itemRemove.id)
      .then((response) => {
        this.setState({
          loading: false,
          itemRemove: null,
          sucesses: text("local.excluido-sucesso"),
        });

        this.loadItens(0, busca);
      })
      .catch((error) => {
        let errorMsg = text("tabelas.erro-remover");

        if (error.response.data && error.response.data.message && error.response.data.status && error.response.data.status >= 400 && error.response.data.status < 500) {
          errorMsg = error.response.data.message
        }

        this.setState({
          loading: false,
          itemRemove: null,
          error: errorMsg
        });
      });
  }

  handleCancelRemove = () => {
    this.setState({ showRemoveDialog: false, itemRemove: null })
  }
  handleFiltro = () => {
    const { filtro } = this.state;
    this.setState({
      filtro:
      {
        ...filtro,
        ativo: !filtro.ativo
      }
    }
    )
  }

  handleFiltroTiposLocais = (valor) => {
    const { filtro } = this.state;
    var temp = filtro.tipos;

    if (temp.includes(valor)) {
      if (temp.length === 1) return; //Não permite desmarcar todos
      temp.splice(temp.indexOf(valor), 1);
    } else {
      temp.push(valor)
    }

    this.setState(
      {
        filtro:
        {
          ...filtro,
          tipos: temp
        }
      },
      () => {
        this.loadItens(0);
      }
    )
  }

  render() {

    const { filtro, loading, itens, totalPages, totalElements, pageNumber, lastPage, firstPage, numberOfElements, sort, sortDirection } = this.state;

    return (
      <Container style={{ padding: '2em' }}>

        <Helmet title={`${text("menu.locais")} ${text("geral.html-title")}`} />

        <Grid stackable columns={2}>
          <Grid.Column>
            <Header as="h1">{text("local.locais")}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">

            <Button icon labelPosition='right' onClick={this.handleFiltro} toggle active={filtro.ativo}>
              <Icon name='filter' />
              {text("formularios.filtros")}
            </Button>

            <Button
              floated='right'
              icon labelPosition='right'
              color='blue'
              as={Link}
              to="/admin/novo-local"
            >
              <Icon name='plus' /> {text("tabelas.novo")}
            </Button>
          </Grid.Column>
        </Grid>

        {filtro.ativo &&
          <>
            <Header as='h5' attached='top'>
              {text("formularios.filtros")}
              <Button circular basic icon onClick={this.handleRefresh} loading={loading} floated="right">
                <Icon name='refresh' />
              </Button>
            </Header>
            <Segment raised attached>

              <Grid divided columns={2} >
                <Grid.Column>
                  <b>{text("local.tipo")}</b>

                  <Form.Group grouped className="filter-checkbox-grid">
                    {this.state.optionsTiposLocais.map(item =>
                      <Form.Checkbox
                        key={item.value}
                        value={item.value}
                        label={item.text}
                        checked={filtro.tipos.includes(item.value)}
                        onChange={(e, { value }) => this.handleFiltroTiposLocais(value)}
                      />
                    )}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  <b>{text("tabelas.buscar")}</b>
                  <Form className="form">
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid icon='search'
                        iconPosition='left'
                        name="busca"
                        placeholder={text('local.buscar')}
                        value={this.state.busca ? this.state.busca : ""}
                        onChange={e => this.setState({ busca: e.target.value })}
                      />
                      <Button color='blue' size='large' onClick={this.handleSearch} >
                        {text('tabelas.buscar')}
                      </Button>

                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid>
            </Segment>
          </>
        }

        <Confirm
          header={text("tabelas.dialogo-remover")}
          content={this.state.itemRemove ? (this.state.itemRemove.nome + " (" + this.state.itemRemove.tipo.nome + ")") : ''}
          open={this.state.showRemoveDialog}
          onCancel={this.handleCancelRemove}
          onConfirm={this.handleConfirmRemove}
          cancelButton={text("geral.nao")}
          confirmButton={text("geral.sim")}
        />

        {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
        {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

        <Table celled striped selectable sortable >
          <Table.Header>
            <Table.Row>

              <Table.HeaderCell
                sorted={sort === 'nome' ? sortDirection : null}
                onClick={this.handleSort('nome')}
              >
                {text("local.nome")}</Table.HeaderCell>

              <Table.HeaderCell
                sorted={sort === 'pai.nome' ? sortDirection : null}
                onClick={this.handleSort('pai.nome')}
              >
                {text("local.pai")}</Table.HeaderCell>

              <Table.HeaderCell
                collapsing
                sorted={sort === 'sigla' ? sortDirection : null}
                onClick={this.handleSort('sigla')}>
                {text("local.sigla")}</Table.HeaderCell>

              <Table.HeaderCell
                sorted={sort === 'tipo.nome' ? sortDirection : null}
                onClick={this.handleSort('tipo.nome')}>
                {text("local.tipo")}</Table.HeaderCell>

              <Table.HeaderCell>
                {text("local.forma")}</Table.HeaderCell>

              <Table.HeaderCell collapsing>{text("tabelas.acoes")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              itens.map((item) =>
                <Table.Row key={item.id} >
                  <Table.Cell>
                    <Link
                      to={"/local/" + item.id}
                    >
                      {item.nome}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {item.pai ?
                      (
                        <Link
                          to={"/local/" + item.pai.id}
                          title={item.pai.nome}
                        >
                          {item.pai.sigla}
                        </Link>
                      )
                      :
                      (<>-</>)
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {item.sigla}
                  </Table.Cell>
                  <Table.Cell>
                    {item.tipo.nome}
                  </Table.Cell>
                  <Table.Cell>
                    {item.poligono ? text("local.area") : text("local.ponto")}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Button.Group size='mini'>
                      <Button animated as={Link} to={"/admin/local/" + (item.id)} color="green" >
                        <Button.Content hidden>{text("tabelas.editar")}</Button.Content>
                        <Button.Content visible><Icon name='edit outline' /></Button.Content>
                      </Button>
                      <Button animated onClick={this.handleRemove(item)} color="red">
                        <Button.Content hidden >{text("tabelas.remover")}</Button.Content>
                        <Button.Content visible ><Icon name='trash alternate' /></Button.Content>
                      </Button>
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              )
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell >
                {html("tabelas.info", { pageElements: numberOfElements, totalElements: totalElements, page: pageNumber + 1, pages: totalPages })}
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='5'>
                <Button
                  disabled={lastPage}
                  floated='right'
                  icon labelPosition='right'
                  size='medium'
                  onClick={this.handleNextPage}
                >
                  <Icon name='angle right' />  {text("tabelas.proxima-pagina")}
                </Button>
                <Button
                  disabled={firstPage}
                  floated='right'
                  icon labelPosition='left'
                  size='medium'
                  onClick={this.handlePreviousPage}
                >
                  <Icon name='angle left' />  {text("tabelas.pagina-anterior")}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>

        </Table>
      </Container>
    )
  }

}