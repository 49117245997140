import React, { Component } from 'react';

import { Header, Container } from 'semantic-ui-react'

import { text } from 'services/locales';
import { Helmet } from 'react-helmet'

import CadastroUsuario from 'components/objetos/usuarios/cadastro'

export default class AdminNovoUsuarioFormulario extends Component {

    state = {
    };

    render() {

        return (
            <Container style={{ padding: '2em', backgroundColor: "#fff" }}>

                <Helmet title={`${text("usuario.novo-usuario")} ${text("geral.html-title")}`} />

                <Header as="h1">
                    {text("usuario.novo-usuario")}
                </Header>

                <CadastroUsuario />

                <br />

            </Container>
        );
    }

}