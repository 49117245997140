import React, { Component } from 'react';

import { Header, Container, Image } from 'semantic-ui-react'
import Footer from 'components/footer'
import "./styles.css";
import { text, html } from 'services/locales'
import { Helmet } from 'react-helmet'
import { getVersion } from 'services/storage'

export default class Sobre extends Component {


    render() {

        return (

            <Container style={{ padding: '2em', backgroundColor: "#fff" }}>

                <Helmet title={`${text("menu.sobre")} ${text("geral.html-title")}`} />

                <Container >
                    <Header as="h1">{process.env.REACT_APP_NAME}
                        <Header.Subheader>{process.env.REACT_APP_DESCRIPTION}. <small>{html("geral.versao", { v: getVersion() })}</small></Header.Subheader>
                    </Header>

                    <br />

                    <Image src={process.env.PUBLIC_URL + '/images/sis-care-logo.png'} floated='right' size="medium" />

                    <p>
                        Em consonância à Estratégia Nacional de Defesa (END), a Marinha do Brasil (MB) considera prioritária a formação do conhecimento científico e o desenvolvimento de ativos que consideram a energia nuclear como cerne de sua base tecnológica. Nesse sentido, a MB vem desenvolvendo o projeto de construção do primeiro Submarino de Propulsão Nuclear Brasileiro (SN-BR), com vistas à formação de capital intelectual nas diversas áreas de conhecimento afins, bem como no aumento de poderio bélico essencial à garantia de soberania.
                    </p>
                    <p>
                        Considerando a magnitude do projeto e os riscos inerentes associados, o projeto do SN-BR tem sido conduzido pela MB conforme prerrogativas, protocolos e procedimentos internacionais que versam sobre a segurança e a prevenção de incidentes de natureza correlata. Assim sendo, visando suprir a avaliação contínua e crítica desse campo de trabalho, foi criada a Agência Naval de Segurança Nuclear e Qualidade (AgNSNQ), Organização Militar (OM) com a missão de atuar como órgão regulador e fiscalizador das atividades afetas à Segurança e ao Licenciamento Nucleares, de meios navais e instalações terrestres da MB, bem como atuar na supervisão de tarefas sujeitas aos critérios de qualidade na MB.
                    </p>

                    <p>
                        De posse dessa missão institucional, a AgNSNQ tem estabelecido as necessidades relativas a um sistema de informação que seja capaz de auxiliá-la em sua missão. A AgNSNQ vem definindo essas necessidades com base nas normas e procedimentos da International Atomic Energy Agency (IAEA), considerando, como requisitos de alto nível, a necessidade de prevenção de incidentes e a monitoração de situações consideradas de risco ou emergência.
                    </p>
                    <p>
                        Nesse contexto, a AgNSNQ solicitou ao Centro de Tecnologia da Marinha no Rio de Janeiro (CTMRJ) e sua OM subordinada, o Instituto de Pesquisas da Marinha (IPqM), uma solução computacional aderente a sua missão. O IPqM possui considerável experiência no desenvolvimento de sistemas, especialmente aqueles desenvolvidos com sua própria plataforma de desenvolvimento, o HIDRA. Assim, o objetivo desse documento é especificar os requisitos necessários para desenvolvimento de um sistema nomeado como Sistema do Centro de Acompanhamento de Respostas a Emergências (SIS-CARE), capaz de atender as necessidades da AgNSNQ com vistas à escalabilidade, a manutenibilidade e a integração com outros sistemas afins.
                    </p>



                    <Header as="h2">Hidra</Header>

                    <Image src={process.env.PUBLIC_URL + '/images/hidra_fundo_azul.png'} floated='right' size="medium" />

                    <p>
                        O Instituto de Pesquisas da Marinha (IPqM) têm desenvolvido diversos sistemas de diferentes propósitos ao longo dos seus anos. Tais sistemas, principalmente aqueles desenvolvidos pelo Grupo de Sistemas Digitais (GSD), tem em sua natureza uma grande parte relativa a códigos de programas cujo objetivo principal reside na implementação de regras específicas aos domínios de sistemas militares navais.
                    </p>
                    <p>
                        As soluções utilizadas em vários projetos foram aprimoradas pelos pesquisadores envolvidos em momentos e contextos diferentes. Ficou claro a necessidade de uma abordagem que orientasse os novos projetos sob uma ótica de componentização, pois o expertise obtido era perdido ou redundante pela falta comunicação.
                    </p>
                    <p>
                        A literatura de computação, razoavelmente jovem em comparação às outras ciências, aponta que o uso sistêmico de soluções padronizadas ajudam a alcançar três importantes requisitos: a manutenibilidade, a escalabilidade e a gestão do conhecimento.                        
                    </p>
                    <p>
                        Nesse contexto, o Centro de Integração de Sensores e Navegação Eletrônica (CISNE) foi pensado e concebido em um primeiro momento como uma plataforma para implantação de um sistema de auxilio à navegação. O rápido desenvolvimento (o ciclo de vida é baseado em metodologias ágeis) e a facilidade constatada para reação às mudanças do escopo do domínio levaram o uso da arquitetura de software do CISNE a se tornar um framework padrão do GSD para criação de sistemas Orientados a Objetos (OO) e aderentes a padrões de projeto. O nome desse framework de aplicações foi batizado como Hidra.                        
                    </p>
                    <p>
                        A solução encontrada para criação deste framework foi inspirada em soluções anteriores e hoje é mantida por programadores de outros projetos. O Hidra está fortemente baseada no consagrado trabalho realizado pelos autores Gamma et al.. A aplicação destes padrões constitui o cerne principal do modo de criação, tratamento e fluxo de objetos entre as diversas plataformas de operação do Hidra. Contudo, entendendo as particularidades dos sistemas da MB no que tange o desempenho e o tempo de resposta, o framework utilizou a obra como catálogo de soluções e não como um conjunto de implementações pré-fabricadas.                        
                    </p>
                    <p>
                        É importante ressaltar ainda que a implementação da arquitetura software do Hidra se utiliza também de programas, bibliotecas e algoritmos de código aberto em diversos módulos do sistema. Tais artefatos são escolhidos considerando não só a sua finalidade, mas também o sucesso obtido em seus fins e a sua longevidade programada e obtida até então na comunidade.
                    </p>

                    <Footer />
                </Container>

            </Container>
        )
    }
}