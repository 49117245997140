import React, { Component } from 'react';

import { Button, Icon, Header, Form, Accordion, Grid } from "semantic-ui-react"
import { text } from 'services/locales'
import api from 'services/api';
import { getFilterObject, setFilterObject } from 'services/storage'
import { optionsOrigens, optionsHostilidade, optionsAmbiente,  optionsAreas } from 'util/filterOptions'
import {Link} from 'react-router-dom'

export default class Filtro extends Component {

    state = {
        filtro: {
            index: -1,
            perdido: true,
            origens: [],
            hostilidade: [],
            ambiente: [],
            tiposLocal: [],
            areas: [],
            fontesMeteorologicas: []
        },
        optionsTiposLocal: [],
        optionsFontesMeteorologicas: []
    }

    componentDidMount() {
        var filter = getFilterObject()

        if (filter) {
            this.loadTipoLocais(false)
            this.loadFontesMeteorologicas(false)
            const { filtro } = this.state;
            this.setState({ filtro: { ...filtro, ...filter } })
        } else {
            this.loadTipoLocais(true)
            this.loadFontesMeteorologicas(true)
            this.defaultState();
        }
    }

    loadTipoLocais(defaultState) {
        api.get("/hidra/locais/tipos?size=999&sort=nome,asc")
            .then((response) => {
                const optionsTiposLocal = response.data.content.map((item) => ({
                    key: item.id,
                    text: item.nome,
                    value: item.id,
                }))
                this.setState(
                    { optionsTiposLocal: optionsTiposLocal }
                    , () => {
                        if (defaultState) {//recarrego o default state para garantir que todos os tipos de locais estejam marcados
                            this.defaultState()
                        }
                    }
                );
            })
            .catch(() => { this.setState({ optionsTiposLocal: [] }) })
    }

    loadFontesMeteorologicas(defaultState) {
        api.get("/meteorologia/fontes/all")
            .then((response) => {
                const optionsFontesMeteorologicas = response.data
                    .sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))
                    .map((item) => ({
                        key: item.id,
                        text: item.sigla,
                        value: item.id,
                    }))
                this.setState(
                    { optionsFontesMeteorologicas: optionsFontesMeteorologicas }
                    , () => {
                        if (defaultState) {//recarrego o default state para garantir que todos os tipos de locais estejam marcados
                            this.defaultState()
                        }
                    }
                );
            })
            .catch(() => { this.setState({ optionsFontesMeteorologicas: [] }) })
    }


    defaultState = () => {
        let todasOrigens = [];
        optionsOrigens.forEach((item) => {
            todasOrigens.push(item.value);
        });

        let todosAmbientes = [];
        optionsAmbiente.forEach((item) => {
            todosAmbientes.push(item.value);
        });

        let todasHostilidades = [];
        optionsHostilidade.forEach((item) => {
            todasHostilidades.push(item.value);
        });

        let todosTiposLocal = [];
        this.state.optionsTiposLocal.forEach((item) => {
            todosTiposLocal.push(item.value);
        });

        let todosFontesMeteorologicas = [];
        this.state.optionsFontesMeteorologicas.forEach((item) => {
            todosFontesMeteorologicas.push(item.value);
        });

        let todosAreas = [];
        optionsAreas.forEach((item) => {
            todosAreas.push(item.value);
        });

        const { filtro } = this.state;
        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    perdido: true,
                    origens: todasOrigens,
                    ambiente: todosAmbientes,
                    hostilidade: todasHostilidades,
                    tiposLocal: todosTiposLocal,
                    areas: todosAreas,
                    fontesMeteorologicas: todosFontesMeteorologicas
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleClear = () => {
        const { filtro } = this.state;
        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    perdido: true,
                    origens: [],
                    tiposLocal: [],
                    areas: [],
                    fontesMeteorologicas: []
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleClose = (e, { name, value }) => {
        this.props.onClose(value)
    }

    handleUpdateFilter = () => {
        this.props.onUpdateFilter(this.state.filtro);
        setFilterObject(this.state.filtro)
    }

    handlePerdido = () => {

        const { filtro } = this.state;

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    perdido: !filtro.perdido
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleOrigens = (valor, desmarcarTodos = false) => {
        const { filtro } = this.state;
        var temp = filtro.origens;

        if (desmarcarTodos) {
            temp.length = 0;
            temp.push(valor)
        } else {
            if (temp.includes(valor)) {
                temp.splice(temp.indexOf(valor), 1);
            } else {
                temp.push(valor)
            }
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    origens: temp
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleAmbiente = (valor) => {
        const { filtro } = this.state;
        var temp = filtro.ambiente;

        if (temp.includes(valor)) {
            temp.splice(temp.indexOf(valor), 1);
        } else {
            temp.push(valor)
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    ambiente: temp
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleHostilidade = (valor) => {
        const { filtro } = this.state;
        var temp = filtro.hostilidade;

        if (temp.includes(valor)) {
            temp.splice(temp.indexOf(valor), 1);
        } else {
            temp.push(valor)
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    hostilidade: temp
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleTipoLocal = (valor) => {
        const { filtro } = this.state;
        var temp = filtro.tiposLocal;

        if (temp.includes(valor)) {
            temp.splice(temp.indexOf(valor), 1);
        } else {
            temp.push(valor)
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    tiposLocal: temp
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleFonteMeteorologica = (valor) => {
        const { filtro } = this.state;
        var temp = filtro.fontesMeteorologicas;

        if (temp.includes(valor)) {
            temp.splice(temp.indexOf(valor), 1);
        } else {
            temp.push(valor)
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    fontesMeteorologicas: temp
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }

    handleAreas = (valor) => {
        const { filtro } = this.state;
        var temp = filtro.areas;

        if (temp.includes(valor)) {
            temp.splice(temp.indexOf(valor), 1);
        } else {
            temp.push(valor)
        }

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    areas: temp
                }
            },
            () => {
                this.handleUpdateFilter()
            }
        )
    }


    handleAccordion = (e, titleProps) => {
        const { index } = titleProps
        const { filtro } = this.state;
        const newIndex = filtro.index === index ? -1 : index

        this.setState(
            {
                filtro:
                {
                    ...filtro,
                    index: newIndex
                }
            },
            () => {
                setFilterObject(this.state.filtro)
            }
        )
    }

    render() {
        const { filtro } = this.state;

        return (
            <>
                <Grid columns={2} >
                    <Grid.Column>
                        <Header inverted as="h2">{text("filtro.filtro")}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">

                        <Button
                            onClick={this.defaultState}
                            inverted
                            size='tiny'
                            icon="undo"
                            title={text("filtro.reset")}
                        />

                        <Button
                            onClick={this.handleClear}
                            inverted
                            size='tiny'
                            icon="eye slash outline"
                            title={text("filtro.clear")}
                        />

                        <Button
                            onClick={this.handleClose}
                            inverted
                            size='tiny'
                            icon="close"
                            color="red"
                            title={text("geral.fechar")}
                        />
                    </Grid.Column>
                </Grid>

                <Header inverted as="h3" dividing>{text("acompanhamento.acompanhamentos")}</Header>

                <Accordion fluid inverted>
                    <Accordion.Title
                        active={filtro.index === 0}
                        index={0}
                        onClick={this.handleAccordion}
                    >
                        <Icon name='dropdown' />
                        {text("filtro.origens")}
                    </Accordion.Title>
                    <Accordion.Content active={filtro.index === 0}>
                        <Form inverted>
                            <Form.Group grouped >
                                {optionsOrigens
                                    .sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
                                    .map(item =>
                                        <Form.Checkbox
                                            key={item.value}
                                            value={item.value}
                                            checked={filtro.origens.includes(item.value)}
                                            onChange={(e, { value }) => this.handleOrigens(value)}
                                            label={
                                                <label>
                                                    {item.text}
                                                    &nbsp;<Link title={text("filtro.marcar-apenas-este")} onClick={() => this.handleOrigens(item.value, true)} style={{fontSize:12, color:'#fff'}}>[+]</Link>
                                                </label>
                                            }
                                        />
                                    )}
                            </Form.Group>
                        </Form>
                    </Accordion.Content>

                    <Accordion.Title
                        active={filtro.index === 1}
                        index={1}
                        onClick={this.handleAccordion}
                    >
                        <Icon name='dropdown' />
                        {text("classificacao.ambiente")}
                    </Accordion.Title>
                    <Accordion.Content active={filtro.index === 1}>
                        <Form inverted>
                            <Form.Group grouped >
                                {optionsAmbiente.map(item =>
                                    <Form.Checkbox
                                        key={item.value}
                                        value={item.value}
                                        label={item.text}
                                        checked={filtro.ambiente.includes(item.value)}
                                        onChange={(e, { value }) => this.handleAmbiente(value)}
                                    />
                                )}
                            </Form.Group>
                        </Form>
                    </Accordion.Content>

                    <Accordion.Title
                        active={filtro.index === 2}
                        index={2}
                        onClick={this.handleAccordion}
                    >
                        <Icon name='dropdown' />
                        {text("classificacao.hostilidade")}
                    </Accordion.Title>
                    <Accordion.Content active={filtro.index === 2}>
                        <Form inverted>
                            <Form.Group grouped>
                                {optionsHostilidade.map(item =>
                                    <Form.Checkbox
                                        key={item.value}
                                        value={item.value}
                                        label={<label>{item.text} <Icon size="small" name="minus" style={{ color: item.color ? item.color : '#f3f3f3' }} /></label>}
                                        checked={filtro.hostilidade.includes(item.value)}
                                        onChange={(e, { value }) => this.handleHostilidade(value)}
                                    />
                                )}
                            </Form.Group>
                        </Form>
                    </Accordion.Content>

                    <Accordion.Title
                        active={filtro.index === 4}
                        index={4}
                        onClick={this.handleAccordion}
                    >
                        <Icon name='dropdown' />
                        {text("filtro.outros")}
                    </Accordion.Title>
                    <Accordion.Content active={filtro.index === 4}>
                        <Form inverted>
                            <Form.Checkbox
                                onChange={this.handlePerdido}
                                checked={filtro.perdido}
                                label={text("filtro.perdido")}
                            />
                        </Form>
                    </Accordion.Content>

                </Accordion>

                {this.state.optionsTiposLocal && this.state.optionsTiposLocal.length > 0 &&
                    <>
                        <Header inverted as="h3" dividing>{text("local.locais")}</Header>

                        <Accordion fluid inverted>
                            <Accordion.Title
                                active={filtro.index === 5}
                                index={5}
                                onClick={this.handleAccordion}
                            >
                                <Icon name='dropdown' />
                                {text("filtro.tipo")}
                            </Accordion.Title>
                            <Accordion.Content active={filtro.index === 5}>
                                <Form inverted>
                                    <Form.Group grouped>
                                        {this.state.optionsTiposLocal.map(item =>
                                            <Form.Checkbox
                                                key={item.value}
                                                value={item.value}
                                                label={item.text}
                                                checked={filtro.tiposLocal.includes(item.value)}
                                                onChange={(e, { value }) => this.handleTipoLocal(value)}
                                            />
                                        )}
                                    </Form.Group>
                                </Form>
                            </Accordion.Content>
                        </Accordion>
                    </>
                }

                {this.state.optionsFontesMeteorologicas && this.state.optionsFontesMeteorologicas.length > 0 &&
                    <>
                        <Header inverted as="h3" dividing>{text("dados-meteorologicos.meteorologia")}</Header>

                        <Accordion fluid inverted>
                            <Accordion.Title
                                active={filtro.index === 6}
                                index={6}
                                onClick={this.handleAccordion}
                            >
                                <Icon name='dropdown' />
                                {text("dados-meteorologicos.fontes")}
                            </Accordion.Title>
                            <Accordion.Content active={filtro.index === 6}>
                                <Form inverted>
                                    <Form.Group grouped>
                                        {this.state.optionsFontesMeteorologicas.map(item =>
                                            <Form.Checkbox
                                                key={item.value}
                                                value={item.value}
                                                label={item.text}
                                                checked={filtro.fontesMeteorologicas.includes(item.value)}
                                                onChange={(e, { value }) => this.handleFonteMeteorologica(value)}
                                            />
                                        )}
                                    </Form.Group>
                                </Form>
                            </Accordion.Content>
                        </Accordion>
                    </>
                }

                <Header inverted as="h3" dividing>{text("filtro.outros")}</Header>

                <Accordion fluid inverted>
                    <Accordion.Title
                        active={filtro.index === 7}
                        index={7}
                        onClick={this.handleAccordion}
                    >
                        <Icon name='dropdown' />
                        {text("areas.areas")}
                    </Accordion.Title>
                    <Accordion.Content active={filtro.index === 7}>
                        <Form inverted>
                            <Form.Group grouped>
                                {optionsAreas.map(item =>
                                    <Form.Checkbox
                                        key={item.value}
                                        value={item.value}
                                        label={item.text}
                                        checked={filtro.areas.includes(item.value)}
                                        onChange={(e, { value }) => this.handleAreas(value)}
                                    />
                                )}
                            </Form.Group>
                        </Form>
                    </Accordion.Content>
                </Accordion>

            </>
        )
    }
}