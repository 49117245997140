import React from "react";

import { Route, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react'

import Main from '../main';
import TipoEventosTable from '../eventos/tipos/table'
import TipoEventosForm from '../eventos/tipos/form'
import UsuarioTable from "../usuarios/table";
import UsuarioForm from "../usuarios/form";
import UsuarioNew from "../usuarios/novo";
import LocalTable from "../locais/table";
import LocalForm from "../locais/form"
import LocalNew from "../locais/novo"
import TipoLocaisTable from "../tipo-locais/table"
import TipoLocaisForm from "../tipo-locais/form"
import HistoricoLogins from "../historico-acessos"
import UsuariosOnline from '../usuarios-online'
import Error from 'pages/error';

import { text } from 'services/locales'
import Footer from 'components/footer'

import { isAdmin } from 'services/auth';

import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

class Routes extends React.Component {
    render() {
        return (
            <>
                {!isAdmin() &&
                    <Redirect to='/' />
                }

                <Helmet title={`${text("menu.admin")} ${text("geral.html-title")}`} />

                <div style={{backgroundColor:'#ff0000', height:'2px'}}></div>

                <Switch>
                    <Route exact path="/admin" component={Main} />
                    <Route exact path="/admin/historico-login" component={HistoricoLogins} />
                    <Route exact path="/admin/usuarios-online" component={UsuariosOnline} />
                    <Route exact path="/admin/tipo-eventos" component={TipoEventosTable} />
                    <Route exact path="/admin/tipo-evento/:id?" component={TipoEventosForm} />
                    <Route exact path="/admin/usuarios" component={UsuarioTable} />
                    <Route exact path="/admin/usuario/:id" component={UsuarioForm} />
                    <Route exact path="/admin/novo-usuario" component={UsuarioNew} />
                    <Route exact path="/admin/locais" component={LocalTable} />
                    <Route exact path="/admin/local/:id" component={LocalForm} />
                    <Route exact path="/admin/novo-local" component={LocalNew} />
                    <Route exact path="/admin/tipo-locais" component={TipoLocaisTable} />
                    <Route exact path="/admin/tipo-local/:id?" component={TipoLocaisForm} />
                    <Route path="*" render={props => <Error {...props} footer={false} />} />
                </Switch>

                <Container  >
                    <Footer />
                </Container>

            </>
        );
    }
}

export default Routes;