import React, { Component } from 'react';

import { Button, Form, Icon, Message, Divider, Container, Grid, Label, Dropdown, Confirm } from 'semantic-ui-react'
import { Formik } from "formik";
import api from 'services/api';
import { text } from 'services/locales';
import { Link, Redirect } from 'react-router-dom'
import InputMask from 'react-input-mask'

export default class CadastroUsuario extends Component {

    state = {
        redirect: null,
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true,
        locais: [],
        gerouSenha: false,
        senha: null,
        senha2: null,
        trocarSenha: false
    };

    componentDidMount() {
        this.loadItens()
    }

    loadItens = () => {
        this.setState({ loading: true });

        this.setState({ loading: true });
        if (this.props.id !== undefined) {
            const { id } = this.props;

            api.get("/hidra/usuarios/" + id)
                .then((response) => {
                    this.setState({
                        item: response.data,
                        local: response.data.local.id,
                        perfil: response.data.perfil.id
                    });
                }).catch((error) => {
                    this.setState({
                        loading: false,
                        error: text("formularios.erro_request")
                    });
                });
        }

        api.get("/hidra/usuarios/perfis/nivel-abaixo")
            .then((response) => {
                const perfis = response.data.map((item) => ({
                    key: item.id,
                    text: item.nome,
                    value: item.id,
                }));

                this.setState({ perfis: perfis });
            })
            .catch((error) => {
                this.setState({
                    perfis: [],
                    error: text("formularios.erro_request")
                });
            });

        api.get("/hidra/locais/all")
            .then((response) => {
                const locais = response.data.map((item) => ({
                    key: item.id,
                    text: item.nome,
                    value: item.id,
                }));

                this.setState({ loading: false, locais: locais });

            }).catch((error) => {
                this.setState({
                    loading: false,
                    error: text("formularios.erro_request")
                });
            });
    }

    handleChangeSenha(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    gerarSenha() {
        var listaLetras = "abcdefghjkmnpqrstuvwxyz",
            listaNumeros = "23456789",
            listaCaracteresEspeciais = "@#$%&",
            senha = '';

        //Obtem os caracteres aleatorios
        for (var i = 0; i < 3; i++) {
            senha += listaLetras.charAt(Math.floor(Math.random() * listaLetras.length));
            senha += listaNumeros.charAt(Math.floor(Math.random() * listaNumeros.length));
            senha += listaLetras.charAt(Math.floor(Math.random() * listaLetras.length)).toUpperCase();
            senha += listaCaracteresEspeciais.charAt(Math.floor(Math.random() * listaCaracteresEspeciais.length));
        }

        //Embaralha
        var controleEmbaralharSenha = senha.split(""),
            n = controleEmbaralharSenha.length;
        for (i = n - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var tmp = controleEmbaralharSenha[i];
            controleEmbaralharSenha[i] = controleEmbaralharSenha[j];
            controleEmbaralharSenha[j] = tmp;
        }
        senha = controleEmbaralharSenha.join("");

        this.setState({
            senhaGerada: senha,
            showGerarSenha: true
        });
    }

    handleConfirmSenha = () => {
        navigator.clipboard.writeText(this.state.senhaGerada)

        this.setState({
            senha: this.state.senhaGerada,
            senha2: this.state.senhaGerada,
            gerouSenha: true,
            showGerarSenha: false
        })
    }

    handleCancelSenha = () => {
        this.setState({
            senha: '',
            senha2: '',
            senhaGerada: null,
            gerouSenha: false,
            showGerarSenha: false
        })
    }

    handleChangeNome = (e, values) => {
        const { id } = this.props
        if (id) return

        var login = e.target.value
        login = login.toLowerCase()
        login = login.replaceAll(" ", "-")
        // eslint-disable-next-line
        login = login.replace(/[`~!@#$%^&*()|+_=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        login = login.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        values.login = login
    }
    render() {

        const { item, loading, submitting, redirect, sucesses } = this.state;
        const { id } = this.props

        if (redirect) {
            return <Redirect to={{
                pathname: '/admin/usuario/' + redirect,
                state: { sucesses: sucesses }
            }}
            />
        }

        return (
            <Container style={{ padding: '0.5em', backgroundColor: "#fff" }}>

                <Formik
                    initialValues={{
                        nome: item ? item.nome : '',
                        nomeExibicao: item ? item.nomeExibicao : '',
                        cpf: item ? item.cpf : '',
                        nip: item ? item.nip : '',
                        login: item ? item.login : '',
                        habilitado: item ? item.habilitado : true
                    }}
                    enableReinitialize={item ? true : false} //Só permito na edição
                    onSubmit={(values) => {
                        this.setState({ error: null, submitting: true, sucesses: null });

                        if (item) {//editando um item
                            api.put('/hidra/usuarios/' + item.id,
                                {
                                    "nome": values.nome,
                                    "nomeExibicao": values.nomeExibicao,
                                    "local": this.state.local,
                                    "cpf": values.cpf,
                                    "nip": values.nip,
                                    "habilitado": values.habilitado,
                                    "perfil": this.state.perfil,
                                    "senha": this.state.senha,
                                    "senha2": this.state.senha2,
                                    "trocarSenha": true
                                }
                            )
                                .then((response) => {
                                    if (response.data.error != null) {
                                        this.setState({ submitting: false, error: response.data });
                                    } else {
                                        this.setState({ submitting: false, error: null, sucesses: text("usuario.atualizado-sucesso") });
                                    }
                                    window.scrollTo(0, 0)
                                    this.props.onSubmit()
                                })
                                .catch((error) => {
                                    var mensagemErro = text("formularios.erro");
                                    if (error.response && error.response.data.message) {
                                        mensagemErro = error.response.data.message;
                                    } else if (error.request) {
                                        mensagemErro = text("formularios.erro_request")
                                    }
                                    window.scrollTo(0, 0)
                                    this.setState({
                                        submitting: false,
                                        error: mensagemErro
                                    });
                                });
                        } else {//novo item
                            api.post('/hidra/usuarios',
                                {
                                    "nome": values.nome,
                                    "nomeExibicao": values.nomeExibicao,
                                    "local": this.state.local,
                                    "cpf": values.cpf,
                                    "nip": values.nip,
                                    "login": values.login,
                                    "perfil": this.state.perfil,
                                    "senha": this.state.senha,
                                    "senha2": this.state.senha2,
                                    "trocarSenha": true
                                }
                            )
                                .then((response) => {
                                    if (response.data.error != null) {
                                        this.setState({ submitting: false, error: response.data });
                                        window.scrollTo(0, 0)
                                    } else {
                                        this.setState({
                                            submitting: false,
                                            error: null,
                                            sucesses: text("usuario.criado-sucesso"),
                                            redirect: response.data.id
                                        });
                                    }
                                })
                                .catch((error) => {
                                    var mensagemErro = text("formularios.erro");
                                    if (error.response && error.response.data.message) {
                                        mensagemErro = error.response.data.message;
                                    } else if (error.request) {
                                        mensagemErro = text("formularios.erro_request")
                                    }
                                    window.scrollTo(0, 0)
                                    this.setState({
                                        submitting: false,
                                        error: mensagemErro
                                    });
                                });
                        }
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                    }) => (
                            <Form
                                onSubmit={handleSubmit}
                                loading={loading || submitting}
                            >
                                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                                <Form.Group >
                                    <Form.Field required width={8}>
                                        <label>{text("usuario.nome")}</label>
                                        <Form.Input
                                            placeholder={text("usuario.nome")}
                                            onChange={handleChange}
                                            onInput={e => this.handleChangeNome(e, values)}
                                            onBlur={handleBlur}
                                            value={values.nome}
                                            name="nome"
                                        />
                                    </Form.Field>
                                    {id &&
                                        <Form.Field width={2} style={{ paddingLeft: "50px" }}>
                                            <label>&nbsp;</label>
                                            <Form.Checkbox

                                                toggle
                                                label={text("usuario.habilitado")}
                                                onChange={() => setFieldValue('habilitado', !values.habilitado)}
                                                checked={values.habilitado}
                                                name="habilitado"

                                            />
                                            {item && item.bloqueado &&
                                                <span style={{ color: "red" }}>
                                                    {text("usuario.bloqueado-descricao")}
                                                </span>
                                            }

                                        </Form.Field >
                                    }
                                </Form.Group>


                                {!id &&
                                    <Form.Field required inline>
                                        <label>{text("usuario.login")}</label><br />
                                        <input
                                            placeholder={text("usuario.login")}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.login}
                                            name="login"
                                        />
                                        {values.login &&
                                            <Label color='yellow' pointing='left'>
                                                {text("usuario.login-descricao")}
                                            </Label>
                                        }
                                        <br />
                                        <small><i>{window.location.host + '/siscare/usuario/' + (values.login ? values.login : 'nome-de-usuario')}</i></small>
                                    </Form.Field>
                                }

                                <Form.Group>
                                    <Form.Field width={8}>
                                        <label>{text("usuario.nomeExibicao")}</label>
                                        <Form.Input
                                            placeholder={text("usuario.nomeExibicao")}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nomeExibicao}
                                            name="nomeExibicao"
                                        />
                                         <small>{text("usuario.nomeExibicao-descricao")}</small>
                                    </Form.Field>
                                   
                                </Form.Group>

                                <Form.Field required style={{ width: '500px' }} >
                                    <label>{text("usuario.perfil")}</label>
                                    <Dropdown
                                        placeholder={text("usuario.perfil-descricao")}
                                        value={this.state.perfil}
                                        onChange={this.handleChange}
                                        name="perfil"
                                        search
                                        selection
                                        options={this.state.perfis ? this.state.perfis : []}
                                    />
                                </Form.Field>

                                <Form.Group style={{ margin: '0 0 0 -7px' }}>
                                    <Form.Field style={{ width: '150px' }}>
                                        <label>{text("usuario.cpf")}</label>
                                        <InputMask
                                            mask="999.999.999-99"
                                            placeholder={text("usuario.cpf")}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cpf}
                                            name="cpf"
                                        />
                                    </Form.Field>

                                    <Form.Field style={{ width: '150px' }}>
                                        <label>{text("usuario.nip")}</label>
                                        <InputMask
                                            mask="99.9999.99"
                                            placeholder={text("usuario.nip")}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nip}
                                            name="nip"
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <small>{text("usuario.documento-identificacao-descricao")}</small>
                                <br /><br />

                                <Form.Field required style={{ width: '500px' }} >
                                    <label>{text("usuario.local")}</label>
                                    <Dropdown
                                        placeholder={text("usuario.local-descricao")}
                                        onChange={this.handleChange}
                                        value={this.state.local}
                                        name="local"
                                        search
                                        selection
                                        options={this.state.locais ? this.state.locais : []}
                                    />
                                </Form.Field>

                                <br />


                                <Divider />

                                <Form.Group style={{ margin: '0 0 0 -7px' }}>
                                    <Form.Field required inline>
                                        <label>{text("usuario.senha")}</label><br />
                                        <input
                                            placeholder={text("usuario.senha")}
                                            type='password'
                                            onChange={this.handleChangeSenha.bind(this)}
                                            onBlur={handleBlur}
                                            value={this.state.senha ? this.state.senha : ""}
                                            name="senha"
                                            autoComplete="new-password"
                                        />
                                    </Form.Field>

                                    <Confirm
                                        open={this.state.showGerarSenha}
                                        cancelButton={text("formularios.cancelar")}
                                        confirmButton={text("usuario.copiar-senha")}
                                        onCancel={this.handleCancelSenha}
                                        onConfirm={this.handleConfirmSenha}
                                        content={this.state.senhaGerada}
                                        header={text("usuario.gerar-senha-titulo")}
                                        size="tiny"
                                    />

                                    <Button
                                        icon labelPosition='right'
                                        style={{ height: '38px', margin: '20px 0px 0px' }}
                                        type='button'
                                        title={text("usuario.gerar-senha")}
                                        onClick={this.gerarSenha.bind(this)}
                                    >
                                        <Icon name='key' />  {text("usuario.gerar-senha")}
                                    </Button>
                                </Form.Group>
                                <small>{text("usuario.senha-descricao")}</small>

                                <br /><br />

                                <Form.Field required inline>
                                    <label>{text("usuario.senha2")}</label><br />
                                    <input
                                        placeholder={text("usuario.senha2")}
                                        type='password'
                                        onChange={this.handleChangeSenha.bind(this)}
                                        onBlur={handleBlur}
                                        value={this.state.senha2 ? this.state.senha2 : ""}
                                        name="senha2"
                                        autoComplete="new-password"
                                        disabled={this.state.gerouSenha ? false : !this.state.senha}
                                    />
                                </Form.Field>

                                {this.state.senha &&
                                    <Label color='yellow'>
                                        {text("usuario.forcar-trocar-senha")}
                                    </Label>
                                }

                                <br />
                                <Divider />
                                <br />
                                <Grid columns={2}>
                                    <Grid.Column>
                                        <Button
                                            icon labelPosition='left'
                                            size='large'
                                            as={Link}
                                            to="/admin/usuarios"
                                            disabled={loading}
                                        >
                                            <Icon name={'angle left'} /> {text("formularios.voltar")}
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            primary
                                            loading={submitting}
                                            type='submit'
                                            icon labelPosition='right'
                                            size="big"
                                            disabled={loading || submitting}
                                        >
                                            <Icon name='save' /> {text("formularios.salvar")}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        )}
                </Formik>

            </Container>
        );
    }

}