import React, { Component } from 'react';

import { Link } from 'react-router-dom'
import { Header, Button, Label } from "semantic-ui-react"
import api from 'services/api';
import { text } from 'services/locales'

import InfoEvento from 'components/objetos/eventos/info'
import Historico from 'components/objetos/eventos/historico'
import EstacoesMeteorologicasProximas from 'components/objetos/meteorologia/estacoesProximas'

export default class DicaEvento extends Component {

    state = {
        objeto: null
    }

    componentDidMount() {
        this.handleRefreshObjetos();
    }

    componentWillUnmount() {
        clearInterval(this.intervalObjetos);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState(
                {
                    objeto: null
                }
                , () => {
                    this.handleRefreshObjetos();
                }
            )
        }
    }

    renderEstado(item) {
        switch (item.estado.codigo) {
            case 'ativo':
                return (<Label tag size="mini" color='green'>{text("eventos.ativo")}</Label>)
            case 'reconhecido':
                return (<Label tag size="mini" color='yellow'>{text("eventos.reconhecido")}</Label>)
            case 'inativo':
                return (<Label tag size="mini" color='grey'>{text("eventos.inativo")}</Label>)
            default: return null;
        }
    }

    handleRefreshObjetos() {
        const { id } = this.props;
        api.get("/eventos/" + id)
            .then(response => {
                this.setState({
                    objeto: response.data,
                    refresh: new Date().getTime()
                });
            })
            .catch(error => {
                var mensagemErro = text("formularios.erro_request");
                if (error.response && error.response.data) {
                    mensagemErro = error.response.data.message;
                }
                this.setState({
                    error: mensagemErro,
                    objeto: null,
                    acoes: []
                });
            });
    }

    handleGoto = () => {
        const { objeto } = this.state;
        const latLong = [objeto.lat, objeto.lng];
        this.props.onGoto(latLong)
    }

    render() {

        const { objeto, refresh } = this.state;
        const { showLinks } = this.props;

        return (
            <>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>
                        <Header inverted as="h2">
                            {objeto ? objeto.titulo : ''}
                            <Header.Subheader>
                                {objeto &&
                                    this.renderEstado(objeto)
                                }
                            </Header.Subheader>
                        </Header>
                    </div>

                    <div style={{ flex: "200px", textAlign: "right" }}>

                        {showLinks &&
                            <Button
                                as={Link}
                                to={"/evento/" + (objeto ? objeto.id : 0)}
                                disabled={!objeto}
                                inverted
                                size='tiny'
                                icon="search plus"
                                title={text("geral.mais-detalhes")}
                            />
                        }

                        {showLinks &&
                            <Button
                                onClick={this.handleGoto}
                                inverted
                                size='tiny'
                                icon="crosshairs"
                                title={text("geral.centralizar")}
                            />
                        }

                        <Button
                            onClick={this.props.onClose}
                            inverted
                            size='tiny'
                            icon="close"
                            title={text("geral.fechar")}
                        />
                    </div>
                </div>
                {objeto &&
                    <>

                        <InfoEvento evento={objeto} refresh={refresh} inverted header='h5' />

                        <br />

                        <EstacoesMeteorologicasProximas showLinks={showLinks} inverted header='h5' lat={objeto.lat} lng={objeto.lng} raio={20} buttonSize="mini" />

                        <Historico showLinks={showLinks} evento={objeto} refresh={refresh} inverted header='h5' buttonSize="mini" />
                    </>
                }
            </>
        )
    }
}