import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import MobileApp from './MobileApp'

import { init as initNotificador } from 'components/Notificador'
import { appUpdated } from 'services/storage'

require('dotenv').config();

//Verifico se a url contem '/app', isso significa que é o webview que será carregado dentro do aplicatico movel
if (window.location.pathname.includes('/app')){

    //Inicio a aplicação 
    ReactDOM.render(<MobileApp />, document.getElementById('root'));

} else {//Senão é a aplicação web normal

    //Verifico se é uma versão diferente da ultima executada, 
    //forço o logout para garantir que será removido do storage 
    //local qualquer referência a versão antiga que possa dar algum problema
    var updated = appUpdated()
    if (updated) {
        window.location.href = "/siscare/logout?reason=update";
    }

    //Inicio o notificador do navegador
    initNotificador()

    //Inicio a aplicação 
    ReactDOM.render(<App />, document.getElementById('root'));

}
