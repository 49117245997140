import React, { Component } from 'react';

import { Button, Step, Header, Icon, Message, Segment, Container, List, Grid, Divider, Form } from 'semantic-ui-react'
import api from 'services/api';
import { text } from 'services/locales';
import { Link, Redirect } from 'react-router-dom'
import { getIcon } from 'util/eventos'
import Footer from 'components/footer'
import { Helmet } from 'react-helmet'
import { convertDMS } from 'util/functions'
import Mapa from 'pages/admin/locais/mapa'
import MapaEvento from 'components/objetos/entidades/mapa'

export default class Formulario extends Component {

    state = {
        redirect: false,
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true,
        tipos: [],
        locais: [],
        step: 1,
        objeto: {
            tipo: null,
            local: null,
            lat: null,
            lng: null,
            titulo: null,
            observacoes: null,
            file: null,
            fileName: null
        },
        posicaoEvento: null,
        idEvento: null
    };

    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {

        this.setState(
            {
                loading: true,
                objeto: {
                    tipo: null,
                    local: null,
                    lat: null,
                    lng: null,
                    titulo: null,
                    observacoes: null,
                    file: null,
                    fileName: null
                }
            }
        );

        api.get("/eventos/tipos?size=999&sort=nome,asc")
            .then(response => {
                this.setState({ tipos: response.data.content, loading: false });
            })
            .catch(error => {
                this.setState({ tipos: [], loading: false });
            })

        api.get("/hidra/locais?size=999&sort=nome,asc")
            .then(response => {
                this.setState({ locais: response.data.content });
            })
            .catch(error => {
                this.setState({ locais: [] });
            })
    }

    tipoSelecionado = (item) => {
        const { objeto } = this.state;
        this.setState(
            {
                objeto:
                {
                    ...objeto,
                    tipo: item
                },
                step: 2
            }
        )
    }

    localSelecionado = (item) => {
        const { objeto } = this.state;
        this.setState(
            {
                objeto:
                {
                    ...objeto,
                    local: item,
                    lat: null,
                    lng: null
                },
                step: 3
            }
        )
    }

    posicaoSelecionada = (lat, lng) => {
        const { objeto } = this.state;
        this.setState(
            {
                objeto:
                {
                    ...objeto,
                    local: null,
                    lat: lat,
                    lng: lng
                },
                step: 3
            }
        )
    }

    onHandleClick(element) {

        const { objeto } = this.state;
        this.setState(
            {
                objeto:
                {
                    ...objeto,
                    local: null,
                    lat: element.lat,
                    lng: element.lng
                },
                step: 3
            }
        )
    }

    getLat(posicoes) {
        let lat = ""
        posicoes.forEach((element, index) => {
            if (index === 0) {
                lat = element.lat
            } else {
                lat = lat + "," + element.lat
            }
        });
        return lat;
    }

    getLng(posicoes) {
        let lng = ""
        posicoes.forEach((element, index) => {
            if (index === 0) {
                lng = element.lng
            } else {
                lng = lng + "," + element.lng
            }
        });
        return lng;
    }

    handleChangeTitulo = (e) => {
        const { objeto } = this.state;
        this.setState({
            objeto: {
                ...objeto,
                titulo: e.target.value,
            }
        })
    }

    handleChangeObservacoes = (e) => {
        const { objeto } = this.state;
        this.setState({
            objeto: {
                ...objeto,
                observacoes: e.target.value,
            }
        })
    }

    handleContinuarObservacoes = () => {
        this.setState(
            {
                step: 4
            }
        )
    }

    handleFileSelect = (e) => {
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            const { objeto } = this.state;
            this.setState({
                objeto: {
                    ...objeto,
                    fileName: e.target.files[0].name,
                    file: e.target.files[0]
                }
            })
        }
    }

    handleStep = (step) => {
        const { objeto, submitting } = this.state;

        if (submitting)
            return

        this.setState({ step: step });

        switch (step) {
            case 1:
                this.setState({
                    objeto: {
                        ...objeto,
                        tipo: null,
                        local: null,
                        lat: null,
                        lng: null,
                        titulo: null,
                        observacoes: null,
                        file: null,
                        fileName: null
                    }
                })
                break;
            case 2:
                this.setState({
                    objeto: {
                        ...objeto,
                        local: null,
                        lat: null,
                        lng: null,
                        titulo: null,
                        observacoes: null,
                        file: null,
                        fileName: null
                    }
                })
                break;
            default: break;
        }
    }

    handleEnviarEvento = () => {

        const { objeto } = this.state;
        this.setState({
            submitting: true,
            error: null
        });

        var bodyFormData = new FormData();
        bodyFormData.append('tipo', objeto.tipo.id);
        if (objeto.titulo) bodyFormData.append('titulo', objeto.titulo);
        if (objeto.observacoes) bodyFormData.append('observacoes', objeto.observacoes);
        if (objeto.local) bodyFormData.append('local', objeto.local.id);
        bodyFormData.append('lat', objeto.local ? objeto.local.lat : objeto.lat);
        bodyFormData.append('lng', objeto.local ? objeto.local.lng : objeto.lng);
        if (objeto.file) bodyFormData.append('file', objeto.file);

        api({
            method: 'post',
            url: "/eventos",
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                this.setState({
                    submitting: false,
                    error: null,
                    redirect: true,
                    idEvento: response.data.id
                });
                window.scrollTo(0, 0)
            })

            .catch(error => {
                var mensagemErro = text("geral.erro_conexao");
                if (error.response && error.response.data) {
                    mensagemErro = error.response.data.message;
                    window.scrollTo(0, 0)
                }
                this.setState({
                    submitting: false,
                    error: mensagemErro
                });
            });

    }

    render() {

        const { objeto, step, tipos, redirect, sucesses, loading, locais, submitting } = this.state;
        const posicaoEvento = this.state.posicaoEvento
        if (redirect === true) {
            return <Redirect to={{
                pathname: '/evento/' + this.state.idEvento,
                state: { sucesses: sucesses }
            }}
            />
        }

        return (
            <Container style={{ padding: '2em' }}>

                <Helmet title={`${text("novo-evento.novo-evento")} ${text("geral.html-title")}`} />

                <Grid stackable columns={2} >
                    <Grid.Column>
                        <Header as="h1">
                            {text("novo-evento.novo-evento")}
                        </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button
                            icon labelPosition='right'
                            size='large'
                            as={Link}
                            to="/eventos"
                        >
                            <Icon name='close' /> {text("formularios.cancelar")}
                        </Button>
                    </Grid.Column>
                </Grid>

                <br />

                {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}

                <Step.Group attached='top' >
                    <Step
                        active={step === 1}
                        link
                        onClick={() => this.handleStep(1)}
                    >
                        <Icon
                            style={{ color: (objeto.tipo !== null && objeto.tipo.severidade.cor) ? objeto.tipo.severidade.cor : '#000' }}
                            name={objeto.tipo === null ? 'question circle' : getIcon(objeto.tipo.severidade.codigo)}
                        />
                        <Step.Content>
                            <Step.Title>{text("novo-evento.tipo")}</Step.Title>
                            <Step.Description>{objeto.tipo === null ? text("novo-evento.tipo-descricao") : objeto.tipo.nome}</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step
                        active={step === 2}
                        disabled={step < 2}
                        completed={step > 2}
                        link
                        onClick={() => this.handleStep(2)}
                    >
                        <Icon
                            name='map marker alternate'
                        />
                        <Step.Content>
                            <Step.Title>{text("novo-evento.local")}</Step.Title>
                            <Step.Description>{text("novo-evento.local-descricao")}</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step
                        active={step === 3}
                        disabled={step < 3}
                        completed={step > 3}
                        link
                        onClick={() => this.handleStep(3)}
                    >
                        <Icon
                            name='align left'
                        />
                        <Step.Content>
                            <Step.Title>{text("novo-evento.informacoes")}</Step.Title>
                            <Step.Description>{text("novo-evento.informacoes-descricao")}</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step
                        active={step === 4}
                        disabled={step < 4}
                    >
                        <Icon
                            name='send'
                        />
                        <Step.Content>
                            <Step.Title>{text("novo-evento.enviar")}</Step.Title>
                            <Step.Description>{text("novo-evento.enviar-descricao")}</Step.Description>
                        </Step.Content>
                    </Step>
                </Step.Group>

                <Segment attached loading={loading}>

                    {step === 1 && //Seleção do tipo
                        <>
                            <Header as="h2">
                                {text("novo-evento.tipo-descricao")}
                            </Header>
                            <List divided relaxed selection >
                                {tipos.map((item) =>
                                    <List.Item
                                        key={item.id}
                                        onClick={() => this.tipoSelecionado(item)}
                                    >
                                        <List.Icon
                                            name={getIcon(item.severidade.codigo)}
                                            size='large'
                                            verticalAlign='middle'
                                            style={{ color: item.severidade.cor ? item.severidade.cor : '#808080' }}
                                        />
                                        <List.Content>
                                            <List.Header as='a'>{item.nome}</List.Header>
                                            <List.Description as='a'>{item.descricao}</List.Description>
                                        </List.Content>
                                    </List.Item>

                                )}
                            </List>
                            <br />
                        </>
                    }

                    {step === 2 && //Seleção do local
                        <>
                            <Grid columns={2} >
                                <Divider vertical>{text("geral.ou")}</Divider>

                                <Grid.Row >
                                    <Grid.Column>

                                        <Header as="h2">
                                            {text("novo-evento.local-descricao")}
                                        </Header>

                                        <Segment style={{ overflow: 'auto', height: "40vh" }}>
                                            <List divided relaxed selection >
                                                {locais.map((item) =>
                                                    <List.Item title={item.nome + " (" + item.tipo.nome + ")"} key={item.id} onClick={() => this.localSelecionado(item)}>
                                                        <Icon
                                                            name={item.tipo.simbolo ? item.tipo.simbolo : 'map marker alternate'}
                                                            style={{color: item.tipo.cor ? item.tipo.cor : '#808080'}}
                                                            size="large"
                                                        />
                                                        <List.Content >
                                                            <List.Header >{item.sigla}</List.Header>
                                                            <List.Description><small>{item.nome}</small></List.Description>
                                                        </List.Content>

                                                    </List.Item>
                                                )}
                                            </List>
                                        </Segment>

                                    </Grid.Column>

                                    <Grid.Column>

                                        <Header as="h2">
                                            {text("novo-evento.posicao-descricao")}
                                        </Header>
                                        < Mapa
                                            center={[]}
                                            title={text("local.posicao")}
                                            position={posicaoEvento ? [this.getLat(posicaoEvento), this.getLng(posicaoEvento)] : null}
                                            onHandleClick={(latlng) => this.onHandleClick(latlng)}
                                            onClick={() => this.localSelecionado(posicaoEvento)}
                                        ></ Mapa>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <br />
                        </>
                    }

                    {step === 3 && //Observações
                        <>
                            <Header as="h2">
                                {text("novo-evento.informacoes-descricao")}
                                <Header.Subheader>
                                    {text("geral.opcional")}
                                </Header.Subheader>
                            </Header>
                            <Form>

                                <b>{text("novo-evento.titulo")}</b>
                                <Form.Input
                                    placeholder={text("novo-evento.titulo-descricao")}
                                    name="titulo"
                                    value={objeto.titulo?objeto.titulo:""}
                                    onChange={e => this.handleChangeTitulo(e)}
                                />

                                <b>{text("novo-evento.observacoes")}</b>
                                <Form.TextArea
                                    rows={10}
                                    placeholder={text("novo-evento.observacoes-descricao")}
                                    name="observacoes"
                                    value={objeto.observacoes?objeto.observacoes:""}
                                    onChange={e => this.handleChangeObservacoes(e)}
                                />

                                <Button
                                    icon
                                    labelPosition="left"
                                    onClick={() => this.fileInputRef.current.click()}
                                >
                                    <Icon name="paperclip" /> {text("anexo.enviar-arquivo")}
                                </Button>
                                &nbsp;{objeto.fileName}
                                <input
                                    ref={this.fileInputRef}
                                    type="file"
                                    hidden
                                    onChange={this.handleFileSelect}
                                />

                                <Button
                                    primary
                                    type='submit'
                                    floated='right'
                                    icon labelPosition='right'
                                    size="big"
                                    onClick={this.handleContinuarObservacoes}
                                    loading={submitting}
                                >
                                    <Icon name='angle right' /> {text("formularios.continuar")}
                                </Button>
                                <br /><br /><br />
                            </Form>
                        </>
                    }


                    {step === 4 && //Enviar
                        <>
                            <Header as="h2">
                                {text("novo-evento.enviar")}
                                <Header.Subheader>
                                    {text("novo-evento.enviar-descricao")}
                                </Header.Subheader>
                            </Header>
                            <Segment>
                                <Grid >
                                    <Grid.Column width={8}>
                                        <b>{text("novo-evento.tipo")}:</b>&nbsp;{objeto.tipo.nome}<br />

                                        {objeto.local &&
                                            <>
                                                <b>{text("novo-evento.local")}:</b>&nbsp;{objeto.local.nome}<br />
                                            </>
                                        }

                                        <b>{text("eventos.posicao")}:</b>&nbsp;{
                                            convertDMS(
                                                objeto.local ? objeto.local.lat : objeto.lat,
                                                objeto.local ? objeto.local.lng : objeto.lng
                                            )
                                        }<br />

                                        {objeto.titulo &&
                                            <>
                                                <b>{text("novo-evento.titulo")}:</b>&nbsp;{objeto.titulo}<br />
                                            </>
                                        }

                                        {objeto.file &&
                                            <>
                                                <b>{text("novo-evento.arquivo")}:</b>&nbsp;{objeto.fileName}<br />
                                            </>
                                        }

                                        {objeto.observacoes &&
                                            <>
                                                <b>{text("novo-evento.observacoes")}:</b>
                                                <div style={{ whiteSpace: 'pre-line', overflow: 'auto', maxHeight: "200px" }}>
                                                    {objeto.observacoes}
                                                </div><br />
                                            </>
                                        }


                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <MapaEvento
                                            header='h3'
                                            title={text("eventos.posicao")}
                                            position={objeto.local ? [objeto.local.lat, objeto.local.lng] : [objeto.lat, objeto.lng]}
                                            zoom={10}
                                            icon={getIcon(objeto.tipo.severidade.codigo)}
                                            color={objeto.tipo.severidade.cor}
                                            hidegoto={true}
                                        >
                                        </MapaEvento>
                                    </Grid.Column>
                                </Grid>

                            </Segment>


                            <br /><br />

                            <Grid columns={1} textAlign="center" >
                                <Button
                                    color="green"
                                    icon labelPosition='right'
                                    size="massive"
                                    onClick={this.handleEnviarEvento}
                                    loading={submitting}
                                >
                                    <Icon name='send' /> {text("formularios.enviar")}
                                </Button>
                            </Grid>
                            <br /><br />
                        </>
                    }

                </Segment>


                <br />
                <Footer />

            </Container>
        );
    }

}