import React, { Component } from 'react';

import { Loader, Image, Button, Icon, Message } from 'semantic-ui-react'
import { text } from 'services/locales';
import api from 'services/api';
import { forceDownload } from 'util/functions'
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player';

export default class AnexosChat extends Component {

    state = {
        arquivos: null,
        loading: false
    }

    componentDidMount() {
        this.loadItens();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.hash !== this.props.hash) {
            this.loadItens();
        }
    }

    loadItens = () => {
        const { hash } = this.props;

        if (hash) {
            this.setState({ loading: true })
            api.get("/arquivos/" + hash)
                .then((response) => {
                    this.setState({ arquivos: response.data, loading: false })
                })
                .catch(error => {
                    var mensagemErro = text("anexo.erro");
                    if (error.response && error.response.data) {
                        mensagemErro = error.response.data.message;
                    } else if (error.request) {
                        mensagemErro = text("anexo.erro")
                    }
                    this.setState({
                        loading: false,
                        error: mensagemErro
                    });
                })
        }
    }

    renderAnexo = (anexo) => {
        const tipo = anexo.type.split("/");
        const url = process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_SLASH + "arquivos/" + anexo.url;

        switch (tipo[0]) {
            case 'image':
                return (
                    <Image
                        style={{ width: "100%" }}
                        src={
                            url
                        }
                    />
                );
            case 'video':
                return (
                    <>
                        <ReactPlayer
                            controls={true}
                            width="100%"
                            url={
                                [
                                    url,
                                    anexo.type
                                ]
                            }
                        />
                        <small><em>{text("anexo.aviso-video-invalido")}</em></small>
                    </>
                )
            case 'audio':
                return (
                    <>
                        <ReactAudioPlayer
                            src={url}
                            style={{ width: '100%' }}
                            controls
                            controlsList="nodownload"
                        />
                        <small><em>{text("anexo.aviso-audio-invalido")}</em></small>
                    </>
                )
            default:
                return (
                    <>
                        {anexo.file}
                        <br />
                        <br />
                    </>
                )

        }
    }

    render() {

        const { arquivos, loading, error } = this.state

        return (
            <>
                {arquivos && arquivos.files &&
                    <>
                        {arquivos.files.map(item =>
                            <div key={item.file} style={{ display: "flex" }}>
                                <div style={{ flex: "60px", textAlign: "left" }}>
                                    <Button
                                        title={text("anexo.download")}
                                        size="mini"
                                        icon="download"
                                        circular
                                        onClick={() => forceDownload(process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_SLASH + "arquivos/" + item.url, item.url)}
                                    />
                                </div>
                                <div style={{ flex: "100%" }}>
                                    {this.renderAnexo(item)}
                                </div>
                            </div>
                        )}

                    </>
                }

                {error &&
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "60px", textAlign: "left" }}>
                            <Icon
                                name="file excel outline"
                                title={error}
                            />
                        </div>
                        <div style={{ flex: "100%" }}>
                            <Message style={{ padding: "5px" }} negative>{error}</Message>
                        </div>
                    </div>
                }

                {loading &&
                    <Loader active inline='centered' />
                }
            </>
        )
    }
}