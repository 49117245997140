import React, { Component } from 'react';

import { Header, Button, Segment } from "semantic-ui-react"
import api from 'services/api';
import { text } from 'services/locales'
import { convertDMS } from 'util/functions'
import DialogoEditarAreaCircular from './edit'

export default class DicaAreaCircular extends Component {

    state = {
        objeto: null,
        editar: false,
    }

    componentDidMount() {
        this.handleRefreshObjetos();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState(
                {
                    objeto: null
                }
                , () => {
                    this.handleRefreshObjetos();
                }
            )
        }
    }

    handleRefreshObjetos() {
        api.get("/hidra/areas/circular/" + this.props.id)
            .then((response) => {
                this.setState({ objeto: response.data, refresh: new Date().getTime() })
            })
            .catch(() => {
                this.setState(
                    { objeto: null, refresh: -1 }
                    , () => {
                        this.props.onClose()
                    }
                )
            })
    }

    handleGoto = () => {
        const { objeto } = this.state;
        const latLong = [objeto.latitude, objeto.longitude];
        this.props.onGoto(latLong)
    }

    handleEdit = () => {
        this.setState({ editar: true })
    }

    handleCloseEdit = () => {
        this.setState({ editar: false })
        this.props.onRefresh()
        this.handleRefreshObjetos()
    }

    render() {

        const { objeto, editar } = this.state;
        const { showLinks } = this.props;

        return (
            <>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "100%" }}>
                        <Header inverted as="h2">
                            {objeto ? objeto.nome : ''}
                            <Header.Subheader>
                                {text("areas.area-circular")}
                            </Header.Subheader>
                        </Header>
                    </div>

                    <div style={{ flex: "200px", textAlign: "right" }}>

                        {showLinks &&
                            <Button
                                onClick={this.handleEdit}
                                inverted
                                size='tiny'
                                icon="edit"
                                title={text("tabelas.editar")}
                            />
                        }

                        {showLinks &&
                            <Button
                                onClick={this.handleGoto}
                                inverted
                                size='tiny'
                                icon="crosshairs"
                                title={text("geral.centralizar")}
                            />
                        }

                        <Button
                            onClick={this.props.onClose}
                            inverted
                            size='tiny'
                            icon="close"
                            color="red"
                            title={text("geral.fechar")}
                        />
                    </div>
                </div>


                {objeto &&
                    <>
                        <Header as="h5" attached="top" inverted>
                            {text("geral.informacoes-gerais")}
                        </Header>
                        <Segment attached inverted>
                            <b>{text("geral.nome")}:</b> {objeto.nome}<br />
                            <b>{text("areas.raio")}:</b> {objeto.raio} metros<br />
                            <b>{text("geo.posicao")}:</b> {convertDMS(objeto.latitude, objeto.longitude)}<br />
                            {objeto.cor &&
                                <>
                                    <b>{text("areas.cor")}:</b> &nbsp;<div style={{ backgroundColor: objeto.cor, paddingRight: '50px', display: 'inline' }}></div><br />
                                </>
                            }
                        </Segment>

                        {editar &&
                            <DialogoEditarAreaCircular
                                id={objeto.id}
                                onClose={this.handleCloseEdit}
                            />
                        }
                    </>
                }


            </>
        )
    }
}