import React, { Component } from 'react';

import { Header, Container, Tab, Message } from 'semantic-ui-react'
import api from 'services/api';
import { text } from 'services/locales';
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import CadastroContato from 'components/objetos/entidades/cadastroContato'
import CadastroEndereco from 'components/objetos/entidades/cadastroEndereco'
import CadastroFoto from 'components/objetos/entidades/cadastroFoto'
import CadastroLocais from 'components/objetos/locais/cadastro'

export default class AdminLocalFormulario extends Component {

    state = {
        redirect: false,
        item: null,
        error: null,
        sucesses: null,
        submitting: false,
        loading: true,
    };

    constructor(props) {
        super(props);
        this._mapa = React.createRef();
    }

    componentDidMount() {

        this.loadItens()

        if (this.props.location.state != null) {
            this.setState({ sucesses: this.props.location.state.sucesses })
        }

    }

    loadItens = () => {
        this.setState({ sucesses: null })

        if (this.props.match.params.id) {
            const { id } = this.props.match.params;

            api.get("/hidra/locais/" + id)
                .then((response) => {
                    this.setState({
                        item: response.data,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error: text("formularios.erro_request")
                    });
                });
        }

    }

    render() {

        const { item, redirect, sucesses } = this.state;
        const { id } = this.props.match.params;

        const panes = [
            {
                menuItem: { key: 'home', icon: 'map marker alternate', content: text("entidade.geral") },
                render: () => <Tab.Pane>
                    <CadastroLocais
                        id={id}
                        onSubmit={() => this.loadItens()}
                    />
                </Tab.Pane>,
            },
            {
                menuItem: { key: 'endereco', icon: 'home', content: text("entidade.endereco") },
                render: () =>
                    <Tab.Pane>
                        <CadastroEndereco
                            id={id}
                            onSubmit={() => this.loadItens()}
                        />
                    </Tab.Pane>,
            },
            {
                menuItem: { key: 'contato', icon: 'phone', content: text("entidade.contatos") },
                render: () =>
                    <Tab.Pane>
                        <CadastroContato
                            id={id}
                            onSubmit={() => this.loadItens()}
                        />
                    </Tab.Pane>,
            },
            {
                menuItem: { key: 'foto', icon: 'file image', content: text("entidade.foto") },
                render: () =>
                    <Tab.Pane>
                        <CadastroFoto
                            id={id}
                            imagem={item ? item.imagem : undefined}
                            onSubmit={() => this.loadItens()}
                        />
                    </Tab.Pane>,
            },
        ]

        if (redirect === true) {
            return <Redirect to={{
                pathname: '/admin/locais',
                state: { sucesses: sucesses }
            }}
            />
        }

        return (
            <Container style={{ padding: '2em', backgroundColor: "#fff" }}>

                <Helmet title={`${text("local.atualizar-local") + (item ? " '" + item.nome + "'" : '')} ${text("geral.html-title")}`} />

                <Header as="h1">
                    {text("local.atualizar-local") + (item ? " '" + item.nome + "'" : '')}
                </Header>

                {this.state.sucesses && <Message positive onDismiss={e => this.setState({ sucesses: null })}>{this.state.sucesses}</Message>}

                <Tab
                    panes={panes}
                />

            </Container >
        );
    }

}