import React, { Component } from 'react';

import { Header, Container } from 'semantic-ui-react'
import { text } from 'services/locales';
import { Helmet } from 'react-helmet'
import CadastroLocal from 'components/objetos/locais/cadastro'

export default class AdminNovoLocal extends Component {

    state = {

    };

    render() {

        return (
            <Container style={{ padding: '2em', backgroundColor: "#fff" }}>

                <Helmet title={`${text("local.novo-local")} ${text("geral.html-title")}`} />
                <Header as="h1">
                    {text("local.novo-local")}
                </Header>

                <CadastroLocal />
            </Container >
        );
    }
}