import React, { Component } from 'react';

import { Button, Header, Form, Divider, Grid, Message } from "semantic-ui-react"

import { convertDMSToDD } from 'util/functions'

import InputMask from 'react-input-mask'

import { text } from 'services/locales'

export default class Goto extends Component {

    state = {
        latitude: null,
        longitude: null,
        error: null,
    }


    handleClose = () => {
        this.props.onClose()
    }

    handleGotoLatLong = e => {
        e.preventDefault();
        const { latitude, longitude } = this.state;

        if (latitude != null && longitude != null) {

            let lat = Number(convertDMSToDD(latitude))
            let lng = Number(convertDMSToDD(longitude))

            if (lat > 90 || lng > 180 || lat < -90 || lng < -180) {
                this.setState({
                    error: text("formularios.erro_posicao")
                })
            } else {
                this.props.onGoto([lat, lng])
                this.handleClose()
            }
        } else {
            this.setState({
                error: text("formularios.erro_posicao")
            })
        }
    }

    handleCenterBrasil = () => {
        this.props.onGotoZoom([-15, -45], 5)
        this.handleClose()
    }

    handleCenterMundo = () => {
        this.props.onGotoZoom([0, 0], 3)
        this.handleClose()
    }

    render() {
        return (

            <>
                <Grid columns={2} >
                    <Grid.Column>
                        <Header inverted as="h2">{text("cenario-tatico-irpara.titulo")}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Button
                            onClick={this.handleClose}
                            inverted
                            size='tiny'
                            icon="close"
                            color="red"
                            title={text("geral.fechar")}
                        />
                    </Grid.Column>
                </Grid>

                <br />

                <Form inverted>
                    
                    <Form.Group widths='equal'>
                        <Form.Field style={{ width: '200px' }}>
                            <label> {text("geo.latitude")}</label>
                            <InputMask
                                mask={"99º99'99''a"}
                                formatChars={{ "9": "[0-9]", "a": "[S|N|s|n]" }}
                                maskChar={null}
                                placeholder={text("geo.latitude-placeholder")}
                                onChange={e => this.setState({ latitude: e.target.value, error: null })}
                            />
                        </Form.Field>
                        <Form.Field style={{ width: '200px' }}>
                            <label> {text("geo.longitude")}</label>
                            <InputMask
                                mask={"999º99'99''a"}
                                formatChars={{ "9": "[0-9]", "a": "[E|W|e|w]" }}
                                maskChar={null}
                                placeholder={text("geo.longitude-placeholder")}
                                onChange={e => this.setState({ longitude: e.target.value, error: null })}
                            />
                        </Form.Field>
                    </Form.Group>
                    {this.state.error && <Message negative onDismiss={e => this.setState({ error: null })}>{this.state.error}</Message>}
                    <Button
                        inverted
                        fluid
                        onClick={this.handleGotoLatLong}
                        disabled={!this.state.latitude || !this.state.longitude || this.state.latitude.length<11 || this.state.longitude.length<12}
                    >
                        {text("geral.ok")}
                    </Button>
                </Form>

                <Divider />


                <Header inverted as="h3">{text("cenario-tatico-irpara.regioes")}</Header>

                <Form inverted>
                    <Form.Group widths='equal'>
                        <Button
                            inverted
                            fluid
                            onClick={this.handleCenterBrasil}
                        >
                            {text("cenario-tatico-irpara.brasil")}
                        </Button>
                        <Button
                            inverted
                            fluid
                            onClick={this.handleCenterMundo}
                        >
                            {text("cenario-tatico-irpara.mundo")}
                        </Button>
                    </Form.Group>

                </Form>

            </>
        )
    }
}